import { Service } from '../../../service/model/Service';
import BaseService from '../../../service/BaseService';
import {
  RequestCreateNoticeList,
  RequestUpdateFaqList,
  RequestCreateFaqList,
  RequestUpdateNoticeList,
} from '../../reservation/model/Workplace';

export default class WorkplaceService extends BaseService {
  async getWorkplaceContentPublicById(workplaceId: string) {
    const serviceName = Service.WORKPLACE;
    const method = 'GET';

    const queryParam = {
      workplaceId: workplaceId,
    };

    const url = '/v1/workplaceContentPublic' + this.getQueryStringFormat(queryParam);

    let response;
    try {
      response = await this.fnRest(method, url, null, serviceName);
    } catch (error) {
      response = error;
    }
    return response.data && response.data.length > 0 ? response.data : null;
  }

  async createNotice(request: RequestCreateNoticeList): Promise<any> {
    const serviceName = Service.WORKPLACE;
    const method = 'POST';
    const url = '/v1/notice';
    let response;
    try {
      response = await this.fnRest(method, url, request, serviceName);
    } catch (error) {
      response = error;
    }
    return response;
  }
  async updateNotice(request: RequestUpdateNoticeList): Promise<any> {
    const serviceName = Service.WORKPLACE;
    const method = 'PATCH';
    const url = '/v1/notice';
    let response;
    try {
      response = await this.fnRest(method, url, request, serviceName);
    } catch (error) {
      response = error;
    }
    return response;
  }

  async createFaq(request: RequestCreateFaqList): Promise<any> {
    const serviceName = Service.WORKPLACE;
    const method = 'POST';
    const url = '/v1/faq';
    let response;
    try {
      response = await this.fnRest(method, url, request, serviceName);
    } catch (error) {
      response = error;
    }
    return response;
  }
  async updateFaq(request: RequestUpdateFaqList): Promise<any> {
    const serviceName = Service.WORKPLACE;
    const method = 'PATCH';
    const url = '/v1/faq';
    let response;
    try {
      response = await this.fnRest(method, url, request, serviceName);
    } catch (error) {
      response = error;
    }
    return response;
  }
}
