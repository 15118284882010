import * as yup from 'yup';
import ReservationConstants, { ImportedGoodsCodeEnum } from '../model/ReservationConstants';

let macAddressYesorno = 'N';
let isNomemberRequest = false;
export const getVisitorDetailInfoSchema = (macYn: string, _isNomemberRequest: boolean) => {
  macAddressYesorno = macYn;
  isNomemberRequest = _isNomemberRequest;
  return visitorDetailInfoSchema;
};

export const visitorDetailInfoSchema = yup.object().shape({
  name: yup.string().test('applyMemberMobilePhone', 'required', function(name) {
    const createError = this.createError;
    const path = this.path;
    if (!isNomemberRequest && (!name || name === '')) {
      return createError({
        path,
        message: 'required',
      });
    }
    return true;
  }),
  applyMemberMobilePhone: yup.string().test('applyMemberMobilePhone', 'required', function(mobile) {
    const createError = this.createError;
    const path = this.path;
    if (!isNomemberRequest && (!mobile || mobile === '')) {
      return createError({
        path,
        message: 'required',
      });
    }
    // if (
    //   mobile &&
    //   !ReservationConstants.REGEXPMOBILE_FOR_CONVERT_KO_010.test(mobile) &&
    //   !ReservationConstants.REGEXPMOBILE_FOR_CONVERT_KO_01X.test(mobile)
    // ) {
    //   return createError({
    //     path,
    //     message: 'no match',
    //   });
    // }
    return true;
  }),
  fromVisitPeriod: yup.string().nullable(),
  toVisitPeriod: yup.string().nullable(),
  applyMemberCarNumber: yup
    .string()
    .nullable()
    .test('applyMemberCarNumber', 'regExpCarNumber', function(applyMemberCarNumber) {
      const createError = this.createError;
      const path = this.path;
      if (applyMemberCarNumber && !ReservationConstants.REGEXP_CAR_NUMBER.test(applyMemberCarNumber)) {
        return createError({
          path,
          message: 'regExpCarNumber',
        });
      }
      return true;
    }),

  importedGoodsList: yup.array().of(
    yup
      .object()
      .shape({
        importedGoodsCode: yup.string(),
        importedGoodsName: yup.string(),
        importedGoodsPurpose: yup.string().required('required'),
        importedGoodsDescription: yup.string(),
        importedGoodsCounts: yup.string().required('required'),
        importedGoodsSerialNumber: yup
          .string()
          .when('importedGoodsCode', {
            is: ImportedGoodsCodeEnum.NOTEBOOK,
            then: yup.string().required('required'),
          })
          .when('importedGoodsCode', {
            is: ImportedGoodsCodeEnum.SERVER,
            then: yup.string().required('required'),
          })
          .when('importedGoodsCode', {
            is: ImportedGoodsCodeEnum.STORAGE,
            then: yup.string().required('required'),
          })
          .when('importedGoodsCode', {
            is: ImportedGoodsCodeEnum.NETWORK,
            then: yup.string().required('required'),
          }),
        importedGoodsWifiYesOrNo: yup.string(),
        importedGoodsWifiMacAddress: yup.string(),
        importedGoodsPortSealExceptYesOrNo: yup.string(),
        importedGoodsPortSealExcept: yup.string(),
        importedGoodsPortSealExceptUsb: yup.string(),
        importedGoodsPortSealExceptLan: yup.string(),
        importedGoodsPortSealExceptSdCard: yup.string(),
        importedGoodsPortSealExceptCamera: yup.string(),
        importedGoodsPortSealExceptEtc: yup.string(),
        importedGoodsPortSealExceptDescription: yup.string().when('importedGoodsPortSealExceptYesOrNo', {
          is: 'Y',
          then: yup.string().required('required'),
        }),
        importedGoodsClientCompanyName: yup
          .string()
          .when('importedGoodsCode', {
            is: ImportedGoodsCodeEnum.SERVER,
            then: yup.string().required('required'),
          })
          .when('importedGoodsCode', {
            is: ImportedGoodsCodeEnum.STORAGE,
            then: yup.string().required('required'),
          })
          .when('importedGoodsCode', {
            is: ImportedGoodsCodeEnum.NETWORK,
            then: yup.string().required('required'),
          })
          .when('importedGoodsCode', {
            is: ImportedGoodsCodeEnum.PART,
            then: yup.string().required('required'),
          }),
        importedGoodsFloor: yup
          .string()
          .when('importedGoodsCode', {
            is: ImportedGoodsCodeEnum.SERVER,
            then: yup.string().required('required'),
          })
          .when('importedGoodsCode', {
            is: ImportedGoodsCodeEnum.STORAGE,
            then: yup.string().required('required'),
          })
          .when('importedGoodsCode', {
            is: ImportedGoodsCodeEnum.NETWORK,
            then: yup.string().required('required'),
          })
          .when('importedGoodsCode', {
            is: ImportedGoodsCodeEnum.PART,
            then: yup.string().required('required'),
          }),
        importedGoodsModelName: yup
          .string()
          .when('importedGoodsCode', {
            is: ImportedGoodsCodeEnum.SERVER,
            then: yup.string().required('required'),
          })
          .when('importedGoodsCode', {
            is: ImportedGoodsCodeEnum.STORAGE,
            then: yup.string().required('required'),
          })
          .when('importedGoodsCode', {
            is: ImportedGoodsCodeEnum.NETWORK,
            then: yup.string().required('required'),
          })
          .when('importedGoodsCode', {
            is: ImportedGoodsCodeEnum.PART,
            then: yup.string().required('required'),
          }),
        importedGoodsTechPreviewYesOrNo: yup
          .string()
          .when('importedGoodsCode', {
            is: ImportedGoodsCodeEnum.SERVER,
            then: yup.string().required('required'),
          })
          .when('importedGoodsCode', {
            is: ImportedGoodsCodeEnum.STORAGE,
            then: yup.string().required('required'),
          })
          .when('importedGoodsCode', {
            is: ImportedGoodsCodeEnum.NETWORK,
            then: yup.string().required('required'),
          }),
        importedGoodsManagerSupportYesOrNo: yup
          .string()
          .when('importedGoodsCode', {
            is: ImportedGoodsCodeEnum.SERVER,
            then: yup.string().required('required'),
          })
          .when('importedGoodsCode', {
            is: ImportedGoodsCodeEnum.STORAGE,
            then: yup.string().required('required'),
          })
          .when('importedGoodsCode', {
            is: ImportedGoodsCodeEnum.NETWORK,
            then: yup.string().required('required'),
          })
          .when('importedGoodsCode', {
            is: ImportedGoodsCodeEnum.PART,
            then: yup.string().required('required'),
          }),
      })
      .test('importedGoodsList', 'required', function(importedGoods) {
        let path = this.path;
        const createError = this.createError;

        if (
          importedGoods &&
          importedGoods.importedGoodsCode === ImportedGoodsCodeEnum.NOTEBOOK &&
          macAddressYesorno === 'Y' &&
          importedGoods.importedGoodsWifiYesOrNo === 'Y'
        ) {
          if (!importedGoods.importedGoodsWifiMacAddress) {
            path += 'importedGoodsWifiMacAddress';

            return createError({
              path,
              message: 'required',
            });
          } else if (importedGoods.importedGoodsWifiMacAddress.length !== 17) {
            path += 'importedGoodsWifiMacAddress';

            return createError({
              path,
              message: 'macAddress',
            });
          }
        }
        return true;
      })
      .test('importedGoodsList', 'required', function(importedGoods) {
        let path = this.path;
        const createError = this.createError;
        if (
          importedGoods &&
          importedGoods.importedGoodsCode === ImportedGoodsCodeEnum.NOTEBOOK &&
          importedGoods.importedGoodsPortSealExceptYesOrNo === 'Y' &&
          !importedGoods.importedGoodsPortSealExceptUsb &&
          !importedGoods.importedGoodsPortSealExceptLan &&
          !importedGoods.importedGoodsPortSealExceptSdCard &&
          !importedGoods.importedGoodsPortSealExceptCamera &&
          !importedGoods.importedGoodsPortSealExceptEtc
        ) {
          path += 'importedGoodsPortSealExcept';
          return createError({
            path,
            message: 'required',
          });
        }
        return true;
      })
  ),
});
