import React from 'react';
import './MainSection.scss';
import { useTranslation } from 'react-i18next';
import { Container, Row } from 'react-bootstrap';
import MemberRegistrationForm from './MemberRegistrationForm';
import MemberRegistrationService from '../../services/MemberRegistrationService';
import StepInformation from '../../model/StepInformation';
import EmailCertification from './EmailCertification';
import { TermsAcception } from './TermsAcception';
import TermService from '../../services/TermService';
import { UserTypeParamEnum } from '../../model/MemberRegistrationConstants';
import { EmployeeMemberRegistrationForm } from './EmployeeMemberRegistrationForm';

export interface MainSectionProps {
  setStepParams: (stepParams: StepInformation) => void;
  stepParams: StepInformation;
}
export const MainSection: React.FC<MainSectionProps> = (props: MainSectionProps) => {
  const { t } = useTranslation();
  return (
    <div id="MainSection">
      <Container>
        <Row>
          <h1 className={'step' + props.stepParams.currentStep}>
            {t('member.label.mainSection.boldTitle' + props.stepParams.currentStep)}
          </h1>
          <h2 className={'step' + props.stepParams.currentStep}>
            {t('member.label.mainSection.subTitle' + props.stepParams.currentStep)}
          </h2>
        </Row>
        <Row>
          {props.stepParams.currentStep === 1 && (
            <TermsAcception
              stepParams={props.stepParams}
              setStepParams={props.setStepParams}
              termService={new TermService()}
              memberRegistrationService={new MemberRegistrationService()}
            />
          )}
          {props.stepParams.currentStep === 2 && props.stepParams.memberType === UserTypeParamEnum.OUTSIDER_MEMBER && (
            <MemberRegistrationForm
              stepParams={props.stepParams}
              setStepParams={props.setStepParams}
              memberRegistrationService={new MemberRegistrationService()}
            />
          )}
          {props.stepParams.currentStep === 2 &&
            (props.stepParams.memberType === UserTypeParamEnum.NON_SSO_EMPLOYEE ||
              props.stepParams.memberType === UserTypeParamEnum.SSO_EMPLOYEE) && (
              <EmployeeMemberRegistrationForm
                stepParams={props.stepParams}
                setStepParams={props.setStepParams}
                memberRegistrationService={new MemberRegistrationService()}
              />
            )}
          {(props.stepParams.currentStep === 3 || props.stepParams.currentStep === 4) && (
            <EmailCertification stepParams={props.stepParams} />
          )}
        </Row>
      </Container>
    </div>
  );
};
export default MainSection;
