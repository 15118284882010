import React, { ReactElement } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './AlertModal.scss';

export interface AlertModalProps {
  message: string;
  show: boolean;
  onClose: Function;
}

export const AlertModal: React.FC<AlertModalProps> = (props: AlertModalProps): ReactElement => {
  const { t } = useTranslation();
  const onClose = () => {
    props.onClose();
  };
  return (
    <Modal
      id="alertModal"
      data-testid="alertModal"
      show={props.show}
      onHide={onClose}
      className="AlertModal"
      enforceFocus={false}
      centered
    >
      <Modal.Body>
        <p id="alertMessage" data-testid="alertMessage">
          {props.message}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button id="closeButton" data-testid="closeButton" variant="primary" onClick={onClose}>
          {t('common.label.AlertModal.button.confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AlertModal;
