export default class ExcelFileConfig {
  public static readonly TEMPLATE_VISITOR_FILE_NAME: string = 'template_visitor.xlsx';
  public static readonly BLOB_TYPE: string =
    "blobType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';";
  public static readonly HEADER_ROW: number = 40;
  public static readonly TEMPLATE_CREATOR: string = 'v-portal';
  public static readonly EXCEL_HEADER_START_COLUMN: number = 65; // A
  public static readonly EXCEL_HEADER_START_ROW: string = '1';
  public static readonly EXCEL_WIDTH_KEY: string = 'Width';
  public static readonly CELL_HORIZEN_ALIGN: string = 'center';
  public static readonly ACCEPT_EXTENSION: string = 'xlsx';
}

export enum ExcelTemplateHeader {
  NAME = 'visitorName',
  MOBILE = 'mobile',
  POSITION = 'position',
  COMPANY = 'company',
  VISIT_START = 'visitStart',
  VISIT_END = 'visitEnd',
}

export enum ExcelTemplateHeaderWidth {
  NAME = 30,
  MOBILE = 30,
  POSITION = 30,
  COMPANY = 30,
  VISIT_START = 30,
  VISIT_END = 30,
}

export class ExcelHeaderInfo {
  public static readonly visitorName: string =
    'reservation.label.ReservationBottomForms.visitors.uploadModal.template.header.name';
  public static readonly mobile: string =
    'reservation.label.ReservationBottomForms.visitors.uploadModal.template.header.mobile';
  public static readonly position: string =
    'reservation.label.ReservationBottomForms.visitors.uploadModal.template.header.position';
  public static readonly company: string =
    'reservation.label.ReservationBottomForms.visitors.uploadModal.template.header.company';
  public static readonly visitStart: string =
    'reservation.label.ReservationBottomForms.visitors.uploadModal.template.header.visitStart';
  public static readonly visitEnd: string =
    'reservation.label.ReservationBottomForms.visitors.uploadModal.template.header.visitEnd';
  public static readonly visitorNameWidth: number = 30;
  public static readonly mobileWidth: number = 30;
  public static readonly positionWidth: number = 30;
  public static readonly companyWidth: number = 30;
  public static readonly visitStartWidth: number = 30;
  public static readonly visitEndWidth: number = 30;
}

export class ExcelValidationMsg {
  public static readonly invalidMobile: string =
    'reservation.label.ReservationBottomForms.visitors.uploadModal.visitorListTable.validation.message.mobile';
  public static readonly invalidPeriod: string =
    'reservation.label.ReservationBottomForms.visitors.uploadModal.visitorListTable.validation.message.visitPeriod';
  public static readonly requiredName: string =
    'reservation.label.ReservationBottomForms.visitors.uploadModal.visitorListTable.validation.message.requiredName';
  public static readonly requiredMobile: string =
    'reservation.label.ReservationBottomForms.visitors.uploadModal.visitorListTable.validation.message.requiredMobile';
  public static readonly requiredCompany: string =
    'reservation.label.ReservationBottomForms.visitors.uploadModal.visitorListTable.validation.message.requiredCompany';
  public static readonly requiredPeriod: string =
    'reservation.label.ReservationBottomForms.visitors.uploadModal.visitorListTable.validation.message.requiredPeriod';
  public static readonly maxVisitPeriod: string =
    'reservation.label.ReservationBottomForms.visitors.uploadModal.visitorListTable.validation.message.maxVisitPeriod';
  public static readonly invalidStartDate: string =
    'reservation.label.ReservationBottomForms.visitors.uploadModal.visitorListTable.validation.message.startDate';
}

export interface ExcelData {
  no: number;
  visitorName: string;
  mobile: string;
  position: string;
  company: string;
  visitStart: string;
  visitEnd: string;
  isSelected: boolean;
  invalidReason?: string;
}

export interface ImportFileInfo {
  fileName: string;
  fileSize: number;
  fileType: string;
}

export interface AlertProps {
  setAlertMessage: Function;
  setIsShowAlert: Function;
}
