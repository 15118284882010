import React, { useState, useContext } from 'react';
import './MemberRegistrationForm.scss';
import { useTranslation } from 'react-i18next';
import { Button, Form, Col } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik, Field, FormikErrors, FormikValues } from 'formik';
import SubmitStatus from '../../model/SubmitStatus';
import MemberRegistrationService from '../../services/MemberRegistrationService';
import Member from '../../model/Member';
import MemberRegistrationConstants from '../../model/MemberRegistrationConstants';
import StepInformation from '../../model/StepInformation';
import MemberRegistrationSubmitStatus from '../../model/MemberRegistrationSubmitStatus';
import PhoneCertification from './PhoneCertification';
import { BusinessTypeCode } from '../../model/CommonConstants';
import ReservationConstants from '../../../reservation/model/ReservationConstants';
import { AuthContext } from '../../../../App';
import { ActionType } from '../../../../reducer/actions';
import { useHistory } from 'react-router-dom';

export interface MemberRegistrationFormProps {
  stepParams: StepInformation;
  setStepParams: (stepParams: StepInformation) => void;
  memberRegistrationService: MemberRegistrationService;
}
export const MemberRegistrationForm: React.FC<MemberRegistrationFormProps> = (props: MemberRegistrationFormProps) => {
  const { t } = useTranslation();
  const { dispatch } = useContext(AuthContext);
  const [receivedCertificationId, setCertificationId] = useState('');
  const [certificationConfirmStatus, setCertificationConfirmStatus] = useState(false);
  const [emailCheckValidationFlag, setEmailCheckValidationFlag] = useState(false);
  const [emailCheckVisible, setEmailCheckVisible] = useState(false);
  const [emailDuplicateStatus, setEmailDuplicateStatus] = useState(false);
  const [emailEmpStatus, setEmailEmpStatus] = useState(false);

  const setLoading = (_isLoading: boolean) => {
    dispatch({ type: ActionType.LOADING, loading: { isLoading: _isLoading } });
  };
  const schema = yup.object({
    password: yup
      .string()
      .required(t('member.message.frontEnd.password.required'))
      .min(8, t('member.message.frontEnd.password.common'))
      .max(32, t('member.message.frontEnd.password.common'))
      .matches(MemberRegistrationConstants.REGEXP_PASSWORD, t('member.message.frontEnd.password.common'))
      .test('password-contains-email-id', t('member.message.frontEnd.password.containsEmailId'), function(
        value: string
      ) {
        const email = this.resolve(yup.ref('emailAddress'));
        const emailId = email ? email.split('@')[0] : undefined;
        return value && value.includes(emailId) ? false : true;
      }),
    passwordConfirm: yup
      .string()
      .required(t('member.message.frontEnd.password.common'))
      .oneOf([yup.ref('password'), null], t('member.message.frontEnd.password.common')),
    phoneNumber: yup
      .string()
      .required(t('member.message.frontEnd.phoneNumber.required'))
      .matches(ReservationConstants.REGEXP_MOBILE, t('member.message.frontEnd.phoneNumber.common')),
    certificationKey: yup
      .string()
      .required(t('member.message.frontEnd.certificationKey.required'))
      .min(6, t('member.message.frontEnd.certificationKey.common'))
      .max(6, t('member.message.frontEnd.certificationKey.common')),
    memberName: yup
      .string()
      .required(t('member.message.frontEnd.memberName.required'))
      .min(2, t('member.message.frontEnd.memberName.common')),
  });

  const checkEmailValidation = async (values: string, setFieldError: any): Promise<void> => {
    const emailAddress = values;
    const emailRule = MemberRegistrationConstants.REGEXP_EMAIL;

    if (emailRule.test(emailAddress)) {
      setEmailCheckValidationFlag(false);
      setEmailCheckVisible(false);
      setEmailDuplicateStatus(false);
      setFieldError('emailAddress', undefined);
      let submitStatus: SubmitStatus = {
        successOrNot: 'N',
        statusCode: '',
      };
      submitStatus = await props.memberRegistrationService.checkExistFlagEmailAddress(
        emailAddress,
        MemberRegistrationConstants.DEFAULT_MEMBER_TYPE_CODE
      );

      if (submitStatus.successOrNot === 'Y') {
        setEmailCheckValidationFlag(true);
        setEmailCheckVisible(true);
        setEmailDuplicateStatus(false);
        setEmailEmpStatus(false);
        setFieldError('emailAddress', undefined);
      } else if (submitStatus.statusCode === MemberRegistrationConstants.UNKNOWN_SERVER_ERROR_CODE) {
        setEmailCheckValidationFlag(false);
        setEmailCheckVisible(false);
        setEmailDuplicateStatus(false);
        setEmailEmpStatus(false);
        alert(t('member.message.backEnd.UE'));
      } else if (submitStatus.statusCode === MemberRegistrationConstants.NOT_ALLOW_EMP_EMAIL) {
        setEmailCheckValidationFlag(false);
        setEmailCheckVisible(false);
        setEmailDuplicateStatus(false);
        setEmailEmpStatus(true);
        setFieldError('emailAddress', t('member.message.backEnd.' + submitStatus.statusCode));
      } else {
        setEmailCheckValidationFlag(false);
        setEmailCheckVisible(false);
        setEmailDuplicateStatus(true);
        setEmailEmpStatus(false);
        setFieldError('emailAddress', t('member.message.backEnd.' + submitStatus.statusCode));
      }
    } else {
      setFieldError(t('member.message.frontEnd.emailAddress.common'));
    }
  };
  const validateEmail = (values: string): FormikErrors<FormikValues> => {
    const emailRule = MemberRegistrationConstants.REGEXP_EMAIL;
    const error: any = {};
    if (!emailRule.test(values)) {
      setEmailCheckVisible(false);
      setEmailCheckValidationFlag(false);
      setEmailDuplicateStatus(false);
      setEmailEmpStatus(false);
      error.email = t('member.message.frontEnd.emailAddress.common');
    } else {
      if (!emailCheckValidationFlag && emailDuplicateStatus) {
        error.email = t('member.message.backEnd.DUPLICATION.EMAIL');
        setEmailCheckVisible(false);
      } else if (!emailCheckValidationFlag && emailEmpStatus) {
        error.email = t('member.message.backEnd.NOT.ALLOW.EMP_EMAIL');
        setEmailCheckVisible(false);
      }
    }
    return error.email;
  };
  const history = useHistory();

  return (
    <div id="MainInformationForm">
      <Formik
        initialValues={{
          emailAddress: '',
          password: '',
          passwordConfirm: '',
          phoneNumber: '',
          certificationId: '',
          certificationKey: '',
          memberName: '',
          companyName: '',
          positionName: '',
          certificationSendAddress: '',
          memberStateCode: MemberRegistrationConstants.DEFAULT_MEMBER_STATE_CODE,
          memberTypeCode: MemberRegistrationConstants.DEFAULT_MEMBER_TYPE_CODE,
          termsApplyGroupCode: props.stepParams.termAgreeInfo?.termsApplyGroupCode,
          termsAgree: props.stepParams.termAgreeInfo?.termsAgree,
        }}
        onSubmit={async values => {
          values.certificationId = receivedCertificationId;
          const member: Member = { ...values, phoneNumber: values.phoneNumber.replace(/-/gi, '') };

          setLoading(true);
          const submitStatus: MemberRegistrationSubmitStatus = await props.memberRegistrationService.registMember(
            member
          );
          setLoading(false);
          if (submitStatus.successOrNot === 'Y') {
            const stepParams: StepInformation = {
              currentStep: 3,
              emailAddress: values.emailAddress,
              memberId: submitStatus.data!.memberId,
            };
            history.push(history.location.pathname + history.location.search + '#step3');
            props.setStepParams(stepParams);
          } else if (
            submitStatus.successOrNot === 'N' &&
            submitStatus.statusCode === MemberRegistrationConstants.UNKNOWN_SERVER_ERROR_CODE
          ) {
            alert(t('member.message.backEnd.UE'));
          } else {
            alert(t('member.message.backEnd.' + submitStatus.statusCode));
          }
        }}
        validationSchema={schema}
      >
        {({
          values,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldError,
          setFieldValue,
          isValid,
        }): any => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Row>
              <Form.Group>
                <Form.Label>{t('member.label.memberRegistrationForm.emailAddress.label')}</Form.Label>
                <br />
                <Field
                  id="emailAddress"
                  name="emailAddress"
                  data-testid="emailAddress"
                  className="formik-field"
                  required
                  type="text"
                  placeholder={t('member.label.memberRegistrationForm.emailAddress.placeholder')}
                  onChange={handleChange}
                  onBlur={async (): Promise<void> => {
                    await checkEmailValidation(values.emailAddress, setFieldError);
                  }}
                  validate={validateEmail}
                />
                {emailCheckVisible && (
                  <div className="checked-area">
                    <i id="email-checked" data-testid="email-checked" className="material-icons">
                      check
                    </i>
                  </div>
                )}
                {errors.emailAddress && (
                  <div id="emailAddressFeedback" data-testid="emailAddressFeedback" className="input-feedback">
                    {errors.emailAddress}
                  </div>
                )}
              </Form.Group>
            </Form.Row>
            <Form.Row className="top-empty-space">
              <Form.Group>
                <Form.Label>{t('member.label.memberRegistrationForm.password.label')}</Form.Label>
                <Form.Control
                  id="password"
                  name="password"
                  data-testid="password"
                  required
                  type="password"
                  placeholder={t('member.label.memberRegistrationForm.password.placeholder1')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.password}
                />
                <Form.Control
                  id="passwordConfirm"
                  name="passwordConfirm"
                  data-testid="passwordConfirm"
                  required
                  type="password"
                  placeholder={t('member.label.memberRegistrationForm.password.placeholder2')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.passwordConfirm}
                />
                <Form.Control.Feedback id="passwordFeedback" data-testid="passwordFeedback" type="invalid">
                  {errors.password || errors.passwordConfirm}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row className="top-empty-space">
              <Form.Group>
                <Form.Label>{t('member.label.memberRegistrationForm.memberName.label')}</Form.Label>
                <Form.Control
                  id="memberName"
                  name="memberName"
                  data-testid="memberName"
                  required
                  type="text"
                  placeholder={t('member.label.memberRegistrationForm.memberName.placeholder')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.memberName}
                  maxLength={MemberRegistrationConstants.NAME_MAX_LENGTH}
                />
                <Form.Control.Feedback id="memberNameFeedback" data-testid="memberNameFeedback" type="invalid">
                  {errors.memberName}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <PhoneCertification
                phoneCertificationFormik={{
                  handleChange: handleChange,
                  // handleChange: phoneNumber => setValues({...values, phoneNumber: phoneNumber}),
                  handleBlur: handleBlur,
                  errors: errors,
                  values: values,
                  setFieldError: setFieldError,
                  setFieldValue: setFieldValue,
                }}
                receivedCertificationId={receivedCertificationId}
                setCertificationId={setCertificationId}
                certificationConfirmStatus={certificationConfirmStatus}
                setCertificationConfirmStatus={setCertificationConfirmStatus}
                certificationBusinessTypeCode={BusinessTypeCode.JOIN}
                memberRegistrationService={props.memberRegistrationService}
              />
            </Form.Row>
            <Form.Row className="top-empty-space">
              <Form.Label>{t('member.label.memberRegistrationForm.companyInformation.label')}</Form.Label>
            </Form.Row>

            <Form.Row className="">
              <Form.Group as={Col} md="8" xs="8" sm="8">
                <Form.Control
                  id="companyName"
                  name="companyName"
                  data-testid="companyName"
                  required
                  type="text"
                  placeholder={t('member.label.memberRegistrationForm.companyInformation.placeholder1')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.companyName}
                  maxLength={50}
                />
              </Form.Group>
              <Form.Group as={Col} md="4" xs="4" sm="4">
                <Form.Control
                  id="positionName"
                  name="positionName"
                  data-testid="positionName"
                  required
                  type="text"
                  placeholder={t('member.label.memberRegistrationForm.companyInformation.placeholder2')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.companyName}
                  className=""
                  maxLength={50}
                />
              </Form.Group>
            </Form.Row>
            <Button
              id="registrationButton"
              name="registrationButton"
              data-testid="registrationButton"
              type="submit"
              disabled={
                isSubmitting ||
                !isValid ||
                !certificationConfirmStatus ||
                !emailCheckValidationFlag ||
                emailDuplicateStatus ||
                emailEmpStatus
              }
              className="submit-button top-empty-space"
            >
              {t('member.label.memberRegistrationForm.button.registration')}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default MemberRegistrationForm;
