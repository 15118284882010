import { ImportedGoods } from './ReservationTypes';

export interface ReservationResponse {
  visitRequestId: string;
  visitGroupCompanyId: string;
  visitWorkplaceId: string;
  visitWorkplace: string;
  applyMemberDivision: string;
  applyMemberId: string;
  applyMemberName: string;
  applyMemberEmployeeNumber: string;
  applyMemberSequence?: string;
  applyMemberMobilePhone: string;
  applyMemberCompanyName: string;
  applyMemberDepartment?: string;
  applyMemberPosition?: string;
  applyMemberQrcodeYn?: string;
  hostEmployeeCompanyId: string;
  hostEmployeeCompanyName?: string;
  hostEmployeeId: string;
  hostEmployeeNumber: string;
  hostEmployeeName: string;
  hostEmployeeDepartment?: string;
  hostEmployeePosition?: string;
  fromVisitPeriod: string;
  toVisitPeriod: string;
  visitPurposeCode: string;
  visitPurposeDetail: string;
  applyDate: string;
  approvalStatus: string;
  approvalMessage?: string;
  workplaceInfo?: string;
  workplaceContent?: string;
  lastUpdateDate?: string;
  entryRequestSequence?: string;
  importedGoodsSummary?: GoodsSummary[];
  applyMemberList?: VisitorResponse[];
  successYesOrNo?: string;
  failMessage?: string;
  errorDetailData?: ReservationErrorDetail;
  showDetailError?: boolean;
  sendTalkMessageYesorno?: string;
}
export interface ReservationErrorDetail {
  detailStatusCode: string;
  detailStatusMessage: string;
  displayMessage: ErrorDisplayMessage;
  target: string;
}

export interface ErrorDisplayMessage {
  meaning: string;
}
export interface VisitorResponse {
  visitRequestId: string;
  applyMemberSequence: string;
  applyMemberDivision: string;
  applyMemberId: string;
  applyMemberName: string;
  applyMemberEmployeeNumber?: string;
  applyMemberMobilePhone: string;
  applyMemberCompanyName?: string;
  applyMemberDepartment?: string;
  applyMemberPosition?: string;
  applyMemberCarNumber?: string;
  applyMemberEmail?: string;
  applyMemberQrcodeYn?: string;
  fromVisitPeriod: string;
  toVisitPeriod: string;
  importedGoodsYesOrNo: string;
  importedGoodsList?: ImportedGoods[];
  applyMemberVisitYesorno?: string;
}

export interface GoodsSummary {
  importedGoodsCode: string;
  importedGoodsCounts: string;
}

export interface ApproveReservationRequest {
  visitRequestId: string;
  visitGroupCompanyId: string;
  visitWorkplaceId: string;
  visitWorkplace: string;
  approvalStatus: string;
  approvalMessage: string;
}

export interface ApproveReservationResponse {
  visitRequestId: string;
  visitGroupCompanyId: string;
  visitWorkplaceId: string;
  visitWorkplace: string;
  successYesOrNo: string;
  statusCode: string;
  statusMessage: string;
}

export enum QueryType {
  REQUEST = 'REQUEST',
  APPROVAL = 'APPROVAL',
}

export interface VisitRequestByQrcodeModel {
  visitRequestId: string;
  applyMemberSequence: string;
  qrString: string;
  memberId: string;
  tenantId: string;
  receiveMobileNo: string;
  workplaceId: string;
  visitWorkplace: string;
  hostEmployeeCompanyName: string;
  hostEmployeeName: string;
  applyMemberName: string;
  applyMemberDivision?: string;
  qrcodeLimitTime: string;
  fromMobile?: boolean;
}

export interface ResponseVisitEntrySequence {
  entryRequestSequence: string;
  qrcodeLimitTime: string;
}
