import React, { ReactElement, useState, useEffect } from 'react';
import { Container, Row, Col, Accordion, Card, Button, useAccordionToggle } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { Visitor, ImportedGoods } from '../model/ReservationTypes';
import { ReservationResponse, VisitorResponse } from '../model/Reservation';
import { User } from '../model/User';

import CommonCodeService from '../services/CommonCodeService';

import './ModalVisitors.scss';
import ReservationBottomVisitor from './ReservationBottomVisitor';
import { VisitorGoodsDetail } from './VisitorGoodsDetail';
import { visitorDetailInfoSchema } from '../validation/VisitorDetailValidationSchema';
import { ApprovalStatusEnum } from '../model/ReservationConstants';

import moment from 'moment';

export interface ModalVisitors {
  reservation: ReservationResponse;
  sessionUser: User;
  commonCodeMap: any;
  checkVisitorModalClose?: Function;
  checkVisitorModalShow?: Function;
}

export const ModalVisitors: React.FC<ModalVisitors> = ({
  reservation,
  sessionUser,
  commonCodeMap,
  checkVisitorModalClose,
  checkVisitorModalShow,
}: ModalVisitors): ReactElement => {
  const { t } = useTranslation();

  const getCommonCode = (groupCodeId: string) => {
    if (commonCodeMap) {
      return commonCodeMap.get(groupCodeId);
    }
  };

  const [otherCollapsed, setOtherCollapsed] = useState('init');

  const [qrcodeHistory, setQrcodeHistory] = useState<any>([]);

  const closeOtherToggle = (presentToggle: string) => {
    setOtherCollapsed(presentToggle);
  };

  /* eslint-disable */
  const dateFormat = (date: string) => {
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  }

  return (
    <div className="divBgGray" data-testid="reservation-modal-visitors">
      <Container className="visitorsContainer">
        <Row className="RMB15 visitor-title-row">
          <Col>
            <span className="visitor-title title">{t('reservation.label.ModalVisitors.body.bottom.title')}</span>
            <span className="title">(</span>
            <span className="title visitor-title-val">
              {reservation.applyMemberList ? reservation.applyMemberList.length : 0}
            </span>
            <span className="title">)</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <div key="visitorList" className="visitorList">
              <Accordion>
                {sessionUser &&
                  reservation.applyMemberList &&
                  reservation.applyMemberList.map((value: VisitorResponse, i) => {
                    const visitor: Visitor = {
                      id: value.applyMemberId,
                      type: value.applyMemberDivision,
                      name: value.applyMemberName,
                      visitRequestId: value.visitRequestId,
                      employeeNumber: value.applyMemberEmployeeNumber,
                      companyName: value.applyMemberCompanyName || '',
                      dept: value.applyMemberDepartment || '',
                      position: value.applyMemberPosition || '',
                      applyMemberMobilePhone: value.applyMemberMobilePhone,
                      applyMemberQrcodeYn: value.applyMemberQrcodeYn,
                      applyMemberSequence: value.applyMemberSequence,
                      applyMemberVisitYesorno: value.applyMemberVisitYesorno,
                      email: value.applyMemberEmail,
                      applyMemberCarNumber: value.applyMemberCarNumber,
                      fromVisitPeriod: value.fromVisitPeriod,
                      toVisitPeriod: value.toVisitPeriod,
                      importedGoodsList: value.importedGoodsList || [],
                    };
                    
                    return (
                      <div key={'visitorListDiv' + '_' + i}>
                        <div key={'visitorDiv' + '_' + i}>
                          <ReservationBottomVisitor
                            className="visitorDiv"
                            closeOtherToggle={closeOtherToggle}
                            setQrcodeHistory={setQrcodeHistory}
                            qrcodeHistory={qrcodeHistory}
                            otherCollapsed={otherCollapsed}
                            checkVisitorModalClose={checkVisitorModalClose}
                            checkVisitorModalShow={checkVisitorModalShow}
                            {...{
                              visitor: visitor,
                              fromVisitPeriod: reservation.fromVisitPeriod,
                              toVisitPeriod: reservation.toVisitPeriod,
                              sessionUser: sessionUser,
                              pageType: 'showDetail',
                              eventKey: 'toggle_' + value.applyMemberSequence,
                              visitRequestId: value.visitRequestId,
                            }}
                          />
                        </div>
                        <Accordion.Collapse eventKey={'toggle_' + value.applyMemberSequence}>
                          <div className="detailsDiv">
                            <div className="qrHistoryInfo">
                              <span>{t('reservation.label.ReservationBottomVisitor.body.qrcodeHistory')}</span>
                                {
                                  visitor.applyMemberQrcodeYn === 'Y' ? (
                                  <Card>
                                    <Card.Body>
                                      <table className="table">
                                        <colgroup>
                                          <col width="30%" />
                                          <col width="70%" />
                                        </colgroup>
                                        <tbody>
                                          {
                                            qrcodeHistory.length > 0 && 
                                            qrcodeHistory.map((item: any) => {
                                              return (
                                                <tr>
                                                  <td className="qrcodeHistory subtitle">
                                                    {t('reservation.label.ReservationBottomVisitor.body.qrcodeRequestDate')}
                                                  </td>
                                                  <td className="qrcodeHistory">{dateFormat(item.requestDateTime)}</td>
                                                </tr>
                                              )
                                            })
                                          }
                                        </tbody>
                                      </table>
                                    </Card.Body>
                                  </Card>
                                  ) : 
                                  <Card>
                                    <Card.Body>
                                      <span className="disabled">{t('reservation.label.ReservationBottomVisitor.body.qrcodeHistoryEmpty')}</span>
                                    </Card.Body>
                                  </Card>
                              }
                              <br />
                            </div>
                            {((reservation.workplaceInfo &&
                              JSON.parse(reservation.workplaceInfo).parkingAbleYesorno === 'Y') ||
                              (reservation.approvalStatus === ApprovalStatusEnum.IP016001 &&
                                visitor.applyMemberCarNumber)) && (
                              <div id="parkingInfo">
                                <span>{t('reservation.label.VisitorGoodsDetail.body.car.title')}</span>
                                <Card>
                                  <Card.Body>
                                    {visitor.applyMemberCarNumber ? (
                                      <span className="carInfo">{visitor.applyMemberCarNumber}</span>
                                    ) : (
                                      <span className="disabled">
                                        {t('reservation.label.VisitorGoodsDetail.body.car.empty')}
                                      </span>
                                    )}
                                  </Card.Body>
                                </Card>
                                <br />
                              </div>
                            )}
                            {visitor.importedGoodsList && visitor.importedGoodsList.length > 0 ? (
                              <span>{t('reservation.label.VisitorGoodsDetail.body.importedGoods.title')}</span>
                            ) : (
                              <div id="importedGoodsDiv">
                                <span>{t('reservation.label.VisitorGoodsDetail.body.importedGoods.title')}</span>
                                <Card>
                                  <Card.Body>
                                    <span className="disabled">
                                      {t('reservation.label.VisitorGoodsDetail.body.importedGoods.empty')}
                                    </span>
                                  </Card.Body>
                                </Card>
                              </div>
                            )}
                            {visitor.importedGoodsList &&
                              visitor.importedGoodsList.map((product: ImportedGoods, i) => {
                                return (
                                  <div key={'goodsDiv' + visitor.id + '_' + i} className="goodsDiv">
                                    <VisitorGoodsDetail
                                      className="goodsDiv"
                                      getCommonCode={getCommonCode}
                                      {...{
                                        product: product,
                                        carNumber: visitor.applyMemberCarNumber || '',
                                        index: i,
                                      }}
                                    />
                                    <br />
                                  </div>
                                );
                              })}
                          </div>
                        </Accordion.Collapse>
                      </div>
                    );
                  })}
              </Accordion>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ModalVisitors;
