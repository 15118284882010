import React, { useContext, useState } from 'react';
import './EmailCertification.scss';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import StepInformation from '../../model/StepInformation';
import { AuthContext } from '../../../../App';
import CertificationRequest from '../../model/CertificationRequest';
import { CertificationTypeCode } from '../../model/MemberRegistrationConstants';
import MemberRegistrationService from '../../services/MemberRegistrationService';
import { BusinessTypeCode } from '../../model/CommonConstants';
import { ActionType } from '../../../../reducer/actions';
import AlertModal from '../../../../layouts/AlertModal';

export interface EmailCertificationProps {
  stepParams: StepInformation;
}
export const EmailCertification: React.FC<EmailCertificationProps> = (props: EmailCertificationProps) => {
  const { t } = useTranslation();
  const { dispatch } = useContext(AuthContext);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const setLoading = (_isLoading: boolean) => {
    dispatch({ type: ActionType.LOADING, loading: { isLoading: _isLoading } });
  };
  const hideAlert = () => {
    setShowAlert(false);
  };
  const requestCertificationKey = async (): Promise<void> => {
    const certificationRequest: CertificationRequest = {
      certificationTypeCode: CertificationTypeCode.EMAIL,
      certificationSendAddress: props.stepParams.emailAddress + '',
      certificationBusinessTypeCode: BusinessTypeCode.JOIN,
      memberId: props.stepParams.memberId,
    };
    const memberRegistrationService = new MemberRegistrationService();
    setLoading(true);
    const submitStatus = await memberRegistrationService.requestCertificationKey(certificationRequest);
    setLoading(false);
    if (submitStatus.successOrNot === 'Y') {
      setAlertMessage(t('member.label.emailCertification.success'));
    } else {
      setAlertMessage(t('member.label.emailCertification.fail'));
    }
    setShowAlert(true);
  };
  return (
    <div id="EmailCertification">
      <AlertModal message={alertMessage} show={showAlert} onClose={hideAlert} />
      <Container>
        <Row>
          <span id="emailAddressLabel" data-testid="emailAddressLabel" className="emailAddressLabel">
            {t('member.label.emailCertification.emailAddressLabel')}
          </span>
        </Row>
        <Row>
          <span id="emailAddress" data-testid="emailAddress" className="emailAddress">
            {props.stepParams.emailAddress}
          </span>
        </Row>
        <Row>
          <Col sm={1}>
            <i id="firstCheckIcons" data-testid="firstCheckIcons" className="material-icons">
              check
            </i>
          </Col>
          <Col sm={11}>
            <p
              id="firstNotification"
              data-testid="firstNotification"
              dangerouslySetInnerHTML={{
                __html: t('member.label.emailCertification.notification1', {
                  interpolation: { escapeValue: false },
                }),
              }}
            ></p>
          </Col>
        </Row>
        <Row>
          <Col sm={1}>
            <i id="secondCheckIcons" data-testid="secondCheckIcons" className="material-icons">
              check
            </i>
          </Col>
          <Col sm={11}>
            <p
              id="secondNotification"
              data-testid="secondNotification"
              dangerouslySetInnerHTML={{
                __html: t('member.label.emailCertification.notification2', {
                  interpolation: { escapeValue: false },
                }),
              }}
            ></p>
          </Col>
        </Row>
        <Row>
          <Link to="/login">
            <Button
              id="loginShortcutButton"
              name="loginShortcutButton"
              data-testid="loginShortcutButton"
              variant="primary"
            >
              {t('member.label.emailCertification.button.loginShortcut')}
            </Button>
          </Link>

          <Button
            id="resendEmailCertificationButton"
            name="resendEmailCertificationButton"
            data-testid="resendEmailCertificationButton"
            variant="outline-primary"
            onClick={(): Promise<void> => requestCertificationKey()}
          >
            {t('member.label.emailCertification.button.resendEmailCertification')}
          </Button>
        </Row>
      </Container>
    </div>
  );
};
export default EmailCertification;
