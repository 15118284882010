import React, { useContext } from 'react';

import { Modal, Button, Form, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaqList } from '../model/Workplace';

import { Formik } from 'formik';
import 'react-datepicker/dist/react-datepicker.css';
import './FAQModifyModal.scss';
import * as yup from 'yup';
// import WorkplaceService from '../../workplace/workplaceManagement/services/WorkplaceService';
import WorkplaceService from '../services/WorkplaceService';
import { AuthContext } from '../../../App';
import { ActionType } from '../../../reducer/actions';

export interface FAQModifyModalI {
  faq: FaqList | null;
  onHide: (faq: FaqList) => void;
}

export const FAQModifyModal: React.FC<FAQModifyModalI> = (props: FAQModifyModalI) => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AuthContext);
  const workplaceService = new WorkplaceService();

  const callUploadService = async (uploadFile: File) => {
    return await workplaceService.uploadPolicyContents('faq', uploadFile);
  };
  const setLoading = (_isLoading: boolean) => {
    dispatch({ type: ActionType.LOADING, loading: { isLoading: _isLoading } });
  };

  const handleChangeFile = async (event: any) => {
    const result = {
      uploadResult: 'FAIL',
      contentsUrl: '',
      fileName: '',
      fileSize: 0,
      fileType: '',
    };
    if (event.target.files && event.target.files[0]) {
      const uploadFile: File = event.target.files[0];

      const response = await callUploadService(uploadFile);
      if (response && response.contentsUrl) {
        result.uploadResult = 'SUCCESS';
        result.contentsUrl = response.contentsUrl;
        result.fileName = uploadFile.name;
        result.fileSize = uploadFile.size;
        result.fileType = uploadFile.type;
      }
      //}
    }
    return result;
  };
  const schema = yup.object({
    faqTitle: yup
      .string()
      .required()
      .max(1500),
    faqContent: yup
      .string()
      .required()
      .max(4000),
  });

  const path = process.env.REACT_APP_S3_FILE_DOWNLOAD_URL || '';

  const filesizeFormatter = (bytes: number): string => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return '(' + Math.round((bytes / Math.pow(1024, i)) * 100) / 100 + sizes[i] + ')';
  };

  const initialValues: FaqList = {
    faqId: '',
    faqTitle: '',
    faqContent: '',
    registerDate: '',
    fileId: '',
    fileName: '',
    fileOriginalName: '',
    filePath: '',
    fileExtension: '',
    fileSize: -1,
    useYn: 'Y',
  };
  return (
    <Modal
      show={true}
      onHide={props.onHide}
      centered={true}
      className="faq-modify-modal"
      data-testid="faq-modify-modal"
      id="FAQModifyModal"
    >
      <Modal.Header closeButton />

      <Modal.Body>
        <Formik
          validateOnMount
          initialValues={props.faq ? props.faq : initialValues}
          onSubmit={values => {
            console.log(values);
          }}
          validationSchema={schema}
        >
          {({
            values,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldError,
            setFieldValue,
            isValid,
          }): any => (
            <Form>
              <Form.Row>
                <Col sm={2}>제목</Col>
                <Col>
                  <Form.Control
                    id="faqTitle"
                    name="faqTitle"
                    data-testid="faqTitle"
                    required
                    type="text"
                    placeholder={'제목을 입력하세요.'}
                    value={values.faqTitle}
                    onChange={e => {
                      setFieldValue('faqTitle', e.target.value);
                    }}
                    onBlur={handleBlur}
                    isInvalid={!!errors.faqTitle}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Col sm={2}>첨부파일</Col>
                <Col>
                  <Form.Control
                    id="fileOriginalName"
                    name="fileOriginalName"
                    data-testid="fileOriginalName"
                    type="file"
                    title={values.fileOriginalName ? values.fileOriginalName + '.' + values.fileExtension : ''}
                    accept=".pdf"
                    onChange={async e => {
                      setLoading(true);
                      const uploadResult = await handleChangeFile(e);

                      if (uploadResult.uploadResult === 'SUCCESS') {
                        const fileFullName = uploadResult.fileName.split('.');
                        setFieldValue('fileId', '');
                        setFieldValue('fileName', fileFullName[0]);
                        setFieldValue('fileExtension', fileFullName[1]);
                        setFieldValue('fileSize', uploadResult.fileSize);
                        setFieldValue('fileOriginalName', uploadResult.contentsUrl.split('/faq/')[1]);
                      }
                      setLoading(false);
                    }}
                    onBlur={handleBlur}
                    isInvalid={!!errors.fileOriginalName}
                  />
                  {values.fileName && values.fileExtension && values.fileSize && values.fileOriginalName && (
                    <a
                      href={path + values.filePath + '/' + values.fileOriginalName}
                      target="_blank"
                      download={values.fileName}
                    >
                      {values.fileName + '.' + values.fileExtension + ' ' + filesizeFormatter(values.fileSize)}
                    </a>
                  )}
                </Col>
              </Form.Row>
              <Form.Row>
                <Col sm={2}>내용</Col>
                <Col>
                  <Form.Control
                    id="faqContent"
                    name="faqContent"
                    data-testid="faqContent"
                    required
                    as="textarea"
                    rows={10}
                    placeholder={'내용을 입력하세요.'}
                    value={values.faqContent}
                    onChange={e => {
                      setFieldValue('faqContent', e.target.value);
                    }}
                    onBlur={handleBlur}
                    isInvalid={!!errors.faqContent}
                  />
                </Col>
              </Form.Row>

              <Form.Row>
                <Col sm={2}>사용여부</Col>
                <Col>
                  <Form.Check
                    id="useYn"
                    name="useYn"
                    data-testid="useYn"
                    type="checkbox"
                    checked={values.useYn === 'Y'}
                    onChange={e => {
                      setFieldValue('useYn', e.target.checked ? 'Y' : 'N');
                    }}
                    onBlur={handleBlur}
                    isInvalid={!!errors.useYn}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Button
                  className="btn-ok"
                  onClick={() => {
                    props.onHide(values);
                  }}
                  disabled={isSubmitting || !isValid}
                >
                  {t('reservation.label.SystemNoticeModal.confirm')}
                </Button>
              </Form.Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default FAQModifyModal;
