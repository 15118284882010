import React, { useState, ReactElement, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import './RightSideContainer.scss';

import { User } from './model/User';
import { QueryType } from './model/Reservation';
import ReservationService from './services/ReservationService';

import { CommonCode } from './model/Code';
import RightSideApprovalList from './components/RightSideApprovalList';
import RightSideRequestList from './components/RightSideRequestList';
import CommonSpinner from '../../layouts/Spinner';
import HistorySearchConditions from './components/HistorySearchConditions';
import { Image, Button } from 'react-bootstrap';
import { HistorySearchConditionModel } from './model/ReservationTypes';

import filterIcon from '../../icons/reservation/filter_darkgray.svg';
import { useHistory } from 'react-router-dom';
import CommonLoadingOverlay from '../../layouts/LoadingOverlay';
import { AuthContext } from '../../App';
import WorkplaceService from './services/WorkplaceService';
import MemberService from './services/MemberService';
import { Reservation } from './model/ReservationTypes';

interface RightSideContainer {
  sessionUser: User;
  reservationService: ReservationService;
  commonCodeMap: Map<string, CommonCode[]> | undefined;
  isRigitSideShow: boolean;
  setRigitSideShow: Function;
  rightSideInfoType: string;
  workplaceService: WorkplaceService;
  memberService: MemberService;
  setReservation: Function;
  handleSideOpen: () => void;
}

const RightSideContainer: React.FC<RightSideContainer> = ({
  sessionUser,
  reservationService,
  commonCodeMap,
  isRigitSideShow,
  setRigitSideShow,
  rightSideInfoType,
  handleSideOpen,
  workplaceService,
  memberService,
  setReservation,
}: RightSideContainer): ReactElement => {
  const { t } = useTranslation();
  const { state } = useContext(AuthContext);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [showSearchCond, setShowSearchCond] = useState<boolean>(false);
  const [searchCondition, setSearchCondition] = useState<HistorySearchConditionModel>();
  const history = useHistory();
  const handleClose = () => {
    handleSideOpen();
    if (history.location.hash.includes('#historySearchCond')) {
      setTimeout(() => {
        history.goBack();
      }, 200);
    }
  };
  const handleSetReservation = (reservation: Reservation) => {
    setReservation(reservation);
    handleClose();
  };

  const handleShowSearchConditions = () => {
    if (showSearchCond && history.location.hash.includes('#historySearchCond')) {
      history.goBack();
    } else {
      history.push(history.location.hash + '#historySearchCond');
    }
    setShowSearchCond(!showSearchCond);
  };

  const handleSetSearchCond = (value: HistorySearchConditionModel | undefined) => {
    setShowSearchCond(false);
    setSearchCondition(value ? { ...value } : undefined);
    history.goBack();
  };

  /* eslint-disable */
  useEffect(() => {
    setSearchCondition(undefined);
    if (showSearchCond === true) {
      setShowSearchCond(false);
    }
  }, [rightSideInfoType]);
  useEffect(() => {
    const location = history.location;

    if (!location.hash.includes('#historySearchCond')) {
      setShowSearchCond(false);
    }
  }, [history.location]);
  /* eslint-enable */
  const isLoadingReducer: boolean = state.loading && state.loading.isLoading ? true : false;
  const getRightSideContainerBody = () => {
    return (
      <div id="reservationDrawer">
        <div
          id="drawerDiv"
          className={['drawer__wrapper', isLoading && 'loading', showSearchCond && 'onSearchCondition'].join(' ')}
        >
          <div className="drawer__header">
            <h3>
              {rightSideInfoType === QueryType.REQUEST
                ? t('reservation.label.RightSideDrawer.header.request')
                : t('reservation.label.RightSideDrawer.header.approval')}
            </h3>
            <a className="drawer__button--close">
              <i className="material-icons pointer" data-testid="sideBarCloseBtn" onClick={handleClose}>
                close
              </i>
            </a>
          </div>
          <div className="btnSearch">
            <Button
              className={showSearchCond ? 'btnSearchCondition' : 'btnSearchCondition on'}
              onClick={handleShowSearchConditions}
            >
              <Image src={filterIcon} />
              {t('reservation.label.RightSideDrawer.body.searchButton')}
            </Button>
            {showSearchCond && (
              <HistorySearchConditions
                commonCodeMap={commonCodeMap}
                rightSideInfoType={rightSideInfoType}
                handleSetSearchCond={handleSetSearchCond}
                searchCondtion={searchCondition}
              />
            )}
          </div>
          {rightSideInfoType === QueryType.APPROVAL && (
            <RightSideApprovalList
              sessionUser={sessionUser}
              reservationService={reservationService}
              commonCodeMap={commonCodeMap}
              isRigitSideShow={isRigitSideShow}
              setRigitSideShow={setRigitSideShow}
              rightSideInfoType={rightSideInfoType}
              isLoading={isLoading}
              handleLoading={setLoading}
              searchCondition={searchCondition}
            />
          )}
          {rightSideInfoType === QueryType.REQUEST && (
            <RightSideRequestList
              sessionUser={sessionUser}
              reservationService={reservationService}
              commonCodeMap={commonCodeMap}
              isRigitSideShow={isRigitSideShow}
              rightSideInfoType={rightSideInfoType}
              isLoading={isLoading}
              handleLoading={setLoading}
              searchCondition={searchCondition}
              workplaceService={workplaceService}
              memberService={memberService}
              handleSetReservation={handleSetReservation}
            />
          )}
          {isLoading && <CommonSpinner />}
        </div>
      </div>
    );
  };
  return <CommonLoadingOverlay active={isLoadingReducer}>{getRightSideContainerBody}</CommonLoadingOverlay>;
};

export default RightSideContainer;
