import React, { ReactElement } from 'react';
import { Modal, Button } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import './ConfirmModal.scss';

export interface ConfirmModalProps {
  message: string;
  show: boolean;
  onConfirm: Function;
  handleClose: () => void;
  cancelBtn?: string;
  confirmBtn?: string;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = (props: ConfirmModalProps): ReactElement => {
  const { t } = useTranslation();
  const onConfirm = () => {
    props.onConfirm();
  };
  return (
    <Modal
      id="confirmModal"
      data-testid="confirmModal"
      show={props.show}
      onHide={props.handleClose}
      className="ConfirmModal"
      enforceFocus={false}
      centered
    >
      <Modal.Body>
        <p id="confirmMessage" data-testid="confirmMessage">
          {props.message}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button id="cancelButton" data-testid="cancelButton" variant="dark" onClick={props.handleClose}>
          {props.cancelBtn}
          {!props.cancelBtn && t('reservation.label.ConfirmModal.button.cancel')}
        </Button>
        <Button id="confirmButton" data-testid="confirmButton" variant="primary" onClick={onConfirm}>
          <i className="material-icons md-24">check</i>
          {props.confirmBtn}
          {!props.confirmBtn && t('reservation.label.ConfirmModal.button.confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
