import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Navbar, Nav, Badge, Popover, OverlayTrigger, Button, Card, Row, Tooltip } from 'react-bootstrap';
import './NavBar.scss';
import { useTranslation } from 'react-i18next';
import MemberRegistrationConstants from '../pages/member/model/MemberRegistrationConstants';
import { User } from '../pages/reservation/model/User';
import ReservationService from '../pages/reservation/services/ReservationService';
import LoginService from '../pages/member/services/LoginService';
import cookie from 'react-cookies';
import { Session } from '../service/model/SessionInfo';
import buildConfig from '../build-env';
import { AuthContext } from '../App';
import SinglexHeader from '../layouts/SinglexHeader';

export interface NavBarProps {
  className?: string;
  handleRequestHistory?: () => void;
  handleNoticeHistory?: () => void;
  sessionUser: User;
  reservationService: ReservationService;
  loginService: LoginService;
}

const NavBar: React.FC<NavBarProps> = (props: NavBarProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [requestCount, setRequestCount] = useState<number>(0);
  const { state } = useContext(AuthContext);
  const memberTypeCode = props.sessionUser.memberTypeCode;
  const singlexHeaderUse = window.location.hostname.includes('singlex') ? true : false;

  const requestLogout = () => {
    cookie.remove(Session.SESSION_ID, { path: '/' });
    cookie.remove('tenantId', { path: '/' });

    props.loginService.requestLogout().then(res => {
      sessionStorage.clear();
      window.location.assign('/');
    });
  };

  const onClickLogout = (e: any): void => {
    e.stopPropagation();
    e.preventDefault();
    requestLogout();
  };

  const userInfoPopover = (
    <Popover className="profile-popover" data-testid="userInfo-popover" id="popover-basic">
      <Popover.Content>
        <Card className="profile-card text-center">
          <Card.Header className="profile-card-header">
            <i
              id="avatar-icon"
              data-testid="avatar-icon"
              className={
                (memberTypeCode === MemberRegistrationConstants.EMPLOYEE_MEMBER_TYPE_CODE
                  ? 'employee-member employee-'
                  : 'default-member default-') + 'avatar-icon material-icons'
              }
            >
              account_circle
            </i>
          </Card.Header>
          <Card.Body className="profile-card-body">
            <Card.Title id="profile-name" data-testid="profile-name">
              {props.sessionUser.name}
            </Card.Title>
            <Card.Text id="profile-email" data-testid="profile-email">
              {props.sessionUser.email}
            </Card.Text>
          </Card.Body>
          <Card.Footer className="profile-footer">
            <Row className="justify-content-md-center">
              <Button
                id="profileEditButton"
                className={
                  (memberTypeCode === MemberRegistrationConstants.EMPLOYEE_MEMBER_TYPE_CODE
                    ? 'employee-member'
                    : 'default-member') + ' profileEdit'
                }
                variant={
                  memberTypeCode === MemberRegistrationConstants.EMPLOYEE_MEMBER_TYPE_CODE ? 'primary' : 'secondary'
                }
                onClick={() => {
                  history.push('/memberInfo');
                }}
              >
                {t('reservation.label.NavBar.profile.memberInfoChange')}
              </Button>
            </Row>
            <Row className="justify-content-md-center">
              <Button
                id="profile-logout"
                data-testid="profile-logout"
                className="logout btn-light"
                onClick={onClickLogout}
              >
                {t('reservation.label.NavBar.profile.logout')}
              </Button>
            </Row>
          </Card.Footer>
        </Card>
      </Popover.Content>
    </Popover>
  );

  /* eslint-disable */
  useEffect(() => {
    if (memberTypeCode !== MemberRegistrationConstants.NON_MEMBER_TYPE_CODE) {
      props.reservationService.getApprovalRequestCount(props.sessionUser.id).then(count => {
        setRequestCount(count);
      });
    }
  }, [props.sessionUser, state.refreshNavBar]);
  /* eslint-enable */

  return (
    <div className={props.className ? 'layouts ' + props.className : 'layouts'}>
      <Navbar
        fixed="top"
        className={
          window.location.hostname.includes('singlex')
            ? 'navbarTop justify-content-end navbarTopSinglex'
            : 'navbarTop justify-content-end navbarTopLG'
        }
      >
        {singlexHeaderUse && <SinglexHeader />}
        {memberTypeCode !== MemberRegistrationConstants.NON_MEMBER_TYPE_CODE ? (
          <Nav className="d-flex">
            {(props.sessionUser.isWorkplaceAdmin || props.sessionUser.isSystemAdmin) &&
              buildConfig.adminAccessAvailable && (
                <Link to="/workplaceManagement">
                  <Nav.Item data-testid="linkToSetting">
                    <i id="settingIcon" data-testid="settingIcon" className="material-icons reservationPage">
                      settings
                    </i>
                  </Nav.Item>
                </Link>
              )}
            <OverlayTrigger
              placement="bottom"
              overlay={p => {
                p.show = true;
                return <Tooltip {...p}>{t('reservation.label.NavBar.tooltip.reservationHistory')}</Tooltip>;
              }}
            >
              <Link to="/reservation#rightSide">
                <Nav.Item onClick={props.handleRequestHistory} data-testid="requestHistoryIcon">
                  <i data-testid="reservationHistoryIcon" className="material-icons reservationHistoryIcon">
                    restore
                  </i>
                </Nav.Item>
              </Link>
            </OverlayTrigger>
            {memberTypeCode === MemberRegistrationConstants.EMPLOYEE_MEMBER_TYPE_CODE && (
              <OverlayTrigger
                placement="bottom"
                overlay={p => {
                  p.show = true;
                  return (
                    <Tooltip {...p}>
                      {requestCount > 0
                        ? t('reservation.label.NavBar.tooltip.haveRequestHistory')
                        : t('reservation.label.NavBar.tooltip.noRequestHistory')}
                    </Tooltip>
                  );
                }}
              >
                <Link to="/reservation#rightSide">
                  <Nav.Item onClick={props.handleNoticeHistory} data-testid="noticeIcon">
                    {requestCount > 0 && (
                      <Badge
                        id="notifierBadge"
                        data-testid="notifierBadge"
                        pill
                        variant="danger"
                        className="notifierBadge"
                      >
                        {requestCount}
                      </Badge>
                    )}
                    <i id="notifierIcon" data-testid="notifierIcon" className="material-icons">
                      notifications
                    </i>
                  </Nav.Item>
                </Link>
              </OverlayTrigger>
            )}
            <div className="profile-dropdown">
              <OverlayTrigger rootClose={true} trigger="click" placement="bottom-end" overlay={userInfoPopover}>
                <div id="userOverlayTrigger" data-testid="userOverlayTrigger">
                  <i
                    id="userImg"
                    data-testid="userImg"
                    className={
                      (memberTypeCode === MemberRegistrationConstants.EMPLOYEE_MEMBER_TYPE_CODE
                        ? 'employee-member'
                        : 'default-member') + ' material-icons'
                    }
                  >
                    account_circle
                  </i>
                  <i id="dropDownIcon" data-testid="dropDownIcon" className="material-icons">
                    arrow_drop_down
                  </i>
                </div>
              </OverlayTrigger>
            </div>
          </Nav>
        ) : (
          <Nav className="d-flex">
            <div className="nonmemberLogout">
              <Button
                id="nonmember-logout"
                data-testid="nonmember-logout"
                className="nonmember-logout btn-light"
                onClick={onClickLogout}
              >
                <i className="material-icons pointer">home</i>
                <span>{t('reservation.label.NavBar.loginPage')}</span>
              </Button>
            </div>
          </Nav>
        )}
      </Navbar>
    </div>
  );
};

export default NavBar;
