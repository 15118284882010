import React, { useState, ReactElement, useEffect, useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './MemberRegistrationContainer.scss';
import { RegistrationStatusPage } from './component/RegistrationStatusPage';
import MainSection from './component/MainSection';
import { useTranslation } from 'react-i18next';
import StepInformation from '../model/StepInformation';
import { Link, useHistory } from 'react-router-dom';
import { AuthContext } from '../../../App';
import queryString from 'query-string';
import { Footer } from '../../../layouts';
import { UserTypeParamEnum } from '../model/MemberRegistrationConstants';
import { Service } from '../../../service/model/Service';
import BaseService from '../../../service/BaseService';
import CommonLoadingOverlay from '../../../layouts/LoadingOverlay';

export interface MemberRegistrationContainerProps {
  currentStep: number;
}

const MemberRegistrationContainer: React.FC<MemberRegistrationContainerProps> = (
  props: MemberRegistrationContainerProps
): ReactElement => {
  const { state } = useContext(AuthContext);
  const [stepParams, setStepParams] = useState<StepInformation>({
    currentStep: state && state.inactiveUserInfo ? 4 : props.currentStep,
    emailAddress: state && state.inactiveUserInfo ? state.inactiveUserInfo.emailAddress : undefined,
    memberId: state && state.inactiveUserInfo ? Number(state.inactiveUserInfo.memberId) : undefined,
    memberType: queryString.parse(window.location.search).memberType + '',
  });

  const { t } = useTranslation();
  const isLoadingReducer: boolean = state.loading && state.loading.isLoading ? true : false;
  const currentStep: number | undefined = props.currentStep;
  const history = useHistory();

  const redirectSso = () => {
    const baseService = new BaseService();
    const tenantId = state.ssoUserInfo?.tenantId;
    const ssoRedirectUrl = baseService.fnCheckServiceUrl('/v1/account/sso/' + tenantId, Service.MEMBER);
    window.location.assign(ssoRedirectUrl);
    return null;
  };
  /* eslint-disable */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [stepParams.currentStep]);

  useEffect(() => {
    const location = history.location;

    if (stepParams.currentStep) {
      history.replace(location.pathname + location.search + '#step' + stepParams.currentStep);
    }
  }, []);

  useEffect(() => {
    const location = history.location;
    if (location.hash.includes('#step1')) {
      setStepParams({ ...stepParams, currentStep: 1 });
    }
    if (location.hash.includes('#step2')) {
      setStepParams({ ...stepParams, currentStep: 2 });
    }
    if (location.hash.includes('#step3')) {
      setStepParams({ ...stepParams, currentStep: 3 });
    }
  }, [history.location]);
  /* eslint-enable */
  const getMemberContainerBody = () => {
    return (
      <div id="MemberContainer">
        {stepParams.memberType === UserTypeParamEnum.SSO_EMPLOYEE && !state.ssoUserInfo?.emailAddress
          ? redirectSso()
          : currentStep && (
              <Container fluid={true}>
                <Row>
                  <Col className="left-side">
                    <div className="left-status-area">
                      <RegistrationStatusPage stepParams={stepParams} />
                    </div>
                  </Col>
                  <Col className="main-side">
                    <div className="main-content-area">
                      <MainSection setStepParams={setStepParams} stepParams={stepParams} />
                    </div>
                  </Col>
                  <Col className="right-side">
                    <Link to="/login">
                      <div className="right-area">
                        <i className="material-icons">home</i>
                        <span id="loginShortcut" data-testid="loginShortcut">
                          {t('member.label.memberRegistrationContainer.loginShortcut')}
                        </span>
                      </div>
                    </Link>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Footer></Footer>
                  </Col>
                </Row>
              </Container>
            )}
      </div>
    );
  };
  return <CommonLoadingOverlay active={isLoadingReducer}>{getMemberContainerBody}</CommonLoadingOverlay>;
};

export default MemberRegistrationContainer;
