import { Form, Button } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MemberRegistrationConstants, { CertificationTypeCode } from '../../model/MemberRegistrationConstants';
import CertificationRequest from '../../model/CertificationRequest';
import CertificationConfirmRequest from '../../model/CertificationConfirmRequest';
import MemberRegistrationService from '../../services/MemberRegistrationService';
import './EmailCertificationForm.scss';

export interface EmailCertificationFormProps {
  emailCertificationFormik: any;
  receivedCertificationId: string;
  setCertificationId: (receivedCertificationId: string) => void;
  certificationConfirmStatus: boolean;
  setCertificationConfirmStatus: (certificationConfirmStatus: boolean) => void;
  certificationBusinessTypeCode: string;
  memberRegistrationService: MemberRegistrationService;
}

export const EmailCertificationForm: React.FC<EmailCertificationFormProps> = ({
  emailCertificationFormik,
  receivedCertificationId,
  setCertificationId,
  certificationConfirmStatus,
  setCertificationConfirmStatus,
  certificationBusinessTypeCode,
  memberRegistrationService,
}: EmailCertificationFormProps) => {
  const { t } = useTranslation();
  const [minutes, setMinutes] = useState(2);
  const [seconds, setSeconds] = useState(59);
  const [isActive, setIsActive] = useState(false);
  const componentDisplayNoneClassName: string = MemberRegistrationConstants.COMPONENT_DISPLAY_NONE_CLASS_NAME;
  const componentDisplayClassName: string = MemberRegistrationConstants.COMPONENT_DISPLAY_CLASS_NAME;
  const [certificationKeyformGroupClassName, setCertificationKeyformGroupClassName] = useState(
    componentDisplayNoneClassName
  );
  const [certificationKeyRequestButtonClassName, setCertificationKeyRequestButtonClassName] = useState(
    'authentication-number-button'
  );
  const [certificationKey, setCertificationKey] = useState('');
  const timerToggle = (): void => {
    if (isActive) {
      setMinutes(2);
      setSeconds(59);
    }
    setIsActive(!isActive);
  };
  const certificationKeyReset = (): void => {
    setCertificationKeyformGroupClassName(componentDisplayNoneClassName);
    setCertificationKeyRequestButtonClassName(componentDisplayClassName);
    timerToggle();
    setCertificationId('');
    setCertificationConfirmStatus(false);
    setCertificationKey('');
  };
  /* eslint-disable */
  useEffect(
    /* istanbul ignore next */ () => {
      let interval: any = null;
      if (isActive) {
        interval = setInterval(() => {
          if (seconds === 0 && minutes > 0) {
            setSeconds(seconds => 59);
            setMinutes(minutes => minutes - 1);
          } else if (seconds === 0 && minutes === 0) {
            certificationKeyReset();
          } else {
            setSeconds(seconds => seconds - 1);
          }
        }, 1000);
      } else if (!isActive && seconds !== 0 && minutes !== 0) {
        clearInterval(interval);
      }
      return (): void => clearInterval(interval);
    },
    [isActive, minutes, seconds]
  );

  /* eslint-disable */
  const requestCertificationKey = async (emailAddress: string): Promise<void> => {
    if (emailCertificationFormik.handleCertification) {
      const isValid = await emailCertificationFormik.handleCertification();
      if (!isValid) {
        return;
      }
    }
    setCertificationConfirmStatus(false);
    const certificationRequest: CertificationRequest = {
      certificationTypeCode: CertificationTypeCode.EMAIL,
      certificationSendAddress: emailAddress,
      certificationBusinessTypeCode: certificationBusinessTypeCode,
    };
    const submitStatus = await memberRegistrationService.requestCertificationKey(certificationRequest);
    if (submitStatus.successOrNot === 'Y') {
      setCertificationKeyformGroupClassName(componentDisplayClassName);
      setCertificationKeyRequestButtonClassName(componentDisplayNoneClassName);
      timerToggle();
      setCertificationId(submitStatus.data.certificationId);
    } else if (submitStatus.statusCode === MemberRegistrationConstants.UNKNOWN_SERVER_ERROR_CODE) {
      setCertificationId('');
      alert(t('member.message.backEnd.UE'));
    } else {
      setCertificationId('');
      alert(t('member.message.backEnd.FAIL.KEY.REQUEST'));
    }
  };

  const confirmCertificationKey = async () => {
    const certificationConfirmRequest: CertificationConfirmRequest = {
      certificationId: receivedCertificationId,
      certificationSendAddress: emailCertificationFormik.values.emailAddress,
      certificationKey: emailCertificationFormik.values.certificationKey,
      certificationTypeCode: CertificationTypeCode.EMAIL,
    };
    const submitStatus = await memberRegistrationService.confirmCertificationKey(certificationConfirmRequest);
    if (submitStatus.successOrNot === 'Y') {
      timerToggle();
      setCertificationConfirmStatus(true);
      setCertificationKeyformGroupClassName(componentDisplayNoneClassName);
    } else if (submitStatus.statusCode === MemberRegistrationConstants.UNKNOWN_SERVER_ERROR_CODE) {
      setCertificationConfirmStatus(false);
      alert(t('member.message.backEnd.UE'));
    } else {
      setCertificationConfirmStatus(false);
      emailCertificationFormik.setFieldError(
        'certificationKey',
        t('member.message.backEnd.' + submitStatus.statusCode)
      );
    }
  };

  return (
    <div id="EmailCertificationForm">
      <Form.Row>
        <Form.Group>
          <Form.Label>{t('member.label.resetPasswordModal.emailAddress.label')}</Form.Label>
          <Form.Control
            id="emailAddress"
            name="emailAddress"
            data-testid="emailAddressForEmailForm"
            required
            type="text"
            placeholder={t('member.label.resetPasswordModal.emailAddress.placeholder')}
            onChange={(event: React.FormEvent<object>): void => {
              emailCertificationFormik.handleChange(event);
              if (isActive) {
                certificationKeyReset();
              }
            }}
            onBlur={emailCertificationFormik.handleBlur}
            isInvalid={!!emailCertificationFormik.errors.emailAddress}
            disabled={certificationConfirmStatus}
            className="email-address-input"
          />

          <div className="certification-area">
            {certificationConfirmStatus && (
              <i id="emailAddressChecked" data-testid="emailAddressChecked" className="material-icons">
                check
              </i>
            )}
            {emailCertificationFormik.errors.emailAddress === undefined &&
              emailCertificationFormik.values.emailAddress.length > 0 &&
              !emailCertificationFormik.isCustomInVisible &&
              (
                <Button
                  id="emailCertificationKeyRequestButton"
                  name="emailCertificationKeyRequestButton"
                  data-testid="emailCertificationKeyRequestButton"
                  className={certificationKeyRequestButtonClassName}
                  variant="outline-dark"
                  onClick={(): Promise<void> => requestCertificationKey(emailCertificationFormik.values.emailAddress)}
                >
                  {t('member.label.resetPasswordModal.emailAddress.button')}
                </Button>
              )}
          </div>
          <Form.Control.Feedback
            id="emailAddressFeedback"
            data-testid="emailAddressFeedbackForEmailForm"
            type="invalid"
          >
            {emailCertificationFormik.errors.emailAddress}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row id="certificationKeyRow">
        <Form.Group data-testid="certificationKeyformGroupForEmailForm" className={certificationKeyformGroupClassName}>
          <Form.Control
            id="certificationKey"
            name="certificationKey"
            data-testid="certificationKeyForEmailForm"
            required
            type="text"
            placeholder={t('member.label.resetPasswordModal.certificationKey.placeholder')}
            value={certificationKey}
            onChange={(e: any) => {
              emailCertificationFormik.handleChange(e);
              setCertificationKey(e.target.value);
            }}
            onBlur={emailCertificationFormik.handleBlur}
            isInvalid={!!emailCertificationFormik.errors.certificationKey}
            className="email-address-input"
          ></Form.Control>
          <div className="certification-area">
            <span id="certificationTimer" data-testid="certificationTimer" className="certification-timer">
              {minutes}:{seconds.toString().padStart(2, '0')}
            </span>
            {emailCertificationFormik.errors.certificationKey === undefined &&
              emailCertificationFormik.values.certificationKey.length > 0 && (
                <Button
                  id="certificationCheckButton"
                  name="certificationCheckButton"
                  data-testid="certificationCheckButtonForEmailForm"
                  className="authentication-number-button"
                  variant="outline-dark"
                  onClick={confirmCertificationKey}
                >
                  {t('member.label.resetPasswordModal.certificationKey.button')}
                </Button>
              )}
          </div>
          <Form.Control.Feedback
            id="certificationKeyFeedback"
            data-testid="certificationKeyFeedbackForEmailForm"
            type="invalid"
          >
            {emailCertificationFormik.errors.certificationKey}
          </Form.Control.Feedback>
          <span
            className="certificationReRequest"
            data-testid="certificationReRequest"
            onClick={(): void => certificationKeyReset()}
          >
            {t('member.label.resetPasswordModal.certificationKey.reRequest')}
          </span>
        </Form.Group>
      </Form.Row>
    </div>
  );
};
export default EmailCertificationForm;
