import * as yup from 'yup';

export const reservationSchema = yup.object().shape({
  visitors: yup
    .array()
    .required('required')
    .of(
      yup.object().shape({
        name: yup.string().nullable(),
        position: yup.string().nullable(),
        id: yup.string().nullable(),
        companyName: yup.string().nullable(),
        dept: yup.string().nullable(),
        type: yup.string().nullable(),
        fromVisitPeriod: yup.string().required('required'),
        toVisitPeriod: yup.string().required('required'),
        importedGoodsList: yup.array().of(
          yup.object().shape({
            importedGoodsCode: yup.string(),
            importedGoodsName: yup.string(),
          })
        ),
      })
    ),
  fromVisitPeriod: yup.string().required('required'),
  toVisitPeriod: yup.string().required('required'),
  isIncludedNoneMember: yup.boolean(),
  termNoneMember: yup.boolean().when(['isIncludedNoneMember'], {
    is: val => val,
    then: yup.boolean().oneOf([true], 'required'),
  }),
  termsAgreeYn: yup.string().nullable(),
  termPersonal: yup.boolean().when(['termsAgreeYn'], {
    is: 'N',
    then: yup
      .boolean()
      .required()
      .oneOf([true], 'required'),
  }),
  termVaccine: yup.boolean().when(['vaccinationYesorno'], {
    is: val => val,
    then: yup.boolean().oneOf([true], 'required'),
  }),
  visitPurposeYesorno: yup.string().nullable(),
  purpose: yup.string().when(['visitPurposeYesorno'], {
    is: 'Y',
    then: yup.string().required('required'),
  }),
  purposeDetail: yup.string().max(200, 'reservation.label.ReservationBottomForms.purpose.exception.max'),
  visitTo: yup
    .object()
    .required('required')

    .shape({
      id: yup.string().required(),
      name: yup.string(),
      position: yup.string(),
      companyName: yup.string(),
      dept: yup.string(),
      type: yup.string(),
      companyId: yup.string(),
      employeeNumber: yup.string(),
      emailAddress: yup.string(),
      phoneNumber: yup.string(),
    })
    .required('required'),
  workplace: yup
    .object()
    .required('required')

    .shape({
      groupCompanyName: yup.string(),
      workplaceId: yup.string().required(),
      workplaceType: yup.string(),
      workplace: yup.string(),
      workplaceAddress: yup.string(),
    }),
  isPass: yup.boolean().oneOf([true], 'required'),
  status: yup.string().nullable(),
  entryAreaYesorno: yup.string().nullable(),
  entryArea: yup.string().when(['entryAreaYesorno'], {
    is: 'Y',
    then: yup.string().required('required'),
  }),
});
