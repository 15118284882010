import TermsInfoRequest from '../model/TermsInfoRequest';
import BaseService from '../../../service/BaseService';
import { Service } from '../../../service/model/Service';
import PrivayPolicyVersionResponse from '../model/PrivacyPolicyVersionResponse';

export default class TermService {
  public baseService: BaseService = new BaseService();

  async requestTermInfo(memberType: string): Promise<TermsInfoRequest> {
    let termsInfoRequest: TermsInfoRequest = {
      successOrNot: 'N',
      statusCode: '',
      data: {
        msg: '',
      },
    };

    const method = 'GET';
    const url = '/v1/terms?termsApplyGroupCode=' + memberType;
    const serviceName = Service.MEMBER;

    try {
      termsInfoRequest = await this.baseService.fnRest(method, url, null, serviceName);
    } catch (error) {
      termsInfoRequest = error;
    }

    return termsInfoRequest;
  }

  async requestPrivacyPolicyVersion(): Promise<PrivayPolicyVersionResponse> {
    let privacyPolicyVersion: PrivayPolicyVersionResponse = {
      successOrNot: 'N',
      statusCode: '',
    };

    const method = 'GET';
    const url = '/v1/privacy/versions';
    const serviceName = Service.MEMBER;

    try {
      privacyPolicyVersion = await this.baseService.fnRest(method, url, null, serviceName);
    } catch (error) {
      privacyPolicyVersion = error;
    }

    return privacyPolicyVersion;
  }

  async requestPrivacyPolicy(termsId: number): Promise<TermsInfoRequest> {
    let privacyPolicyVersion: TermsInfoRequest = {
      successOrNot: 'N',
      statusCode: '',
      data: {
        msg: '',
      },
    };

    const method = 'GET';
    const url = '/v1/privacy/' + termsId;
    const serviceName = Service.MEMBER;

    try {
      privacyPolicyVersion = await this.baseService.fnRest(method, url, null, serviceName);
    } catch (error) {
      privacyPolicyVersion = error;
    }

    return privacyPolicyVersion;
  }
}
