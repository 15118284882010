import React, { ReactElement, useEffect, useState } from 'react';
import queryString from 'query-string';
import CertificationConfirmRequest from '../model/CertificationConfirmRequest';
import { CertificationTypeCode } from '../model/MemberRegistrationConstants';
import MemberRegistrationService from '../services/MemberRegistrationService';
import CommonLoadingOverlay from '../../../layouts/LoadingOverlay';

const EmailCertificationConfirmPage: React.FC = (): ReactElement => {
  const confirmCertificationId = queryString.parse(window.location.search).certificationId + '';
  const confirmCertificationKey = queryString.parse(window.location.search).certificationKey + '';
  const [isLoading, setLoading] = useState<boolean>(false);
  /* eslint-disable */
  useEffect(() => {
    if (
      confirmCertificationId !== '' &&
      confirmCertificationKey !== '' &&
      confirmCertificationId !== 'undefined' &&
      confirmCertificationKey !== 'undefined'
    ) {
      setLoading(true);
      const certificationConfirmRequest: CertificationConfirmRequest = {
        certificationId: confirmCertificationId,
        certificationKey: confirmCertificationKey,
        certificationTypeCode: CertificationTypeCode.EMAIL,
      };
      const memberRegistrationService = new MemberRegistrationService();
      const emailCertificationConfirmResult = async (certificationConfirmRequest: CertificationConfirmRequest) => {
        return await memberRegistrationService.confirmCertificationKey(certificationConfirmRequest);
      };
      emailCertificationConfirmResult(certificationConfirmRequest).then(() => {
        window.location.assign('/login');
      });
    }
  }, []);
  /* eslint-enable */

  const emptyBody = (): JSX.Element => {
    return <div style={{ height: window.screen.height }}></div>;
  };
  return <CommonLoadingOverlay active={isLoading}>{emptyBody}</CommonLoadingOverlay>;
};

export default EmailCertificationConfirmPage;
