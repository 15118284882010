import React, { useState, useEffect } from 'react';

import './ExcelUploadModal.scss';
import { Modal, Container, Button, Form } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import ExcelFileConfig, {
  ExcelTemplateHeader,
  ExcelTemplateHeaderWidth,
  ExcelHeaderInfo,
  ExcelData,
  ImportFileInfo,
  AlertProps,
} from '../model/Excel';
import ExcelFileUpload from './ExcelFileUpload';
import ExcelVisitorList from './ExcelVisitorList';
import AlertModal from '../../../layouts/AlertModal';
import { Workplace } from '../model/Workplace';
import { Visitor, Reservation } from '../model/ReservationTypes';

export interface ExcelUploadModalI {
  show: boolean;
  onClose: Function;
  onAddVisitor: Function;
  workplaceInfo: Workplace;
  formikVal: Reservation;
}

export const ExcelUploadModal: React.FC<ExcelUploadModalI> = (props: ExcelUploadModalI) => {
  const { t } = useTranslation();
  const [excelObject, setExcelObject] = useState<ExcelData[]>();
  const [importFileInfo, setImportFileInfo] = useState<ImportFileInfo>();
  const [step, setStep] = useState<number>(0);
  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');

  const alertProps: AlertProps = {
    setAlertMessage: setAlertMessage,
    setIsShowAlert: setIsShowAlert,
  };
  const handleClose = () => {
    setIsShowAlert(false);
  };
  const onClose = () => {
    setStep(0);
    props.onClose();
  };
  const onUpload = () => {
    setStep(1);
  };
  const onAddVisitor = (users: Visitor[]) => {
    setStep(0);
    props.onAddVisitor(users);
  };

  useEffect(() => {
    setStep(0);
    setExcelObject(undefined);
    setImportFileInfo(undefined);
  }, [props.show]);

  return (
    <div>
      <Modal
        show={props.show}
        onHide={onClose}
        centered={true}
        className={step === 0 ? 'excel-upload-modal' : 'excel-visitor-modal'}
        data-testid="excel-upload-modal-test"
        id="ExcelUploadModal"
      >
        {(!step || step === 0) && (
          <ExcelFileUpload
            setExcelObject={setExcelObject}
            onUpload={onUpload}
            importFileInfo={importFileInfo}
            setImportFileInfo={setImportFileInfo}
            workplaceInfo={props.workplaceInfo}
            alertProps={alertProps}
          />
        )}
        {excelObject && importFileInfo && step === 1 && (
          <ExcelVisitorList
            excelObject={excelObject}
            onAddVisitor={onAddVisitor}
            onClose={onClose}
            importFileInfo={importFileInfo}
            alertProps={alertProps}
            workplaceInfo={props.workplaceInfo}
            formikVal={props.formikVal}
          />
        )}
      </Modal>
      <AlertModal show={isShowAlert} onClose={handleClose} message={alertMessage} />
    </div>
  );
};

export default ExcelUploadModal;
