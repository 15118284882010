import React, { ReactElement, useState, useEffect } from 'react';
import VisitPeriodCalendar from './VisitPeriodCalendar';
import moment from 'moment';
import { HistorySearchConditionModel } from '../model/ReservationTypes';
import {
  InputGroup,
  Form,
  Button,
  Container,
  Col,
  Row,
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
  Image,
} from 'react-bootstrap';
import { CommonCode } from '../model/Code';
import { QueryType } from '../model/Reservation';
import { ApprovalStatusEnum, DateFormat } from '../model/ReservationConstants';
import './HistorySearchConditions.scss';
import searchWhite16Icon from '../../../icons/search_white-16px.svg';
import { useTranslation } from 'react-i18next';
import { cpuUsage } from 'process';

export interface SearchCond {
  rightSideInfoType: string;
  commonCodeMap: Map<string, CommonCode[]> | undefined;
  handleSetSearchCond: (value: HistorySearchConditionModel | undefined) => void;
  searchCondtion: HistorySearchConditionModel | undefined;
}

export const HistorySearchConditions = (props: SearchCond): ReactElement => {
  const { t } = useTranslation();
  const initSearchCond: HistorySearchConditionModel = {
    name: '',
    approvalStatus: [],
    fromApplyDate: '',
    toApplyDate: '',
    visitorName: '',
  };

  const [searchCond, setSearchCond] = useState<HistorySearchConditionModel>(
    props.searchCondtion ? { ...props.searchCondtion } : { ...initSearchCond }
  );

  const [fromApplyDate, setFromApplyDate] = useState<moment.Moment | null>(
    props.searchCondtion
      ? props.searchCondtion.fromApplyDate
        ? moment(props.searchCondtion.fromApplyDate)
        : null
      : null
  );
  const [toApplyDate, setToApplyDate] = useState<moment.Moment | null>(
    props.searchCondtion ? (props.searchCondtion.toApplyDate ? moment(props.searchCondtion.toApplyDate) : null) : null
  );
  const [statusCodes, setStatusCodes] = useState<string[]>([]);
  const [showCalendar, setShowCalendar] = useState<boolean>(false);

  const getApprovalStatusCode = () => {
    const statusCode: string[] = [];
    if (props.rightSideInfoType === QueryType.APPROVAL) {
      statusCode.push(ApprovalStatusEnum.IP016002);
      statusCode.push(ApprovalStatusEnum.IP016003);
      statusCode.push(ApprovalStatusEnum.IP016004);
      statusCode.push(ApprovalStatusEnum.IP016005);
    } else if (props.rightSideInfoType === QueryType.REQUEST) {
      statusCode.push(ApprovalStatusEnum.IP016002);
      statusCode.push(ApprovalStatusEnum.IP016003);
      statusCode.push(ApprovalStatusEnum.IP016004);
      statusCode.push(ApprovalStatusEnum.IP016005);
      statusCode.push(ApprovalStatusEnum.IP016001);
    }
    setStatusCodes(statusCode);
  };

  const onPeriodChange = (fromApplyDate: moment.Moment, toApplyDate: moment.Moment): void => {
    setSearchCond({
      ...searchCond,
      fromApplyDate: fromApplyDate.format(DateFormat.DATE_IF),
      toApplyDate: toApplyDate.format(DateFormat.DATE_IF),
    });
    setFromApplyDate(fromApplyDate);
    setToApplyDate(toApplyDate);
  };

  const handleVisitorNameCondChange = event => {
    const visitorName = event.target.value.trim();
    setSearchCond({ ...searchCond, visitorName: visitorName });
  };

  const handleNameCondChange = event => {
    const tmpName = event.target.value.trim();
    setSearchCond({ ...searchCond, name: tmpName });
  };

  const handleApprovalStatusChange = (statusCode: string[]) => {
    setSearchCond({ ...searchCond, approvalStatus: statusCode });
  };

  const handleResetCondition = () => {
    setSearchCond({ ...initSearchCond });
    setFromApplyDate(null);
    setToApplyDate(null);
  };

  const handleClickSearchButton = () => {
    const cond: HistorySearchConditionModel = {};
    let hasCondition = false;

    if (searchCond) {
      if (searchCond.name && searchCond.name.trim() !== '') {
        cond.name = searchCond.name;
        hasCondition = true;
      }
      if (searchCond.fromApplyDate && searchCond.fromApplyDate !== '') {
        cond.fromApplyDate = searchCond.fromApplyDate;
        hasCondition = true;
      }
      if (searchCond.toApplyDate && searchCond.toApplyDate !== '') {
        cond.toApplyDate = searchCond.toApplyDate;
        hasCondition = true;
      }
      if (searchCond.approvalStatus && searchCond.approvalStatus.length > 0) {
        cond.approvalStatus = searchCond.approvalStatus;
        hasCondition = true;
      }
      if (searchCond.visitorName && searchCond.visitorName.trim() !== '') {
        cond.visitorName = searchCond.visitorName;
        hasCondition = true;
      }

      if (cond && hasCondition) {
        props.handleSetSearchCond(cond);
      } else {
        props.handleSetSearchCond(undefined);
      }
    } else {
      props.handleSetSearchCond(undefined);
    }
  };

  const showDates = (): string => {
    let date = '';

    if (fromApplyDate && toApplyDate) {
      if (fromApplyDate.format() === toApplyDate.format()) {
        date = '(' + fromApplyDate.format(DateFormat.DATE_DISP) + ')';
      } else {
        date =
          '(' + fromApplyDate.format(DateFormat.DATE_DISP) + ' ~ ' + toApplyDate.format(DateFormat.DATE_DISP) + ')';
      }
    }

    return date;
  };
  const handleClickCloseButton = () => {
    props.handleSetSearchCond(undefined);
  };
  /* eslint-disable */
  useEffect(() => {
    getApprovalStatusCode();
  }, []);
  /* eslint-enable */

  return (
    <Container id="HistorySearchConditions" className={showCalendar ? 'showCalendar' : ''}>
      <div className="searchCond">
        <Row>
          <span>{t('reservation.label.HistorySearchConditions.title.applyDate')}</span>
          <span className="applyDate">{showDates()}</span>
        </Row>

        <Row className="calendarRow">
          <VisitPeriodCalendar
            show={showCalendar}
            className={'outline-gray'}
            fromVisitPeriod={fromApplyDate}
            toVisitPeriod={toApplyDate}
            onPeriodChange={onPeriodChange}
            isCalendarAwalysOpen={false}
            setShowCalendar={setShowCalendar}
          />
        </Row>
        <Row>
          <span>{t('reservation.label.HistorySearchConditions.title.status')}</span>
        </Row>
        <Row>
          <ButtonToolbar className="statusCode d-inline-block">
            <ToggleButtonGroup
              className="outline-gray"
              type="checkbox"
              onChange={handleApprovalStatusChange}
              value={searchCond.approvalStatus}
            >
              {statusCodes.map((sc, i) => {
                return (
                  <ToggleButton value={sc} key={i} id={sc}>
                    {t('reservation.label.HistorySearchConditions.status.' + sc)}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </ButtonToolbar>
        </Row>
        <Row>
          <span>{t('reservation.label.HistorySearchConditions.title.visitorPerson')}</span>
        </Row>
        <Row>
          <InputGroup>
            <div className="form-item">
              <i></i>
              <Form.Control
                id="visitorNameInput"
                data-id="visitorNameInput"
                className="visitorNameInput"
                type="text"
                placeholder={t('reservation.label.HistorySearchConditions.placeholder.visitorPerson')}
                name="visitorName"
                value={searchCond.visitorName}
                onChange={handleVisitorNameCondChange}
              />
            </div>
          </InputGroup>
        </Row>
        <Row>
          <span>
            {t(
              'reservation.label.HistorySearchConditions.title.' +
                (props.rightSideInfoType === QueryType.REQUEST ? 'visitToPerson' : 'requestPerson')
            )}
          </span>
        </Row>
        <Row>
          <InputGroup>
            <div className="form-item">
              <i></i>
              <Form.Control
                id="userNameInput"
                data-id="userNameInput"
                className="userNameInput"
                type="text"
                placeholder={t(
                  'reservation.label.HistorySearchConditions.placeholder.' +
                    (props.rightSideInfoType === QueryType.REQUEST ? 'visitToPerson' : 'requestPerson')
                )}
                name="userName"
                value={searchCond.name}
                onChange={handleNameCondChange}
              />
            </div>
          </InputGroup>
        </Row>
        <Row>
          <Button className="btnReset" variant="dark" onClick={handleResetCondition}>
            {t('reservation.label.HistorySearchConditions.button.reset')}
          </Button>
          <Button className="btnSearch" variant="primary" onClick={handleClickSearchButton}>
            <Image src={searchWhite16Icon} />
            {t('reservation.label.HistorySearchConditions.button.search')}
          </Button>
        </Row>
        <Row className="closeRow">
          <span onClick={handleClickCloseButton}>{t('reservation.label.HistorySearchConditions.button.close')}</span>
        </Row>
      </div>
    </Container>
  );
};

export default HistorySearchConditions;
