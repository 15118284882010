import 'es6-promise/auto';
import axios, { AxiosRequestConfig, Method, AxiosResponse } from 'axios';
import { Service, ServicePort } from './model/Service';
import SessionService from './SessionService';
import { Error } from './model/Error';
import { Status } from './model/enum/Status.enum';
import uuidv4 from 'uuid';

export default class BaseService {
  private environment: string;
  private apiLocation: string;
  private protocol = 'https://';
  protected languageCode = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ko';
  private sessionService = new SessionService();
  protected defaultPagingSize = 10;

  constructor() {
    this.apiLocation = process.env.REACT_APP_API_URL || '';
    this.environment = process.env.REACT_APP_NODE_ENV || '';
  }

  public getAPILocation = (): string => {
    return this.apiLocation;
  };

  public setEnvironment = (env: string): void => {
    this.environment = env;
  };

  protected correlationId = {
    correlationId:
      window.location.pathname === '/'
        ? 'root'.concat('_').concat(uuidv4())
        : window.location.pathname.concat('_').concat(uuidv4()), // 화면 경로 + uuid
  };

  protected headers = {
    Accept: 'application/json', //Accept-Charset은 고려 필요
    Authorization: 'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia', //임의값 적용, 인증관련 로직적용시 변경예정
    'Content-Type': 'application/json',
    'X-Correlation-Id': JSON.stringify(this.correlationId), // header 규칙 확인
    'Correlation-Object': JSON.stringify(this.correlationId), //Back-end 서버 업데이트 후 삭제
    'X-VPORTAL-APIKEY': 'API KEY BY TENANT',
    'X-Language-Code': this.languageCode,
    'X-Session-Id': this.sessionService.getSessionId(),
    'X-VPORTAL-GROUP': window.location.hostname.includes('singlex') ? 'TRIAL' : 'LG',
  };

  protected config: AxiosRequestConfig = {
    method: 'GET',
    url: '',
    headers: this.headers,
    data: {},
  };

  /* eslint-disable */
  public async fnRest(method: string, url: string, requestBody: any, service?: string): Promise<any> {
    this.config.method = this.fnCheckHTTPMethod(method);
    this.config.url = this.fnCheckServiceUrl(url, service ? service : 'etc');
    this.config.data = requestBody ? requestBody : {};

    try {
      const response: AxiosResponse<any> = await axios.request(this.config);

      if (response && response.data) {
        if (response.data.successOrNot) {
          if (response.data.sessionId) {
            this.sessionService.setSessionId(response.data.sessionId);
          }
          return response.data;
        } else {
          const noProperError: Error = {
            successOrNot: 'N',
            statusCode: 'NO.PROPER',
            data: {},
          };
          throw noProperError;
        }
      } else {
        const noResponseError: Error = {
          successOrNot: 'N',
          statusCode: 'NO.RESPONSE',
          data: {},
        };
        throw noResponseError;
      }
    } catch (error) {
      if (error.response && error.response.status === Status.RESPONSE_STATUS_401) {
        this.sessionService.deleteSessionInfo();
        window.location.assign('/unauthorized');
        return false;
      }
      if (error && (error as Error).successOrNot) {
        throw error;
      } else {
        const unknownError: Error = {
          successOrNot: 'N',
          statusCode: 'UNKNOWN.SERVER',
          data: error,
        };
        throw unknownError;
      }
    }
  }
  /* eslint-disable */

  public fnCheckServiceUrl = (url: string, service: string) => {
    let serviceUrl = '';
    if (this.environment === 'local') {
      serviceUrl = this.apiLocation;
      switch (service) {
        case Service.MEMBER:
          serviceUrl += ':' + ServicePort.MEMBER + url;
          break;
        case Service.VISIT:
          serviceUrl += ':' + ServicePort.VISIT + url;
          break;
        case Service.WORKPLACE:
          serviceUrl += ':' + ServicePort.WORKPLACE + url;
          break;
        case Service.INTERFACE:
          serviceUrl += ':' + ServicePort.INTERFACE + url;
          break;
        case Service.NOTIFICATION:
          serviceUrl += ':' + ServicePort.NOTIFICATION + url;
          break;
        case Service.SYSTEM_META:
          serviceUrl += ':' + ServicePort.SYSTEM_META + url;
          break;
        case Service.AUTHORITY:
          serviceUrl += ':' + ServicePort.AUTHORITY + url;
          break;
        case Service.API_KEY:
          serviceUrl += ':' + ServicePort.API_KEY + url;
          break;
        default:
          serviceUrl = url;
      }
    } else {
      switch (service) {
        case Service.MEMBER:
          serviceUrl = this.protocol + Service.MEMBER + '.' + this.apiLocation + url;
          break;
        case Service.VISIT:
          serviceUrl = this.protocol + Service.VISIT + '.' + this.apiLocation + url;
          break;
        case Service.WORKPLACE:
          serviceUrl = this.protocol + Service.WORKPLACE + '.' + this.apiLocation + url;
          break;
        case Service.INTERFACE:
          serviceUrl = this.protocol + Service.INTERFACE + '.' + this.apiLocation + url;
          break;
        case Service.NOTIFICATION:
          serviceUrl = this.protocol + Service.NOTIFICATION + '.' + this.apiLocation + url;
          break;
        case Service.SYSTEM_META:
          serviceUrl = this.protocol + Service.SYSTEM_META + '.' + this.apiLocation + url;
          break;
        case Service.AUTHORITY:
          serviceUrl = this.protocol + Service.AUTHORITY + '.' + this.apiLocation + url;
          break;
        case Service.API_KEY:
          serviceUrl = this.protocol + Service.API_KEY + '.' + this.apiLocation + url;
          break;
        default:
          serviceUrl = url;
      }
    }
    return serviceUrl;
  };

  public fnCheckHTTPMethod = (method: string) => {
    let httpMethod: Method;
    switch (method) {
      case 'GET':
      case 'get':
        httpMethod = 'GET';
        break;
      case 'POST':
      case 'post':
        httpMethod = 'POST';
        break;
      case 'PUT':
      case 'put':
        httpMethod = 'PUT';
        break;
      case 'PATCH':
      case 'patch':
        httpMethod = 'PATCH';
        break;
      case 'DELETE':
      case 'delete':
        httpMethod = 'DELETE';
        break;
      default:
        httpMethod = 'GET';
    }
    return httpMethod;
  };

  public getQueryStringFormat = (queryParam: any) => {
    const keys = Object.keys(queryParam);
    let queryString = '?';
    for (const key of keys) {
      if (queryString.length > 1) {
        queryString = queryString.concat('&');
      }
      queryString = queryString
        .concat(key)
        .concat('=')
        .concat(encodeURIComponent(queryParam[key]));
    }
    return queryString;
  };
}
