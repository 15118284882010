import React, { useState, useEffect, ReactElement, useContext } from 'react';
import { Image, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './RightSideRequestList.scss';
import icoResult from '../../../icons/ico--result.svg';
import moment from 'moment';

import { User } from '../model/User';
import { ReservationResponse, VisitorResponse, QueryType } from '../model/Reservation';
import ReservationService from '../services/ReservationService';
import { ApprovalStatusEnum, UserTypeEnum, MemberTypeCode, DateFormat } from '../model/ReservationConstants';

import update from 'react-addons-update';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CommonCode } from '../model/Code';
import { WorkplaceContent } from '../model/Workplace';
import { reservationDetailUtil } from '../utils/ReservationDetailUtil';
import ReservationDetailModal from './ReservationDetailModal';
import { HistorySearchConditionModel } from '../model/ReservationTypes';
import { useHistory } from 'react-router-dom';
import copyIcon from '../../../icons/ic_copy.svg';
import editIcon from '../../../icons/ic_edit.svg';

import ConfirmModal from './ConfirmModal';
import WorkplaceService from '../services/WorkplaceService';
import { Workplace } from '../model/Workplace';
import AlertModal from './AlertModal';
import MemberService from '../services/MemberService';
import { SearchUser, Reservation, VisitTo, Visitor } from '../model/ReservationTypes';
import { getNullToEmpty } from '../utils/util';
import { ActionType } from '../../../reducer/actions';
import { AuthContext } from '../../../App';

interface RightSideRequestList {
  sessionUser: User;
  reservationService: ReservationService;
  commonCodeMap: Map<string, CommonCode[]> | undefined;
  isRigitSideShow: boolean;
  rightSideInfoType: string;
  isLoading: boolean;
  handleLoading: (value: boolean) => void;
  workplaceService: WorkplaceService;
  memberService: MemberService;
  handleSetReservation: Function;
  searchCondition: HistorySearchConditionModel | undefined;
}

const RightSideRequestList: React.FC<RightSideRequestList> = ({
  sessionUser,
  reservationService,
  commonCodeMap,
  isRigitSideShow,
  rightSideInfoType,
  isLoading,
  handleLoading,
  workplaceService,
  memberService,
  handleSetReservation,
  searchCondition,
}: RightSideRequestList): ReactElement => {
  const { t } = useTranslation();
  const languageCode = localStorage.getItem('lang') ? (localStorage.getItem('lang') as string) : 'ko';

  const [reservationList, setReservationList] = useState<ReservationResponse[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [pagingIndex, setPagingIndex] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const history = useHistory();
  const [confirmModalMessage, setConfirmModalMessage] = useState<string>('');
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showAlertModal, setShowAlertModal] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const { state, dispatch } = useContext(AuthContext);
  const addReservationList = (additionList: ReservationResponse[]) => {
    return update(reservationList, {
      $push: additionList,
    });
  };

  const handleItemClick = (index: number) => {
    setSelectedIndex(index);
    setShowModal(true);
    history.push(history.location.hash + '#requestModal');
  };

  const updateReservationList = (index: number, approvalStatus: ApprovalStatusEnum) => {
    return update(reservationList, {
      [index]: {
        approvalStatus: { $set: approvalStatus },
      },
    });
  };

  const handleModalClose = (approvalStatus?: ApprovalStatusEnum) => {
    history.goBack();
    approvalStatus &&
      selectedIndex !== undefined &&
      setReservationList(reservationList => updateReservationList(selectedIndex, approvalStatus));
  };

  const handleLoadMore = async () => {
    let response;
    if (searchCondition) {
      response = await reservationService.getReservationList(sessionUser, pagingIndex, searchCondition);
    } else {
      response = await reservationService.getReservationList(sessionUser, pagingIndex);
    }
    if (response && response.length > 0) {
      setReservationList(reservationList => addReservationList(response));
      setPagingIndex(pagingIndex + response.length);
      setHasMore(true);
    } else {
      setHasMore(false);
    }
  };

  const getAdditionalVisitor = (applyMemberId: string, applyMemberList: VisitorResponse[]) => {
    return applyMemberList.length > 1 ? applyMemberList.length - 1 : 0;
  };

  const getVisitorInfo = (reservation: ReservationResponse) => {
    let visitorCnt = 0;
    if (reservation.applyMemberList) {
      visitorCnt = getAdditionalVisitor(reservation.applyMemberId, reservation.applyMemberList);
      return reservation.applyMemberList.length > 1
        ? t('reservation.label.RightSideDrawer.body.visitorInfo', {
            applyMember: reservation.applyMemberList[0].applyMemberName,
            count: reservation.applyMemberList.length - 1,
          })
        : reservation.applyMemberList.length === 1
        ? reservation.applyMemberList[0].applyMemberName
        : '';
    }
  };

  const getVisitWorkplace = (reservation: ReservationResponse) => {
    let workplace;
    if (reservation && reservation.workplaceContent && reservation.workplaceContent.length > 0) {
      const workplaceContents: WorkplaceContent[] = JSON.parse(reservation.workplaceContent);
      workplace = workplaceContents.find(
        workplace => workplace.languageCode.toUpperCase() === languageCode.toUpperCase()
      );
    }
    return workplace ? workplace.groupCompanyName.concat(' ', workplace.workplace) : reservation.visitWorkplace;
  };

  const getApplyDateStr = (applyDate: string) => {
    const diff = moment(moment().format(DateFormat.DATE_IF)).diff(moment(applyDate).format(DateFormat.DATE_IF), 'days');
    if (diff === 0) {
      return t('reservation.label.RightSideDrawer.body.applyDate.today');
    } else if (diff > 0 && diff <= 5) {
      return t('reservation.label.RightSideDrawer.body.applyDate.fromNow', {
        days: diff,
      });
    } else {
      return moment(applyDate).format(DateFormat.DATE_DISP);
    }
  };

  const getVisitPeriod = (fromDate, toDate) => {
    if (fromDate === toDate) {
      return moment(fromDate).format(DateFormat.DATE_DISP);
    } else {
      return moment(fromDate)
        .format(DateFormat.DATE_DISP)
        .concat(' ~ ', moment(toDate).format(DateFormat.DATE_DISP))
        .concat(
          ' ',
          t('reservation.label.RightSideDrawer.body.visitPeriodDiff', {
            diff: moment(toDate).diff(moment(fromDate), 'days') + 1,
          })
        );
    }
  };

  const handleCloseConfirmModal = (): void => {
    history.goBack();
  };

  const handleShowConfirmModal = (index: number): void => {
    setSelectedIndex(index);
    let message = t('reservation.label.RightSideDrawer.body.message.copyConfirm');
    if (reservationList[index].approvalStatus === ApprovalStatusEnum.IP016001) {
      message = t('reservation.label.RightSideDrawer.body.message.loadConfirm');
    }
    setConfirmModalMessage(message);
    setShowConfirmModal(true);
    history.push(history.location.hash + '#confirmModal');
  };

  const copyReservationInfo = async () => {
    // validation reservation
    history.goBack();

    if (selectedIndex !== undefined) {
      const copyReservationInfo = reservationList[selectedIndex];
      const reservation: Reservation = {
        visitRequestId:
          copyReservationInfo.approvalStatus === ApprovalStatusEnum.IP016001 ? copyReservationInfo.visitRequestId : '',
        visitors: [],
        fromVisitPeriod: null,
        toVisitPeriod: null,
        termsAgreeYn: '',
        status: copyReservationInfo.approvalStatus,
        sendTalkMessageYesorno: copyReservationInfo.sendTalkMessageYesorno === 'N' ? false : true,
      };
      const workplaceList: Workplace[] | null = await workplaceService.getWorkplaceById(
        copyReservationInfo.visitWorkplaceId
      );
      const today = moment().format(DateFormat.DATE_IF);

      if (copyReservationInfo.approvalStatus === ApprovalStatusEnum.IP016001) {
        reservation.visitRequestId = copyReservationInfo.visitRequestId;
        if (copyReservationInfo.fromVisitPeriod >= today) {
          reservation.fromVisitPeriod = copyReservationInfo.fromVisitPeriod
            ? copyReservationInfo.fromVisitPeriod
            : null;
          reservation.toVisitPeriod = copyReservationInfo.toVisitPeriod ? copyReservationInfo.toVisitPeriod : null;
        }
      }

      if (workplaceList) {
        // 사업장 정보 변경시
        dispatch({ type: ActionType.LOADING, loading: { isWorkplaceLoading: true } });
        if (
          copyReservationInfo.lastUpdateDate &&
          moment(workplaceList[0].lastUpdateDate).format(DateFormat.DATETIME_IF) >
            moment(copyReservationInfo.lastUpdateDate).format(DateFormat.DATETIME_IF)
        ) {
          const message =
            copyReservationInfo.approvalStatus === ApprovalStatusEnum.IP016001
              ? t('reservation.label.RightSideDrawer.body.message.invalidLoadWorkplace')
              : t('reservation.label.RightSideDrawer.body.message.invalidWorkplace');
          dispatch({ type: ActionType.LOADING, loading: { isWorkplaceLoading: false } });
          setAlertMessage(message);
          setShowAlertModal(true);
          history.push(history.location.hash + '#alertModal');
          return;
        } else {
          reservation.workplace = workplaceList[0];
          const employeeId = copyReservationInfo.hostEmployeeId;
          const memberList = await memberService.checkEmployee([+employeeId]);
          // 임직원 퇴사시
          if (!memberList || memberList.length === 0) {
            const message =
              copyReservationInfo.approvalStatus === ApprovalStatusEnum.IP016001
                ? t('reservation.label.RightSideDrawer.body.message.invalidLoadHost')
                : t('reservation.label.RightSideDrawer.body.message.invalidHost');

            setAlertMessage(message);
            dispatch({ type: ActionType.LOADING, loading: { isWorkplaceLoading: false } });
            setShowAlertModal(true);
            history.push(history.location.hash + '#alertModal');
            return;
          } else {
            reservation.visitTo = {
              id: copyReservationInfo.hostEmployeeId,
              name: copyReservationInfo.hostEmployeeName || '',
              employeeNumber: copyReservationInfo.hostEmployeeNumber,
              companyId: copyReservationInfo.hostEmployeeCompanyId,
              companyName: copyReservationInfo.hostEmployeeCompanyName,
              dept: copyReservationInfo.hostEmployeeDepartment || '',
              position: copyReservationInfo.hostEmployeePosition,
            } as VisitTo;
            if (copyReservationInfo.applyMemberList && copyReservationInfo.applyMemberList.length > 0) {
              for (const applyMember of copyReservationInfo.applyMemberList) {
                if (applyMember.applyMemberDivision !== MemberTypeCode.NO_MEM) {
                  const condUser: SearchUser = {
                    name: applyMember.applyMemberName,
                    mobile: applyMember.applyMemberMobilePhone.substring(
                      applyMember.applyMemberMobilePhone.length,
                      applyMember.applyMemberMobilePhone.length - 4
                    ),
                  };
                  const searchMemberList = await memberService.getSearchUsers([], condUser);

                  const findMember = searchMemberList.find(
                    searchMember => searchMember.id === applyMember.applyMemberId
                  );
                  if (!findMember) {
                    const message =
                      copyReservationInfo.approvalStatus === ApprovalStatusEnum.IP016001
                        ? t('reservation.label.RightSideDrawer.body.message.invalidLoadMember')
                        : t('reservation.label.RightSideDrawer.body.message.invalidMember');

                    setAlertMessage(message);
                    dispatch({ type: ActionType.LOADING, loading: { isWorkplaceLoading: false } });
                    setShowAlertModal(true);
                    history.push(history.location.hash + '#alertModal');
                    return;
                  } else {
                    const aUser = await memberService.isValidMember(findMember, workplaceList[0], sessionUser, true);

                    if (aUser.isValid) {
                      const addVisitor: Visitor = {
                        name: aUser.name,
                        id: aUser.id,
                        position: aUser.position,
                        companyName: aUser.companyName,
                        employeeNumber: aUser.employeeNumber,
                        dept: aUser.dept,
                        type: aUser.type,
                        applyMemberMobilePhone: aUser.mobile ? getNullToEmpty(aUser.mobile).replace(/-/gi, '') : '',
                        fromVisitPeriod: null,
                        toVisitPeriod: null,
                        idpUserId: aUser.idpUserId,
                        isValid: aUser.isValid,
                        importedGoodsList: applyMember.importedGoodsList || [],
                        applyMemberCarNumber: applyMember.applyMemberCarNumber,
                      };
                      if (copyReservationInfo.fromVisitPeriod >= today) {
                        addVisitor.fromVisitPeriod = applyMember.fromVisitPeriod ? applyMember.fromVisitPeriod : null;
                        addVisitor.toVisitPeriod = applyMember.toVisitPeriod ? applyMember.toVisitPeriod : null;
                      }
                      reservation.visitors.push(addVisitor);
                    } else {
                      const message =
                        copyReservationInfo.approvalStatus === ApprovalStatusEnum.IP016001
                          ? t('reservation.label.RightSideDrawer.body.message.invalidLoadMember')
                          : t('reservation.label.RightSideDrawer.body.message.invalidMember');
                      dispatch({ type: ActionType.LOADING, loading: { isWorkplaceLoading: false } });
                      setAlertMessage(message);
                      setShowAlertModal(true);
                      history.push(history.location.hash + '#alertModal');
                      return;
                    }
                  }
                } else {
                  const addVisitor: Visitor = {
                    name: applyMember.applyMemberName,
                    id: applyMember.applyMemberId,
                    position: applyMember.applyMemberPosition || '',
                    companyName: applyMember.applyMemberCompanyName || '',
                    employeeNumber: applyMember.applyMemberEmployeeNumber,
                    dept: applyMember.applyMemberDepartment || '',
                    type: UserTypeEnum.NONEMEMBER,

                    applyMemberMobilePhone: applyMember.applyMemberMobilePhone
                      ? getNullToEmpty(applyMember.applyMemberMobilePhone).replace(/-/gi, '')
                      : '',
                    fromVisitPeriod: null,
                    toVisitPeriod: null,
                    isValid: true,
                    importedGoodsList: applyMember.importedGoodsList || [],
                    applyMemberCarNumber: applyMember.applyMemberCarNumber,
                  };

                  if (copyReservationInfo.fromVisitPeriod >= today) {
                    addVisitor.fromVisitPeriod = applyMember.fromVisitPeriod ? applyMember.fromVisitPeriod : null;
                    addVisitor.toVisitPeriod = applyMember.toVisitPeriod ? applyMember.toVisitPeriod : null;
                  }
                  reservation.visitors.push(addVisitor);
                }
              }
            }
          }
        }
        reservation.purpose = copyReservationInfo.visitPurposeCode;
        reservation.purposeDetail = copyReservationInfo.visitPurposeDetail;
        handleSetReservation(reservation);
        dispatch({ type: ActionType.LOADING, loading: { isWorkplaceLoading: false } });
        if (history.location.hash.includes('#requestModal')) {
          history.goBack();
        }
      } else {
        const message =
          copyReservationInfo.approvalStatus === ApprovalStatusEnum.IP016001
            ? t('reservation.label.RightSideDrawer.body.message.invalidLoadWorkplace')
            : t('reservation.label.RightSideDrawer.body.message.invalidWorkplace');

        setAlertMessage(message);
        dispatch({ type: ActionType.LOADING, loading: { isWorkplaceLoading: false } });
        setShowAlertModal(true);
        history.push(history.location.hash + '#alertModal');
        return;
      }
    }
  };

  const onAlertClose = (): void => {
    history.goBack();
  };
  /* eslint-disable */
  useEffect(() => {
    const getInitialReservationList = async () => {
      if (sessionUser) {
        handleLoading(true);
        if (searchCondition) {
          return await reservationService.getReservationList(sessionUser, 0, searchCondition);
        } else {
          return await reservationService.getReservationList(sessionUser, 0);
        }
      }
    };
    isRigitSideShow &&
      sessionUser &&
      getInitialReservationList()
        .then(res => {
          if (res && res.length > 0 && rightSideInfoType === QueryType.REQUEST) {
            setReservationList(res);
            setPagingIndex(0 + res.length);
            setHasMore(true);
          } else {
            setReservationList([]);
            setPagingIndex(0);
            setHasMore(false);
          }
        })
        .finally(() => {
          handleLoading(false);
        });
    if (!history.location.hash.includes('#rightSide')) history.push(history.location.hash + '#rightSide');
  }, [isRigitSideShow, searchCondition]);
  /* eslint-enable */

  /* eslint-disable */
  useEffect(() => {
    const location = history.location;
    if (!location.hash.includes('#requestModal')) {
      setShowModal(false);
    }
    if (!location.hash.includes('#confirmModal')) {
      setShowConfirmModal(false);
    }
    if (!location.hash.includes('#alertModal')) {
      setShowAlertModal(false);
    }
  }, [history.location]);

  /* eslint-enable */

  return (
    <div>
      {reservationList && reservationList.length > 0 ? (
        <InfiniteScroll
          dataLength={reservationList.length}
          next={handleLoadMore}
          hasMore={hasMore}
          loader={<Spinner className="infiniteScrollLoader" animation="border" />}
          className="drawer__request--list"
          scrollableTarget="drawerDiv"
          scrollThreshold={0.9}
        >
          {reservationList.map((value, index) => {
            return (
              <li
                id={value.visitRequestId + index}
                key={value.visitRequestId + index}
                className={value.approvalStatus === ApprovalStatusEnum.IP016002 ? 'drawer__status--ready' : ''}
                data-testid={'selectedReservation' + index}
                onClick={() => handleItemClick(index)}
              >
                <p className="drawer__list--header">
                  <span>
                    <em>{getVisitWorkplace(value)}</em>
                  </span>
                  <span>{getApplyDateStr(value.applyDate)}</span>
                </p>
                <p className="drawer__list--info">
                  {sessionUser.id === value.hostEmployeeId && (
                    <em className="drawer__chip--me">
                      <span>{t('reservation.label.RightSideDrawer.body.badge.me')}</span>
                    </em>
                  )}
                  {value.hostEmployeeName} / {value.hostEmployeeCompanyName} {value.hostEmployeeDepartment}
                </p>
                <div>
                  <div className="drawer__chip">
                    {value.approvalStatus === ApprovalStatusEnum.IP016001 && (
                      <em className="drawer__chip--temp">
                        <span>{t(reservationDetailUtil.getStatusDesc(value.approvalStatus, value.workplaceInfo))}</span>
                      </em>
                    )}
                    {value.approvalStatus === ApprovalStatusEnum.IP016002 && (
                      <em className="drawer__chip--request">
                        <i className="material-icons">more_horiz</i>
                        <span>{t(reservationDetailUtil.getStatusDesc(value.approvalStatus, value.workplaceInfo))}</span>
                      </em>
                    )}
                    {value.approvalStatus === ApprovalStatusEnum.IP016003 && (
                      <em className="drawer__chip--confirm">
                        <i className="material-icons">check</i>
                        <span>{t(reservationDetailUtil.getStatusDesc(value.approvalStatus))}</span>
                      </em>
                    )}
                    {value.approvalStatus === ApprovalStatusEnum.IP016004 && (
                      <em className="drawer__chip--cancel">
                        <i className="material-icons">warning</i>
                        <span>{t(reservationDetailUtil.getStatusDesc(value.approvalStatus))}</span>
                      </em>
                    )}
                    {value.approvalStatus === ApprovalStatusEnum.IP016005 && (
                      <em className="drawer__chip--selfcancel">
                        <span>{t(reservationDetailUtil.getStatusDesc(value.approvalStatus))}</span>
                      </em>
                    )}
                  </div>
                  {value.approvalMessage && <p className="drawer__msg--default">{value.approvalMessage}</p>}
                </div>
                <div className="drawer__extra--info">
                  <p className="drawer__list--item">
                    <span>
                      <em>{t('reservation.label.RightSideDrawer.body.title.visitPeriod')}</em>
                    </span>
                    <span>{getVisitPeriod(value.fromVisitPeriod, value.toVisitPeriod)}</span>
                  </p>
                  <p className="drawer__list--item">
                    <span>
                      <em>{t('reservation.label.RightSideDrawer.body.title.visitor')}</em>
                    </span>
                    <span>{getVisitorInfo(value)}</span>
                  </p>
                  <Image
                    src={value.approvalStatus !== ApprovalStatusEnum.IP016001 ? copyIcon : editIcon}
                    id="loadIcon"
                    data-testid="loadIcon"
                    className="load copyIcon"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleShowConfirmModal(index);
                    }}
                  />
                </div>
              </li>
            );
          })}
        </InfiniteScroll>
      ) : (
        !isLoading && (
          <div className="drawer__none">
            <Image src={icoResult} className="ico--result" />
            <p>{t('reservation.message.info.query.nodata')}</p>
          </div>
        )
      )}
      {showModal && selectedIndex !== undefined && (
        <ReservationDetailModal
          reservation={reservationList[selectedIndex]}
          sessionUser={sessionUser}
          show={showModal}
          commonCodeMap={commonCodeMap}
          onHide={handleModalClose}
          selectedIndex={selectedIndex}
          handleShowConfirmModal={handleShowConfirmModal}
          reservationService={reservationService}
          rightSideInfoType={rightSideInfoType}
        />
      )}
      <ConfirmModal
        message={confirmModalMessage}
        show={showConfirmModal}
        onConfirm={copyReservationInfo}
        handleClose={handleCloseConfirmModal}
      />
      <AlertModal message={alertMessage} show={showAlertModal} onClose={onAlertClose} />
    </div>
  );
};

export default RightSideRequestList;
