import React, { ReactElement, useState, useEffect, useContext } from 'react';
import { NavBar, Footer, SinglexHeader } from '../../layouts';
import { Container, Row, Col, Jumbotron } from 'react-bootstrap';
import './ReservationPage.scss';
import ReservationContainer from './ReservationContainer';
import RightSideContainer from './RightSideContainer';

import ReservationService from './services/ReservationService';
import CommonCodeService from './services/CommonCodeService';
import { User } from './model/User';
import { reservationDetailUtil } from './utils/ReservationDetailUtil';
import { CommonCode } from './model/Code';
import CommonLoadingOverlay from '../../layouts/LoadingOverlay';
import LoginService from '../member/services/LoginService';
import { QueryType } from './model/Reservation';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../App';
import WorkplaceService from './services/WorkplaceService';
import MemberService from './services/MemberService';
import { Reservation } from './model/ReservationTypes';
import { NoticeList } from './model/Workplace';
import CookieService from '../../service/CookieService';
import PopupNoticeModal from './components/PopupNoticeModal';

const ReservationPage = (): ReactElement => {
  const { state } = useContext(AuthContext);
  const [isRigitSideShow, setRigitSideShow] = useState<boolean>(false);
  const [rightSideInfoType, setRightSideInfoType] = useState<string>();
  const [commonCodeMap, setCommonCodeMap] = useState<Map<string, CommonCode[]>>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const loginService = new LoginService();
  const reservationService = new ReservationService();
  const commonCodeService = new CommonCodeService();
  const workplaceService = new WorkplaceService();
  const cookieService = new CookieService();
  const [navBarClassName, setNavBarClassName] = useState<string>();
  const [sessionUser, setSessionUser] = useState<User>();
  const [popupNotice, setPopupNotice] = useState<NoticeList | null>(null);
  const history = useHistory();
  const isLoadingReducer: boolean = state.loading && state.loading.isLoading ? true : false;
  const isLoadingReducerWorkplace: boolean = state.loading && state.loading.isWorkplaceLoading ? true : false;
  const memberService = new MemberService();

  const initReservation: Reservation = {
    visitors: [],
    fromVisitPeriod: null,
    toVisitPeriod: null,
    termsAgreeYn: '',
  };
  const [reservation, setReservation] = useState<Reservation>(initReservation);

  /* eslint-disable */
  /* istanbul ignore next */
  const handleScroll = () => {
    const top = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;

    if (top > 140) {
      setNavBarClassName('background');
    } else {
      setNavBarClassName(undefined);
    }
  };

  const getSessionInfo = async (callback) => {
    setLoading(true);
    await reservationDetailUtil.getSessionUserInfo().then((res: User) => {
      const user: User = res;
      setSessionUser(user);
    });
    callback()
    setLoading(false);
  };

  const getPopupInfo = async () => {
    setLoading(true);
    const cookieDate = cookieService.getCookie('popupDisableDate');
    const today = new Date();
    const todayString =
      today
        .getFullYear()
        .toString()
        .padStart(4, '0') +
      (today.getMonth() + 1).toString().padStart(2, '0') +
      today
        .getDate()
        .toString()
        .padStart(2, '0');

    if (cookieDate !== todayString) {
      await workplaceService.getPopupNotice().then((res: NoticeList[]) => {
        if (res.length > 0) {
          cookieService.deleteCookie('popupDisableDate');
          setPopupNotice(res[0]);
        } else {
          setPopupNotice(null);
        }
      });
    } else {
      setPopupNotice(null);
    }
    setLoading(false);
  };
  /* istanbul ignore next */
  useEffect(() => {
    getSessionInfo(() => getPopupInfo());
    history.replace('/reservation');
    document.body.className = 'reservation';
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  /* eslint-disable */
  useEffect(() => {
    const initCommonCodeMap = async () => {
      setLoading(true);
      return await commonCodeService.initCommonCodeMap();
    };
    sessionUser &&
      isLoading &&
      initCommonCodeMap()
        .then(res => {
          if (res) {
            setCommonCodeMap(res);
          }
        })
        .finally(() => {
          setLoading(false);
        });
  }, [sessionUser]);
  /* eslint-enable */
  /* eslint-disable */
  useEffect(() => {
    const location = history.location;
    if (!location.hash.includes('#rightSide')) {
      setRigitSideShow(false);
      setRightSideInfoType(undefined);
    }
  }, [history.location]);

  useEffect(() => {
    if (rightSideInfoType) setRigitSideShow(true);
    else setRigitSideShow(false);
  }, [rightSideInfoType, isRigitSideShow]);
  /* eslint-enable */

  const handleSideOpen = () => {
    setTimeout(() => {
      history.goBack();
    }, 200);
  };

  const reservationPageBody = (): JSX.Element => {
    const handleRequestHistory = () => {
      if (!isLoading) {
        if (rightSideInfoType !== QueryType.REQUEST) {
          setRightSideInfoType(QueryType.REQUEST);
          setRigitSideShow(false);
        } else {
          setRightSideInfoType(undefined);
        }
      }
    };

    const handleNoticeHistory = () => {
      if (!isLoading) {
        if (rightSideInfoType !== QueryType.APPROVAL) {
          setRightSideInfoType(QueryType.APPROVAL);
          setRigitSideShow(false);
        } else {
          setRightSideInfoType(undefined);
        }
      }
    };

    return (
      <div className={['TopContainer', isRigitSideShow && 'on'].join(' ')} id="ReservationPageTopContainer">
        {sessionUser && (
          <div className="ReservationPage" id="ReservationPage" data-testid="ReservationPage">
            <NavBar
              className={navBarClassName}
              handleNoticeHistory={handleNoticeHistory}
              sessionUser={sessionUser}
              reservationService={reservationService}
              loginService={loginService}
              handleRequestHistory={handleRequestHistory}
            />
            <Jumbotron></Jumbotron>
            <Container>
              <Row>
                <ReservationContainer
                  reservationService={reservationService}
                  commonCodeService={commonCodeService}
                  commonCodeMap={commonCodeMap}
                  sessionUser={sessionUser}
                  setLoading={setLoading}
                  reservation={reservation}
                />
              </Row>
              <Row>
                <Col>
                  <Footer></Footer>
                </Col>
              </Row>
            </Container>
          </div>
        )}
        {sessionUser && isRigitSideShow && rightSideInfoType && (
          <RightSideContainer
            sessionUser={sessionUser}
            reservationService={reservationService}
            commonCodeMap={commonCodeMap}
            isRigitSideShow={isRigitSideShow}
            setRigitSideShow={setRigitSideShow}
            rightSideInfoType={rightSideInfoType}
            workplaceService={workplaceService}
            memberService={memberService}
            setReservation={setReservation}
            handleSideOpen={handleSideOpen}
          />
        )}
        {popupNotice && (
          <PopupNoticeModal
            onHide={(disablePopup: boolean) => {
              if (disablePopup) {
                const today = new Date();
                const todayString =
                  today
                    .getFullYear()
                    .toString()
                    .padStart(4, '0') +
                  (today.getMonth() + 1).toString().padStart(2, '0') +
                  today
                    .getDate()
                    .toString()
                    .padStart(2, '0');
                cookieService.deleteCookie('popupDisableDate');
                cookieService.setCookie('popupDisableDate', todayString);
              }
              setPopupNotice(null);
            }}
            notice={popupNotice}
          />
        )}
      </div>
    );
  };

  return (
    <CommonLoadingOverlay active={isLoading || isLoadingReducer || isLoadingReducerWorkplace}>
      {reservationPageBody}
    </CommonLoadingOverlay>
  );
};

export default ReservationPage;
