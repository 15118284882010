import React, { ReactElement, useState, useEffect } from 'react';
import { Modal, Container, Row, Col, ButtonToolbar, Button, Image, Form } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { Visitor, Reservation, WorkplaceVisitInfo } from '../model/ReservationTypes';
import { User } from '../model/User';
import { PolicyContentsModel, Policy, Workplace } from '../model/Workplace';
import './ReservationEduAndAgreementModal.scss';
import ConfirmModal from './ConfirmModal';

import WorkplaceService from '../services/WorkplaceService';

// https://github.com/wojtekmaj/react-pdf -> api 참고
import { Document, Page, pdfjs } from 'react-pdf';
import CommonSpinner from '../../../layouts/Spinner';
import { useHistory } from 'react-router-dom';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export interface ReservationEduAndAgreementModalI {
  reservation: Reservation;
  user: User;
  policyContents: PolicyContentsModel[];
  initPolicyContent: PolicyContentsModel;
  workplaceVisitInfo: WorkplaceVisitInfo;
  setWorkplaceVisitInfo: (workplaceVisitInfo: WorkplaceVisitInfo) => void;
  show: boolean;
  onHide: () => void;
  setLoading?: Function;
}

export const ReservationEduAndAgreementModal: React.FC<ReservationEduAndAgreementModalI> = (
  props: ReservationEduAndAgreementModalI
): ReactElement => {
  const { t } = useTranslation();
  const workplaceService = new WorkplaceService();

  const workplaceId = props.reservation && props.reservation.workplace ? props.reservation.workplace.workplaceId : '';
  const workplaceName = props.reservation && props.reservation.workplace ? props.reservation.workplace.workplace : '';

  const initPolicyContent: PolicyContentsModel = { ...props.initPolicyContent };

  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedPolicyContent, setSelectedPolicyContent] = useState<PolicyContentsModel>({ ...initPolicyContent });
  const [selectedContentIndex, setSelectedContentIndex] = useState(0);
  const [showCancelAlertConfirmModal, setShowCancelAlertConfirmModal] = useState(false);
  const history = useHistory();
  const onLoadSuccess = (pdf: pdfjs.PDFDocumentProxy): void => {
    setNumPages(pdf.numPages);
  };

  const goToNextPage = () => {
    if (pageNumber + 1 >= numPages) {
      setIsCompleted(true);
      setPageNumber(numPages);
      return false;
    }
    setPageNumber(pageNumber + 1);
  };

  const goToPreviousPage = () => {
    if (pageNumber <= 1) {
      return false;
    }
    setPageNumber(pageNumber - 1);
  };

  const fnSubmit = async (): Promise<void> => {
    props.setLoading && props.setLoading(true);
    await workplaceService
      .savePolicyComplete(workplaceId)
      .then(response => {
        if (response.successOrNot === 'Y') {
          const workplaceVisitInfo: WorkplaceVisitInfo = {
            workplaceNotice: props.workplaceVisitInfo.workplaceNotice || '',
            groupCompanyName: props.workplaceVisitInfo.groupCompanyName,
            workplace: props.workplaceVisitInfo.workplace,
            visitInfoLink: props.workplaceVisitInfo.visitInfoLink,
            requiredEducation: props.workplaceVisitInfo.requiredEducation,
            requiredEducationLink: props.workplaceVisitInfo.requiredEducationLink,
            requestNotiYesorno: props.workplaceVisitInfo.requestNotiYesorno,
            approvalNotiYesorno: props.workplaceVisitInfo.approvalNotiYesorno,
            isPass: true,
          };
          props.setWorkplaceVisitInfo(workplaceVisitInfo);
          props.onHide();
        }
      })
      .finally(() => {
        props.setLoading && props.setLoading(false);
      });
  };

  const fnNextContent = () => {
    if (isCompleted && isChecked) {
      setIsCompleted(false);
      const currentNumPages = numPages;
      setNumPages(0);
      setPageNumber(1);
      setSelectedContentIndex(selectedContentIndex + 1);
      const nextContents = props.policyContents[selectedContentIndex + 1];
      if (nextContents.fileUrl === selectedPolicyContent.fileUrl) {
        setNumPages(currentNumPages);
      }
      setSelectedPolicyContent(nextContents);
      handleCheckChange();
    }
  };

  const fnJustNextContent = () => {
    setSelectedContentIndex(selectedContentIndex + 1);
    setSelectedPolicyContent(props.policyContents[selectedContentIndex + 1]);
    handleCheckChange();
  };

  const tabChange = (i: number) => {
    setSelectedContentIndex(i);
    setSelectedPolicyContent(props.policyContents[i]);
    handleCheckChange();
  };

  const handleCheckChange = () => {
    setIsChecked(!isChecked);
  };

  const fnShowCancelAlert = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    history.push(history.location.hash + '#confirmModal');
    setShowCancelAlertConfirmModal(true);
  };

  const handleCancelAlertConfirm = () => {
    history.goBack();
    props.onHide();
  };

  const handleCancel = () => {
    history.goBack();
  };
  /* eslint-disable */
  useEffect(() => {
    setNumPages(0);
    if (props.show) {
      var viewPort = document.getElementById('viewPort');
      if (viewPort) {
        viewPort.setAttribute(
          'content',
          'width=device-width, initial-scale=1.0, maximum-scale=3.0, minimum-scale=1.0, user-scalable=yes, orientation=portrait'
        );
      }
    } else {
      var viewPort = document.getElementById('viewPort');
      if (viewPort) {
        viewPort.setAttribute(
          'content',
          'width=device-width, initial-scale=1.0, maximum-scale=3.0, minimum-scale=1.0, user-scalable=no, orientation=portrait'
        );
      }
    }
  }, [props.show]);

  useEffect(() => {
    setSelectedPolicyContent(props.initPolicyContent);
    setIsCompleted(false);
    setIsChecked(false);
    setSelectedContentIndex(0);
  }, [props.initPolicyContent, props.show]);

  useEffect(() => {
    setPageNumber(1);
  }, [selectedPolicyContent]);

  useEffect(() => {
    if (numPages === pageNumber) {
      setIsCompleted(true);
    }
  }, [numPages, pageNumber, selectedContentIndex]);
  useEffect(() => {
    const location = history.location;
    if (!location.hash.includes('#confirmModal')) {
      setShowCancelAlertConfirmModal(false);
    }
  }, [history.location]);

  /* eslint-enable */
  return (
    <Modal
      onHide={props.onHide}
      data-testid="reservation-eduAndAgreement-modal"
      centered={true}
      show={props.show}
      className="reservation-edu-agreement-modal"
    >
      <Modal.Header></Modal.Header>
      <Modal.Body>
        <Container className="PDFStepContainer" id="PDFStepContainer" data-testid="PDFStepContainer">
          <Row>
            <Col>
              <div className="pdf-step-group" data-testid="r-ea-modal-title">
                {props.policyContents &&
                  props.policyContents.map((policyContent: PolicyContentsModel, i: number) => {
                    return props.reservation.isPass ? (
                      <button
                        onClick={e => tabChange(i)}
                        className={selectedContentIndex === i ? 'pdf-step active' : 'pdf-step inactive'}
                        key={i}
                        data-testid={'PDFtab_' + i}
                      >
                        <span>0{i + 1}</span>
                        {selectedContentIndex === i && <span>&nbsp;{policyContent.title}</span>}
                      </button>
                    ) : (
                      <span className={selectedContentIndex === i ? 'pdf-step active' : 'pdf-step inactive'} key={i}>
                        <span>0{i + 1}</span>
                        {selectedContentIndex === i && <span>&nbsp;{policyContent.title}</span>}
                      </span>
                    );
                  })}
              </div>
            </Col>
            {props.reservation.isPass && (
              <a className="pdf__modal--close">
                <i className="material-icons pointer" onClick={props.onHide}>
                  close
                </i>
              </a>
            )}
          </Row>
        </Container>
        {props.policyContents && (
          <Container className="PDFContainer" id="PDFContainer" data-testid="PDFContainer">
            {numPages > 1 && (
              <div>
                <div className="pdf__navigation">
                  <span className="pdf__navigation--prev" onClick={goToPreviousPage} id="r-ea-modal-btn-prePage">
                    prev
                  </span>
                  <span className="pdf__navigation--next" onClick={goToNextPage} id="r-ea-modal-btn-nextPage">
                    next
                  </span>
                </div>
              </div>
            )}
            <Document
              file={selectedPolicyContent.fileUrl}
              loading={<CommonSpinner />}
              onLoadSuccess={onLoadSuccess}
              className="pdf-document"
            >
              <Page pageNumber={pageNumber} renderAnnotationLayer={false}>
                <p>
                  {pageNumber} / {numPages}
                </p>
              </Page>
            </Document>
          </Container>
        )}
        {props.reservation.isPass ? (
          <Container
            className="PDFButtonContainerWithoutCheckBox"
            id="PDFButtonContainer"
            data-testid="PDFButtonContainer"
          >
            <Button
              name="next"
              data-testid="r-ea-modal-btn-next"
              variant="primary"
              onClick={selectedContentIndex + 1 === props.policyContents.length ? handleCancel : fnJustNextContent}
            >
              {selectedContentIndex + 1 === props.policyContents.length && (
                <span>{t('reservation.label.button.ok')}</span>
              )}
              {selectedContentIndex + 1 < props.policyContents.length && (
                <span>
                  {t('reservation.label.button.next')}&nbsp;({selectedContentIndex + 1}/{props.policyContents.length})
                </span>
              )}
            </Button>
          </Container>
        ) : (
          <Container className="PDFButtonContainer" id="PDFButtonContainer" data-testid="PDFButtonContainer">
            <Form.Check
              custom
              id="educationAndAgreeCheck"
              type="checkbox"
              name="educationAndAgreeCheck"
              label={
                selectedPolicyContent.typeCode == 'PRIVACY'
                  ? t('reservation.label.ReservationEduAndAgreementModal.checkPrivacyMsg', {
                      title: selectedPolicyContent.title,
                      workspace: workplaceName,
                    })
                  : t('reservation.label.ReservationEduAndAgreementModal.checkMsg', {
                      title: selectedPolicyContent.title,
                      workspace: workplaceName,
                    })
              }
              onChange={handleCheckChange}
              className="d-inline-block"
              checked={isChecked === true ? true : false}
              data-testid="r-ea-modal-agree"
            />
            <Button
              name="cancel"
              data-testid="r-ea-modal-btn-done"
              variant="primary"
              disabled={isCompleted && isChecked ? false : true}
              onClick={
                isCompleted && isChecked && selectedContentIndex + 1 === props.policyContents.length
                  ? fnSubmit
                  : fnNextContent
              }
            >
              {selectedContentIndex + 1 === props.policyContents.length && (
                <span>{t('reservation.label.button.complete')}</span>
              )}
              {selectedContentIndex + 1 < props.policyContents.length && (
                <span>
                  {t('reservation.label.button.next')}&nbsp;({selectedContentIndex + 1}/{props.policyContents.length})
                </span>
              )}
            </Button>
            <a href="#" onClick={fnShowCancelAlert} data-testid="r-ea-modal-disagree" className="agreement__cancel">
              {t('reservation.label.ReservationEduAndAgreementModal.disagreeMsg')}
            </a>
          </Container>
        )}
      </Modal.Body>
      <ConfirmModal
        message={t('reservation.label.ReservationEduAndAgreementModal.cancelAlert')}
        show={showCancelAlertConfirmModal}
        onConfirm={handleCancelAlertConfirm}
        handleClose={handleCancel}
        cancelBtn={t('reservation.label.button.no')}
        confirmBtn={t('reservation.label.button.yes')}
      />
    </Modal>
  );
};

export default ReservationEduAndAgreementModal;
