import { CommonCode } from '../model/Code';
import { User } from '../model/User';
import {
  PurposeTypeEnum,
  PurposeTypeDesc,
  ApprovalStatusEnum,
  ApprovalStatusDesc,
  MemberTypeCode,
  UserTypeEnum,
  ApproveModalTitle,
  UserTypeEnumForClass,
  MemberTypeInfo,
} from '../model/ReservationConstants';
import SessionService from '../../../service/SessionService';
import MemberService from '../services/MemberService';
import { Visitor } from '../model/ReservationTypes';
import WorkplaceService from '../services/WorkplaceService';
import { ResponseAdminAuth } from '../model/Workplace';

class ReservationDetailUtil {
  public getSessionUserInfo = async (): Promise<User> => {
    const sessionService = new SessionService();
    const memberService = new MemberService();
    const sessionInfo = sessionService.getSessionInfo();
    const workplaceService = new WorkplaceService();
    let memberInfo: User;

    if (sessionInfo.memberTypeCode === MemberTypeCode.MEM || sessionInfo.memberTypeCode === MemberTypeCode.EMP) {
      memberInfo = await memberService.getMemberInfo();
      if (sessionInfo.memberTypeCode === MemberTypeCode.EMP) {
        const adminAuthInfo: ResponseAdminAuth = await workplaceService.getAdminAuthByMemberId();
        memberInfo.isWorkplaceAdmin = adminAuthInfo.workplaceAdminYn === 'Y';
        memberInfo.isSystemAdmin = adminAuthInfo.systemAdminYn === 'Y';
        memberInfo.ip = adminAuthInfo.ip;
      }
      return memberInfo;
    } else {
      return {
        id: sessionInfo.memberId + '',
        type: UserTypeEnum.NONEMEMBER,
        memberTypeCode: MemberTypeCode.NO_MEM,
        name: sessionInfo.memberName || '',
        companyName: sessionInfo.noMemCompanyName || '',
        position: sessionInfo.noMemPositionName || '',
        mobile: sessionInfo.noMemPhoneNumber || '',
        dept: '',
        email: '',
      };
    }
  };

  public getMemberTypeInfo = (user: User | Visitor, sessionUser: User): MemberTypeInfo => {
    let result;
    if (
      user.id === sessionUser.id ||
      (sessionUser.memberTypeCode === MemberTypeCode.NO_MEM && user.type === MemberTypeCode.NO_MEM)
    ) {
      result = {
        type: '',
        class: UserTypeEnumForClass.ME,
        desc: 'reservation.label.ReservationBottomForms.visitors.badge.me',
      };
    } else {
      switch (user.type) {
        case UserTypeEnum.LGMEMBER:
        case MemberTypeCode.EMP:
          result = {
            type: MemberTypeCode.EMP,
            class: UserTypeEnumForClass.LGMEMBER,
            desc: 'reservation.label.ReservationBottomForms.visitors.badge.lgMember',
          };
          break;
        case UserTypeEnum.MEMBER:
        case MemberTypeCode.MEM:
          result = {
            type: MemberTypeCode.MEM,
            class: UserTypeEnumForClass.MEMBER,
            desc: 'reservation.label.ReservationBottomForms.visitors.badge.member',
          };
          break;
        case UserTypeEnum.NONEMEMBER:
        case MemberTypeCode.NO_MEM:
          result = {
            type: MemberTypeCode.NO_MEM,
            class: UserTypeEnumForClass.NONEMEMBER,
            desc: 'reservation.label.ReservationBottomForms.visitors.badge.noneMember',
          };
          break;
      }
    }
    return result;
  };

  public getPurposeTypeDesc = (purpose: PurposeTypeEnum): string => {
    return PurposeTypeDesc[purpose];
  };

  public getStatusDesc = (status: ApprovalStatusEnum, workplaceStr?: string): string => {
    let isIntegrateApproval = false;
    if (status === ApprovalStatusEnum.IP016002 && workplaceStr) {
      const workplace = JSON.parse(workplaceStr);
      isIntegrateApproval = workplace.integrateApprovalYesorno === 'Y' ? true : false;
    }

    return isIntegrateApproval
      ? 'reservation.label.ReservationCompleteModal.approval.IP016002_IntegrateApproval'
      : ApprovalStatusDesc[status];
  };

  public getApproveModalTitle = (status: ApprovalStatusEnum): string => {
    return ApproveModalTitle[status];
  };
}
export const reservationDetailUtil = new ReservationDetailUtil();
