export interface Workplace {
  groupCompanyId: string;
  groupCompanyName: string;
  workplaceId: string;
  workplaceType: string;
  workplace: string;
  workplaceAddress: string;
  workplaceNotice: string;
  requiredEducation: number;
  approvalNotiYesorno: string;
  integrateApprovalYesorno: string;
  idpYesorno: string;
  idpUrl: string;
  integrateApprovalUrl: string;
  targetApplicationDivision: string;
  safeEduYesorno: string;
  securityEduYesorno: string;
  safeAgreementYesorno: string;
  securityAgreementYesorno: string;
  privacyAgreementYesorno: string;
  registerAccompanyingVisitorYesorno: string;
  registerNoneMemberYesorno: string;
  visitToPartnerYesorno: string;
  visitToTemporaryYesorno: string;
  visitPurposeYesorno?: string;
  visitImportedGoodsYesorno?: string;
  parkingAbleYesorno: string;
  portSealExceptYesorno: string;
  wifiAbleYesorno: string;
  macAddressYesorno: string;
  duplicateImportedGoodsYesorno: string;
  maxVisitPeriod: number;
  accessInterlockYesorno: string;
  workplaceAuthorityId: string;
  useYesorno: string;
  languageCode: string;
  lastUpdateDate: string;
  memberRegisterImpossibleUrl: string;
  nonmemberRegisterImpossibleUrl: string;
  registerMemberYesorno: string;
  nonmemberRegisterImpossibleMessage?: string;
  memberRegisterImpossibleMessage?: string;
  requestNotiYesorno: string;
  registerVisitorEachPeriodYesorno: string;
  visitQrcodeYesorno: string;
  entryAreaYesorno: string;
  monthlyParkingMaxCount?: number;
}

export interface WorkplaceContent {
  languageCode: string;
  groupCompanyId: string;
  groupCompanyName: string;
  workplace: string;
  workplaceAddress: string;
  workplaceNotice: string;
  approvalNotice: string;
}

export interface PolicyContentsModel {
  title: string;
  fileName: string;
  fileUrl: string;
  isComplete?: boolean;
  typeCode?: string;
}

export interface PolicyCompleteSummary {
  safeEducationComplete: string;
  securityEducationComplete: string;
  safeAgreementComplete: string;
  securityAgreementComplete: string;
  privacyAgreementComplete: string;
}

export enum Policy {
  TYPE_EDUCATION = 'EDUCATION',
  TYPE_AGREEMENT = 'AGREEMENT',
  SUB_SAFE = 'SAFE',
  SUB_SECURITY = 'SECURITY',
}

export interface RequestGetNoticeList {
  languageCode: string;
  startIndex: number;
  pageSize: number;
  requestedId?: string;
  keyword?: string;
  searchType?: string;
}

export interface ResponseGetNoticeList {
  startIndex: number;
  totalPageCount: number;
  noticeList: NoticeList[];
}
export interface ResponseAdminAuth {
  workplaceAdminYn: string;
  systemAdminYn: string;
  ip: string;
}
export interface NoticeList {
  noticeId: string;
  noticeTitle: string;
  noticeContent: string;
  registerDate: string;
  noticeStartDt: string;
  noticeEndDt: string;
  importanceYn: string;
  popupYn: string;
  useYn: string;
  fileName: string;
  fileOriginalName: string;
  filePath: string;
  fileExtension: string;
  fileSize: number;
  fileId: string;
}
export interface FaqRequestModel {
  languageCode: string;
  startIndex: number;
  pageSize: number;
  requestedId?: string;
  keyword?: string;
  searchType?: string;
}
export interface ResponseGetFaqList {
  startIndex: number;
  totalPageCount: number;
  faqList: FaqList[];
}
export interface FaqList {
  faqId: string;
  faqTitle: string;
  faqContent: string;
  registerDate: string;
  fileId: string;
  fileName: string;
  fileOriginalName: string;
  filePath: string;
  fileExtension: string;
  fileSize: number;
  useYn: string;
}
export interface RequestCreateNoticeList {
  noticeTitle: string;
  noticeContent: string;
  noticeStartDt: string;
  noticeEndDt: string;
  noticeFile?: AttachFile;
  importanceYn: string;
  popupYn: string;
  useYn: string;
}

export interface RequestUpdateNoticeList {
  noticeId: string;
  noticeTitle?: string;
  noticeContent?: string;
  noticeStartDt?: string;
  noticeEndDt?: string;
  noticeFile?: AttachFile;
  importanceYn?: string;
  popupYn?: string;
  useYn?: string;
}

export interface RequestCreateFaqList {
  faqTitle: string;
  faqContent: string;
  faqFile?: AttachFile;
  useYn: string;
}

export interface RequestUpdateFaqList {
  faqId: string;
  faqTitle?: string;
  faqContent?: string;
  faqFile?: AttachFile;
  useYn?: string;
}

export interface AttachFile {
  fileExtension: string;
  fileName: string;
  fileOriginalName: string;
  filePath: string;
  fileSize: number;
}
export enum NoticeModifyMode {
  CREATE = 'CREATE', //신규생성
  EDIT = 'EDIT', //수정
  IMPORTANCE = 'IMPORTANCE', //중요도변경
  VISIBLE = 'VISIBLE', //표시여부변경
  POPUP = 'POPUP', //팝업여부변경
}

export enum FAQModifyMode {
  CREATE = 'CREATE', //신규생성
  EDIT = 'EDIT', //수정
  VISIBLE = 'VISIBLE', //표시여부변경
}

export interface ResponseValidVisitQrTime {
  validQrTime: number;
}

export interface EntryAreaList {
  entryAreaId: string;
  workplaceId: string;
  entryAreaCode: string;
  entryAreaKrName: string;
  entryAreaEnName: string;
  idpCode: string;
}
