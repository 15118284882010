export default class CommonConstants {
  public static readonly API_HEADER_CONFIG = {
    headers: {
      'X-Correlation-Id': '123',
    },
  };
  public static readonly DEFAULT_CONTACT_EMAIL: string = 'visitmaster@lgcns.com';
}

export enum BusinessTypeCode {
  JOIN = 'JOIN',
  PSWD = 'PSWD',
  NOMEM = 'NOMEM',
}
