import React, { ReactElement, useState, SyntheticEvent, useEffect } from 'react';
import { Form, Card, InputGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { useTranslation } from 'react-i18next';
import { ImportedGoods } from '../model/ReservationTypes';
import { CommonCode } from '../model/Code';
import { PortSealExceptCodeEnum } from '../model/ReservationConstants';
import './ImportedNotebookForm.scss';
import TextareaAutosize from 'react-textarea-autosize';
import { setRawCookie } from 'react-cookies';
export interface ImportedNotebookFormProps {
  importedGoods: ImportedGoods;
  portSealExceptYesorno: string;
  wifiAbleYesorno: string;
  macAddressYesorno: string;
  onClose: Function;
  errors?: any;
  touched?: any;
  onImportedGoodsChange?: Function;
  portSealExceptCodeList?: CommonCode[];
  importedGoodsPurposeCodeList?: CommonCode[];
  notebookSerialNo?: string;
}

export const ImportedNotebookForm: React.FC<ImportedNotebookFormProps> = (
  props: ImportedNotebookFormProps
): ReactElement => {
  const { t } = useTranslation();
  const importedGoods = { ...props.importedGoods };
  const [importedGoodsDescription, setImportedGoodsDescription] = useState<string>(
    importedGoods.importedGoodsDescription || ''
  );
  const [portSealExceptDescription, setPortSealExceptDescription] = useState<string>(
    importedGoods.importedGoodsPortSealExceptDescription || ''
  );
  const [importedGoodsPurpose, setImportedGoodsPurpose] = useState<string>(importedGoods.importedGoodsPurpose);

  const [showPortSealExceptDropDown, setShowPortSealExceptDropDown] = useState<boolean>(false);
  const [portSealExceptUseYn, setPortSealExceptUseYn] = useState<boolean>(
    importedGoods.importedGoodsPortSealExceptYesOrNo === 'Y' ? true : false
  );
  const [serialNumber, setSerialNumber] = useState<string>(importedGoods.importedGoodsSerialNumber || '');
  const [importedGoodsWifiYesOrNo, setImportedGoodsWifiYesOrNo] = useState<boolean>(
    importedGoods.importedGoodsWifiYesOrNo === 'Y' ? true : false
  );
  const [importedGoodsWifiMacAddress, setImportedGoodsWifiMacAddress] = useState<string>(
    importedGoods.importedGoodsWifiMacAddress || ''
  );
  const getCheckedCodeList = (): CommonCode[] => {
    const checkedPortSealExceptCodeList: CommonCode[] = [];
    props.portSealExceptCodeList &&
      props.portSealExceptCodeList.forEach(commonCode => {
        switch (commonCode.codeId) {
          case PortSealExceptCodeEnum.USB:
            importedGoods.importedGoodsPortSealExceptUsb === 'Y' &&
              checkedPortSealExceptCodeList.push({ codeId: commonCode.codeId, codeName: commonCode.codeName });
            break;
          case PortSealExceptCodeEnum.LAN:
            importedGoods.importedGoodsPortSealExceptLan === 'Y' &&
              checkedPortSealExceptCodeList.push({ codeId: commonCode.codeId, codeName: commonCode.codeName });
            break;
          case PortSealExceptCodeEnum.SD_CARD:
            importedGoods.importedGoodsPortSealExceptSdCard === 'Y' &&
              checkedPortSealExceptCodeList.push({ codeId: commonCode.codeId, codeName: commonCode.codeName });

            break;
          case PortSealExceptCodeEnum.CAMERA:
            importedGoods.importedGoodsPortSealExceptCamera === 'Y' &&
              checkedPortSealExceptCodeList.push({ codeId: commonCode.codeId, codeName: commonCode.codeName });
            break;
          case PortSealExceptCodeEnum.ETC:
            importedGoods.importedGoodsPortSealExceptEtc === 'Y' &&
              checkedPortSealExceptCodeList.push({ codeId: commonCode.codeId, codeName: commonCode.codeName });
            break;
          default:
            break;
        }
      });
    return checkedPortSealExceptCodeList;
  };

  const onClose = (): void => {
    props.onClose(importedGoods.id);
  };

  const [checkedPortSealExceptCodeList, setCheckedPortSealExceptCodeList] = useState<CommonCode[]>(
    getCheckedCodeList()
  );
  const checkedPortSealCodIdList = checkedPortSealExceptCodeList.map(commonCode => commonCode.codeId);
  const getPortSealExceptTitle = (): string => {
    let title = '';
    checkedPortSealExceptCodeList.forEach((commonCode, index) => {
      if (index === 0) {
        title += commonCode.codeName;
      } else {
        title += ', ' + commonCode.codeName;
      }
    });
    return title || t('reservation.label.ImportedNotebookForm.portSealExcept.select');
  };
  const getImportedGoodsPurposeName = (): string => {
    let goodsPurposeCode;
    if (props.importedGoodsPurposeCodeList) {
      goodsPurposeCode = props.importedGoodsPurposeCodeList.find(
        commonCode => commonCode.codeId === importedGoodsPurpose
      );
    }
    return goodsPurposeCode ? goodsPurposeCode.codeName : t('reservation.label.ImportedNotebookForm.purpose.select');
  };
  const onNotebookSerialNumberChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let notebookSn = event.target.value;
    if (notebookSn.length > 100) {
      notebookSn = notebookSn.substring(0, 100);
    }
    setSerialNumber(notebookSn);
    importedGoods.importedGoodsSerialNumber = notebookSn;
    props.onImportedGoodsChange && props.onImportedGoodsChange(importedGoods);
  };

  const onWifiAbleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      setImportedGoodsWifiYesOrNo(true);
      importedGoods.importedGoodsWifiYesOrNo = 'Y';
      props.onImportedGoodsChange && props.onImportedGoodsChange(importedGoods);
    } else {
      setImportedGoodsWifiYesOrNo(false);
      setImportedGoodsWifiMacAddress('');
      importedGoods.importedGoodsWifiMacAddress = '';
      importedGoods.importedGoodsWifiYesOrNo = 'N';
      props.onImportedGoodsChange && props.onImportedGoodsChange(importedGoods);
    }
  };
  const onMacAddressChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setImportedGoodsWifiMacAddress(event.target.value);
    importedGoods.importedGoodsWifiMacAddress = event.target.value;
    props.onImportedGoodsChange && props.onImportedGoodsChange(importedGoods);
  };

  const onImportedGoodsPurposeChange = (eventKey: any, event: Record<string, any>): void => {
    setImportedGoodsPurpose(eventKey);
    importedGoods.importedGoodsPurpose = eventKey;
    props.onImportedGoodsChange && props.onImportedGoodsChange(importedGoods);
  };
  const onPortSealExceptUseYnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      importedGoods.importedGoodsPortSealExceptYesOrNo = 'Y';
    } else {
      importedGoods.importedGoodsPortSealExceptYesOrNo = 'N';
      importedGoods.importedGoodsPortSealExceptUsb = 'N';
      importedGoods.importedGoodsPortSealExceptLan = 'N';
      importedGoods.importedGoodsPortSealExceptSdCard = 'N';
      importedGoods.importedGoodsPortSealExceptCamera = 'N';
      importedGoods.importedGoodsPortSealExceptEtc = 'N';
      importedGoods.importedGoodsPortSealExceptDescription = '';

      setCheckedPortSealExceptCodeList([]);
      setPortSealExceptDescription('');
    }
    setPortSealExceptUseYn(event.target.checked);
    props.onImportedGoodsChange && props.onImportedGoodsChange(importedGoods);
  };

  const onImportedGoodsDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    let description = event.target.value;
    if (description.length > 150) {
      description = description.substring(0, 150);
    }
    setImportedGoodsDescription(description);
    importedGoods.importedGoodsDescription = description;
    props.onImportedGoodsChange && props.onImportedGoodsChange(importedGoods);
  };

  const onPortSealExceptDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    let description = event.target.value;
    if (description.length > 100) {
      description = description.substring(0, 100);
    }
    setPortSealExceptDescription(description);
    importedGoods.importedGoodsPortSealExceptDescription = description;
    props.onImportedGoodsChange && props.onImportedGoodsChange(importedGoods);
  };
  const onSelectPortSealExcept = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const checkedCode = [...checkedPortSealExceptCodeList];
    const eventCode = event.target.value;
    const checked = event.target.checked;
    const value = checked ? 'Y' : 'N';
    switch (eventCode) {
      case PortSealExceptCodeEnum.USB:
        importedGoods.importedGoodsPortSealExceptUsb = value;
        break;
      case PortSealExceptCodeEnum.LAN:
        importedGoods.importedGoodsPortSealExceptLan = value;
        break;
      case PortSealExceptCodeEnum.SD_CARD:
        importedGoods.importedGoodsPortSealExceptSdCard = value;
        break;
      case PortSealExceptCodeEnum.CAMERA:
        importedGoods.importedGoodsPortSealExceptCamera = value;
        break;
      case PortSealExceptCodeEnum.ETC:
        importedGoods.importedGoodsPortSealExceptEtc = value;
        break;
      default:
        break;
    }
    if (checked) {
      checkedCode.push({ codeId: eventCode, codeName: event.target.name });
      setCheckedPortSealExceptCodeList(checkedCode);
    } else {
      setCheckedPortSealExceptCodeList(checkedCode.filter(commonCode => commonCode.codeId !== eventCode));
    }
    props.onImportedGoodsChange && props.onImportedGoodsChange(importedGoods);
  };

  const onToggle = (
    isOpen: boolean,
    event: SyntheticEvent<Dropdown<'div'>, Event>,
    metadata: { source: 'select' | 'click' | 'rootClose' | 'keydown' }
  ): void => {
    if (metadata.source === 'click' || !metadata.source) {
      setShowPortSealExceptDropDown(isOpen);
    }
  };
  /* eslint-disable */
  useEffect(() => {
    if (!serialNumber && props.notebookSerialNo) {
      setSerialNumber(props.notebookSerialNo);
      importedGoods.importedGoodsSerialNumber = props.notebookSerialNo;
      props.onImportedGoodsChange && props.onImportedGoodsChange(importedGoods);
    }
  }, [props.notebookSerialNo]);
  /* eslint-enable */
  return (
    <Card id="importedNotebookForm" data-testid="importedNotebookForm" className="importedNotebookForm">
      <Card.Header>
        {importedGoods.importedGoodsName}
        <i id="closeIcon" data-testid="itemCloseIcon" className="material-icons pointer" onClick={onClose}>
          close
        </i>
      </Card.Header>
      <Card.Body>
        <InputGroup
          id="serialNumberGroup"
          data-testid="serialNumberGroup"
          className={
            props.errors &&
            props.touched &&
            props.errors.importedGoodsSerialNumber &&
            props.touched.importedGoodsPurpose
              ? 'required error'
              : 'required'
          }
        >
          <InputGroup.Prepend>
            <InputGroup.Text>{t('reservation.label.ImportedNotebookForm.serialNumber.name')}</InputGroup.Text>
          </InputGroup.Prepend>
          <i className="required"></i>
          <Form.Control
            id="notebookSerialNumber"
            data-testid="notebookSerialNumber"
            aria-label="notebookSerialNumber"
            type="text"
            name="importedGoodsSerialNumber"
            onChange={onNotebookSerialNumberChange}
            value={serialNumber}
            maxLength={100}
            placeholder={t('reservation.label.ImportedNotebookForm.serialNumber.placeholder')}
          />
        </InputGroup>
        <InputGroup
          id="importedGoodsPurposeGroup"
          data-testid="importedGoodsPurposeGroup"
          className={
            props.errors && props.touched && props.errors.importedGoodsPurpose && props.touched.importedGoodsPurpose
              ? 'required error'
              : 'required'
          }
        >
          <InputGroup.Prepend>
            <InputGroup.Text>{t('reservation.label.ImportedNotebookForm.purpose.name')}</InputGroup.Text>
          </InputGroup.Prepend>
          <i className="required"></i>
          <DropdownButton
            id="importedGoodsPurpose"
            className="importedGoodsPurpose"
            title={getImportedGoodsPurposeName()}
          >
            {props.importedGoodsPurposeCodeList &&
              props.importedGoodsPurposeCodeList.map((commonCode: CommonCode, index) => (
                <Dropdown.Item
                  onSelect={onImportedGoodsPurposeChange}
                  className="importedGoodsPurpose"
                  key={commonCode.codeId}
                  eventKey={commonCode.codeId}
                  data-testid="importedGoodsPurposeItem"
                >
                  {commonCode.codeName}
                </Dropdown.Item>
              ))}
          </DropdownButton>
        </InputGroup>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>{t('reservation.label.ImportedNotebookForm.description.name')}</InputGroup.Text>
          </InputGroup.Prepend>

          <TextareaAutosize
            className="contentEdit"
            value={importedGoodsDescription}
            onChange={onImportedGoodsDescriptionChange}
            placeholder={t('reservation.label.ImportedNotebookForm.description.placeholder')}
            id="importedGoodsDescription"
            data-testid="importedGoodsDescription"
            maxRows={5}
            minRows={1}
          />
        </InputGroup>
        {props.wifiAbleYesorno === 'Y' && (
          <InputGroup
            id="importedGoodsWifiYesOrNoGroup"
            data-testid="importedGoodsWifiYesOrNoGroup"
            className={
              props.errors &&
              props.touched &&
              props.errors.importedGoodsWifiMacAddress &&
              props.touched.importedGoodsPurpose
                ? 'text required error'
                : 'text required'
            }
          >
            <InputGroup.Prepend>
              <InputGroup.Text>{t('reservation.label.ImportedNotebookForm.wifi.name')}</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Check
              type="switch"
              id={`importedGoodsWifiYesOrNo${importedGoods.id}`}
              data-testid="importedGoodsWifiYesOrNo"
              className="secondary d-inline-block"
              name="importedGoodsWifiYesOrNo"
              label=""
              onChange={onWifiAbleChange}
              checked={importedGoodsWifiYesOrNo}
            />
            {props.macAddressYesorno === 'Y' && importedGoodsWifiYesOrNo && (
              <div className="d-inline-block macAddressGroup">
                <i className="required"></i>
                <InputMask
                  mask="MM-MM-MM-MM-MM-MM"
                  formatChars={{
                    M: '^[a-fA-F0-9]*$',
                  }}
                  maskChar={null}
                  value={importedGoodsWifiMacAddress}
                  onChange={onMacAddressChange}
                >
                  {(inputProps: any) => (
                    <Form.Control
                      id="importedGoodsWifiMacAddress"
                      name="importedGoodsWifiMacAddress"
                      data-testid="importedGoodsWifiMacAddress"
                      type="text"
                      placeholder={t('reservation.label.ImportedNotebookForm.macAddress.placeholder')}
                      value={inputProps.importedGoodsWifiMacAddress}
                      maxLength={17}
                    />
                  )}
                </InputMask>
              </div>
            )}
          </InputGroup>
        )}

        {props.portSealExceptYesorno === 'Y' && (
          <InputGroup className="importedGoodsPortSealExceptYesOrNo">
            <InputGroup.Prepend>
              <InputGroup.Text>{t('reservation.label.ImportedNotebookForm.portSealExcept.name')}</InputGroup.Text>
            </InputGroup.Prepend>
            <div
              id="portSealExceptGroup"
              data-testid="portSealExceptGroup"
              className={
                portSealExceptUseYn
                  ? props.errors &&
                    props.touched &&
                    props.errors.importedGoodsPortSealExcept &&
                    props.touched.importedGoodsPurpose
                    ? 'portSealExceptGroup d-flex required error'
                    : 'portSealExceptGroup d-flex required'
                  : 'd-flex required'
              }
            >
              <Form.Check
                type="switch"
                id={`importedGoodsPortSealExceptYesOrNo${importedGoods.id}`}
                data-testid="importedGoodsPortSealExceptYesOrNo"
                className="secondary d-inline-block"
                name="importedGoodsPortSealExceptYesOrNo"
                label=""
                onChange={onPortSealExceptUseYnChange}
                checked={portSealExceptUseYn}
              />
              {portSealExceptUseYn && (
                <Dropdown onToggle={onToggle} show={showPortSealExceptDropDown} className="d-inline-block">
                  <i className="required"></i>
                  <Dropdown.Toggle
                    disabled={!portSealExceptUseYn}
                    id="portSealExceptCodeList"
                    data-testid="portSealExceptCodeList"
                  >
                    {getPortSealExceptTitle()}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {props.portSealExceptCodeList &&
                      props.portSealExceptCodeList.map((commonCode: CommonCode, i: number) => {
                        return (
                          <Form.Check
                            key={i}
                            custom
                            type="checkbox"
                            id={commonCode.codeId + '-' + importedGoods.id}
                            data-testid="portSealExceptCode"
                            className={
                              checkedPortSealCodIdList.includes(commonCode.codeId)
                                ? 'portSealExceptCode checked'
                                : 'portSealExceptCode'
                            }
                            label={commonCode.codeName}
                            name={commonCode.codeName}
                            onChange={onSelectPortSealExcept}
                            value={commonCode.codeId}
                            checked={checkedPortSealCodIdList.includes(commonCode.codeId) ? true : false}
                          />
                        );
                      })}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
            {portSealExceptUseYn && (
              <div className="d-inlinie-block portSealExceptDescriptionGroup">
                <i className="portSealExceptDescription required"></i>
                <TextareaAutosize
                  value={portSealExceptDescription}
                  onChange={onPortSealExceptDescriptionChange}
                  placeholder={t('reservation.label.ImportedNotebookForm.portSealExceptDescription.placeholder')}
                  id="importedGoodsPortSealExceptDescription"
                  disabled={!portSealExceptUseYn}
                  className={
                    props.errors &&
                    props.touched &&
                    props.errors.importedGoodsPortSealExceptDescription &&
                    props.touched.importedGoodsPurpose
                      ? 'contentEdit portSealExceptDescription required error'
                      : 'contentEdit portSealExceptDescription required'
                  }
                  data-testid="importedGoodsPortSealExceptDescription"
                  maxRows={5}
                  minRows={1}
                />
              </div>
            )}
          </InputGroup>
        )}
      </Card.Body>
    </Card>
  );
};

export default ImportedNotebookForm;
