import React, { useState, useEffect, useContext } from 'react';
import './PrivacyPolicyModal.scss';
import { Modal, Container, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TermService from '../../services/TermService';
import PrivacyPolicyVersion from '../../model/PrivacyPolicyVersion';
import PrivayPolicyVersionResponse from '../../model/PrivacyPolicyVersionResponse';
import { AuthContext } from '../../../../App';
import { ActionType } from '../../../../reducer/actions';

export interface PrivacyPolicyModal {
  onHide: () => void;
}
const versionMap = new Map<number, string>();
export const PrivacyPolicyModal: React.FC<PrivacyPolicyModal> = (props: PrivacyPolicyModal) => {
  const termService = new TermService();
  const { t } = useTranslation();
  const { dispatch } = useContext(AuthContext);
  const [currentVersion, setCurrnetVersion] = useState<string>();
  const [currentVersionId, setCurrnetVersionId] = useState<number>();
  const [privacyPolicyVersionItem, setPrivacyPolicyVersionItem] = useState<string>();
  const [privacyPolicyDetail, setPrivacyPolicyDetail] = useState<string>('');
  const handleSelect = (eventKey: any, event: Record<string, any>) => {
    setCurrnetVersion(versionMap.get(+eventKey));
    setCurrnetVersionId(eventKey);
  };

  const setLoading = (_isLoading: boolean) => {
    dispatch({ type: ActionType.LOADING, loading: { isLoading: _isLoading } });
  };
  /* eslint-disable */
  useEffect(() => {
    if (currentVersionId) {
      const fetchTermData = async () => {
        return await termService.requestPrivacyPolicy(currentVersionId);
      };
      setLoading(true);
      fetchTermData().then(res => {
        if (res.successOrNot === 'Y') {
          setPrivacyPolicyDetail(res.data[0].termsDetailContents);
        } else {
          setPrivacyPolicyDetail('');
        }
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [currentVersionId]);

  useEffect(() => {
    let dropdownArea = [] as any;
    const fetchTermData = async () => {
      return await termService.requestPrivacyPolicyVersion();
    };
    setLoading(true);
    fetchTermData().then(res => {
      if (res.successOrNot === 'Y' && res.data) {
        const privacyPolicyVersionInfo: PrivacyPolicyVersion[] = res.data as PrivacyPolicyVersion[];
        for (let i = 0; i < privacyPolicyVersionInfo?.length; i++) {
          const dropdownItem = (
            <Dropdown.Item key={i} eventKey={privacyPolicyVersionInfo[i].termsId + ''}>
              {privacyPolicyVersionInfo[i].termsVersion}
            </Dropdown.Item>
          );
          dropdownArea.push(dropdownItem);
          versionMap.set(privacyPolicyVersionInfo[i].termsId, privacyPolicyVersionInfo[i].termsVersion);
        }
        setCurrnetVersion(privacyPolicyVersionInfo[0].termsVersion);
        setPrivacyPolicyVersionItem(dropdownArea);
        setCurrnetVersionId(privacyPolicyVersionInfo[0].termsId);
      } else {
        setPrivacyPolicyDetail('');
      }
    }).finally(() => {
      setLoading(false);
    });
  }, []);
  /* eslint-enable */

  return (
    <Modal
      show={true}
      onHide={props.onHide}
      centered={true}
      className="privacy-policy-modal"
      data-testid="privacy-policy-modal-test"
      id="PrivacyPolicyModal"
    >
      <Modal.Header closeButton>
        <div className="title bold">{t('member.label.PrivacyPolicyModal.header.title')}</div>
        <div className="modal__header--info">{t('member.label.PrivacyPolicyModal.header.titleSub')}</div>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <div className="modal__subheader">
            <div className="modal__subheader--dropdown">
              <label>{t('member.label.PrivacyPolicyModal.header.versionLabel')}</label>
              <Dropdown onSelect={handleSelect}>
                <Dropdown.Toggle id="dropdown">{currentVersion}</Dropdown.Toggle>
                <Dropdown.Menu>{privacyPolicyVersionItem}</Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="divTerms">
            <div
              className="terms-content"
              data-testid="terms-acception-detail"
              dangerouslySetInnerHTML={{
                __html: privacyPolicyDetail,
              }}
            />
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default PrivacyPolicyModal;
