import React, { ReactElement, useContext } from 'react';
import { Modal, Image } from 'react-bootstrap';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './SinglexModal.scss';
import image01 from '../icons/photo/images-01-new.png';
import image02 from '../icons/photo/images-02-new.png';
import image03 from '../icons/photo/images-03-new.png';
import CookieService from '../service/CookieService';
import { AuthContext } from '../App';
import { ActionType } from '../reducer/actions';
import SessionService from '../service/SessionService';

export interface SinglexModalProps {
  show: boolean;
  onClose: Function;
}

export const SinglexModal: React.FC<SinglexModalProps> = (props: SinglexModalProps): ReactElement => {
  const sessionService = new SessionService();
  const cookieService = new CookieService();
  const { state, dispatch } = useContext(AuthContext);
  const onClose = () => {
    const isGuideClose = window.confirm('가이드 투어를 종료하시겠습니까??');
    if (isGuideClose) {
      dispatch({ type: ActionType.CLOSE_TOOLTIP, closeTooltip: true });
      props.onClose();
    }
  };

  const onStart = () => {
    dispatch({ type: ActionType.USE_TOOLTIP, useTooltip: true });
    sessionService.setSessionStorageValue('trialGuideOn', 'Y');
    props.onClose();
  };

  const onCloseToday = () => {
    dispatch({ type: ActionType.USE_TOOLTIP, useTooltip: false });
    sessionService.setSessionStorageValue('trialGuideOn', 'N');
    const today = new Date();
    const todayString =
      today
        .getFullYear()
        .toString()
        .padStart(4, '0') +
      (today.getMonth() + 1).toString().padStart(2, '0') +
      today
        .getDate()
        .toString()
        .padStart(2, '0');
    cookieService.deleteCookie('singlexModalDisableDate');
    cookieService.setCookie('singlexModalDisableDate', todayString);
    dispatch({ type: ActionType.CLOSE_TOOLTIP, closeTooltip: true });
    props.onClose();
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    draggable: true,
    arrows: true,
  };
  return (
    <Modal
      id="singlexModal"
      data-testid="singlexModal"
      show={props.show}
      onHide={onClose}
      className="singlexModal"
      enforceFocus={false}
      centered
    >
      <Modal.Body>
        <div className="quick-wrapper quick-pos">
          <div className="inner">
            <div className="quick-header">
              <h2 className="blind">Quick Guide</h2>
            </div>
            <div className="quick-conts">
              <h3 className="title">
                <strong>
                  SINGLEX 방문예약
                  <br />
                  가이드투어와 함께 시작해볼까요?
                </strong>
              </h3>
              <div className="rolling-wrap">
                <Slider {...settings}>
                  <div className="rolling-item">
                    <div className="item-img">
                      <Image src={image01} alt="" />
                    </div>
                    <div className="item-info">
                      <dl>
                        <dt className="blind">PC버전 안내</dt>
                        <dd>
                          번거로운 절차와 기다림 없이
                          <br />
                          빠르게 원하시는 사업장을 방문할 수 있어요!
                        </dd>
                      </dl>
                    </div>
                  </div>
                  <div className="rolling-item">
                    <div className="item-img">
                      <Image src={image02} alt="" />
                    </div>
                    <div className="item-info">
                      <dl>
                        <dt className="blind">신청내역 안내</dt>
                        <dd>
                          신청 내역은 간편하게 조회하고,
                          <br />
                          다음 예약은 One- Click으로 더 스마트하게!
                        </dd>
                      </dl>
                    </div>
                  </div>
                  <div className="rolling-item">
                    <div className="item-img">
                      <Image src={image03} alt="" />
                    </div>
                    <div className="item-info">
                      <dl>
                        <dt>3. 신청 내역 조회</dt>
                        <dd>방문 예약 신청 현황 조회 및 One-Click 재신청</dd>
                      </dl>
                    </div>
                  </div>
                </Slider>
              </div>
              <div className="btn-action">
                <a href="#none" className="btn-round btn-start" onClick={() => onStart()}>
                  시작하기
                </a>
              </div>
            </div>
          </div>
          <div className="quick-footer">
            <div className="quick-close">
              <button type="button" className="btn-close" title="Quick Guide (닫기)" onClick={() => onClose()}></button>
            </div>
            <div className="quick-today">
              <button type="button" className="btn-today" onClick={() => onCloseToday()}>
                오늘 하루 보지 않기
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SinglexModal;
