import { Service } from '../../../service/model/Service';
import BaseService from '../../../service/BaseService';

import {
  PolicyCompleteSummary,
  RequestGetNoticeList,
  ResponseGetNoticeList,
  FaqRequestModel,
  ResponseGetFaqList,
  ResponseAdminAuth,
  NoticeList,
  ResponseValidVisitQrTime,
} from '../model/Workplace';
import { UserTypeEnum } from '../model/ReservationConstants';

export default class WorkplaceService extends BaseService {
  async getWorkplaceByKeyword(keyword?: string, isId?: boolean) {
    const serviceName = Service.WORKPLACE;
    const method = 'GET';

    const queryParam: any = isId
      ? {
          id: keyword,
        }
      : {
          keyword: keyword,
        };

    const url = '/v1/workplace' + this.getQueryStringFormat(queryParam);

    let response;
    try {
      response = await this.fnRest(method, url, null, serviceName);
    } catch (error) {
      response = error;
    }
    return response.data && response.data.length > 0 ? response.data : null;
  }

  async getPolicyAllContents(workplaceId: string): Promise<any> {
    const serviceName = Service.WORKPLACE;
    const method = 'GET';

    const queryParam: any = {
      workplaceId: workplaceId,
    };

    const url = '/v1/policyContentsAll' + this.getQueryStringFormat(queryParam);

    let response;
    try {
      response = await this.fnRest(method, url, null, serviceName);
    } catch (error) {
      response = error;
    }
    return response && response.successOrNot === 'Y' ? response.data : null;
  }

  async getPolicyWorkplaceContents(workplaceId: string): Promise<any> {
    const serviceName = Service.WORKPLACE;
    const method = 'GET';

    const queryParam: any = {
      workplaceId: workplaceId,
    };

    const url = '/v1/policyContentsWorkplace' + this.getQueryStringFormat(queryParam);

    let response;
    try {
      response = await this.fnRest(method, url, null, serviceName);
    } catch (error) {
      response = error;
    }
    return response && response.successOrNot === 'Y' ? response.data : null;
  }

  async getPolicyCompleteSummary(
    workplaceId: string,
    memberId: string,
    memberType: string
  ): Promise<PolicyCompleteSummary | null> {
    const serviceName = Service.WORKPLACE;
    const method = 'GET';

    let queryParam: any = {};
    if (memberType !== UserTypeEnum.NONEMEMBER) {
      queryParam = {
        workplaceId: workplaceId,
        memberId: memberId,
      };
    } else {
      queryParam = {
        workplaceId: workplaceId,
        termAgreeId: memberId,
      };
    }

    const url = '/v1/policyComplete' + this.getQueryStringFormat(queryParam);

    let response;
    try {
      response = await this.fnRest(method, url, null, serviceName);
    } catch (error) {
      response = error;
    }

    return response.data ? (response.data as PolicyCompleteSummary) : null;
  }

  async savePolicyComplete(workplaceId: string): Promise<any> {
    const serviceName = Service.WORKPLACE;
    const method = 'POST';
    const url = '/v1/policyCompleteAll';
    const body = {
      workplaceId: workplaceId,
    };
    let response: unknown;
    try {
      response = await this.fnRest(method, url, body, serviceName);
    } catch (error) {
      response = error;
    }
    return response;
  }

  async getAdminAuthByMemberId(): Promise<ResponseAdminAuth> {
    const serviceName = Service.WORKPLACE;
    const method = 'GET';
    const url = '/v1/workplaceManagementAuth';
    let response: ResponseAdminAuth = { workplaceAdminYn: 'N', systemAdminYn: 'N', ip: '' };

    try {
      const res = await this.fnRest(method, url, null, serviceName);

      if (res && res.successOrNot === 'Y' && res.data) {
        response = {
          workplaceAdminYn: res.data.workplaceAdminYn || 'N',
          systemAdminYn: res.data.systemAdminYn || 'N',
          ip: res.data.ip || '',
        };
      }
    } catch (error) {
      response = { workplaceAdminYn: 'N', systemAdminYn: 'N', ip: '' };
    }

    return response;
  }

  async getNoticeList(request: RequestGetNoticeList): Promise<ResponseGetNoticeList> {
    const serviceName = Service.WORKPLACE;
    const method = 'GET';

    let queryParam: any = {
      startIndex: request.startIndex,
      pageSize: request.pageSize,
    };

    if (request.requestedId) {
      queryParam = { ...queryParam, requestedId: request.requestedId };
    }

    if (request.keyword) {
      queryParam = { ...queryParam, keyword: request.keyword, searchType: request.searchType };
    }

    const url = '/v1/notice' + this.getQueryStringFormat(queryParam);
    let response;
    try {
      response = await this.fnRest(method, url, null, serviceName);
    } catch (error) {
      response = error;
    }
    return response && response.successOrNot === 'Y' ? response.data : null;
  }

  async getWorkplaceById(id: string) {
    const serviceName = Service.WORKPLACE;
    const method = 'GET';

    const queryParam: any = {
      id: id,
    };

    const url = '/v1/workplace' + this.getQueryStringFormat(queryParam);

    let response;
    try {
      response = await this.fnRest(method, url, null, serviceName);
    } catch (error) {
      response = error;
    }
    return response.data && response.data.length > 0 ? response.data : null;
  }

  async getFAQList(request: FaqRequestModel): Promise<ResponseGetFaqList> {
    const serviceName = Service.WORKPLACE;
    const method = 'GET';

    let queryParam: any = {
      startIndex: request.startIndex,
      pageSize: request.pageSize,
    };

    if (request.requestedId) {
      queryParam = { ...queryParam, requestedId: request.requestedId };
    }

    if (request.keyword) {
      queryParam = { ...queryParam, keyword: request.keyword, searchType: request.searchType };
    }

    const url = '/v1/faq' + this.getQueryStringFormat(queryParam);
    let response;
    try {
      response = await this.fnRest(method, url, null, serviceName);
    } catch (error) {
      response = error;
    }
    return response && response.successOrNot === 'Y' ? response.data : null;
  }

  async getPopupNotice(): Promise<NoticeList[]> {
    const serviceName = Service.WORKPLACE;
    const method = 'GET';

    const url = '/v1/notice/popup';
    let response;
    try {
      response = await this.fnRest(method, url, null, serviceName);
    } catch (error) {
      response = error;
    }
    return response && response.successOrNot === 'Y' ? response.data : [];
  }

  async uploadPolicyContents(workplaceId: string, file: File): Promise<any> {
    const serviceName = Service.WORKPLACE;
    const method = 'POST';

    const queryParam: any = {
      workplaceId: workplaceId,
    };

    const url = '/v1/workplaceManagement/policyContentsUpload' + this.getQueryStringFormat(queryParam);

    const formData = new FormData();
    formData.append('policyContentsUpload', file);

    let response;
    try {
      response = await this.fnRest(method, url, formData, serviceName);
    } catch (error) {
      response = error;
    }
    return response && response.successOrNot === 'Y' ? response.data : null;
  }

  async getVisitEntryCount(workplaceId: string) {
    const serviceName = Service.WORKPLACE;
    const method = 'GET';
    const queryParam = { workplaceId: workplaceId };
    const url = '/v1/visitEntryCount' + this.getQueryStringFormat(queryParam);
    let response;

    try {
      response = await this.fnRest(method, url, null, serviceName);
    } catch (error) {
      response = error;
    }
    return response && response.successOrNot === 'Y' ? response.data : null;
  }

  async getValidVisitQrTime(workplaceId: string): Promise<ResponseValidVisitQrTime> {
    const serviceName = Service.WORKPLACE;
    const method = 'GET';
    const queryParam = { workplaceId: workplaceId };
    const url = '/v1/validQrTime' + this.getQueryStringFormat(queryParam);
    let response;

    try {
      response = await this.fnRest(method, url, null, serviceName);
    } catch (error) {
      response = error;
    }
    return response && response.successOrNot === 'Y' ? response.data : null;
  }

  async getEntryAreaList(workplaceId: string) {
    const serviceName = Service.WORKPLACE;
    const method = 'GET';

    const queryParam: any = {
      workplaceId: workplaceId,
    };

    const url = '/v1/workplaceEntryAreaList' + this.getQueryStringFormat(queryParam);

    let response;
    try {
      response = await this.fnRest(method, url, null, serviceName);
    } catch (error) {
      response = error;
    }
    return response && response.successOrNot === 'Y' ? response.data : null;
  }
}
