import React, { useState } from 'react';

import './ExcelVisitorList.scss';
import { Modal, Container, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import ExcelFileConfig, {
  ExcelTemplateHeader,
  ExcelHeaderInfo,
  ExcelData,
  ImportFileInfo,
  AlertProps,
} from '../model/Excel';
import BootstrapTable, { ColumnDescription, SelectRowProps } from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Workplace } from '../model/Workplace';
import { getNullToEmpty } from '../utils/util';
import { UserTypeEnum, MemberTypeCode } from '../model/ReservationConstants';
import { Visitor, Reservation } from '../model/ReservationTypes';

export interface ExcelVisitorListI {
  excelObject: ExcelData[];
  onAddVisitor: Function;
  onClose: Function;
  importFileInfo: ImportFileInfo;
  alertProps: AlertProps;
  workplaceInfo: Workplace;
  formikVal: Reservation;
}

export const ExcelVisitorList: React.FC<ExcelVisitorListI> = (props: ExcelVisitorListI) => {
  const { t } = useTranslation();
  const [excelObject, setExcelObject] = useState<ExcelData[]>(props.excelObject);
  const [checkCount, setCheckCount] = useState<number>();
  const importFileInfo = props.importFileInfo;
  const workplaceInfo = props.workplaceInfo;
  const registerVisitorEachPeriodYesorno =
    workplaceInfo.registerVisitorEachPeriodYesorno && workplaceInfo.registerVisitorEachPeriodYesorno === 'Y'
      ? true
      : false;
  const onAddVisitor = () => {
    const users: Visitor[] = props.formikVal.visitors ? [...props.formikVal.visitors] : [];
    let countVisitors = 0;

    users.map(val => {
      if (
        (val.type === UserTypeEnum.NONEMEMBER &&
          excelObject.findIndex(_excel => {
            return _excel.visitorName === val.name && _excel.mobile === val.applyMemberMobilePhone;
          }) > -1) ||
        val.type !== UserTypeEnum.NONEMEMBER
      ) {
        countVisitors++;
      }
    });
    const selectedVisitorCount = excelObject.filter(val => {
      return val.isSelected;
    }).length;

    if (countVisitors + selectedVisitorCount > 50) {
      props.alertProps.setAlertMessage(
        t(
          'reservation.label.ReservationBottomForms.visitors.uploadModal.visitorListTable.validation.message.maxVisitor'
        )
      );
      props.alertProps.setIsShowAlert(true);
      return false;
    }

    excelObject.map(aUser => {
      if (aUser.isSelected && !aUser.invalidReason) {
        const _fromVisitPeriod = props.formikVal.fromVisitPeriod;
        const _toVisitPeriod = props.formikVal.toVisitPeriod;
        const aUserMobile = aUser.mobile ? getNullToEmpty(aUser.mobile).replace(/-/gi, '') : '';
        const inx = users.findIndex(_val => {
          return aUser.visitorName === _val.name && aUserMobile === _val.applyMemberMobilePhone;
        });

        if (inx > -1) {
          users[inx].companyName = aUser.company;
          if (registerVisitorEachPeriodYesorno) {
            users[inx].fromVisitPeriod = aUser.visitStart
              ? aUser.visitStart
              : users[inx].fromVisitPeriod
              ? users[inx].fromVisitPeriod
              : _fromVisitPeriod;
            users[inx].toVisitPeriod = aUser.visitEnd
              ? aUser.visitEnd
              : users[inx].toVisitPeriod
              ? users[inx].toVisitPeriod
              : _toVisitPeriod;
          }
          users[inx].position = aUser.position;
        } else {
          const addUserInfo: Visitor = {
            name: aUser.visitorName,
            id: '',
            position: aUser.position,
            companyName: aUser.company,
            employeeNumber: '',
            dept: '',
            type: UserTypeEnum.NONEMEMBER,
            applyMemberMobilePhone: aUserMobile,
            fromVisitPeriod: registerVisitorEachPeriodYesorno
              ? aUser.visitStart
                ? aUser.visitStart
                : _fromVisitPeriod
              : _fromVisitPeriod,
            toVisitPeriod: registerVisitorEachPeriodYesorno
              ? aUser.visitEnd
                ? aUser.visitEnd
                : _toVisitPeriod
              : _toVisitPeriod,
            idpUserId: '',
            isValid: true,
            importedGoodsList: [],
          };
          users.push(addUserInfo);
        }
      }
    });

    props.onAddVisitor && props.onAddVisitor(users);
  };
  const onClose = () => {
    props.onClose && props.onClose();
  };
  const nonSelectedRow: number[] = [];
  let invalidCount = 0;
  excelObject &&
    excelObject.map((val, inx) => {
      if (val.invalidReason) {
        invalidCount++;
        nonSelectedRow.push(inx);
      }
    });

  const defaultRender = (val: any, className: string): JSX.Element => {
    return (
      <div className={className}>
        <span>{val}</span>
      </div>
    );
  };
  const defaultRenderHeader = (column: any, className: string): JSX.Element => {
    return (
      <div className={className}>
        <span>{column.text}</span>
      </div>
    );
  };

  const renderVisitPeriod = (row: any): JSX.Element => {
    const visitPeriodStr = row[ExcelTemplateHeader.VISIT_START] + ' ~ ' + row[ExcelTemplateHeader.VISIT_END];

    return (
      <div className={'visitPeriod' + (registerVisitorEachPeriodYesorno ? '' : ' disabled')}>
        <span>{visitPeriodStr}</span>
      </div>
    );
  };

  const renderVisitPeriodHeader = (column: any): JSX.Element => {
    return (
      <div className={'visitPeriod'}>
        <span>{column.text}</span>
        {!registerVisitorEachPeriodYesorno ? (
          <OverlayTrigger
            placement="bottom"
            overlay={p => {
              p.show = true;
              return (
                <Tooltip {...p}>
                  {t(
                    'reservation.label.ReservationBottomForms.visitors.uploadModal.visitorListTable.tooltip.visitPeriod'
                  )}
                </Tooltip>
              );
            }}
          >
            <i className="material-icons pointer">error_outline</i>
          </OverlayTrigger>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const setCheckCountList = () => {
    setCheckCount(excelObject.filter(val => val.isSelected).length);
  };
  // setCheckCountList();
  const handleOnSelect = (row, isSelect) => {
    excelObject.map(val => {
      if (val.no === row.no) {
        val.isSelected = isSelect;
      }
    });
    setExcelObject(excelObject);
    setCheckCountList();
  };
  const handleOnSelectAll = (isSelect, row) => {
    excelObject.map(val => {
      if (!val.invalidReason) {
        val.isSelected = isSelect;
      }
    });
    setExcelObject(excelObject);
    setCheckCountList();
  };

  const selectRow: SelectRowProps<any> = {
    mode: 'checkbox',
    clickToSelect: true,
    nonSelectable: nonSelectedRow,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
  };

  const getColumn = (): ColumnDescription[] => {
    return [
      {
        dataField: ExcelTemplateHeader.NAME,
        text: t('reservation.label.ReservationBottomForms.visitors.uploadModal.visitorListTable.header.name'),
        formatter: (cell, row) => {
          return defaultRender(cell, ExcelTemplateHeader.NAME);
        },
        headerFormatter: column => {
          return defaultRenderHeader(column, ExcelTemplateHeader.NAME);
        },
        align: ExcelFileConfig.CELL_HORIZEN_ALIGN,
        headerAlign: ExcelFileConfig.CELL_HORIZEN_ALIGN,
      },
      {
        dataField: ExcelTemplateHeader.MOBILE,
        text: t('reservation.label.ReservationBottomForms.visitors.uploadModal.visitorListTable.header.mobile'),
        formatter: (cell, row) => {
          return defaultRender(cell, ExcelTemplateHeader.MOBILE);
        },
        headerFormatter: column => {
          return defaultRenderHeader(column, ExcelTemplateHeader.MOBILE);
        },
        align: ExcelFileConfig.CELL_HORIZEN_ALIGN,
        headerAlign: ExcelFileConfig.CELL_HORIZEN_ALIGN,
      },
      {
        dataField: ExcelTemplateHeader.POSITION,
        text: t('reservation.label.ReservationBottomForms.visitors.uploadModal.visitorListTable.header.position'),
        formatter: (cell, row) => {
          return defaultRender(cell, ExcelTemplateHeader.POSITION);
        },
        headerFormatter: column => {
          return defaultRenderHeader(column, ExcelTemplateHeader.POSITION);
        },
        align: ExcelFileConfig.CELL_HORIZEN_ALIGN,
        headerAlign: ExcelFileConfig.CELL_HORIZEN_ALIGN,
      },
      {
        dataField: ExcelTemplateHeader.COMPANY,
        text: t('reservation.label.ReservationBottomForms.visitors.uploadModal.visitorListTable.header.company'),
        formatter: (cell, row) => {
          return defaultRender(cell, ExcelTemplateHeader.COMPANY);
        },
        headerFormatter: column => {
          return defaultRenderHeader(column, ExcelTemplateHeader.COMPANY);
        },
        align: ExcelFileConfig.CELL_HORIZEN_ALIGN,
        headerAlign: ExcelFileConfig.CELL_HORIZEN_ALIGN,
      },
      {
        dataField: 'visitStart',
        text: t('reservation.label.ReservationBottomForms.visitors.uploadModal.visitorListTable.header.visitPeriod'),
        formatter: (cell, row) => {
          return renderVisitPeriod(row);
        },
        headerFormatter: (column: any) => {
          return renderVisitPeriodHeader(column);
        },
        align: ExcelFileConfig.CELL_HORIZEN_ALIGN,
        headerAlign: ExcelFileConfig.CELL_HORIZEN_ALIGN,
      },
      {
        dataField: 'invalidReason',
        text: t('reservation.label.ReservationBottomForms.visitors.uploadModal.visitorListTable.header.invalidReason'),
        formatter: (cell, row) => {
          return defaultRender(cell, 'invalidReason');
        },
        headerFormatter: column => {
          return defaultRenderHeader(column, 'invalidReason');
        },
        align: ExcelFileConfig.CELL_HORIZEN_ALIGN,
        headerAlign: ExcelFileConfig.CELL_HORIZEN_ALIGN,
      },
    ];
  };

  return (
    <>
      <Modal.Header closeButton>
        <div className="title bold" data-testid="excel-upload-title">
          {importFileInfo.fileName}
        </div>
        <div className="modal__header--info">
          {t('reservation.label.ReservationBottomForms.visitors.uploadModal.visitorListTable.label.total', {
            count: excelObject.length,
          })}
        </div>
        {invalidCount > 0 && (
          <div className="invalid-visitor-count">
            <span>
              {t('reservation.label.ReservationBottomForms.visitors.uploadModal.visitorListTable.label.invalidTitle', {
                count: invalidCount,
              })}
            </span>

            <OverlayTrigger
              placement="bottom"
              overlay={p => {
                p.show = true;
                return (
                  <Tooltip {...p}>
                    {t(
                      'reservation.label.ReservationBottomForms.visitors.uploadModal.visitorListTable.tooltip.invalid'
                    )}
                  </Tooltip>
                );
              }}
            >
              <i className="material-icons pointer">error_outline</i>
            </OverlayTrigger>
          </div>
        )}
      </Modal.Header>
      <Modal.Body>
        <Container>
          <div className="visitorList">
            <BootstrapTable
              id={'visitorList'}
              data-testid={'visitorList'}
              remote={{
                filter: false,
                pagination: false,
                sort: false,
                cellEdit: false,
              }}
              keyField="no"
              data={excelObject}
              columns={getColumn()}
              selectRow={selectRow}
            />
          </div>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button
          id="cancelButton"
          data-testid="cancelButton"
          className={'cancelButton'}
          variant="dark"
          onClick={onClose}
        >
          {t('reservation.label.ReservationBottomForms.visitors.uploadModal.visitorListTable.button.cancel')}
        </Button>
        <Button
          id="addVisitorButton"
          data-testid="addVisitorButton"
          className={'addVisitorButton'}
          variant="primary"
          onClick={onAddVisitor}
          disabled={checkCount === undefined || checkCount <= 0}
        >
          {checkCount === undefined || checkCount <= 0
            ? t('reservation.label.ReservationBottomForms.visitors.uploadModal.visitorListTable.button.add')
            : t('reservation.label.ReservationBottomForms.visitors.uploadModal.visitorListTable.button.addCount', {
                count: checkCount,
              })}
        </Button>
      </Modal.Footer>
    </>
  );
};

export default ExcelVisitorList;
