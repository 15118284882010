import React, { ReactElement, useState } from 'react';
import { ButtonToolbar, Button, ToggleButtonGroup, ToggleButton, Accordion, ButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CommonCode } from '../model/Code';
import './ImportedGoodsButtonGroup.scss';
export interface ImportedGoodsButtonGroupProps {
  checkedGoodsCodeList: string[];
  availableGoodsList: CommonCode[];
  onButtonGroupChange: Function;
  duplicateImportedGoods: string;
}

export const ImportedGoodsButtonGroup: React.FC<ImportedGoodsButtonGroupProps> = (
  props: ImportedGoodsButtonGroupProps
): ReactElement => {
  const { t } = useTranslation();
  const onChange = (values: string[]): void => {
    props.onButtonGroupChange(values);
  };
  const [showMoreButton, setShowMoreButton] = useState<boolean>(false);
  const baseButtonList = props.availableGoodsList.slice(0, 3);
  const moreButtonList = props.availableGoodsList.slice(3);

  return (
    <div id="ImportedGoodsButtonGroup" data-testid="ImportedGoodsButtonGroup" className="ImportedGoodsButtonGroup">
      <Accordion>
        <ButtonToolbar className="d-inline-block">
          {props.duplicateImportedGoods === 'Y' ? (
            <ButtonGroup id="importGoodsGroup" className="importGoodsGroup inline-block">
              {baseButtonList.map((commonCode: CommonCode, i: number) => {
                return (
                  <Button
                    value={commonCode.codeId}
                    variant="outline-secondary"
                    key={i}
                    id="importedGoodsButton"
                    data-testid="importedGoodsButton"
                    className="importedGoodsButton"
                    onClick={e => onChange([e.target.value])}
                  >
                    {commonCode.codeName}
                  </Button>
                );
              })}
              {showMoreButton &&
                moreButtonList.map((commonCode: CommonCode, i: number) => {
                  return (
                    <Button
                      value={commonCode.codeId}
                      variant="outline-secondary"
                      key={i}
                      id="importedGoodsButton"
                      data-testid="importedGoodsButton"
                      className="importedGoodsButton"
                      onClick={e => onChange([e.target.value])}
                    >
                      {commonCode.codeName}
                    </Button>
                  );
                })}
              {props.availableGoodsList.length > 3 && !showMoreButton && (
                <Accordion.Toggle
                  as={Button}
                  variant="outline-secondary"
                  eventKey="0"
                  id="moreShowImportedGoodsButton"
                  data-testid="moreShowImportedGoodsButton"
                  className="moreShowImportedGoodsButton"
                  onClick={(e: any) => {
                    e.preventDefault();
                    setShowMoreButton(true);
                  }}
                >
                  {t('reservation.label.ImportedGoodsButtonGroup.button.more')}
                  <i className="material-icons">arrow_drop_down</i>
                </Accordion.Toggle>
              )}
            </ButtonGroup>
          ) : (
            <ToggleButtonGroup
              type="checkbox"
              id="importGoodsToggleGroup"
              className="importGoodsToggleGroup inline-block"
              onChange={onChange}
              value={props.checkedGoodsCodeList}
            >
              {baseButtonList.map((commonCode: CommonCode, i: number) => {
                return (
                  <ToggleButton
                    value={commonCode.codeId}
                    variant="outline-secondary"
                    key={i}
                    id="importedGoodsButton"
                    data-testid="importedGoodsButton"
                    className="importedGoodsButton"
                    disabled={props.checkedGoodsCodeList.includes(commonCode.codeId)}
                  >
                    {commonCode.codeName}
                  </ToggleButton>
                );
              })}
              {showMoreButton &&
                moreButtonList.map((commonCode: CommonCode, i: number) => {
                  return (
                    <ToggleButton
                      value={commonCode.codeId}
                      variant="outline-secondary"
                      key={i}
                      id="importedGoodsButton"
                      data-testid="importedGoodsButton"
                      className="importedGoodsButton"
                      disabled={props.checkedGoodsCodeList.includes(commonCode.codeId)}
                    >
                      {commonCode.codeName}
                    </ToggleButton>
                  );
                })}
              {props.availableGoodsList.length > 3 && !showMoreButton && (
                <Accordion.Toggle
                  as={Button}
                  variant="outline-secondary"
                  eventKey="0"
                  id="moreShowImportedGoodsButton"
                  data-testid="moreShowImportedGoodsButton"
                  className="moreShowImportedGoodsButton"
                  onClick={(e: any) => {
                    e.preventDefault();
                    setShowMoreButton(true);
                  }}
                >
                  {t('reservation.label.ImportedGoodsButtonGroup.button.more')}
                  <i className="material-icons">arrow_drop_down</i>
                </Accordion.Toggle>
              )}
            </ToggleButtonGroup>
          )}
        </ButtonToolbar>
      </Accordion>
    </div>
  );
};

export default ImportedGoodsButtonGroup;
