import React, { ReactElement, useContext, useLayoutEffect } from 'react';
import queryString from 'query-string';
import SessionService from '../../service/SessionService';
import { SessionInfo } from '../../service/model/SessionInfo';
import LoginService from '../member/services/LoginService';
import moment from 'moment';

const TrialLoginPage: React.FC = (): ReactElement => {
  const sessionService = new SessionService();
  const encryptedText = queryString.parse(window.location.search).encryptedText || null;
  const endDay = queryString.parse(window.location.search).endDay?.toString() || '';

  const singlexbackground = window.location.hostname.includes('singlex') ? true : false;
  const singlexErrorPage = 'https://www.singlex.com/app/error/experienceLogin';

  useLayoutEffect(() => {
    if (encryptedText) {
      const loginRequest = {
        encryptedText: encryptedText.toString().replace(/ /g, '+'),
      };
      new LoginService().requestTrialLogin(loginRequest).then(res => {
        localStorage.removeItem('endDay');
        if (res.successOrNot == 'Y') {
          sessionService.deleteSessionInfo();
          sessionService.setSessionId(res.data.sessionId);
          sessionService.setSessionInfo(res.data.member as SessionInfo);
          sessionService.setSessionStorageValue('trialGuideOn', 'Y');

          const _endDay = moment(endDay, 'YYYY-MM-DD');
          const today = moment().format('YYYY-MM-DD');
          const dDay = _endDay.diff(today, 'days');

          if (dDay < 1) {
            window.location.href = singlexErrorPage;
          } else {
            localStorage.setItem('endDay', dDay.toString());
            window.location.assign('/reservation');
          }
        } else {
          window.location.href = singlexErrorPage;
        }
      });
    } else {
      window.location.href = singlexErrorPage;
    }
  }, []);

  return <></>;
};

export default TrialLoginPage;
