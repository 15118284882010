import React, { ReactElement } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { WorkplaceVisitInfo } from '../model/ReservationTypes';
import './WorkplaceNoticeModal.scss';

export interface WorkplaceNoticeModalProps {
  workplaceVisitInfo: WorkplaceVisitInfo;
  show: boolean;
  onClose: () => void;
}

export const WorkplaceNoticeModal: React.FC<WorkplaceNoticeModalProps> = (
  props: WorkplaceNoticeModalProps
): ReactElement => {
  const { t } = useTranslation();

  return (
    <Modal
      id="workplaceNoticeModal"
      data-testid="workplaceNoticeModal"
      show={props.show}
      className="workplaceNoticeModal"
      centered
      enforceFocus={false}
      onHide={props.onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title id="workplaceNoticeTitle" data-testid="workplaceNoticeTitle">
          <p>
            <strong>{t('reservation.label.WorkplaceNoticeModal.title')}</strong>
          </p>
          <p>{props.workplaceVisitInfo.groupCompanyName + ' ' + props.workplaceVisitInfo.workplace}</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="workplaceNoticeContent" data-testid="workplaceNoticeContent">
          {props.workplaceVisitInfo.workplaceNotice}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          id="workplaceNoticeOkButton"
          data-testid="workplaceNoticeOkButton"
          variant="primary"
          onClick={(event: React.MouseEvent<HTMLButtonElement>): void => {
            event.preventDefault();
            props.onClose();
          }}
        >
          {t('reservation.label.WorkplaceNoticeModal.button.confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WorkplaceNoticeModal;
