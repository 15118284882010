import React, { useState, ReactElement, useContext, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './LoginForm.scss';
import { useTranslation } from 'react-i18next';
import { Button, Form, Toast } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik, FormikValues } from 'formik';
import SubmitStatus from '../../model/SubmitStatus';
import LoginRequest from '../../model/LoginRequest';
import LoginService from '../../services/LoginService';
import ArrowIcon from '../../../../icons/ArrowRight';
import { useHistory } from 'react-router-dom';
import FindIdModal from './FindIdModal';
import ResetPasswordModal from './ResetPasswordModal';
import SessionService from '../../../../service/SessionService';
import { Error } from '../../../../service/model/Error';
import { AuthContext } from '../../../../App';
import { SessionInfo } from '../../../../service/model/SessionInfo';
import { ActionType } from '../../../../reducer/actions';
import { InactiveUserInfo } from '../../../../reducer/states';
import CookieService from '../../../../service/CookieService';
import { UserTypeParamEnum } from '../../model/MemberRegistrationConstants';
import { reservationDetailUtil } from '../../../reservation/utils/ReservationDetailUtil';
import ModifyPasswordModal from '../../memberInfo/component/ModifyPasswordModal';
// import TwoFactorModal from '../../login/component/TwoFactorModal';
import moment from 'moment';

const LoginForm: React.FC = (): ReactElement => {
  const history = useHistory();
  const { t } = useTranslation();

  const schema = yup.object({
    emailId: yup
      .string()
      .email(t('member.message.login.validation.emailId.common'))
      .required(t('member.message.login.validation.emailId.required')),
    password: yup.string().required(t('member.message.login.validation.password.required')),
  });
  const [messageSubmit, setMessageSubmit] = useState('');
  const loginService = new LoginService();
  const sessionService = new SessionService();

  const clearMessageSubmit = (): void => {
    if (messageSubmit !== '') {
      setMessageSubmit('');
    }
  };

  const [showChangePasswordModal, setShowChangePasswordModal] = useState<boolean>(false);
  const handleShowPasswordModal = (): void => {
    if (history.location.hash.includes('#findIdModal')) {
      history.replace('#loginForm#changePasswordModal');
    } else {
      history.push(history.location.hash + '#changePasswordModal');
    }

    setShowChangePasswordModal(true);
    setShowMemberInfo(true);
  };
  const handleShowModifyPasswordModal = (): void => {
    history.push(history.location.hash + '#modifyPasswordModal');
    setShowModifyPasswordModal(true);
  };
  const [showFindIdModal, setShowFindIdModal] = useState<boolean>(false);
  const handleShowFindIDModal = (): void => {
    history.push(history.location.hash + '#findIdModal');
    setShowFindIdModal(true);
    setShowIdInfo(true);
  };
  // const handleShowTwoFactorModal = (): void => {
  //   history.push(history.location.hash + '#twoFactorModal');
  //   setShowTwoFactorModal(true);
  // }
  const [internalIPs, setInternalIPs] = useState<string[]>([]);
  const [showIdInfo, setShowIdInfo] = useState<boolean>(true);
  const [showMemberInfo, setShowMemberInfo] = useState<boolean>(true);
  const [toastMessage, setToastMessage] = useState<string>('');

  const { state, dispatch } = useContext(AuthContext);
  const [showModifyPasswordModal, setShowModifyPasswordModal] = useState<boolean>(false); // 90일 초과
  // const [showTwoFactorModal, setShowTwoFactorModal] = useState<boolean>(false); // 2Factor인증

  const [showToast, setShowToast] = useState<boolean>(() => {
    if (state && state.toastMessage) {
      setToastMessage(state.toastMessage.toastMessage);
      return true;
    } else {
      return false;
    }
  });

  // const [certificationConfirmStatus, setCertificationConfirmStatus] = useState<boolean>(false);

  const handleShowToast = (message: string): void => {
    setShowToast(true);
    setToastMessage(message);
  };
  const setLoading = (_isLoading: boolean) => {
    dispatch({ type: ActionType.LOADING, loading: { isLoading: _isLoading } });
  };

  const cookieService = new CookieService();

  const onHideFindIdModal = () => {
    history.goBack();
  };
  const onHideChangePasswordModal = () => {
    history.goBack();
  };
  const onHideModifyPasswordModal = () => {
    sessionService.deleteSessionInfo();
    history.goBack();
  };
  // const onHideTwoFactorModal = () => {
  //   history.goBack();
  // }

  const getLocalIPs = async (): Promise<string[]> => {
    const ips: string[] = [];
    const pc = new RTCPeerConnection({
      iceServers: [],
    });
    pc.createDataChannel('');
    const offer = await pc.createOffer();
    await pc.setLocalDescription(offer);
    pc.onicecandidate = (event: RTCPeerConnectionIceEvent) => {
      if (!event.candidate) return;

      const candidate = event.candidate.candidate;
      const parts = candidate.split(' ');
      const ip = parts[4];
      const ipRegex = /(\b25[0-5]|\b2[0-4][0-9]|\b1[0-9]{2}|\b[1-9]?[0-9])\.(\b25[0-5]|\b2[0-4][0-9]|\b1[0-9]{2}|\b[1-9]?[0-9])\.(\b25[0-5]|\b2[0-4][0-9]|\b1[0-9]{2}|\b[1-9]?[0-9])\.(\b25[0-5]|\b2[0-4][0-9]|\b1[0-9]{2}|\b[1-9]?[0-9])\b/;
      if (ipRegex.test(ip) && !ips.includes(ip)) ips.push(ip);
    };
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(ips);
        pc.close();
      }, 1000);
    });
  };

  /* eslint-disable */
  useEffect(() => {
    const location = history.location;
    if (!location.hash.includes('#findIdModal')) {
      setShowFindIdModal(false);
    }
    if (!location.hash.includes('#changePasswordModal')) {
      setShowChangePasswordModal(false);
    }
    if (!location.hash.includes('#modifyPasswordModal')) {
      setShowModifyPasswordModal(false);
    }
  }, [history.location]);
  
  useEffect(()=> {
    getLocalIPs().then(setInternalIPs)
  },[])

  return (
    <div id="LoginForm" className="login-form">
      <Formik
        initialValues={{
          emailId: cookieService.getCookie('emailAddress'),
          password: '',
          checkSaveId: cookieService.getCookie('emailAddress').length > 0,
        }}
        validationSchema={schema}
        onSubmit={async (values: FormikValues): Promise<void> => {
          const loginRequest: LoginRequest = {
            emailAddress: values.emailId,
            password: values.password,
          };
          setLoading(true);
          const submitStatus: SubmitStatus = await loginService.requestLogin(loginRequest);
          setLoading(false);
          if (submitStatus.successOrNot === 'Y') {
            sessionService.deleteSessionInfo();
            sessionService.setSessionId(submitStatus.data.sessionId);
            sessionService.setSessionInfo(submitStatus.data.member as SessionInfo);
            values.checkSaveId
              ? cookieService.setCookie('emailAddress', loginRequest.emailAddress)
              : cookieService.deleteCookie('emailAddress');
            const sessionUser = await reservationDetailUtil.getSessionUserInfo(); // 관리자 권한 조회
            if (sessionUser.isWorkplaceAdmin || sessionUser.isSystemAdmin) {
              if(sessionUser.isSystemAdmin){
                console.log(internalIPs)
                const allowedIPs = internalIPs.filter(ip => ip.startsWith('10'))
                if(allowedIPs[0] !== sessionUser.ip){
                  console.log(allowedIPs)
                  setMessageSubmit('시스템 관리자는 지정된 IP로만 접속할 수 있습니다.')
                  return
                }
              }
              const passwordDateTime: string =
                submitStatus.data.member.modifyPasswordDatetime 
                ? submitStatus.data.member.modifyPasswordDatetime
                : submitStatus.data.member.createdDatetime;
              const days: number = moment().diff(moment(passwordDateTime, 'YYYY-MM-DD'), 'days');
              if (days > 90) { // 마지막 비밀번호 변경일로부터 90일 초과
                handleShowModifyPasswordModal();
                return
              } 
              // else if(certificationConfirmStatus === true){                
              //   window.location.assign('/reservation');
              // } 
            }

              window.location.assign('/reservation');

          } else if (submitStatus.statusCode === 'NOT.MATCH.ID.PASSWORD') {
            setMessageSubmit(t('member.message.login.reponse.submit.NOT_MATCH_ID_PASSWORD'));
          } else if (submitStatus.statusCode === 'LOCKED.ACCOUNT') {
            setMessageSubmit(t('member.message.login.reponse.submit.LOCKED_ACCOUNT'));
          } else if (submitStatus.statusCode === 'NO.EMPLOYEE.CERTIFICATION') {
            setMessageSubmit(t('member.message.login.reponse.submit.NO_EMPLOYEE_CERTIFICATION'));
          } else if (submitStatus.statusCode === 'INACTIVE.ACCOUNT') {
            const memberId = (submitStatus as Error).data.memberId;
            const emailAddress = (submitStatus as Error).data.emailAddress;
            const inactiveUserInfo: InactiveUserInfo = {
              memberId: memberId,
              emailAddress: emailAddress,
            };
            dispatch({ type: ActionType.PASS_INACTIVE_USER_INFO_TO_EMAIL_PAGE, inactiveUserInfo: inactiveUserInfo });
            history.push('/memberRegistration');
          } else {
            setMessageSubmit(t('member.message.login.reponse.submit.COMMON'));
          }
        }}
      >
        {({ values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              <Form.Group className="form-email">
                <Form.Control
                  id="emailId"
                  name="emailId"
                  data-testid="emailId"
                  className="formik-field"
                  required
                  type="text"
                  placeholder={t('member.label.LoginForm.emailIdPlaceholder')}
                  onChange={
                    messageSubmit === ''
                      ? handleChange
                      : () => {
                          clearMessageSubmit();
                        }
                  }
                  onBlur={handleBlur}
                  onFocus={clearMessageSubmit}
                  isInvalid={!!errors.emailId}
                  defaultValue={values.emailId}
                />
                <Form.Check
                  id="checkSaveId"
                  name="checkSaveId"
                  data-testid="checkSaveId"
                  className="check-saveid"
                  type="checkbox"
                  label={t('member.label.LoginForm.saveId')}
                  onChange={
                    messageSubmit === ''
                      ? handleChange
                      : () => {
                          clearMessageSubmit();
                        }
                  }
                  defaultChecked={values.checkSaveId}
                />
                <Form.Control.Feedback id="emailIdFeedback" data-testid="emailIdFeedback" type="invalid">
                  {touched.emailId && !!errors.emailId ? errors.emailId : ''}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group>
                <Form.Control
                  id="password"
                  name="password"
                  data-testid="password"
                  required
                  type="password"
                  placeholder={t('member.label.LoginForm.passwordPlaceholder')}
                  onChange={
                    messageSubmit === ''
                      ? handleChange
                      : () => {
                          clearMessageSubmit();
                        }
                  }
                  onBlur={handleBlur}
                  onFocus={clearMessageSubmit}
                  isInvalid={!!errors.password}
                />
                <Form.Control.Feedback id="passwordFeedback" data-testid="passwordFeedback" type="invalid">
                  {touched.password && !!errors.password ? errors.password : ''}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <div id="submitArea" className="submit-area">
              <div id="submitFeedback" data-testid="submitFeedback" className="submit-feedback">
                <span>{messageSubmit}</span>
              </div>
              <Form.Row className="submit-row">
                <a
                  id="findIdLink"
                  data-testid="findIdLink"
                  className="link-forgotidpassword"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleShowFindIDModal();
                  }}
                >
                  {t('member.label.LoginForm.findId')}
                </a>
                <a
                  className="link-forgotidpassword"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleShowPasswordModal();
                  }}
                >
                  {t('member.label.LoginForm.resetPassword')}
                </a>
                <a
                  className="link-forgotidpassword"
                  href={'/memberRegistration?memberType=' + UserTypeParamEnum.OUTSIDER_MEMBER}
                >
                  {t('member.label.LoginSection.menuLink.memberRegistration')}
                </a>
                <Button
                  id="loginSubmitButton"
                  name="loginSubmitButton"
                  data-testid="loginSubmitButton"
                  className="submit-button"
                  type="submit"
                  disabled={
                    isSubmitting || !(!errors.emailId && !errors.password && !!values.emailId && !!values.password)
                  }
                >
                  <span id="loginSubmitButtonText" data-testid="loginSubmitButtonText" className="submit-button-text">
                    {t('member.label.LoginForm.login')}
                  </span>
                  <ArrowIcon />
                </Button>
              </Form.Row>
            </div>
          </Form>
        )}
      </Formik>
      <Toast
        id="LoginFormToast"
        className="login-form-toast"
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
      >
        <Toast.Body>
          <div>
            <i className="material-icons">check_circle</i>
            <span id="toastMessage" className="toastMessage">
              {toastMessage}
            </span>
          </div>
        </Toast.Body>
      </Toast>
      <FindIdModal
        {...{
          show: showFindIdModal,
          onHide: onHideFindIdModal,
          showIdInfo: showIdInfo,
          setShowIdInfo: setShowIdInfo,
          passwordOnHide: handleShowPasswordModal,
        }}
      ></FindIdModal>
      <ResetPasswordModal
        {...{
          show: showChangePasswordModal,
          onHide: onHideChangePasswordModal,
          showMemberInfo: showMemberInfo,
          passwordOnHide: setShowMemberInfo,
          setToastMessage: handleShowToast,
        }}
      ></ResetPasswordModal>

      <ModifyPasswordModal
        show={showModifyPasswordModal}
        onHide={onHideModifyPasswordModal}
      >
      </ModifyPasswordModal>
      {/* <TwoFactorModal
        show={showTwoFactorModal}
        onHide={onHideTwoFactorModal}
        setCertificationConfirmStatus={setCertificationConfirmStatus}
        certificationConfirmStatus={certificationConfirmStatus}
      >
      </TwoFactorModal> */}
    </div>
  );
};
export default LoginForm;
