import 'react-dates/initialize';
import React, { ReactElement, useState, useEffect } from 'react';
import { ButtonToolbar, ToggleButtonGroup, ToggleButton, ButtonProps, ToggleButtonProps } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { DayPickerRangeController, DayPickerSingleDateController, isInclusivelyAfterDay } from 'react-dates';
import moment from 'moment';
import 'react-dates/lib/css/_datepicker.css';
import { CalendarTypeEnum, localeEnum } from '../model/ReservationConstants';
import './VisitPeriodCalendar.scss';
import 'moment/locale/ko';
import { ReplaceProps, BsPrefixComponentClass, BsPrefixProps } from 'react-bootstrap/helpers';
import { useHistory } from 'react-router-dom';
export interface VisitPeriodCalendarProps {
  show: boolean;
  className?: string;
  fromVisitPeriod: moment.Moment | null;
  toVisitPeriod: moment.Moment | null;
  onPeriodChange: (fromVisitPeriod: moment.Moment, toVisitPeriod: moment.Moment) => void;
  maxVisitPeriod?: number;
  isCalendarAwalysOpen: boolean;
  setShowCalendar?: Function;
  isVisitor?: boolean;
}

export const VisitPeriodCalendar: React.FC<VisitPeriodCalendarProps> = (
  props: VisitPeriodCalendarProps
): ReactElement => {
  const { t, i18n } = useTranslation();
  moment.locale(i18n.language);
  const [calendarType, setCalendarType] = useState<string>(props.isCalendarAwalysOpen ? CalendarTypeEnum.ONEDAY : '');
  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate'>('startDate');
  const [focused, setFocused] = useState<boolean>(true);
  const [fromVisitPeriod, setFromVisitPeriod] = useState<moment.Moment | null>(props.fromVisitPeriod);
  const [toVisitPeriod, setToVisitPeriod] = useState<moment.Moment | null>(props.toVisitPeriod);
  const [open, setOpen] = useState<boolean>(props.isCalendarAwalysOpen);

  const [date, setDate] = useState<moment.Moment | null>(props.fromVisitPeriod ? props.fromVisitPeriod : moment());
  const history = useHistory();

  const onCalendarTypeChange = (type: string): void => {
    setDate(date ? date : moment());
    setCalendarType(type);
    if (!history.location.hash.includes('#subCalendar') && !props.isCalendarAwalysOpen) {
      history.push(history.location.hash + '#subCalendar');
    }
    setOpen(true);

    props.setShowCalendar && props.setShowCalendar(true);
  };

  const onDateChange = (date: moment.Moment | null): void => {
    setDate(date);
    date && props.onPeriodChange(date, date);
    history.goBack();
    setCalendarType('');
  };

  const onDatesChange = (dates: { startDate: moment.Moment | null; endDate: moment.Moment | null }): void => {
    setFromVisitPeriod(dates.startDate);
    setToVisitPeriod(dates.endDate);
    if (focusedInput === 'endDate' && dates.startDate && dates.endDate) {
      props.onPeriodChange(dates.startDate, dates.endDate);
      setCalendarType('');
      history.goBack();
    }
  };

  const onRangeFocusChange = (focusedInput: 'startDate' | 'endDate' | null): void => {
    setFocusedInput(!focusedInput ? 'startDate' : focusedInput);
  };

  const onSingleFocusChange = (arg: { focused: boolean | null }): void => {
    setFocused(arg.focused ? true : false);
  };

  const isDayHighlighted = (day: moment.Moment): boolean => {
    return day.day() % 7 === 0;
  };

  const isOutsideRange = (day: moment.Moment): boolean => {
    const oneday = moment();
    if (!props.isCalendarAwalysOpen) return false;
    if (focusedInput === 'endDate' && fromVisitPeriod && calendarType === CalendarTypeEnum.RANGE) {
      return (
        !isInclusivelyAfterDay(day, moment(fromVisitPeriod)) ||
        isInclusivelyAfterDay(day, moment(fromVisitPeriod).add(props.maxVisitPeriod ? props.maxVisitPeriod : 0, 'days'))
      );
    } else {
      return !isInclusivelyAfterDay(day, oneday);
    }
  };

  const className = props.className ? props.className : 'outline-white';
  const weekDayFormat = 'ddd';
  const minimumNights = 0;
  const daySize = 44;
  const navNext = (
    <div className="nextButton navButton">
      <i className="material-icons">chevron_right</i>
    </div>
  );
  const navPrev = (
    <div className="prevButton navButton">
      <i className="material-icons">chevron_left</i>
    </div>
  );

  const headerSpan = (day: moment.Moment) => {
    const year = day.format('YYYY');

    const locale = moment.locale();

    if (locale === localeEnum.KO) {
      return (
        <div className="calendarHeaderText">
          <span className="yearText" id="yearText" data-testid="yearText">
            {year}
            {t('reservation.label.ReservationBaseInfo.calendar.year')}
          </span>
          <span className="monthText" id="monthText" data-testid="monthText">
            {day.format('MM')}
            {t('reservation.label.ReservationBaseInfo.calendar.month')}
          </span>
        </div>
      );
    } else {
      return (
        <div className="calendarHeaderText">
          <span className="yearText" id="yearText" data-testid="yearText">
            {year}
          </span>
          <span className="monthText" id="monthText" data-testid="monthText">
            {day.format('MMMM')}
          </span>
        </div>
      );
    }
  };

  const getCalendar = (calendarType: string): ReactElement => {
    if (calendarType === CalendarTypeEnum.RANGE) {
      return (
        <DayPickerRangeController
          renderMonthText={headerSpan}
          onDatesChange={onDatesChange}
          onFocusChange={onRangeFocusChange}
          focusedInput={focusedInput}
          startDate={fromVisitPeriod}
          endDate={toVisitPeriod}
          keepOpenOnDateSelect={true}
          hideKeyboardShortcutsPanel={true}
          enableOutsideDays={true}
          isOutsideRange={isOutsideRange}
          minimumNights={minimumNights}
          daySize={daySize}
          weekDayFormat={weekDayFormat}
          isDayHighlighted={isDayHighlighted}
          navNext={navNext}
          navPrev={navPrev}
        />
      );
    } else {
      return (
        <DayPickerSingleDateController
          renderMonthText={headerSpan}
          onDateChange={onDateChange}
          onFocusChange={onSingleFocusChange}
          focused={focused}
          date={date}
          keepOpenOnDateSelect={true}
          enableOutsideDays={true}
          isOutsideRange={isOutsideRange}
          hideKeyboardShortcutsPanel={true}
          daySize={daySize}
          weekDayFormat={weekDayFormat}
          isDayHighlighted={isDayHighlighted}
          navNext={navNext}
          navPrev={navPrev}
        />
      );
    }
  };

  const handleOpenCalendar = event => {
    event.stopPropagation();
    if (!props.isCalendarAwalysOpen) {
      if (event.target.value === calendarType && open) {
        setCalendarType('');
        setOpen(false);
      }
      setFocusedInput('startDate');
      setFocused(true);
    }
  };

  /* eslint-disable */
  useEffect(() => {
    if (props.isCalendarAwalysOpen) {
      setOpen(true);
    }
  }, [calendarType]);
  useEffect(() => {
    if (!props.isVisitor && !history.location.hash.includes('#calendar') && props.isCalendarAwalysOpen) {
      props.setShowCalendar && props.setShowCalendar(false);
    }
    if (!history.location.hash.includes('#subCalendar') && !props.isCalendarAwalysOpen) {
      setOpen(false);
      setCalendarType('');
      props.setShowCalendar && props.setShowCalendar(false);
    }

    if (props.isVisitor && !history.location.hash.includes('#visitorCalendar') && props.isCalendarAwalysOpen) {
      props.setShowCalendar && props.setShowCalendar(false);
    }
  }, [history.location]);
  /* eslint-enable */

  return (
    <div className={'VisitPeriodCalendar d-inline-block'}>
      <ButtonToolbar className="calendarType d-inline-block">
        <ToggleButtonGroup
          className={className}
          type="radio"
          name="calendarType"
          defaultValue={calendarType}
          value={calendarType}
          onChange={onCalendarTypeChange}
          onClick={handleOpenCalendar}
        >
          <ToggleButton value={'oneday'} id="onedayButton" data-testid="onedayButton">
            {t('reservation.label.VisitPeriodCalendar.button.oneday')}
          </ToggleButton>
          <ToggleButton
            value={'range'}
            id={
              !props.maxVisitPeriod || (props.maxVisitPeriod && props.maxVisitPeriod > 1)
                ? 'rangeButton'
                : 'rangeButton-hidden'
            }
            data-testid="rangeButton"
          >
            {t('reservation.label.VisitPeriodCalendar.button.range')}
          </ToggleButton>
        </ToggleButtonGroup>
      </ButtonToolbar>
      <div className={['calendar', open && 'open'].join(' ')} id="calendar" data-testid="calendar">
        {open && getCalendar(calendarType)}
      </div>
    </div>
  );
};

export default VisitPeriodCalendar;
