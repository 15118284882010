import SubmitStatus from '../model/SubmitStatus';
import CertificationRequest from '../model/CertificationRequest';
import CertificationSubmitStatus from '../model/CertificationSubmitStatus';
import CertificationConfirmSubmitStatus from '../model/CertificationConfirmSubmitStatus';
import CertificationConfirmRequest from '../model/CertificationConfirmRequest';
import MemberRegistrationSubmitStatus from '../model/MemberRegistrationSubmitStatus';
import NonMemberRegistrationSubmitStatus from '../model/NonMemberRegistrationSubmitStatus';
import Member from '../model/Member';
import { Service } from '../../../service/model/Service';
import BaseService from '../../../service/BaseService';
import EmployeeCertification from '../model/EmployeeCertification';
import NonMember from '../model/NonMember';
import SearchAccountSubmitStatus from '../model/SearchAccountSubmitStatus';
import InvitationModel from '../model/InvitationMessageModel';

export default class MemberRegistrationService {
  public baseService: BaseService = new BaseService();

  async checkExistFlagEmailAddress(emailAddress: string, memberTypeCode: string): Promise<SubmitStatus> {
    let submitStatus: SubmitStatus = {
      successOrNot: 'N',
      statusCode: '',
    };

    const method = 'POST';
    const url = '/v1/account/email';
    const serviceName = Service.MEMBER;
    const body = { emailAddress: emailAddress, memberTypeCode: memberTypeCode };
    try {
      submitStatus = await this.baseService.fnRest(method, url, body, serviceName);
    } catch (error) {
      submitStatus = error;
    }

    return submitStatus;
  }

  async requestCertificationKey(certificationKeyRequest: CertificationRequest): Promise<CertificationSubmitStatus> {
    let submitStatus: CertificationSubmitStatus = {
      successOrNot: 'N',
      statusCode: '',
      data: { certificationId: '' },
    };

    const method = 'POST';
    const url = '/v1/certification';
    const serviceName = Service.MEMBER;
    const body = JSON.stringify(certificationKeyRequest);

    try {
      submitStatus = await this.baseService.fnRest(method, url, body, serviceName);
    } catch (error) {
      submitStatus = error;
    }

    return submitStatus;
  }

  async confirmCertificationKey(
    certificationConfirmRequest: CertificationConfirmRequest
  ): Promise<CertificationConfirmSubmitStatus> {
    let submitStatus: CertificationConfirmSubmitStatus = {
      successOrNot: 'N',
      statusCode: '',
      data: { msg: '' },
    };

    const method = 'POST';
    const url = '/v1/certification/confirm';
    const serviceName = Service.MEMBER;
    const body = JSON.stringify(certificationConfirmRequest);

    try {
      submitStatus = await this.baseService.fnRest(method, url, body, serviceName);
    } catch (error) {
      submitStatus = error;
    }
    return submitStatus;
  }

  async registMember(member: Member): Promise<MemberRegistrationSubmitStatus> {
    let submitStatus: MemberRegistrationSubmitStatus = {
      successOrNot: 'N',
      statusCode: '',
      data: {
        memberId: -1,
      },
    };

    const method = 'POST';
    const url = '/v1/account';
    const serviceName = Service.MEMBER;
    const body = JSON.stringify(member);

    try {
      submitStatus = await this.baseService.fnRest(method, url, body, serviceName);
    } catch (error) {
      submitStatus = error;
    }

    return submitStatus;
  }

  async deleteMember(member: Member): Promise<MemberRegistrationSubmitStatus> {
    let submitStatus: MemberRegistrationSubmitStatus = {
      successOrNot: 'N',
      statusCode: '',
    };

    const method = 'DELETE';
    const url = '/v1/account';
    const serviceName = Service.MEMBER;
    const body = JSON.stringify(member);

    try {
      submitStatus = await this.baseService.fnRest(method, url, body, serviceName);
    } catch (error) {
      submitStatus = error;
    }
    return submitStatus;
  }

  async certificateEmployeeMember(
    employeeCertification: EmployeeCertification
  ): Promise<MemberRegistrationSubmitStatus> {
    let submitStatus: MemberRegistrationSubmitStatus = {
      successOrNot: 'N',
      statusCode: '',
      data: {
        memberId: -1,
      },
    };

    const method = 'PUT';
    const url = '/v1/account/certification';
    const serviceName = Service.MEMBER;
    const body = JSON.stringify(employeeCertification);

    try {
      submitStatus = await this.baseService.fnRest(method, url, body, serviceName);
    } catch (error) {
      submitStatus = error;
    }

    return submitStatus;
  }

  async registNonMember(nonMember: NonMember) {
    let submitStatus: NonMemberRegistrationSubmitStatus = {
      successOrNot: 'N',
      statusCode: '',
      data: {
        msg: '',
      },
    };

    const method = 'POST';
    const url = '/v1/account/nomember';
    const serviceName = Service.MEMBER;
    const body = JSON.stringify(nonMember);

    try {
      submitStatus = await this.baseService.fnRest(method, url, body, serviceName);
    } catch (error) {
      submitStatus = error;
    }

    return submitStatus;
  }

  async checkAccountInfo(member: Member) {
    let submitStatus: SearchAccountSubmitStatus = {
      successOrNot: 'N',
      statusCode: '',
      data: {
        existenceYn: '',
        msg: '',
      },
    };

    const method = 'POST';
    const url = '/v1/account/check';
    const serviceName = Service.MEMBER;
    const body = {
      memberName: member.memberName,
      emailAddress: member.emailAddress,
      phoneNumber: member.phoneNumber.replace(/-/gi, ''),
      searchConditionType: member.searchConditionType,
    };
    try {
      submitStatus = await this.baseService.fnRest(method, url, body, serviceName);
    } catch (error) {
      submitStatus = error;
    }
    return submitStatus;
  }

  async updatePassword(member: Member) {
    let submitStatus: SubmitStatus = {
      successOrNot: 'N',
      statusCode: '',
    };

    const method = 'PUT';
    const url = '/v1/account/password';
    const serviceName = Service.MEMBER;
    const body = {
      memberName: member.memberName,
      emailAddress: member.emailAddress,
      phoneNumber: member.phoneNumber.replace(/-/gi, ''),
      certificationId: member.certificationId,
      password: member.password,
      searchConditionType: member.searchConditionType,
    };
    try {
      submitStatus = await this.baseService.fnRest(method, url, body, serviceName);
    } catch (error) {
      submitStatus = error;
    }
    return submitStatus;
  }

  async findId(member: Member) {
    let submitStatus: SubmitStatus = {
      successOrNot: 'N',
      statusCode: '',
      data: {
        emailAddress: [],
      },
    };

    const method = 'POST';
    const url = '/v1/account/id';
    const serviceName = Service.MEMBER;
    const body = { memberName: member.memberName, phoneNumber: member.phoneNumber.replace(/-/gi, '') };

    try {
      submitStatus = await this.baseService.fnRest(method, url, body, serviceName);
    } catch (error) {
      submitStatus = error;
    }
    return submitStatus;
  }

  async invieteMember(invitationMessage: InvitationModel) {
    let submitStatus: SubmitStatus = {
      successOrNot: 'N',
      statusCode: '',
    };

    const method = 'POST';
    const url = '/v1/members/sendInviteMessage';
    const serviceName = Service.MEMBER;
    const body = {
      inviteeName: invitationMessage.inviteeName,
      inviteePhoneNumber: invitationMessage.inviteePhoneNumber.replace(/-/gi, ''),
      invitationMessage: invitationMessage.invitationMessage,
    };
    try {
      submitStatus = await this.baseService.fnRest(method, url, body, serviceName);
    } catch (error) {
      submitStatus = error;
    }
    return submitStatus;
  }
}
