import React, { ReactElement } from 'react';
import { Footer, NavBar } from '../../layouts';
import { Container, Row } from 'react-bootstrap';
import './HomePage.scss';

const HomePage = (): ReactElement => {
  return (
    <div className="homePage">
      {/* <NavBar /> */}
      <Container>
        <Row>
          <h1>Header Here</h1>
        </Row>
        <Row>
          <h1>Contents Container Here</h1>
        </Row>
        <Row>
          <Footer />
        </Row>
      </Container>
    </div>
  );
};

export default HomePage;
