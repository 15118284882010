import React, { ReactElement, useState, useEffect, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './LoginSection.scss';
import cookie from 'react-cookies';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Image } from 'react-bootstrap';
import lgLogo from '../../../../icons/lg.svg';
import SinglexLogoImage from '../../../../icons/logo_cmis_kr_white.png';
import LoginForm from './LoginForm';
import MemberRegistrationConstants, { UserTypeParamEnum } from '../../model/MemberRegistrationConstants';
import { NonMemberApplicationHistoryModal } from '../../nonMemberHistory/NonMemberApplicationHistoryModal';
import MemberRegistrationService from '../../services/MemberRegistrationService';
import { SsoUserInfo } from '../../../../reducer/states';
import { ActionType } from '../../../../reducer/actions';
import { AuthContext } from '../../../../App';
import { useHistory } from 'react-router-dom';
import MemberService from '../../../reservation/services/MemberService';
import { MemberSearchResponse } from '../../../reservation/model/Member';
import { getNullToEmpty } from '../../../reservation/utils/util';
import { Session } from '../../../../service/model/SessionInfo';

const LoginSection: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  const [showNonMemberApplicationHistoryModal, setShowNonMemberApplicationHistoryModal] = useState<boolean>(false);
  const [sessionUser, setSessionUser] = useState<MemberSearchResponse>({} as MemberSearchResponse);
  const [loginButtonForm, setLoginButtonForm] = useState(true);
  const singlexHeaderUse = window.location.hostname.includes('singlex') ? true : false;

  const handleShowNonMemberApplicationHistoryModal = (): void => {
    history.push(history.location.hash + '#reservationHistory');
    setShowNonMemberApplicationHistoryModal(true);
  };
  const handleCloseNonMemberApplicationHistoryModal = (): void => {
    history.goBack();
  };

  const history = useHistory();
  const { dispatch } = useContext(AuthContext);
  /* eslint-disable */

  if(queryString.parse(window.location.search).loginType?.length! > 0)
    sessionStorage.setItem(Session.LOGIN_TYPE, queryString.parse(window.location.search).loginType?.toString()!);
  if(queryString.parse(window.location.search).sessionId?.length! > 0)
    sessionStorage.setItem(Session.SESSION_ID, queryString.parse(window.location.search).sessionId?.toString()!);
  if(queryString.parse(window.location.search).tenantId?.length! > 0)
    sessionStorage.setItem(Session.TENANT_ID, queryString.parse(window.location.search).tenantId?.toString()!);

  const loginType = sessionStorage.getItem(Session.LOGIN_TYPE);
  const sessionId = sessionStorage.getItem(Session.SESSION_ID);
  const tenantId = sessionStorage.getItem(Session.TENANT_ID);
  const setLoading = (_isLoading: boolean) => {
    dispatch({ type: ActionType.LOADING, loading: { isLoading: _isLoading } });
  };

  const getSessionInfo = async () => {
    const memberService = new MemberService();
    setLoading(true);
    const sessionUser: MemberSearchResponse = await memberService.getUserInfoBySessionId();
    setLoading(false);
    if (sessionUser && Object.keys(sessionUser).length > 0) {
      sessionStorage.setItem('memberId', sessionUser.memberId.toString());
      sessionStorage.setItem('memberTypeCode', sessionUser.memberTypeCode);
      sessionStorage.setItem('memberName', sessionUser.memberName);

      setSessionUser(sessionUser);
    }
  };

  useEffect(() => {
    if (loginType && loginType === 'sso' && sessionId && !sessionUser.employeeNumber && tenantId) {
      sessionStorage.clear();
      sessionStorage.setItem(Session.LOGIN_TYPE, loginType);
      sessionStorage.setItem(Session.SESSION_ID, sessionId);
      sessionStorage.setItem(Session.TENANT_ID, tenantId);
      getSessionInfo();
    }
    
    if(window.location.hash.includes('#loginForm')){
      history.replace('/login');
      history.push(history.location.hash + '#loginForm')
    } else {
      history.replace('/login');
    }
  }, []);

  useEffect(() => { 
    if (Object.keys(sessionUser).length > 0 && sessionUser.emailAddress && sessionUser.employeeNumber) {
      const ssoUserInfo: SsoUserInfo = {
        emailAddress: getNullToEmpty(sessionUser.emailAddress),
        employeeNumber: getNullToEmpty(sessionUser.employeeNumber),
        tenantId: tenantId!,
      };

      dispatch({ type: ActionType.SSO_LOGIN, ssoUserInfo: ssoUserInfo });
      let redirectUrl = '/memberRegistration?memberType=' + UserTypeParamEnum.SSO_EMPLOYEE;
      if (sessionUser.memberStateCode === MemberRegistrationConstants.DEFAULT_MEMBER_STATE_CODE) {  
        redirectUrl = '/reservation';
      }else{
        sessionStorage.clear();
      }
      setSessionUser({} as MemberSearchResponse);
      history.push(redirectUrl);
    }
  }, [sessionUser]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleResize = () => {
    if (window.innerWidth > 850) {
      setMobileMode(false);
    } else {
      setMobileMode(true);
    }
  };

  /* eslint-disable */
  useEffect(() => {
    const location = history.location;
    if (!location.hash.includes('#reservationHistory')) {
      setShowNonMemberApplicationHistoryModal(false);
    }
    if (!location.hash.includes('#loginForm')) {
      setLoginButtonForm(true);
    } else {
      setLoginButtonForm(false);
    }
  }, [history.location]);

  const [mobilemode, setMobileMode] = useState<boolean>(window.innerWidth > 850 ? false : true);

  /* eslint-enable */
  if (loginType && loginType === 'sso' && sessionId) {
    return <div></div>;
  } else {
    return (
      <div id="LoginSection">
        <Row className="loginContainer-top-row">
          <Col>
            <div id="LoginSectionTitle" className="LoginSection-title">
              {singlexHeaderUse ? (
                <Image src={SinglexLogoImage} className="lgLogo" alt="SINGLEX 방문 예약" style={{ width: '80%' }} />
              ) : (
                <Image src={lgLogo} className="lgLogo" alt="LG 방문 예약" />
              )}
              <h2>
                <span id="LoginSectionTitleTarget" className="LoginSection-title-target">
                  {singlexHeaderUse
                    ? t('member.label.LoginSection.main.singlexTitle')
                    : t('member.label.LoginSection.main.title')}
                </span>
                {/* {t('member.label.LoginSection.main.titleDescription')} */}
              </h2>
            </div>
            <div id="LoginSectionForm" className="LoginSection-form">
              <Row>
                {loginButtonForm && (
                  <div id="LoginButtonComponent" className="LoginButton-component">
                    <div id="LoginSectionDescription" className="LoginSection-description">
                      {singlexHeaderUse ? (
                        <p
                          dangerouslySetInnerHTML={{ __html: t('member.label.LoginSection.main.singlexContents') }}
                        ></p>
                      ) : (
                        <p dangerouslySetInnerHTML={{ __html: t('member.label.LoginSection.main.contents') }}></p>
                      )}
                    </div>
                    <div id="LoginButtonForm" className="login-button-form">
                      <Button
                        id="nonmemberReservation"
                        className="nonmemberReservation"
                        data-testid="nonmemberReservation"
                        variant="primary"
                        href={'/memberRegistration?memberType=' + UserTypeParamEnum.NON_MEMBER}
                        title="비회원 LG 방문 예약"
                      >
                        <i data-testid="nonmemberReservationIcon" className="material-icons md-48">
                          event_available
                        </i>
                        <div className="button-text-align">
                          {t('member.label.LoginButtonForm.nonMemberApplicationForVisit')}
                        </div>
                      </Button>
                      <Button
                        id="nonmemberHistory"
                        className="nonmemberHistory"
                        data-testid="nonmemberHistory"
                        variant="dark"
                        href="#none"
                        onClick={(e: any) => {
                          e.stopPropagation();
                          e.preventDefault();
                          handleShowNonMemberApplicationHistoryModal();
                        }}
                        title="LG 방문 신청 조회"
                      >
                        <i data-testid="nonmemberHistoryIcon" className="material-icons md-48">
                          history
                        </i>
                        <div className="button-text-align">
                          {t('member.label.LoginButtonForm.nonMemberApplicationForVisitHistory')}
                        </div>
                      </Button>
                    </div>
                  </div>
                )}
                {!loginButtonForm && (
                  <div id="LoginformComponent" className="Loginform-component">
                    <div id="backLoginButtonForm" className="backLoginButtonForm">
                      <i
                        className="material-icons"
                        onClick={(e: any) => {
                          history.goBack();
                        }}
                      >
                        arrow_back
                      </i>
                      <a
                        onClick={(e: any) => {
                          history.goBack();
                        }}
                      >
                        {t('member.label.LoginSection.main.back')}
                      </a>
                    </div>
                    <LoginForm />
                  </div>
                )}
              </Row>
              {mobilemode && (
                <div>
                  {loginButtonForm && (
                    <div>
                      <Row className="row-with-width">
                        <Col className="link-info">{t('member.label.LoginSection.main.areYouMember')}</Col>
                        <Col>
                          <div className="float-right">
                            <a
                              id="loginFormChanger"
                              data-testid="loginFormChanger"
                              className="link-loginForm link-with-margin-right"
                              onClick={(e: any) => {
                                history.push(history.location.hash + '#loginForm');
                              }}
                            >
                              {t('member.label.LoginForm.login')}
                            </a>
                            <a
                              id="registerOutsiderMember"
                              data-testid="registerOutsiderMember"
                              className="link-loginForm"
                              href={'/memberRegistration?memberType=' + UserTypeParamEnum.OUTSIDER_MEMBER}
                            >
                              {t('member.label.LoginSection.menuLink.memberRegistration')}
                            </a>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}
                  {!loginButtonForm && (
                    <div>
                      <Row className="row-with-width">
                        <Col className="link-info">
                          {t('member.label.LoginSection.main.makeReservationWithoutLogin')}
                        </Col>
                        <Col className="col-with-width">
                          <div className="float-right">
                            <a
                              id="loginButtonFormChanger"
                              data-testid="loginButtonFormChanger"
                              className="link-loginForm"
                              href={'/memberRegistration?memberType=' + UserTypeParamEnum.NON_MEMBER}
                            >
                              {t('member.label.LoginSection.menuLink.visitReservationWithoutLogin')}
                            </a>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}
                  <Row className="row-with-width bottom-empty-space">
                    <Col className="link-info">{t('member.label.LoginSection.main.areYouMemberOfLG')}</Col>
                    <Col className="col-with-width">
                      <div className="float-right">
                        <a
                          id="verifyNonSsoMember"
                          data-testid="verifyNonSsoMember"
                          className="link-loginForm"
                          href={'/memberRegistration?memberType=' + UserTypeParamEnum.NON_SSO_EMPLOYEE}
                        >
                          {t('member.label.LoginSection.menuLink.employeeAuthentication')}
                        </a>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
              {!mobilemode && (
                <div>
                  <Row className="row-info">
                    {loginButtonForm && <Col>{t('member.label.LoginSection.main.areYouMember')}</Col>}
                    {!loginButtonForm && <Col>{t('member.label.LoginSection.main.makeReservationWithoutLogin')}</Col>}
                    <Col>{t('member.label.LoginSection.main.areYouMemberOfLG')}</Col>
                  </Row>
                  <Row className="row-linker">
                    {loginButtonForm && (
                      <Col>
                        <a
                          id="loginFormChanger"
                          data-testid="loginFormChanger"
                          className="link-loginForm link-with-margin-right"
                          onClick={(e: any) => {
                            history.push(history.location.hash + '#loginForm');
                          }}
                        >
                          {t('member.label.LoginForm.login')}
                        </a>
                        <a
                          id="registerOutsiderMember"
                          data-testid="registerOutsiderMember"
                          className="link-loginForm"
                          href={'/memberRegistration?memberType=' + UserTypeParamEnum.OUTSIDER_MEMBER}
                        >
                          {t('member.label.LoginSection.menuLink.memberRegistration')}
                        </a>
                      </Col>
                    )}
                    {!loginButtonForm && (
                      <Col>
                        <a
                          id="loginButtonFormChanger"
                          data-testid="loginButtonFormChanger"
                          className="link-loginForm"
                          href={'/memberRegistration?memberType=' + UserTypeParamEnum.NON_MEMBER}
                        >
                          {t('member.label.LoginSection.menuLink.visitReservationWithoutLogin')}
                        </a>
                      </Col>
                    )}
                    <Col>
                      <a
                        id="verifyNonSsoMember"
                        data-testid="verifyNonSsoMember"
                        className="link-loginForm"
                        href={'/memberRegistration?memberType=' + UserTypeParamEnum.NON_SSO_EMPLOYEE}
                      >
                        {t('member.label.LoginSection.menuLink.employeeAuthentication')}
                      </a>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          </Col>
        </Row>
        {showNonMemberApplicationHistoryModal && (
          <NonMemberApplicationHistoryModal
            memberRegistrationService={new MemberRegistrationService()}
            show={showNonMemberApplicationHistoryModal}
            handleClose={handleCloseNonMemberApplicationHistoryModal}
          />
        )}
      </div>
    );
  }
};
export default LoginSection;
