import React, { createContext, useReducer, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import {
  AboutPage,
  ErrorPage,
  HomePage,
  MemberRegistrationPage,
  LoginPage,
  ReservationPage,
  UnauthorizedErrorPage,
  ApprovalInfoPage,
  EmailCertificationConfirmPage,
  MemberInfoPage,
  QrcodePage,
  ReservationInfoByQrcodePage,
  KioskPage,
  KioskReservationPage,
  TrialLoginPage,
  PrivacyPolicyPage,
} from './pages';
import 'moment/locale/ko';
import moment from 'moment';
import './App.scss';

import { reducer, initialState } from './reducer';
import { State } from './reducer/states';
import { Action } from './reducer/actions';
import SessionService from './service/SessionService';
import buildConfig from './build-env';
import queryString from 'query-string';

const WorkplaceManagementPage = buildConfig.adminAccessAvailable
  ? lazy(() => import('./pages/workplace/workplaceManagement'))
  : ErrorPage;

const WorkplaceListPage = buildConfig.adminAccessAvailable
  ? lazy(() => import('./pages/workplace/workplaceList'))
  : ErrorPage;

const ReservationStatusPage = buildConfig.adminAccessAvailable
  ? lazy(() => import('./pages/workplace/reservationStatus'))
  : ErrorPage;

interface AuthContextProps {
  state: State;
  dispatch: React.Dispatch<Action>;
}

export const AuthContext = createContext({} as AuthContextProps);

const App: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const lang = localStorage.getItem('lang') + '';
  if (lang === '' || lang.length > 2) {
    localStorage.setItem('lang', 'ko');
  }

  const sessionService = new SessionService();
  const encryptedText = queryString.parse(window.location.search).encryptedText || null;

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route exact path="/">
              {sessionService.getSessionId() ? <Redirect to="/reservation" /> : <Redirect to="/login" />}
            </Route>
            <Route exact path="/home">
              <Redirect to="/reservation" />
            </Route>
            <Route exact path="/about">
              <Redirect to="/login" />
            </Route>
            <Route exact path="/home" render={HomePage} />
            <Route exact path="/about" render={AboutPage} />
            <Route exact path="/memberRegistration" render={MemberRegistrationPage} />
            <Route exact path="/login" render={props => (encryptedText ? <TrialLoginPage /> : <LoginPage />)} />
            <Route
              exact
              path="/reservation"
              render={props => (sessionService.getSessionId() ? <ReservationPage /> : <Redirect to="/login" />)}
            />
            <Route exact path="/info/:workplaceId" component={ApprovalInfoPage} />
            <Route exact path="/emailCertificationConfirm" render={props => <EmailCertificationConfirmPage />} />
            <Route
              exact
              path="/workplaceManagement"
              render={props => (sessionService.getSessionId() ? <WorkplaceListPage /> : <Redirect to="/login" />)}
            />
            <Route
              exact
              path="/workplaceManagement/:workplaceId"
              render={props =>
                sessionService.getSessionId() ? <WorkplaceManagementPage {...props} /> : <Redirect to="/login" />
              }
            />
            <Route
              exact
              path="/workplaceManagement/reservationStatus/:workplaceId"
              render={props =>
                sessionService.getSessionId() ? <ReservationStatusPage {...props} /> : <Redirect to="/login" />
              }
            />
            <Route exact path="/unauthorized" component={UnauthorizedErrorPage} />
            <Route exact path="/memberInfo" component={MemberInfoPage} />
            {/* <Route exact path="/qrcode" component={QrcodePage}/>
            <Route exact path="/reservationInfoByQrcode" component={ReservationInfoByQrcodePage} />
            <Route exact path="/kiosk" component={KioskPage} />
            <Route exact path="/kiosk/reservation" component={KioskReservationPage} /> */}
            <Route exact path="/privacyPolicy" component={PrivacyPolicyPage} />
            <Route component={ErrorPage} />
          </Switch>
        </Suspense>
      </Router>
    </AuthContext.Provider>
  );
};
export default App;
