import React from 'react';

import './ExcelFileUpload.scss';
import { Modal, Container, Button, Form } from 'react-bootstrap';

import * as FileSaver from 'file-saver';
import * as Excel from 'exceljs';
import * as yup from 'yup';
import * as XLSX from 'xlsx';

import { useTranslation } from 'react-i18next';
import ExcelFileConfig, {
  ExcelTemplateHeader,
  ExcelHeaderInfo,
  ExcelData,
  ImportFileInfo,
  AlertProps,
} from '../model/Excel';
import { getVisitorExcelValidationSchema } from '../validation/VisitorExcelValidationSchema';
import { Visitor } from '../model/ReservationTypes';
import { Workplace } from '../model/Workplace';

export interface ExcelFileUploadI {
  setExcelObject: Function;
  onUpload: Function;
  importFileInfo?: ImportFileInfo;
  setImportFileInfo: Function;
  workplaceInfo: Workplace;
  alertProps: AlertProps;
}

export const ExcelFileUpload: React.FC<ExcelFileUploadI> = (props: ExcelFileUploadI) => {
  const { t } = useTranslation();
  const setExcelObject = props.setExcelObject;
  const onUpload = () => {
    props.onUpload && props.onUpload();
  };
  const importFileInfo = props.importFileInfo;
  const setImportFileInfo = props.setImportFileInfo;
  const validationSchema = getVisitorExcelValidationSchema(props.workplaceInfo);

  const handleTemplateDownload = () => {
    const headerList: Partial<Excel.Column>[] = [];

    for (const key in ExcelTemplateHeader) {
      const val: string = ExcelTemplateHeader[key];
      const header: Partial<Excel.Column> = {
        header: t(ExcelHeaderInfo[val]),
        key: val,
        width: ExcelHeaderInfo[val + ExcelFileConfig.EXCEL_WIDTH_KEY],
      };
      headerList.push(header);
    }

    const workbook = new Excel.Workbook();
    workbook.creator = ExcelFileConfig.TEMPLATE_CREATOR;
    workbook.properties.date1904 = true;
    const workSheet = workbook.addWorksheet(
      t('reservation.label.ReservationBottomForms.visitors.uploadModal.template.sheet.name')
    );
    workSheet.columns = headerList;

    workSheet.getRow(1).height = 50;

    headerList.map((val, inx) => {
      const cellStr =
        String.fromCharCode(ExcelFileConfig.EXCEL_HEADER_START_COLUMN + inx) + ExcelFileConfig.EXCEL_HEADER_START_ROW;
      const cell = workSheet.getCell(cellStr);
      if (cell) {
        cell.border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } },
        };
        cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        cell.font = {
          bold: true,
          size: 16,
        };
      }
    });

    workSheet.getColumn(ExcelTemplateHeader.MOBILE).numFmt = '@';

    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: ExcelFileConfig.BLOB_TYPE });
      FileSaver.saveAs(blob, ExcelFileConfig.TEMPLATE_VISITOR_FILE_NAME);
    });
  };

  const handleChangeExcelFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExcelObject(undefined);
    if (event.target.files && event.target.files[0]) {
      const uploadFile = event.target.files[0];
      event.target.value = '';
      if (
        !uploadFile.name ||
        uploadFile.name.lastIndexOf('.') < 0 ||
        uploadFile.name.substr(uploadFile.name.lastIndexOf('.') + 1) !== ExcelFileConfig.ACCEPT_EXTENSION
      ) {
        props.alertProps.setAlertMessage(
          t('reservation.label.ReservationBottomForms.visitors.uploadModal.message.alertExtension')
        );
        props.alertProps.setIsShowAlert(true);
        return false;
      }
      const reader = new FileReader();
      reader.onload = e => {
        if (e.target) {
          const data: any = e.target.result;

          if (data != null && data) {
            const wb: XLSX.WorkBook = XLSX.read(data, { type: 'array' });
            const sheet = wb.Sheets[wb.SheetNames[0]];
            const _json = XLSX.utils.sheet_to_json(sheet, { raw: false, blankrows: false });
            const _excelObj: ExcelData[] = setObject(_json);
            if (_excelObj) {
              checkValidation(_excelObj);
              setExcelObject(_excelObj);
            }
          }
        }
      };
      reader.readAsArrayBuffer(uploadFile);
      setImportFileInfo({
        fileName: uploadFile.name,
        fileSize: uploadFile.size,
        fileType: uploadFile.type,
      });
    }
  };

  const excelValidateSync = (_excelObj: ExcelData[]) => {
    let error: any;

    try {
      validationSchema.validateSync(_excelObj, { abortEarly: false });
    } catch (err) {
      error = err;
    }
    return error;
  };

  const checkValidation = (_excelObj: ExcelData[]) => {
    const errors = excelValidateSync(_excelObj);
    if (errors && errors.inner) {
      errors.inner.map((val, inx) => {
        const arrTmp = val.path.split('.');
        if (arrTmp && arrTmp.length > 0) {
          const arrIndex = parseInt(arrTmp[0].slice(1, arrTmp[0].length - 1));
          _excelObj[arrIndex].invalidReason = _excelObj[arrIndex].invalidReason
            ? _excelObj[arrIndex].invalidReason + ',' + t(val.message, { max: props.workplaceInfo.maxVisitPeriod })
            : t(val.message, { max: props.workplaceInfo.maxVisitPeriod });
        }
      });
    }
    _excelObj.map(val => {
      if (
        _excelObj.filter(_val => {
          return _val.visitorName === val.visitorName && _val.mobile === val.mobile;
        }).length > 1
      ) {
        val.invalidReason = t(
          'reservation.label.ReservationBottomForms.visitors.uploadModal.visitorListTable.validation.message.duplicateVisitor'
        );
      }
    });
  };

  const setObject = (_json: any): ExcelData[] => {
    const result: ExcelData[] = [];

    const headerKeyVal: any = {};
    for (const key in ExcelTemplateHeader) {
      const val: string = ExcelTemplateHeader[key];
      const str: string = t(ExcelHeaderInfo[val]);
      headerKeyVal[str] = val;
    }
    _json.map((val, inx) => {
      const _excelData: any = {
        no: inx,
        isSelected: false,
      };
      const _obj = val;
      Object.keys(_obj).forEach(_val => {
        _excelData[headerKeyVal[_val]] = _obj[_val];
      });
      result.push(_excelData as ExcelData);
    });

    return result;
  };

  return (
    <>
      <Modal.Header closeButton>
        <div className="title bold" data-testid="excel-upload-title">
          {t('reservation.label.ReservationBottomForms.visitors.uploadModal.title')}
        </div>
        <div className="modal__header--info">
          {t('reservation.label.ReservationBottomForms.visitors.uploadModal.subTitle')}
        </div>
        <Button
          name="save"
          id="save-button"
          variant={'outline-secondary'}
          className={'bold download'}
          onClick={handleTemplateDownload}
        >
          <i className="material-icons pointer">cloud_download</i>
          <span>{t('reservation.label.ReservationBottomForms.visitors.uploadModal.button.template')}</span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <div className="divUpload">
            <div className={'title'}>
              <span>{t('reservation.label.ReservationBottomForms.visitors.uploadModal.body.title')}</span>
            </div>
            <div>
              <Form.Label className={'file-label'}>
                <p className={'button-label'}>
                  <i className="material-icons pointer">cloud_upload</i>
                  <span>{t('reservation.label.ReservationBottomForms.visitors.uploadModal.button.file')}</span>
                </p>
                {importFileInfo ? (
                  <p className="fileName">{importFileInfo.fileName}</p>
                ) : (
                  <p className="fileName placeholder">
                    {t('reservation.label.ReservationBottomForms.visitors.uploadModal.button.placeholder')}
                  </p>
                )}
                <Form.Control type="file" accept=".xls,.xlsx" onChange={handleChangeExcelFile} />
              </Form.Label>
            </div>
          </div>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button
          id="uploadButton"
          data-testid="uploadButton"
          variant="primary"
          onClick={onUpload}
          disabled={importFileInfo ? false : true}
        >
          {t('reservation.label.ReservationBottomForms.visitors.uploadModal.button.upload')}
        </Button>
      </Modal.Footer>
    </>
  );
};

export default ExcelFileUpload;
