import { ReactElement, useState, useEffect } from 'react';
import React from 'react';
import WorkplaceService from '../services/WorkplaceService';
import { WorkplaceContent } from '../model/Workplace';
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import { localeEnum, localeCodeEnum } from '../model/InfoConstant';
import OutsideClickHandler from 'react-outside-click-handler';
import './ApprovalNotice.scss';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

export interface ApprovalNoticeI {
  workplaceId: string;
  workplaceService: WorkplaceService;
}

export const ApprovalNotice: React.FC<ApprovalNoticeI> = (props: ApprovalNoticeI): ReactElement => {
  const { t } = useTranslation();
  const [workplaceContents, setworkplaceContents] = useState<WorkplaceContent[]>();
  const [locale, setLocale] = useState<string>(localeCodeEnum.KO);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [selectedContent, setSelectedContent] = useState<WorkplaceContent | undefined>();
  const [showPage, setShowPage] = useState<boolean>(false);

  const getDropdownLang = (): ReactElement => {
    return (
      <div>
        <OutsideClickHandler onOutsideClick={(): void => setShowDropdown(false)}>
          {Object.keys(localeCodeEnum).map(
            (k, idx) =>
              k && (
                <Dropdown.Item
                  key={k}
                  eventKey={k}
                  onSelect={(): void => onSelectLanguage(localeCodeEnum[k])}
                  id={'selectLocale-' + k}
                  data-testid={'selectLocale-' + k}
                >
                  <p className="d-inline-block">{localeEnum[k]}</p>
                </Dropdown.Item>
              )
          )}
        </OutsideClickHandler>
      </div>
    );
  };

  const onSelectLanguage = (locale: string): void => {
    setLocale(locale);

    if (workplaceContents) {
      workplaceContents.map(k => {
        if (k.languageCode.toLowerCase() === locale.toLowerCase()) {
          setSelectedContent(k);
          setShowPage(true);
        } else {
          setSelectedContent(undefined);
          setShowPage(false);
        }
      });
    } else {
      setSelectedContent(undefined);
      setShowPage(false);
    }

    setShowDropdown(false);
  };

  useEffect(() => {
    const getNotiData = async () => {
      return await props.workplaceService.getWorkplaceContentPublicById(props.workplaceId);
    };

    getNotiData().then(res => {
      if (res) {
        setworkplaceContents(res);

        res.map(k => {
          if (k.languageCode.toLowerCase() === locale.toLowerCase()) {
            setSelectedContent(k);
            setShowPage(true);
          }
        });
      } else {
        setworkplaceContents(undefined);
      }
    });
  }, [locale, props.workplaceId, props.workplaceService]);

  return (
    <div className="approval-notice-container">
      <Container>
        {showPage && (
          <React.Fragment key={props.workplaceId}>
            <Row>
              <Col>
                <span className="notice-title">
                  {selectedContent ? selectedContent.groupCompanyName : ''}{' '}
                  {selectedContent ? selectedContent.workplace : ''} {t('info.label.ApprovalNotice.notice-title')}
                  <i
                    className="material-icons lang-disabled"
                    data-testid="change-lanuage"
                    onClick={(): void => setShowDropdown(true)}
                  >
                    languagearrow_drop_down
                  </i>
                  {showDropdown && getDropdownLang()}
                </span>
              </Col>
            </Row>
            <Row className="line-row">
              <hr className="notice-line"></hr>
            </Row>
            <Row className="content-row">
              <Col className="content-col">
                <div className="notice-content">{selectedContent?.approvalNotiContent}</div>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </Container>
    </div>
  );
};

export default ApprovalNotice;
