import React, { ReactElement, useState, useEffect } from 'react';
import './NonMemberApplicationHistoryModal.scss';

import icoResult from '../../../icons/ico--result.svg';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Form, Container, Row, Col, Image, ButtonToolbar } from 'react-bootstrap';
import * as yup from 'yup';
import CertificationConfirmRequest from '../model/CertificationConfirmRequest';
import CertificationRequest from '../model/CertificationRequest';
import MemberRegistrationConstants, { CertificationTypeCode } from '../model/MemberRegistrationConstants';
import { Formik } from 'formik';
import { ReservationResponse, VisitorResponse } from '../../reservation/model/Reservation';
import {
  ApprovalStatusEnum,
  UserTypeEnum,
  MemberTypeCode,
  DateFormat,
} from '../../reservation/model/ReservationConstants';

import moment from 'moment';
import ReservationService from '../../reservation/services/ReservationService';
import { User } from '../../reservation/model/User';
import MemberRegistrationService from '../services/MemberRegistrationService';
import { reservationDetailUtil } from '../../reservation/utils/ReservationDetailUtil';

import PhoneCertification from '../memberRegistration/component/PhoneCertification';
import { BusinessTypeCode } from '../model/CommonConstants';

import { useHistory } from 'react-router-dom';
import { CommonCode } from '../../reservation/model/Code';
import CommonCodeService from '../../reservation/services/CommonCodeService';
import ModalVisitToTarget from '../../reservation/components/ModalVisitToTarget';
import { ModalVisitors } from '../../reservation/components/ModalVisitors';
import CommonLoadingOverlay from '../../../layouts/LoadingOverlay';

export interface NonMemberApplicationHistoryModalProps {
  memberRegistrationService: MemberRegistrationService;
  show: boolean;
  handleClose: () => void;
}

export const NonMemberApplicationHistoryModal: React.FC<NonMemberApplicationHistoryModalProps> = (
  props: NonMemberApplicationHistoryModalProps
): ReactElement => {
  const reservationService = new ReservationService();
  const { t } = useTranslation();
  const visitorHeaderStrong = t('member.label.NonMemberApplicationHistoryModal.VisitorInformation.headerString');
  const visitorHeaderNormal = t('member.label.NonMemberApplicationHistoryModal.VisitorInformation.headerNormal');
  const historyHeader = t('member.label.NonMemberApplicationHistoryModal.ApplicationHistory.header');

  const [headerStrong, setHeaderStrong] = useState<string>(visitorHeaderStrong);
  const [headerNormal, setHeaderNormal] = useState<string>(visitorHeaderNormal);
  const [body, setBody] = useState<string>('visitor');

  const [receivedCertificationId, setCertificationId] = useState('');
  const [certificationConfirmStatus, setCertificationConfirmStatus] = useState(false);
  const [minutes, setMinutes] = useState(2);
  const [seconds, setSeconds] = useState(59);
  const [isActive, setIsActive] = useState(false);
  const componentDisplayNoneClassName: string = MemberRegistrationConstants.COMPONENT_DISPLAY_NONE_CLASS_NAME;
  const componentDisplayClassName: string = MemberRegistrationConstants.COMPONENT_DISPLAY_CLASS_NAME;
  const [certificationKeyformGroupClassName, setCertificationKeyformGroupClassName] = useState(
    componentDisplayNoneClassName
  );
  const [certificationKeyRequestButtonClassName, setCertificationKeyRequestButtonClassName] = useState(
    'authentication-number-button'
  );
  const isShow: boolean = props.show ? true : false;
  const _memberRegistrationService: MemberRegistrationService = props.memberRegistrationService
    ? props.memberRegistrationService
    : new MemberRegistrationService();

  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [pagingIndex, setPagingIndex] = useState<number>(0);
  const [hasBefore, setHasBefore] = useState<boolean>(false);
  const [hasNext, setHasNext] = useState<boolean>(false);

  const [selectedListIndex, setSelectedListIndex] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [commonCodeMap, setCommonCodeMap] = useState<Map<string, CommonCode[]>>();
  const history = useHistory();
  const [isLoading, setLoading] = useState<boolean>(false);
  const commonCodeService = new CommonCodeService();
  const [sessionUser, setSessionUser] = useState<User>();

  const getSessionInfo = async () => {
    await reservationDetailUtil.getSessionUserInfo().then((res: User) => {
      const user: User = res;
      setSessionUser(user);
    });
  };

  const handleItemClick = (index: number) => {
    setSelectedListIndex(index);
    setShowModal(true);
    setBody('historyDetail');
    history.push(history.location.hash + '#requestModal');
  };

  const handleModalClose = () => {
    setBody('history');
    history.goBack();
  };

  const timerToggle = (): void => {
    if (isActive) {
      setMinutes(2);
      setSeconds(59);
    }
    setIsActive(!isActive);
  };

  /* eslint-disable */
  useEffect(
    /* istanbul ignore next */ () => {
      let interval: any = null;
      if (isActive) {
        interval = setInterval(() => {
          if (seconds === 0 && minutes > 0) {
            setSeconds(seconds => 59);
            setMinutes(minutes => minutes - 1);
          } else if (seconds === 0 && minutes === 0) {
            certificationKeyReset();
          } else {
            setSeconds(seconds => seconds - 1);
          }
        }, 1000);
      } else if (!isActive && seconds !== 0 && minutes !== 0) {
        clearInterval(interval);
      }
      return (): void => clearInterval(interval);
    },
    [isActive, minutes, seconds]
  );
  /* eslint-disable */

  /* eslint-disable */
  useEffect(() => {
    getSessionInfo();
  }, []);
  /* eslint-enable */

  /* eslint-disable */
  useEffect(() => {
    const location = history.location;
    if (!location.hash.includes('#requestModal')) {
      setShowModal(false);
    }
  }, [history.location]);
  /* eslint-enable */

  /* eslint-disable */
  useEffect(() => {
    const initCommonCodeMap = async () => {
      return await commonCodeService.initCommonCodeMap();
    };
    if (props.show && !isLoading) {
      setLoading(true);
      initCommonCodeMap()
        .then(res => {
          if (res) {
            setCommonCodeMap(res);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [props.show]);
  /* eslint-enable */
  const schema = yup.object({
    memberName: yup
      .string()
      .required(t('member.message.frontEnd.memberName.required'))
      .test('member-name', t('member.message.frontEnd.memberName.blank'), function(value: string) {
        const trimResult = value ? value.trimStart().trimEnd() : value;

        return value === trimResult ? true : false;
      })
      .min(2, t('member.message.frontEnd.memberName.common')),
    phoneNumber: yup
      .string()
      .required(t('member.message.frontEnd.phoneNumber.required'))
      .matches(MemberRegistrationConstants.REGEXP_MOBILE, t('member.message.frontEnd.phoneNumber.common')),
    certificationKey: yup
      .string()
      .required(t('member.message.frontEnd.certificationKey.required'))
      .min(6, t('member.message.frontEnd.certificationKey.common'))
      .max(6, t('member.message.frontEnd.certificationKey.common')),
  });

  const requestCertificationKey = async (phoneNumber: string): Promise<void> => {
    setCertificationConfirmStatus(false);
    const certificationRequest: CertificationRequest = {
      certificationTypeCode: CertificationTypeCode.SMS,
      certificationSendAddress: phoneNumber.replace(/-/gi, ''),
      certificationBusinessTypeCode: MemberRegistrationConstants.NON_MEMBER_TYPE_CODE,
    };
    const submitStatus = await _memberRegistrationService.requestCertificationKey(certificationRequest);
    if (submitStatus.successOrNot === 'Y') {
      setCertificationKeyformGroupClassName(componentDisplayClassName);
      setCertificationKeyRequestButtonClassName(componentDisplayNoneClassName);
      timerToggle();
      setCertificationId(submitStatus.data.certificationId);
    } else {
      setCertificationId('');
      alert(t('member.message.backEnd.UE'));
    }
  };
  const confirmCertificationKey = async (
    phoneNumber: string,
    certificationKey: string,
    setFieldError: any
  ): Promise<void> => {
    const certificationConfirmRequest: CertificationConfirmRequest = {
      certificationId: receivedCertificationId,
      certificationSendAddress: phoneNumber.replace(/-/gi, ''),
      certificationKey: certificationKey,
    };
    const submitStatus = await _memberRegistrationService.confirmCertificationKey(certificationConfirmRequest);
    if (submitStatus.successOrNot === 'Y') {
      timerToggle();
      setCertificationConfirmStatus(true);
      setCertificationKeyformGroupClassName(componentDisplayNoneClassName);
    } else if (submitStatus.statusCode === MemberRegistrationConstants.UNKNOWN_SERVER_ERROR_CODE) {
      setCertificationConfirmStatus(false);
      alert(t('member.message.backEnd.UE'));
    } else {
      setCertificationConfirmStatus(false);
      setFieldError('certificationKey', t('member.message.backEnd.' + submitStatus.statusCode));
    }
    setIsActive(false);
  };

  const certificationKeyReset = (): void => {
    setCertificationKeyformGroupClassName(componentDisplayNoneClassName);
    setCertificationKeyRequestButtonClassName(componentDisplayClassName);
    timerToggle();
    setCertificationId('');
    setCertificationConfirmStatus(false);
  };

  const getReservationList = async (
    memberName: string,
    phoneNumber: string,
    certificationId: string
  ): Promise<ReservationResponse[]> => {
    const user: User = {
      type: UserTypeEnum.NONEMEMBER,
      memberTypeCode: MemberTypeCode.NO_MEM,
      name: memberName,
      mobile: phoneNumber.replace(/-/gi, ''),
      id: '',
      companyCode: '',
      companyName: '',
      dept: '',
      position: '',
      email: '',
    };

    return await reservationService.getNonMemberReservationList(user, certificationId);
  };

  const getApplyDateDiff = (applyDate: string): string => {
    const days = moment(moment().format(DateFormat.DATE_IF)).diff(moment(applyDate).format(DateFormat.DATE_IF), 'days');

    if (days === 0) {
      return t('member.label.NonMemberApplicationHistoryModal.ApplicationHistory.body.period.today');
    } else {
      return days + t('member.label.NonMemberApplicationHistoryModal.ApplicationHistory.body.period.daysBefore');
    }
  };

  const getPeriod = (fromVisitPeriod: string, toVisitPeriod: string): string => {
    const days = moment(toVisitPeriod).diff(moment(fromVisitPeriod), 'days') + 1;

    return (
      moment(fromVisitPeriod).format(DateFormat.DATE_DISP) +
      ' ~ ' +
      moment(toVisitPeriod).format(DateFormat.DATE_DISP) +
      ' (' +
      days +
      t('member.label.NonMemberApplicationHistoryModal.ApplicationHistory.body.period.days') +
      ')'
    );
  };

  const getVistors = (list: VisitorResponse[]): string => {
    const applyMemberName = list[0].applyMemberName;
    if (list.length > 1) {
      return (
        applyMemberName +
        ' ' +
        t('member.label.NonMemberApplicationHistoryModal.ApplicationHistory.body.visitors.and') +
        ' ' +
        list.length +
        t('member.label.NonMemberApplicationHistoryModal.ApplicationHistory.body.visitors.others')
      );
    } else {
      return applyMemberName;
    }
  };

  const showHistory = async (
    memberName: string,
    phoneNumber: string,
    certificationId: string,
    setFieldValue: Function
  ) => {
    setHeaderStrong(historyHeader);
    setHeaderNormal('');
    setBody('history');

    setLoading(true);
    const reservationList = await getReservationList(memberName, phoneNumber, certificationId);
    setLoading(false);

    const pagingIndex = Math.floor((reservationList.length - 1) / 3);
    setPagingIndex(pagingIndex);
    setSelectedIndex(0);
    setHasBefore(false);
    setHasNext(0 < pagingIndex);

    setFieldValue('reservationList', reservationList, true);
  };

  const getReservationListByIndex = (index: number) => {
    if (-1 < index && index <= pagingIndex) {
      setSelectedIndex(index);
      setHasBefore(index > 0);
      setHasNext(index < pagingIndex);
    }
  };

  const handleOnHide = (resetForm: Function) => {
    certificationKeyReset();
    setIsActive(false);
    resetForm({});
    setHeaderStrong(visitorHeaderStrong);
    setHeaderNormal(visitorHeaderNormal);
    setBody('visitor');
    props.handleClose();
  };

  const initialValues = {
    memberName: '',
    phoneNumber: '',
    certificationId: '',
    certificationKey: '',
    reservationList: [] as ReservationResponse[],
  };

  const isEmptyReservation = (values: any) => {
    return !values.reservationList || values.reservationList.length === 0;
  };

  const getNonMemberReservationBody = () => {
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={() => {
          /*do nothing*/
        }}
        validationSchema={schema}
      >
        {({
          values,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldError,
          isValid,
          resetForm,
          setFieldValue,
        }): any => (
          <Modal
            id="NonMemberApplicationHistoryModal"
            data-testid="nonMemberApplicationHistoryModal"
            show={isShow}
            onHide={() => {
              body === 'historyDetail' ? handleModalClose() : handleOnHide(resetForm);
            }}
            enforceFocus={false}
            centered
            className={
              body === 'historyDetail' ? 'modal-z-index reservation-modal' : 'nonMemberApplicationHistoryModal'
            }
          >
            <Modal.Header
              closeButton
              className="nonMemberApplicationHistoryModalHeader"
              data-testid="nonMemberApplicationHistoryModalHeader"
            >
              <Modal.Title>
                {body !== 'historyDetail' && (
                  <Container>
                    <p>
                      <strong data-testid="modalHeaderTitle">{headerStrong}</strong>
                      {headerNormal.substring(0, 2)}
                      <br />
                      {headerNormal.substring(2, headerNormal.length)}
                    </p>
                  </Container>
                )}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {body === 'visitor' && (
                <div
                  className="nonMemberApplicationHistoryContainerBodyVisitor"
                  data-testid="nonMemberApplicationHistoryContainerBodyVisitor"
                >
                  <Row>
                    <Col>
                      <Form.Label>
                        {t('member.label.NonMemberApplicationHistoryModal.VisitorInformation.body.name.label')}
                      </Form.Label>
                      <Form.Control
                        id="memberName"
                        name="memberName"
                        data-testid="memberName"
                        required
                        type="text"
                        placeholder={t(
                          'member.label.NonMemberApplicationHistoryModal.VisitorInformation.body.name.placeholder'
                        )}
                        onChange={handleChange}
                        isInvalid={!!errors.memberName}
                      />
                      <Form.Control.Feedback id="memberNameFeedback" data-testid="memberNameFeedback" type="invalid">
                        {errors.memberName}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <PhoneCertification
                        phoneCertificationFormik={{
                          handleChange: handleChange,
                          handleBlur: handleBlur,
                          errors: errors,
                          values: values,
                          setFieldError: setFieldError,
                          setFieldValue: setFieldValue,
                        }}
                        receivedCertificationId={receivedCertificationId}
                        setCertificationId={setCertificationId}
                        certificationConfirmStatus={certificationConfirmStatus}
                        setCertificationConfirmStatus={setCertificationConfirmStatus}
                        certificationBusinessTypeCode={BusinessTypeCode.NOMEM}
                        memberRegistrationService={_memberRegistrationService}
                      />
                    </Col>
                  </Row>
                </div>
              )}
              {body === 'history' && isEmptyReservation(values) && (
                <div className="noHistoryCard" data-testid="noHistoryCard">
                  <span>{t('member.label.NonMemberApplicationHistoryModal.ApplicationHistory.body.noHistory')}</span>
                </div>
              )}
              {body === 'history' && values.reservationList && values.reservationList.length > 0 && (
                <div
                  id="nonMemberApplicationHistoryContainerBodyHistory"
                  className="nonMemberApplicationHistoryContainerBodyHistory"
                  data-testid="nonMemberApplicationHistoryContainerBodyHistory"
                >
                  {values.reservationList.map((value, index) => {
                    if (
                      value.applyMemberList &&
                      value.applyMemberList.length > 0 &&
                      Math.floor(index / 3) == selectedIndex
                    ) {
                      return (
                        <li
                          id={value.visitRequestId + index}
                          key={value.visitRequestId + index}
                          className="visitHistoryCardBox"
                          data-testid="selectedReservation"
                          onClick={() => handleItemClick(index)}
                        >
                          <Container className="visitHistoryCard" data-testid="visitHistoryCard">
                            <Row className="card__header">
                              <Col className="card__header--place">
                                {value.hostEmployeeCompanyName + ' ' + value.visitWorkplace}
                              </Col>
                              <Col className="card__header--applydate">
                                <span className="float-right">{getApplyDateDiff(value.applyDate)}</span>
                              </Col>
                            </Row>
                            <Row className="card__host">
                              {value.hostEmployeeName +
                                ' / ' +
                                value.hostEmployeeCompanyName +
                                ' ' +
                                value.hostEmployeeDepartment}
                            </Row>
                            {value.approvalStatus === ApprovalStatusEnum.IP016002 && (
                              <Row className="card__chip">
                                <Col>
                                  <em className="card__chip--apply">
                                    <i className="material-icons">more_horiz</i>
                                    <span>
                                      {t(
                                        reservationDetailUtil.getStatusDesc(value.approvalStatus, value.workplaceInfo)
                                      )}
                                    </span>
                                  </em>
                                </Col>
                              </Row>
                            )}
                            {value.approvalStatus === ApprovalStatusEnum.IP016003 && (
                              <Row className="card__chip">
                                <Col>
                                  <em className="card__chip--confirm">
                                    <i className="material-icons">check</i>
                                    <span>
                                      {t(
                                        reservationDetailUtil.getStatusDesc(value.approvalStatus, value.workplaceInfo)
                                      )}
                                    </span>
                                  </em>
                                </Col>
                              </Row>
                            )}
                            {value.approvalStatus === ApprovalStatusEnum.IP016004 && (
                              <Row className="card__chip">
                                <Col>
                                  <em className="card__chip--cancel">
                                    <i className="material-icons">warning</i>
                                    <span>
                                      {t(
                                        reservationDetailUtil.getStatusDesc(value.approvalStatus, value.workplaceInfo)
                                      )}
                                    </span>
                                  </em>
                                </Col>
                              </Row>
                            )}
                            {value.approvalMessage && (
                              <Row className="card__msg">
                                <Col>
                                  <p className="card__msg--default">{value.approvalMessage}</p>
                                </Col>
                              </Row>
                            )}
                            <Row className="card__period">
                              <Col md="3" xs="3" sm="3">
                                <label>
                                  {t(
                                    'member.label.NonMemberApplicationHistoryModal.ApplicationHistory.body.visitors.period'
                                  )}
                                </label>
                              </Col>
                              <Col>
                                <span>{getPeriod(value.fromVisitPeriod, value.toVisitPeriod)}</span>
                              </Col>
                            </Row>
                            <Row className="card__member">
                              <Col md="3" xs="3" sm="3">
                                <label>
                                  {t(
                                    'member.label.NonMemberApplicationHistoryModal.ApplicationHistory.body.visitors.visitors'
                                  )}
                                </label>
                              </Col>
                              <Col>
                                <span>{getVistors(value.applyMemberList)}</span>
                              </Col>
                            </Row>
                          </Container>
                        </li>
                      );
                    }
                  })}
                </div>
              )}
              {body === 'historyDetail' && sessionUser && selectedListIndex !== undefined && (
                <div>
                  <ModalVisitToTarget
                    reservation={values.reservationList[selectedListIndex]}
                    sessionUser={sessionUser}
                    showRequestor={false}
                    commonCodeMap={commonCodeMap}
                  />
                  <ModalVisitors
                    reservation={values.reservationList[selectedListIndex]}
                    sessionUser={sessionUser}
                    commonCodeMap={commonCodeMap}
                  />
                  <ButtonToolbar>
                    <Button
                      className="approveConformButton"
                      name="ok"
                      data-testid="ok-button"
                      variant="primary"
                      onClick={handleModalClose}
                    >
                      <span>{t('reservation.label.button.ok')}</span>
                    </Button>
                  </ButtonToolbar>
                </div>
              )}
            </Modal.Body>
            {body !== 'historyDetail' && (
              <Modal.Footer>
                {body === 'visitor' && (
                  <Row>
                    <Button
                      id="nextButton"
                      data-testid="nextButton"
                      variant="primary"
                      type="submit"
                      disabled={!isValid || !certificationConfirmStatus}
                      onClick={(): Promise<void> =>
                        showHistory(values.memberName, values.phoneNumber, receivedCertificationId, setFieldValue)
                      }
                    >
                      {t('member.label.NonMemberApplicationHistoryModal.VisitorInformation.footer.button.next')}
                    </Button>
                  </Row>
                )}
                {body === 'history' && values.reservationList && (
                  <Row>
                    {hasBefore && (
                      <Col className="footer-left">
                        <div onClick={(): void => getReservationListByIndex(selectedIndex - 1)}>
                          <i className="material-icons">arrow_back</i>
                          <span>
                            {t('member.label.NonMemberApplicationHistoryModal.ApplicationHistory.footer.button.before')}
                          </span>
                        </div>
                      </Col>
                    )}
                    {hasNext && (
                      <Col className="footer-right">
                        <div onClick={(): void => getReservationListByIndex(selectedIndex + 1)}>
                          <span>
                            {t('member.label.NonMemberApplicationHistoryModal.ApplicationHistory.footer.button.next')}
                          </span>
                          <i className="material-icons">arrow_forward</i>
                        </div>
                      </Col>
                    )}
                  </Row>
                )}
              </Modal.Footer>
            )}
          </Modal>
        )}
      </Formik>
    );
  };

  return <CommonLoadingOverlay active={isLoading}>{getNonMemberReservationBody}</CommonLoadingOverlay>;
};

export default NonMemberApplicationHistoryModal;
