import BaseService from '../../../service/BaseService';
import { Service } from '../../../service/model/Service';
import { CommonCode } from '../model/Code';

export default class CommonCodeService extends BaseService {
  private commonCodeMap = new Map<string, CommonCode[]>();

  public static readonly IMPORTED_GOODS_CODE = 'importedGoodsCode';
  public static readonly IMPORTED_GOODS_PURPOSE = 'importedGoodsPurpose';
  public static readonly SEAL_EXCEPTION_IMPORTED_GOODS = 'sealExceptionImportedGoods';
  public static readonly VISIT_PURPOSE_CODE = 'visitPurposeCode';
  public static readonly SEARCH_KEYWORD_CODE = 'searchKeywordCode';

  private defaultGroupCodes: string[] = [
    CommonCodeService.IMPORTED_GOODS_PURPOSE,
    CommonCodeService.VISIT_PURPOSE_CODE,
    CommonCodeService.IMPORTED_GOODS_CODE,
    CommonCodeService.SEAL_EXCEPTION_IMPORTED_GOODS,
    CommonCodeService.SEARCH_KEYWORD_CODE,
  ];

  getCommonCodeMap(): Map<string, CommonCode[]> {
    return this.commonCodeMap;
  }

  async initCommonCodeMap() {
    for (let i = 0; i < this.defaultGroupCodes.length; i++) {
      const response = await this.getCommonCode(this.defaultGroupCodes[i]);
      this.commonCodeMap.set(this.defaultGroupCodes[i], response);
    }
    return this.commonCodeMap;
  }

  async getCommonCode(groupCodeId: string): Promise<any> {
    let result = this.commonCodeMap.get(groupCodeId);
    if (result) {
      return result;
    }

    const serviceName = Service.SYSTEM_META;
    const method = 'GET';

    const queryParam: any = {
      groupCodeId: groupCodeId,
    };

    const url = '/v1/commonCode' + this.getQueryStringFormat(queryParam);

    let response;
    try {
      response = await this.fnRest(method, url, null, serviceName);
      result = response && response.successOrNot === 'Y' && response.data;
    } catch (error) {
      response = error;
    }
    return result;
  }

  async getCodeByWorkplace(workplaceId: string, groupCodeId: string): Promise<CommonCode[]> {
    const serviceName = Service.WORKPLACE;
    const method = 'GET';

    const queryParam: any = {
      workplaceId: workplaceId,
      groupCodeId: groupCodeId,
    };

    const url = '/v1/code' + this.getQueryStringFormat(queryParam);

    let response;
    let result: CommonCode[] = [];
    try {
      response = await this.fnRest(method, url, null, serviceName);
      result = response && response.successOrNot === 'Y' && response.data;
    } catch (error) {
      response = error;
    }
    return result;
  }
}
