import React, { ReactElement, useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button, Modal, Container } from 'react-bootstrap';
import {
  MemberInfoChange,
  MemberChange,
  FAIL_ICON,
  CHECK_ICON,
  SelectItem,
  TOAST_ID_TYPE,
  TOAST_CLASS_TYPE,
} from '../../model/MemberChange';
import MemberInfoService from '../../services/MemberInfoService';
import { MemberChangeType } from '../../model/MemberChangeType.enum';
import MemberRegistrationConstants from '../../model/MemberRegistrationConstants';
import './ModifyPasswordModal.scss';
import { Formik } from 'formik';
import { AuthContext } from '../../../../App';
import { ActionType } from '../../../../reducer/actions';
import * as yup from 'yup';

export interface ModifyPasswordModalProps {
  show: boolean;
  onHide: Function;
}

export const ModifyPasswordModal: React.FC<ModifyPasswordModalProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const { dispatch } = useContext(AuthContext);
  const memberInfoService = new MemberInfoService();

  /* eslint-disable */
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastId, setToastId] = useState(TOAST_ID_TYPE.FAIL);
  const [toastClassName, setToastClassName] = useState(TOAST_CLASS_TYPE.PASSWORD);
  const [toastIcon, setToastIcon] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const clearPasswordError = (): void => {
    if (passwordError !== '') {
      setPasswordError('');
    }
  };

  const setLoading = (_isLoading: boolean) => {
    dispatch({ type: ActionType.LOADING, loading: { isLoading: _isLoading } });
  };

  const passwordSchema = yup.object({
    password: yup.string().required(' '),
    newPassword: yup
      .string()
      .required(' ')
      .matches(MemberRegistrationConstants.REGEXP_PASSWORD, t('member.message.frontEnd.password.common')),
    newPasswordConfirm: yup
      .string()
      .required(' ')
      .oneOf([yup.ref('newPassword'), null], t('member.message.frontEnd.password.common'))
  });

  const findToastMessage = (idType: TOAST_ID_TYPE, classType: TOAST_CLASS_TYPE): string => {
    switch (idType) {
      case TOAST_ID_TYPE.SUCCESS:
        switch (classType) {
          case TOAST_CLASS_TYPE.PASSWORD:
            return t('member.label.memberInfoForm.password.updateSuccess');
          case TOAST_CLASS_TYPE.PHONE:
            return t('member.label.memberInfoForm.phoneNumber.updateSuccess');
          case TOAST_CLASS_TYPE.NOTEBOOK:
            return t('member.label.memberInfoForm.serialNo.updateSuccess');
          case TOAST_CLASS_TYPE.WORKPLACE:
            return t('member.label.memberInfoForm.mainWorkplace.updateSuccess');
          case TOAST_CLASS_TYPE.DELEGATE:
            return t('member.label.memberInfoForm.delegateMember.updateSuccess');
          default:
            return '';
        }
      case TOAST_ID_TYPE.FAIL:
        switch (classType) {
          case TOAST_CLASS_TYPE.PASSWORD:
            return t('member.label.memberInfoForm.password.updateFail');
          case TOAST_CLASS_TYPE.PHONE:
            return t('member.label.memberInfoForm.phoneNumber.updateFail');
          case TOAST_CLASS_TYPE.NOTEBOOK:
            return t('member.label.memberInfoForm.serialNo.updateFail');
          case TOAST_CLASS_TYPE.WORKPLACE:
            return t('member.label.memberInfoForm.mainWorkplace.updateFail');
          case TOAST_CLASS_TYPE.DELEGATE:
            return t('member.label.memberInfoForm.delegateMember.updateFail');
          default:
            return '';
        }
      default:
        return '';
    }
  };

  const setToast = (idType: TOAST_ID_TYPE, classType: TOAST_CLASS_TYPE) => {
    setShowToast(false);
    setToastId(idType);
    setToastClassName(classType);
    idType === TOAST_ID_TYPE.SUCCESS ? setToastIcon(CHECK_ICON) : setToastIcon(FAIL_ICON);
    setToastMessage(findToastMessage(idType, classType));
    setShowToast(true);
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.onHide}
        id="ModifyPasswordModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Container>
              <div className="header bold">{'비밀번호 변경'}</div>
              <br/>
              <div>마지막으로 비밀번호를 변경한 지 90일이 지났습니다.</div>
              <div>비밀번호를 변경해주세요.</div>
            </Container>
          </Modal.Title>
        </Modal.Header>
        <Formik
          validateOnMount
          initialValues={{
            password: '',
            newPassword: '',
            newPasswordConfirm: '',
          }}
          onSubmit={async (values, { setFieldError, resetForm}) => {
            const memberChangeInfo: MemberInfoChange = {
              memberChangeType: MemberChangeType.PASSWORD,
              currentValue: values.password,
              changeValue: values.newPassword,
            };
            setLoading(true);
            const submitStatus = await memberInfoService.putMemberInfo(memberChangeInfo);
            setLoading(false);
            if (submitStatus.successOrNot === 'Y') {
              resetForm();
              props.onHide();
            } else {
              if (submitStatus.statusCode === 'INVALID.CURRENT.PASSWORD') {
                setFieldError('password', t('member.message.frontEnd.password.currentPasswordNotMatch'));
              } else if (submitStatus.statusCode === 'DUPLICATION.PASSWORD') {
                setFieldError('newPassword', t('member.message.frontEnd.password.passwordDuplicated'));
              } else if (submitStatus.statusCode === 'INVALID.PASSWORD') {
                setFieldError('newPassword', t('member.message.frontEnd.password.containsEmailId'));
              } else {
                setToast(TOAST_ID_TYPE.FAIL, TOAST_CLASS_TYPE.PASSWORD);
              }
            }
          }}
          validationSchema={passwordSchema}
        >
          {({
            values,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldError,
            isValid,
            resetForm,
            initialStatus,
          }): any => (
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Control
                  type="password"
                  placeholder={t('member.label.memberInfoForm.password.placeHolder')}
                  id="password"
                  name="password"
                  data-testid="password"
                  value={values.password}
                  required
                  onChange={
                    passwordError === ''
                      ? handleChange
                      : () => {
                          clearPasswordError();
                        }
                  }
                  onBlur={handleBlur}
                  isInvalid={errors.password ? true : false}
                ></Form.Control>
                <Form.Control.Feedback id="passwordFeedback" data-testid="passwordFeedback" type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="password"
                  placeholder={t('member.label.memberInfoForm.password.placeHolder2')}
                  id="newPassword"
                  name="newPassword"
                  data-testid="newPassword"
                  value={values.newPassword}
                  required
                  onChange={
                    passwordError === ''
                      ? handleChange
                      : () => {
                          clearPasswordError();
                        }
                  }
                  onBlur={handleBlur}
                  isInvalid={!!errors.newPassword}
                />
                <Form.Control
                  type="password"
                  placeholder={t('member.label.memberInfoForm.password.placeHolder3')}
                  id="newPasswordConfirm"
                  name="newPasswordConfirm"
                  data-testid="newPasswordConfirm"
                  value={values.newPasswordConfirm}
                  required
                  onChange={
                    passwordError === ''
                      ? handleChange
                      : () => {
                          clearPasswordError();
                        }
                  }
                  onBlur={handleBlur}
                  isInvalid={!!errors.newPasswordConfirm}
                />
                <Form.Control.Feedback id="newPasswordFeedback" data-testid="newPasswordFeedback" type="invalid">
                  {passwordError || errors.newPassword || errors.newPasswordConfirm}
                </Form.Control.Feedback>
              </Form.Group>
              <Button
                id="passwordChangeButton"
                name="passwordChangeButton"
                data-testid="passwordChangeButton"
                type="submit"
                disabled={isSubmitting || !isValid}
                className="submit-button top-empty-space"
              >
                {t('member.label.memberInfoForm.password.buttonLabel')}
              </Button>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  )
}

export default ModifyPasswordModal;