export enum Service {
  MEMBER = 'member',
  VISIT = 'visit',
  WORKPLACE = 'workplace',
  INTERFACE = 'interface',
  NOTIFICATION = 'notification',
  SYSTEM_META = 'systemMeta',
  AUTHORITY = 'authority',
  API_KEY = 'apiKey',
}
export enum ServicePort {
  MEMBER = 3800,
  VISIT = 3100,
  WORKPLACE = 3200,
  INTERFACE = 3300,
  NOTIFICATION = 3400,
  SYSTEM_META = 3500,
  AUTHORITY = 3600,
  API_KEY = 3700,
}
