import React, { CSSProperties, ReactElement, useContext, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './LoginContainer.scss';
import { Container, Row } from 'react-bootstrap';
import LoginSection from './component/LoginSection';
import { AuthContext } from '../../../App';
import CommonLoadingOverlay from '../../../layouts/LoadingOverlay';
import cookie from 'react-cookies';
import { Session } from '../../../service/model/SessionInfo';
import LoginService from '../../member/services/LoginService';
import LoginFooter from './component/LoginFooter';
import queryString from 'query-string';
import SessionService from '../../../service/SessionService';
import { SessionInfo } from '../../../service/model/SessionInfo';
import moment from 'moment';

const LoginContainer: React.FC = (): ReactElement => {
  const { state } = useContext(AuthContext);
  const sessionService = new SessionService();
  const isLoadingReducer: boolean = state.loading && state.loading.isLoading ? true : false;
  const loginType = queryString.parse(window.location.search).loginType || null;
  const encryptedText = queryString.parse(window.location.search).encryptedText || null;
  const endDay = queryString.parse(window.location.search).endDay?.toString() || '';
  const requestLogout = () => {
    cookie.save(Session.SESSION_ID, '', { path: '/', maxAge: -1 });
    cookie.save('tenantId', '', { path: '/', maxAge: -1 });

    cookie.remove(Session.SESSION_ID, { path: '/' });
    cookie.remove('tenantId', { path: '/' });

    new LoginService().requestLogout().then(res => {
      sessionStorage.clear();
    });
  };
  const singlexbackground = window.location.hostname.includes('singlex') ? true : false;
  const singlexErrorPage = 'https://www.singlex.com/app/error/experienceLogin';

  useEffect(() => {
    if (encryptedText) {
      const loginRequest = {
        encryptedText: encryptedText.toString().replace(/ /g, '+'),
      };
      new LoginService().requestTrialLogin(loginRequest).then(res => {
        localStorage.removeItem('endDay');
        if (res.successOrNot == 'Y') {
          sessionService.deleteSessionInfo();
          sessionService.setSessionId(res.data.sessionId);
          sessionService.setSessionInfo(res.data.member as SessionInfo);
          sessionService.setSessionStorageValue('trialGuideOn', 'Y');

          const _endDay = moment(endDay, 'YYYY-MM-DD');
          const today = moment().format('YYYY-MM-DD');
          const dDay = _endDay.diff(today, 'days');

          if (dDay < 1) {
            window.location.href = singlexErrorPage;
          } else {
            localStorage.setItem('endDay', dDay.toString());
            window.location.assign('/reservation');
          }
        } else {
          window.location.href = singlexErrorPage;
        }
      });
    }
  }, []);

  useEffect(() => {
    if (loginType !== 'sso' && sessionStorage.length > 0) {
      requestLogout();
    }
  }, []);

  const getLoginContainerBody = () => {
    return (
      <div id="LoginContainer" style={{ background: singlexbackground ? 'url(/visitTrial-background.png)' : '' }}>
        <Container fluid={true}>
          <Row>
            <LoginSection />
          </Row>
          <Row className="row-footer loginContainer-bottom-row">
            <LoginFooter />
          </Row>
        </Container>
      </div>
    );
  };
  return <CommonLoadingOverlay active={isLoadingReducer}>{getLoginContainerBody}</CommonLoadingOverlay>;
};

export default LoginContainer;
