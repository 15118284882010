import React, { ReactElement, useState } from 'react';
import { Form, Card, Table } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { Visitor, ImportedGoods } from '../model/ReservationTypes';
import { User } from '../model/User';
import { reservationDetailUtil } from '../utils/ReservationDetailUtil';
import moment from 'moment';
import { ImportedGoodsCodeEnum, PortSealExceptCodeEnum } from '../model/ReservationConstants';
import './VisitorGoodsDetail.scss';
import { CommonCode } from '../model/Code';
import CommonCodeService from '../services/CommonCodeService';

export interface GoodInterface {
  product: ImportedGoods;
  carNumber: string;
  index: string;
  getCommonCode?: (groupCodeId: string) => CommonCode[];
}

export const VisitorGoodsDetail: React.FC<any> = (props: GoodInterface): ReactElement => {
  const { t } = useTranslation();

  const importedGoodsPurposeCodeList =
    props.getCommonCode && props.getCommonCode(CommonCodeService.IMPORTED_GOODS_PURPOSE);
  const portSealExceptCodeList =
    props.getCommonCode && props.getCommonCode(CommonCodeService.SEAL_EXCEPTION_IMPORTED_GOODS);

  const importedGoodsCodeList = props.getCommonCode && props.getCommonCode(CommonCodeService.IMPORTED_GOODS_CODE);

  const getCheckedCodeList = (): CommonCode[] => {
    const checkedPortSealExceptCodeList: CommonCode[] = [];
    portSealExceptCodeList &&
      portSealExceptCodeList.forEach(commonCode => {
        switch (commonCode.codeId) {
          case PortSealExceptCodeEnum.USB:
            props.product.importedGoodsPortSealExceptUsb === 'Y' &&
              checkedPortSealExceptCodeList.push({ codeId: commonCode.codeId, codeName: commonCode.codeName });
            break;
          case PortSealExceptCodeEnum.LAN:
            props.product.importedGoodsPortSealExceptLan === 'Y' &&
              checkedPortSealExceptCodeList.push({ codeId: commonCode.codeId, codeName: commonCode.codeName });
            break;
          case PortSealExceptCodeEnum.SD_CARD:
            props.product.importedGoodsPortSealExceptSdCard === 'Y' &&
              checkedPortSealExceptCodeList.push({ codeId: commonCode.codeId, codeName: commonCode.codeName });

            break;
          case PortSealExceptCodeEnum.CAMERA:
            props.product.importedGoodsPortSealExceptCamera === 'Y' &&
              checkedPortSealExceptCodeList.push({ codeId: commonCode.codeId, codeName: commonCode.codeName });
            break;
          case PortSealExceptCodeEnum.ETC:
            props.product.importedGoodsPortSealExceptEtc === 'Y' &&
              checkedPortSealExceptCodeList.push({ codeId: commonCode.codeId, codeName: commonCode.codeName });
            break;
          default:
            break;
        }
      });
    return checkedPortSealExceptCodeList;
  };

  const checkedPortSealExceptCodeList = getCheckedCodeList();

  const importedGoodsPurpose = props.product.importedGoodsPurpose;

  const getPortSealExceptTitle = (product: ImportedGoods) => {
    let portSealExceptString = '';
    checkedPortSealExceptCodeList.forEach((commonCode, index) => {
      if (index === 0) {
        portSealExceptString += commonCode.codeName;
      } else {
        portSealExceptString += ', ' + commonCode.codeName;
      }
    });
    return portSealExceptString;
  };

  const getImportedGoodsPurposeName = (): string => {
    let goodsPurposeCode;
    if (importedGoodsPurposeCodeList) {
      goodsPurposeCode = importedGoodsPurposeCodeList.find(commonCode => commonCode.codeId === importedGoodsPurpose);
    }
    return goodsPurposeCode ? goodsPurposeCode.codeName : '';
  };

  const getProducInfo = (product: ImportedGoods) => {
    if (product.importedGoodsCode == ImportedGoodsCodeEnum.NOTEBOOK) {
      return getNotebookInfo(product);
    } else if (
      product.importedGoodsCode == ImportedGoodsCodeEnum.SERVER ||
      product.importedGoodsCode == ImportedGoodsCodeEnum.STORAGE ||
      product.importedGoodsCode == ImportedGoodsCodeEnum.NETWORK ||
      product.importedGoodsCode == ImportedGoodsCodeEnum.PART
    ) {
      return getServerInfo(product);
    } else {
      return getOtherGoodsInfo(product);
    }
  };

  const getProductKindName = (productCode: string) => {
    let codeName;
    if (importedGoodsCodeList && importedGoodsCodeList.length > 0) {
      codeName = importedGoodsCodeList?.find(code => code.codeId === productCode)?.codeName;
    }

    return codeName;
  };

  const getWifiInfo = (product: ImportedGoods) => {
    return (
      <td>
        <span>{t('reservation.label.VisitorGoodsDetail.body.importedGoods.usage')}</span>
        <span>{product.importedGoodsWifiMacAddress}</span>
      </td>
    );
  };

  const getNotebookInfo = (product: ImportedGoods) => {
    return (
      <Table>
        <colgroup>
          <col width="30%" />
          <col width="70%" />
        </colgroup>
        <thead>
          <tr>
            <th colSpan={2}>{getProductKindName(product.importedGoodsCode)}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="product1stRow subtitle">{t('reservation.label.ImportedNotebookForm.serialNumber.name')}</td>
            <td className="product1stRow">{product.importedGoodsSerialNumber}</td>
          </tr>
          <tr>
            <td className="subtitle">{t('reservation.label.ImportedNotebookForm.purpose.name')}</td>
            <td>{getImportedGoodsPurposeName()}</td>
          </tr>
          <tr>
            <td className="subtitle">{t('reservation.label.ImportedNotebookForm.description.name')}</td>
            <td>{product.importedGoodsDescription}</td>
          </tr>
          <tr>
            <td className="subtitle">{t('reservation.label.ImportedNotebookForm.wifi.name')}</td>
            {product.importedGoodsWifiYesOrNo && product.importedGoodsWifiYesOrNo == 'Y' ? (
              getWifiInfo(product)
            ) : (
              <td>
                <span>{t('reservation.label.VisitorGoodsDetail.body.importedGoods.NotUsing')}</span>
              </td>
            )}
          </tr>
          <tr>
            <td className="subtitle">{t('reservation.label.ImportedNotebookForm.portSealExcept.name')}</td>
            <td>
              <span>
                {product.importedGoodsPortSealExceptYesOrNo && product.importedGoodsPortSealExceptYesOrNo == 'Y'
                  ? t('reservation.label.VisitorGoodsDetail.body.importedGoods.exception') +
                    getPortSealExceptTitle(product)
                  : t('reservation.label.VisitorGoodsDetail.body.importedGoods.NotUsing')}
              </span>
            </td>
          </tr>
          {getPortSealExceptDescription(product)}
        </tbody>
      </Table>
    );
  };

  const getServerInfo = (product: ImportedGoods) => {
    return (
      <Table>
        <colgroup>
          <col width="30%" />
          <col width="70%" />
        </colgroup>
        <thead>
          <tr>
            <th colSpan={2}>{getProductKindName(product.importedGoodsCode)}</th>
          </tr>
        </thead>
        <tbody>
          {product.importedGoodsCode != ImportedGoodsCodeEnum.PART && (
            <tr>
              <td className="product1stRow subtitle">
                {t('reservation.label.ImportedNotebookForm.serialNumber.name')}
              </td>
              <td className="product1stRow">{product.importedGoodsSerialNumber}</td>
            </tr>
          )}
          <tr>
            <td className="product1stRow subtitle">
              {t('reservation.label.ImportedCNSGoodsForm.goodsModelName.name')}
            </td>
            <td className="product1stRow">{product.importedGoodsModelName}</td>
          </tr>
          <tr>
            <td className="subtitle">{t('reservation.label.ImportedNotebookForm.purpose.name')}</td>
            <td>{getImportedGoodsPurposeName()}</td>
          </tr>
          <tr>
            <td className="subtitle">{t('reservation.label.ImportedNotebookForm.description.name')}</td>
            <td>{product.importedGoodsDescription}</td>
          </tr>
          <tr>
            <td className="subtitle">{t('reservation.label.ImportedCNSGoodsForm.clientCompanyName.name')}</td>
            <td>{product.importedGoodsClientCompanyName}</td>
          </tr>
          <tr>
            <td className="subtitle">{t('reservation.label.ImportedCNSGoodsForm.goodsFloor.name')}</td>
            <td>{product.importedGoodsFloor}</td>
          </tr>
          {product.importedGoodsCode != ImportedGoodsCodeEnum.PART && (
            <tr>
              <td
                className="subtitle"
                dangerouslySetInnerHTML={{
                  __html: t('reservation.label.ImportedCNSGoodsForm.techPreview.name', {
                    interpolation: { escapeValue: false },
                  }),
                }}
              ></td>
              <td>
                <span>
                  {product.importedGoodsTechPreviewYesOrNo && product.importedGoodsTechPreviewYesOrNo == 'Y'
                    ? t('reservation.label.VisitorGoodsDetail.body.importedGoods.necessity') +
                      getPortSealExceptTitle(product)
                    : t('reservation.label.VisitorGoodsDetail.body.importedGoods.unnecessity')}
                </span>
              </td>
            </tr>
          )}
          <tr>
            <td
              className="subtitle"
              dangerouslySetInnerHTML={{
                __html: t('reservation.label.ImportedCNSGoodsForm.managerSupport.name', {
                  interpolation: { escapeValue: false },
                }),
              }}
            ></td>
            <td>
              <span>
                {product.importedGoodsManagerSupportYesOrNo && product.importedGoodsManagerSupportYesOrNo == 'Y'
                  ? t('reservation.label.VisitorGoodsDetail.body.importedGoods.necessity') +
                    getPortSealExceptTitle(product)
                  : t('reservation.label.VisitorGoodsDetail.body.importedGoods.unnecessity')}
              </span>
            </td>
          </tr>
          {/* {getPortSealExceptDescription(product)} */}
        </tbody>
      </Table>
    );
  };

  const getPortSealExceptDescription = (product: ImportedGoods) => {
    if (product.importedGoodsPortSealExceptDescription && product.importedGoodsPortSealExceptDescription != '') {
      return (
        <tr>
          <td />
          <td>
            <span>{product.importedGoodsPortSealExceptDescription}</span>
          </td>
        </tr>
      );
    }
  };
  const getOtherGoodsInfo = (product: ImportedGoods) => {
    return (
      <Table>
        <colgroup>
          <col width="30%" />
          <col width="70%" />
        </colgroup>
        <thead>
          <tr>
            <th colSpan={2}>{getProductKindName(product.importedGoodsCode)}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="product1stRow subtitle">{t('reservation.label.ImportedGoodsForm.count.name')}</td>
            <td className="product1stRow">{product.importedGoodsCounts}</td>
          </tr>
          <tr>
            <td className="subtitle">{t('reservation.label.ImportedGoodsForm.purpose.name')}</td>
            <td>{getImportedGoodsPurposeName()}</td>
          </tr>
          <tr>
            <td className="subtitle">{t('reservation.label.ImportedNotebookForm.description.name')}</td>
            <td>{product.importedGoodsDescription}</td>
          </tr>
        </tbody>
      </Table>
    );
  };

  return (
    <Card>
      <Card.Body>
        <Form>
          <Form.Group>{getProducInfo(props.product)}</Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default VisitorGoodsDetail;
