// This must be the first line in src/index.js
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'core-js/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';

import './i18n';

ReactDOM.render(<App />, document.getElementById('root'));
