import React, { ReactElement, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import './ReservationBottomRight.scss';
import ReservationEduAndAgreementModal from '../components/ReservationEduAndAgreementModal';
import { PolicyContentsModel, RequestGetNoticeList, ResponseGetNoticeList, NoticeList } from '../model/Workplace';
import WorkplaceService from '../services/WorkplaceService';
import { WorkplaceVisitInfo } from '../model/ReservationTypes';
import { useHistory } from 'react-router-dom';

/* eslint-disable */
export const ReservationBottomRight: React.FC<any> = (props: any): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();
  const handleShowWorkplaceNoticeModals = (): void => {
    props.setShowWorkplaceModal(true);
    history.push(history.location.hash + '#workplaceNoticeModal');
  };
  const handleShowSystemNoticeModals = (): void => {
    props.setShowSystemNoticeModal(true);
    history.push(history.location.hash + '#systemNoticeModal');
  };
  const workplaceService = new WorkplaceService();
  const [systemNotice, setSystemNotice] = useState<NoticeList>();

  useEffect(() => {
    const request: RequestGetNoticeList = {
      languageCode: localStorage.getItem('lang') ? (localStorage.getItem('lang') as string) : 'ko',
      startIndex: 0,
      pageSize: 1,
    };

    workplaceService.getNoticeList(request).then(response => {
      if (response !== null) {
        setSystemNotice(response.noticeList[0]);
      }
    });
  }, []);
  /* eslint-enable */
  const showWorkplaceNotice = (): ReactElement => {
    if (props.workplaceVisitInfo.workplaceNotice.length > 260) {
      const workplaceShortNotice = props.workplaceVisitInfo.workplaceNotice.substring(0, 259);
      return (
        <React.Fragment key="workplaceNotice">
          <p className="workplaceNotice" id="workplaceVisitInfo" data-testid="workplaceVisitInfo">
            {workplaceShortNotice + '... '}
          </p>
          <a
            id="showMoreWorkplaceNoticeButton"
            data-testid="showMoreWorkplaceNoticeButton"
            href="#"
            className="fontGrren underline"
            onClick={(e: any): void => {
              e.preventDefault();
              handleShowWorkplaceNoticeModals();
            }}
          >
            {t('reservation.label.ReservationBottomRight.serviceInfo.link')}
          </a>
        </React.Fragment>
      );
    } else {
      return (
        <p className="workplaceNotice" id="workplaceVisitInfo" data-testid="workplaceVisitInfo">
          {props.workplaceVisitInfo.workplaceNotice}
        </p>
      );
    }
  };

  const showSystemNotice = (): string => {
    let noticeContent = '';
    if (systemNotice) {
      noticeContent =
        systemNotice.noticeContent.length > 100
          ? systemNotice.noticeContent.substring(0, 100) + '... '
          : systemNotice.noticeContent;
    }
    return noticeContent;
  };

  return (
    <div className="ReservationBottomRight" id="ReservationBottomRight" data-testid="ReservationBottomRight">
      {systemNotice && (
        <div className="notice">
          <p className="title fontGreen bold">{t('reservation.label.ReservationBottomRight.title')}</p>
          <p>
            <strong>{systemNotice?.noticeTitle}</strong>
          </p>
          <p>{showSystemNotice()}</p>
          <a
            id="showMoreSystemNoticeButton"
            data-testid="showMoreSystemNoticeButton"
            href="#"
            className="fontGrren underline"
            onClick={(e: any): void => {
              e.preventDefault();
              handleShowSystemNoticeModals();
            }}
          >
            {t('reservation.label.ReservationBottomRight.notice.link')}
          </a>
        </div>
      )}
      {props.workplaceVisitInfo && props.workplaceVisitInfo.workplaceNotice && (
        <div className="info">
          <p className="title fontGreen bold">
            {t('reservation.label.ReservationBottomRight.serviceInfo.subTitle')}
            <a
              id="showMoreWorkplaceNoticeMobileButton"
              data-testid="showMoreWorkplaceNoticeMobileButton"
              href="#"
              className="underline showMoreWorkplaceNoticeMobileButton"
              onClick={(e: any): void => {
                e.preventDefault();
                handleShowWorkplaceNoticeModals();
              }}
            >
              {t('reservation.label.ReservationBottomRight.serviceInfo.detail')}
            </a>
          </p>
          {showWorkplaceNotice()}{' '}
        </div>
      )}
      <Button
        name="next"
        id="next-button"
        className=""
        disabled={
          !props.values.workplace ||
          !props.values.visitTo ||
          !props.values.fromVisitPeriod ||
          !props.values.toVisitPeriod
        }
        onClick={(e: any) => {
          e.stopPropagation();
          e.preventDefault();
          props.setShowBottomForms(true);
          history.push(history.location.hash + '#visitorInfo');
        }}
      >
        <span>
          {t('reservation.label.ReservationBottomRight.button.next')}(<em>1/2</em>)
        </span>
        <i className="material-icons pointer">chevron_right</i>
      </Button>
    </div>
  );
};

export default ReservationBottomRight;
