import './MemberInfoPage.scss';
import { useState, useEffect } from 'react';
import React from 'react';
import { Footer } from '../../../layouts';
import { MemberInfoContainer } from './MemberInfoContainer';
import { useTranslation } from 'react-i18next';
import { reservationDetailUtil } from '../../reservation/utils/ReservationDetailUtil';

import { UserTypeEnum, MemberTypeCode } from '../../reservation/model/ReservationConstants';
import { MemberChange } from '../model/MemberChange';

import SessionService from '../../../service/SessionService';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { SinglexHeader } from '../../../layouts';

export const MemberInfoPage: React.FC = () => {
  const { t } = useTranslation();
  const [memberInfo, setMemberInfo] = useState<MemberChange | undefined>(undefined);
  const history = useHistory();
  const singlexHeaderUse = window.location.hostname.includes('singlex') ? true : false;
  /* eslint-disable */
  useEffect(() => {
    const sessionService = new SessionService();

    if (sessionService.getSessionId() === '') {
      history.push('/login');
    } else {
      reservationDetailUtil.getSessionUserInfo().then(response => {
        let res = JSON.parse(JSON.stringify(response));

        if (res.id !== null + '') {
          const fetchedMemberInfo: MemberChange = {
            memberId: res.id,
            memberTypeCode: res.type === UserTypeEnum.LGMEMBER ? MemberTypeCode.EMP : MemberTypeCode.MEM,
            memberName: res.name,
            emailAddress: res.email,
            phoneNumber: res.mobile,
            companyName: res.companyName || '',
            positionName: res.position || '',
            notebookSerialNo: res.notebookSerialNo || '',
            thirdTermsAgreeYn: res.termsAgreeYn || '',
            termsSaveDatetime: res.termsSaveDatetime || '',
            departmentName: res.dept || '',
            companyCode: res.companyCode || '',
            mainWorkplaceId: res.mainWorkplaceId || '',
            delegateMemberId: res.delegateMemberId || '',
            delegateMemberName: res.delegateMemberName || '',
            delegateMemberPositionName: res.delegateMemberPositionName || '',
          };
          setMemberInfo(fetchedMemberInfo);
        } else {
          alert(t('member.message.backEnd.UE'));
        }
      });
    }
  }, []);
  /* eslint-enable */
  return (
    <div id="MemberInfoModifyPage">
      {singlexHeaderUse && <SinglexHeader />}
      {memberInfo !== undefined && (
        <Row>
          <Col className="left-side">
            <Button
              variant="link"
              className="back-button"
              id="backButton"
              data-testid="backButton"
              onClick={() => {
                history.goBack();
              }}
            >
              <i className="material-icons">arrow_back</i>
            </Button>
          </Col>
          <Col>
            <MemberInfoContainer memberInfo={memberInfo} />
          </Col>
          <Col></Col>
        </Row>
      )}
      <Row>
        <Col>
          <Footer />
        </Col>
      </Row>
    </div>
  );
};
export default MemberInfoPage;
