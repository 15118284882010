export interface SessionInfo {
  memberId: number | null;
  memberTypeCode: string;
  memberName?: string;
  noMemPhoneNumber?: string;
  noMemCompanyName?: string;
  noMemPositionName?: string;
  modifyPasswordDatetime?: string;
}

export enum Session {
  SESSION_ID = 'sessionId',
  LOGIN_TYPE = 'loginType',
  TENANT_ID = 'tenantId',
}
