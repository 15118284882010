import React, { useState, useEffect, ReactElement, useContext } from 'react';
import './TermsAcception.scss';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Form } from 'react-bootstrap';
import StepInformation from '../../model/StepInformation';
import TermService from '../../services/TermService';
import TermsInfo from '../../model/TermsInfo';
import TermAgreeInfo from '../../model/TermAgreeInfo';
import TermCheckboxStatus from '../../model/TermCheckboxStatus';
import { UserTypeParamEnum, UserTypeParamEnumForTermViewAPICall } from '../../model/MemberRegistrationConstants';
import { Redirect } from 'react-router-dom';
import TermsAcceptionModal from './TermsAcceptionModal';
import MemberRegistrationService from '../../services/MemberRegistrationService';
import SessionService from '../../../../service/SessionService';
import { SessionInfo } from '../../../../service/model/SessionInfo';
import NonMember from '../../model/NonMember';
import NonMemberRegistrationSubmitStatus from '../../model/NonMemberRegistrationSubmitStatus';
import MemberRegistrationConstants from '../../model/MemberRegistrationConstants';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../../../App';
import { ActionType } from '../../../../reducer/actions';
import HistorySearchConditions from '../../../reservation/components/HistorySearchConditions';
export interface TermsAcceptionProps {
  setStepParams: (stepParams: StepInformation) => void;
  stepParams: StepInformation;
  termService: TermService;
  memberRegistrationService: MemberRegistrationService;
}
export const TermsAcception: React.FC<TermsAcceptionProps> = (props: TermsAcceptionProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { dispatch } = useContext(AuthContext);
  const sessionService: SessionService = new SessionService();

  const createTermAgreeInfo = (): TermAgreeInfo => {
    return {
      termsApplyGroupCode: userType,
      termsAgree: terms.map((term: TermCheckboxStatus) => {
        return {
          termsId: term.termsId,
          termsAgreeYn: term.checked ? 'Y' : 'N',
        };
      }),
    };
  };

  const setLoading = (_isLoading: boolean) => {
    dispatch({ type: ActionType.LOADING, loading: { isLoading: _isLoading } });
  };
  let stepParams: StepInformation;

  const userTypeMap = (inputVal: string | string[] | undefined | null): string => {
    let returnVal = '';

    if (inputVal === UserTypeParamEnum.SSO_EMPLOYEE || inputVal === UserTypeParamEnum.NON_SSO_EMPLOYEE) {
      returnVal = UserTypeParamEnumForTermViewAPICall.EMPLOYEE;
    } else if (inputVal === UserTypeParamEnum.NON_MEMBER) {
      returnVal = UserTypeParamEnumForTermViewAPICall.NON_MEMBER;
    } else if (inputVal === UserTypeParamEnum.OUTSIDER_MEMBER) {
      returnVal = UserTypeParamEnumForTermViewAPICall.OUTSIDER;
    } else {
      returnVal = UserTypeParamEnumForTermViewAPICall.WRONG_PARAM;
    }

    return returnVal;
  };

  const userType: string = userTypeMap(props.stepParams.memberType);

  const [terms, setTerms] = useState<TermCheckboxStatus[]>([]);
  const [APICalled, setAPICalled] = useState(false);

  const isChecked = (checkBox: TermCheckboxStatus) => checkBox.checked;
  const isRequiredAndChecked = (checkBox: TermCheckboxStatus) =>
    !checkBox.required || (checkBox.required && checkBox.checked);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedTerm, setSelectedTerm] = useState<TermCheckboxStatus>();

  /* eslint-disable */
  useEffect(() => {
    if (userType !== UserTypeParamEnumForTermViewAPICall.WRONG_PARAM) {
      const fetchTermData = async (memeberType: string) => {
        return await props.termService.requestTermInfo(memeberType);
      };
      setLoading(true);
      fetchTermData(userType)
        .then(res => {
          const response = JSON.parse(JSON.stringify(res));

          if (response.successOrNot === 'N') {
            alert(t('member.message.backEnd.UE'));
          } else {
            if (response.data !== undefined && response.data.length > 0) {
              const tempTerms = response.data.map((dat: TermsInfo) => {
                return {
                  checked: false,
                  termsTypeCode: dat.termsTypeCode,
                  required: dat.mandatoryTermsYn === 'Y',
                  termsTitle: dat.termsTitle,
                  termsContents: dat.termsContents,
                  termsDetailContents: dat.termsDetailContents,
                  termsId: dat.termsId,
                };
              });
              setAPICalled(true);
              setTerms(tempTerms);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);
  useEffect(() => {
    const location = history.location;
    if (!location.hash.includes('#termsAcceptionModal')) {
      setSelectedTerm(undefined);
      setShowModal(false);
    }
  }, [history.location]);
  /* eslint-disable */

  const [validated, setValidated] = useState(false);

  const hideModals = () => {
    history.goBack();
  };

  const showModals = (term: TermCheckboxStatus) => {
    setSelectedTerm(term);
    setShowModal(true);
  };

  return (
    <div id="TermsAcception">
      {props.stepParams.memberType === UserTypeParamEnum.NON_MEMBER && (
        <div id="noticeBox">
          <Row>
            <label className="notice-header-label">{t('member.label.NonMemberRegistrationForm.notice.header')}</label>
          </Row>
          <Row>
            <Col md="10" xs="10" sm="10">
              <label
                className="notice-content-label"
                dangerouslySetInnerHTML={{
                  __html: t('member.label.NonMemberRegistrationForm.notice.content', {
                    interpolation: { escapeValue: false },
                  }),
                }}
              />
            </Col>
            <Col md="2" xs="2" sm="2">
              <Button
                href={'/memberRegistration?memberType=' + UserTypeParamEnum.OUTSIDER_MEMBER}
                variant="outline-primary"
                className="join-redirect-button"
              >
                {t('member.label.NonMemberRegistrationForm.notice.button')}
              </Button>
            </Col>
          </Row>
        </div>
      )}
      {userType === UserTypeParamEnumForTermViewAPICall.WRONG_PARAM ? (
        <Redirect to="/login" />
      ) : (
        <Form
          noValidate
          validated={validated}
          onSubmit={async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            setValidated(true);

            if (props.stepParams.memberType !== UserTypeParamEnum.NON_MEMBER) {
              stepParams = {
                currentStep: 2,
                termAgreeInfo: createTermAgreeInfo(),
                memberType: props.stepParams.memberType,
              };
              history.push(history.location.pathname + history.location.search + '#step2');
              props.setStepParams(stepParams);
            } else {
              const nonMember: NonMember = {
                memberTypeCode: UserTypeParamEnumForTermViewAPICall.NON_MEMBER,
                termsApplyGroupCode: userType,
                termsAgree: terms.map((term: TermCheckboxStatus) => {
                  return {
                    termsId: term.termsId,
                    termsAgreeYn: term.checked ? 'Y' : 'N',
                  };
                }),
              };

              setLoading(true);
              const submitStatus: NonMemberRegistrationSubmitStatus = await props.memberRegistrationService.registNonMember(
                nonMember
              );
              setLoading(false);

              if (submitStatus.successOrNot === 'Y') {
                const sessionInfo: SessionInfo = {
                  memberId: +(submitStatus.data?.member?.memberId + ''),
                  memberTypeCode: submitStatus.data?.member?.memberTypeCode + '',
                };
                sessionService.deleteSessionInfo();
                sessionService.setSessionId(submitStatus.data?.sessionId + '');
                sessionService.setSessionInfo(sessionInfo);
                history.push('/reservation');
              } else if (
                submitStatus.successOrNot === 'N' &&
                submitStatus.statusCode === MemberRegistrationConstants.UNKNOWN_SERVER_ERROR_CODE
              ) {
                alert(t('member.message.backEnd.UE'));
              } else {
                alert(t('member.message.backEnd.' + submitStatus.statusCode));
              }
            }
          }}
        >
          {terms.map((term: TermCheckboxStatus, i) => {
            return (
              <div key={i}>
                <Row className="top-empty-space">
                  <Col sm={11}>
                    <Form.Check
                      required={term.required}
                      custom
                      id={term.termsTypeCode}
                      type="checkbox"
                      name={term.termsTypeCode}
                      data-testid={term.termsTypeCode}
                      label={
                        term.required
                          ? t('member.label.termsAcception.Label.required') + term.termsTitle
                          : t('member.label.termsAcception.Label.notRequired') + term.termsTitle
                      }
                      checked={term.checked}
                      onChange={() => {
                        const modifiedCheckBoxStatus: TermCheckboxStatus[] = terms.map(eachTerm => {
                          return eachTerm.termsTypeCode === term.termsTypeCode
                            ? {
                                ...eachTerm,
                                ...{ checked: !eachTerm.checked },
                              }
                            : eachTerm;
                        });
                        setTerms(modifiedCheckBoxStatus);
                      }}
                    />
                  </Col>
                  {term.termsDetailContents !== null && (
                    <Col sm={1}>
                      <i
                        id="firstRightArrow"
                        data-testid={term.termsTypeCode + '-firstRightArrow'}
                        className="material-icons term-detail-icon"
                        onClick={() => {
                          history.push(history.location.hash + '#termsAcceptionModal');

                          showModals(term);
                        }}
                      >
                        keyboard_arrow_right
                      </i>
                    </Col>
                  )}
                </Row>
                {term.termsContents !== null && (
                  <Row className="left-empty-space">
                    <Col sm={11}>
                      <span
                        id={term.termsTypeCode + 'Desc'}
                        data-testid={term.termsTypeCode + 'Desc'}
                        className="term-desc"
                      >
                        {term.termsContents}
                      </span>
                    </Col>
                    <Col sm={1}></Col>
                  </Row>
                )}
              </div>
            );
          })}
          {terms.length > 1 && (
            <div>
              <Row className="seperation-line"></Row>
              <Row className="top-empty-space">
                <Col sm={11}>
                  <Form.Check
                    custom
                    id="termAllAgree"
                    type="checkbox"
                    name="termAllAgree"
                    data-testid="termAllAgree"
                    label={t('member.label.termsAcception.termLabel.termAllAgree')}
                    className="bold"
                    disabled={!APICalled}
                    checked={APICalled && terms.every(isChecked)}
                    onChange={() => {
                      const asIs: boolean = terms.every(isChecked);
                      const modifiedCheckBoxStatus: TermCheckboxStatus[] = terms.map(termDetail => ({
                        ...termDetail,
                        ...{ checked: !asIs },
                      }));
                      setTerms(modifiedCheckBoxStatus);
                    }}
                  />
                </Col>
                <Col sm={1} />
              </Row>
              <Row className="top-empty-space bottom-empty-space left-empty-space">
                <Col sm={11}>
                  <span id="termAllAgreeDesc" data-testid="termAllAgreeDesc" className="term-desc">
                    {t('member.label.termsAcception.termDesc.termAllAgree')}
                  </span>
                </Col>
                <Col sm={1}></Col>
              </Row>
            </div>
          )}
          <Row
            className={
              props.stepParams.memberType === UserTypeParamEnum.NON_SSO_EMPLOYEE ? 'nse-agree-btn-row' : 'agree-btn-row'
            }
          >
            <Button
              id="agreeButton"
              name="agreeButton"
              data-testid="agreeButton"
              className="agree-button"
              type="submit"
              disabled={!APICalled || !terms.every(isRequiredAndChecked)}
            >
              {t('member.label.termsAcception.Label.agree')}
            </Button>
          </Row>
          {showModal && selectedTerm && <TermsAcceptionModal term={selectedTerm} onHide={hideModals} />}
        </Form>
      )}
    </div>
  );
};
