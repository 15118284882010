import BaseService from '../../../service/BaseService';
import { Service } from '../../../service/model/Service';
import SubmitStatus from '../../member/model/SubmitStatus';
import TenantInfo from '../../member/model/TenantInfo';
import { MemberInfoChange, CompanyInfoChange, AgreeInfoChange } from '../model/MemberChange';

export default class MemberInfoService {
  public baseService: BaseService = new BaseService();

  async putMemberInfo(memberInfoChange: MemberInfoChange | CompanyInfoChange): Promise<SubmitStatus> {
    let submitStatus: SubmitStatus = {
      successOrNot: 'N',
      statusCode: '',
    };

    const method = 'PUT';
    const url = '/v1/account';
    const serviceName = Service.MEMBER;

    const body: MemberInfoChange | CompanyInfoChange = memberInfoChange;

    try {
      submitStatus = await this.baseService.fnRest(method, url, body, serviceName);
    } catch (error) {
      submitStatus = error;
    }

    return submitStatus;
  }

  async patchAccountAgreeInfo(agreeInfoChange: AgreeInfoChange): Promise<SubmitStatus> {
    let submitStatus: SubmitStatus = {
      successOrNot: 'N',
      statusCode: '',
    };

    const method = 'PATCH';
    const url = '/v1/account/agree';
    const serviceName = Service.MEMBER;

    const body: AgreeInfoChange = agreeInfoChange;

    try {
      submitStatus = await this.baseService.fnRest(method, url, body, serviceName);
    } catch (error) {
      submitStatus = error;
    }

    return submitStatus;
  }

  async getWorkplaceListByGroupCompanyId(groupCompanyId: string): Promise<SubmitStatus> {
    let submitStatus: SubmitStatus = {
      successOrNot: 'N',
      statusCode: '',
    };

    const method = 'GET';
    const url = '/v1/workplaceList?groupCompanyId=' + groupCompanyId;
    const serviceName = Service.WORKPLACE;
    const body = null;

    try {
      submitStatus = await this.baseService.fnRest(method, url, body, serviceName);
    } catch (error) {
      submitStatus = error;
    }

    return submitStatus;
  }

  async getTenantInfo(memberId: string): Promise<TenantInfo> {
    const serviceName = Service.MEMBER;
    const method = 'POST';
    const url = '/v1/members/tenantId';
    const body = { memberId: memberId };
    let response;
    try {
      response = await this.baseService.fnRest(method, url, body, serviceName);
    } catch (error) {
      response = error;
    }

    return response && response.successOrNot === 'Y' ? response.data : null;
  }
}
