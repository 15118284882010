import { ReactElement, useEffect, useState } from 'react';
import React from 'react';
import ApprovalNotice from './component/ApprovalNotice';
import WorkplaceService from './services/WorkplaceService';
import { Container, Row, Col, Image } from 'react-bootstrap';
import queryString from 'query-string';
import logoImage from '../../icons/vportal_logo_color.png';
import './ApprovalInfoContainer.scss';
import QRCode from 'qrcode';

import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

export interface ApprovalInfoI {
  workplaceId: string;
}

export const ApprovalInfoContainer: React.FC<ApprovalInfoI> = (props: ApprovalInfoI): ReactElement => {
  const { t } = useTranslation();
  const workplaceService = new WorkplaceService();
  const [qrcode, setQrcode] = useState<string>('');

  // useEffect(() => {
  //   QRCode.toDataURL('https://visit.lg.com', (err, url) => {
  //     setQrcode(url);
  //   })
  // },[])

  return (
    <div className="approval-info-container">
      <Container id="ApprovalInfoContainer">
        <Row className="top-row">
          <Col className="top-col">
            <p className="top-title">
              <strong> {t('info.label.ApprovalInfoContainer.top-title.forward')}</strong>{' '}
              {t('info.label.ApprovalInfoContainer.top-title.back')}
            </p>
          </Col>
        </Row>
        <Row className="middle-row">
          <Col className="middle-col">
            <ApprovalNotice workplaceService={workplaceService} workplaceId={props.workplaceId} />
            {/* <img src={qrcode} /> */}
          </Col>
        </Row>
        <Row className="bottom-row">
          <Col className="bottom-col">
            <div className="bottom">
              <div>
                <strong>{t('info.label.ApprovalInfoContainer.bottom.contactPhone')}</strong>{' '}
                {t('info.label.ApprovalInfoContainer.bottom.contactPhoneNumber')}
                {'  |  '}
                <strong>{t('info.label.ApprovalInfoContainer.bottom.contactEmail')}</strong>{' '}
                {t('info.label.ApprovalInfoContainer.bottom.contactEmailAddress')}
              </div>
              <div>{t('info.label.ApprovalInfoContainer.bottom.copyRight')}</div>
              <strong className="name-strong">{t('info.label.ApprovalInfoContainer.bottom.LGCNS')}</strong>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ApprovalInfoContainer;
