import './MemberInfoContainer.scss';
import React, { useContext } from 'react';
import { MemberTypeCode } from '../../reservation/model/ReservationConstants';
import { ExternalMemberInfoForm } from './component/ExternalMemberInfoForm';
import { useTranslation } from 'react-i18next';
import { MemberChange } from '../model/MemberChange';
import EmployeeMemberInfoForm from './component/EmployeeMemberInfoForm';
import CommonLoadingOverlay from '../../../layouts/LoadingOverlay';
import { AuthContext } from '../../../App';

export interface MemberInfoContainerProps {
  memberInfo: MemberChange;
}

export const MemberInfoContainer: React.FC<MemberInfoContainerProps> = (props: MemberInfoContainerProps) => {
  const { t } = useTranslation();
  const { state } = useContext(AuthContext);
  const _memberInfo: MemberChange | undefined = props.memberInfo ? props.memberInfo : undefined;

  const isLoadingReducer: boolean = state.loading && state.loading.isLoading ? true : false;

  const getMemberInfoContainerBody = () => {
    return (
      <div id="MemberInfoContainer">
        <h1 className="member-info-title">{t('member.label.memberInfoForm.common.title')}</h1>
        {_memberInfo && _memberInfo.memberTypeCode === MemberTypeCode.MEM && (
          <ExternalMemberInfoForm memberInfo={_memberInfo} />
        )}
        {_memberInfo && _memberInfo.memberTypeCode === MemberTypeCode.EMP && (
          <EmployeeMemberInfoForm memberInfo={_memberInfo} />
        )}
      </div>
    );
  };

  return <CommonLoadingOverlay active={isLoadingReducer}>{getMemberInfoContainerBody}</CommonLoadingOverlay>;
};
export default MemberInfoContainer;
