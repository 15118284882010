import React, { ReactElement, useState, useEffect } from 'react';
import './LoginFooter.scss';
import { Row, Col, Image, DropdownButton, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import lgLogo from '../../../../icons/lg.svg';
import PrivacyPolicyModal from '../../memberRegistration/component/PrivacyPolicyModal';
import { TermsTypeCodeEnum } from '../../../reservation/model/ReservationConstants';
import TermsAcceptionModal from '../../memberRegistration/component/TermsAcceptionModal';
import MemberRegistrationConstants from '../../model/MemberRegistrationConstants';
import TermService from '../../services/TermService';
import { useHistory } from 'react-router-dom';
import { Language } from '../../../../model/Language';

const LoginFooter: React.FC = (): ReactElement => {
  const { t, i18n } = useTranslation();
  const [showTermsAcceptionModal, setShowTermsAcceptionModal] = useState<boolean>(false);
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState<boolean>(false);
  const memberTypeCode = sessionStorage.getItem('memberTypeCode') || MemberRegistrationConstants.NON_MEMBER_TYPE_CODE;
  const barSplitter = ' | ';
  const history = useHistory();

  const hidePrivacyPolicyModals = () => {
    history.goBack();
  };
  const showPrivacyPolicyModals = () => {
    history.push(history.location.hash + '#privacyPolicyModal');
    setShowPrivacyPolicyModal(true);
  };
  const hideTermsAcceptionModals = () => {
    history.goBack();
  };
  const showTermsAcceptionModals = () => {
    history.push(history.location.hash + '#termsAcceptionModal');
    setShowTermsAcceptionModal(true);
  };

  /* eslint-disable */
  useEffect(() => {
    const location = history.location;

    if (!location.hash.includes('#privacyPolicyModal')) {
      setShowPrivacyPolicyModal(false);
    }
    if (!location.hash.includes('#termsAcceptionModal')) {
      setShowTermsAcceptionModal(false);
    }
  }, [history.location]);

  const onSelectLanguage = (eventKey: any, event: Record<string, any>): void => {
    i18n.changeLanguage(eventKey, () => {
      localStorage.setItem('lang', eventKey);
    });
  };
  return (
    <div id="LoginFooter">
      <footer>
        <Row>
          <Col className="languageCol">
            <DropdownButton
              id="select-language"
              className="select-language"
              title={Language[localStorage.getItem('lang')?.toString() || 'ko']}
            >
              {Object.keys(Language).map((key, index) => {
                return (
                  <Dropdown.Item
                    id={'select-language-item'}
                    onSelect={onSelectLanguage}
                    className="select-language-item"
                    key={index}
                    eventKey={key}
                    data-testid="select-language-item"
                  >
                    {Language[key]}
                  </Dropdown.Item>
                );
              })}
            </DropdownButton>
          </Col>
        </Row>
        <Row className={'footer-links'}>
          <Col sm={5}>
            <a id="showTermsAcceptionLink" onClick={() => showTermsAcceptionModals()}>
              {t('member.label.LoginFooter.serviceTerm')}
            </a>
          </Col>
          <Col sm={1} className={'splitter'}>
            <a> • </a>
          </Col>
          <Col sm={6}>
            <a id="showPrivacyPolicyLink" onClick={() => showPrivacyPolicyModals()}>
              {t('member.label.LoginFooter.personalInfoTerm')}
            </a>
          </Col>
        </Row>
        <Row className={'contact-info'}>
          <b>
            {t('member.label.LoginFooter.helpDesk')} {t('member.label.LoginFooter.contactPhoneNumber')}
            {barSplitter}
            {t('member.label.LoginFooter.contactEmailAddress')}
          </b>
        </Row>
        <Row>
          <Image src={lgLogo} className="lgLogo" />
        </Row>
      </footer>
      {showTermsAcceptionModal && (
        <TermsAcceptionModal
          term={undefined}
          termService={new TermService()}
          termsTypeCode={TermsTypeCodeEnum.SERVICE}
          memberTypeCode={memberTypeCode}
          onHide={hideTermsAcceptionModals}
        />
      )}
      {showPrivacyPolicyModal && <PrivacyPolicyModal onHide={hidePrivacyPolicyModals} />}
    </div>
  );
};

export default LoginFooter;
