import React, { ReactElement } from 'react';
import { Row, Col } from 'react-bootstrap';
import { ReservationBottomForms } from './ReservationBottomForms';
import { ReservationBottomRight } from './ReservationBottomRight';

import './ReservationBottomContainer.scss';
export const ReservationBottomContainer: React.FC<any> = (formProps: any): ReactElement => {
  return (
    <div
      className="ReservationBottomContainer"
      id="ReservationBottomContainer"
      data-testid="ReservationBottomContainer"
    >
      <Row>
        <Col md={8} className={formProps.showBottomForms ? 'covisitor on' : 'covisitor'}>
          <ReservationBottomForms {...formProps} />
        </Col>
        <Col>
          <ReservationBottomRight {...formProps} />
        </Col>
      </Row>
    </div>
  );
};

export default ReservationBottomContainer;
