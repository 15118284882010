import React, { ReactElement, useState } from 'react';
import { Modal, Button, Accordion, Card } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import './AlertModal.scss';

export interface AlertModalProps {
  message: string;
  show: boolean;
  onClose: Function;
  useDetailView?: boolean;
  detailMessage?: string;
}

export const AlertModal: React.FC<AlertModalProps> = (props: AlertModalProps): ReactElement => {
  const { t } = useTranslation();
  const [showDetail, setShowDetail] = useState(false);

  const onClose = () => {
    props.onClose();
  };
  return (
    <Modal
      id="alertModal"
      data-testid="alertModal"
      show={props.show}
      onHide={onClose}
      className="AlertModal"
      enforceFocus={false}
      centered
    >
      <Modal.Body>
        <p id="alertMessage" data-testid="alertMessage">
          {props.message}
        </p>
        {props.useDetailView && (
          <Accordion defaultActiveKey="-1" activeKey={showDetail ? '0' : '-1'}>
            <Card>
              <Accordion.Collapse eventKey="0">
                <Card.Body>{props.detailMessage || ''}</Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        )}
      </Modal.Body>
      <Modal.Footer>
        {props.useDetailView && (
          <Button
            id="detailButton"
            data-testid="detailButton"
            variant="primary"
            onClick={() => {
              setShowDetail(!showDetail);
            }}
          >
            {showDetail ? t('common.label.hide') : t('common.label.showDetail')}
          </Button>
        )}
        <Button id="closeButton" data-testid="closeButton" variant="primary" onClick={onClose}>
          {t('reservation.label.AlertModal.button.confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AlertModal;
