export default class MemberRegistrationConstants {
  public static readonly REGEXP_EMAIL: RegExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
  public static readonly REGEXP_MOBILE: RegExp = /^(010-?)([0-9]{4}-?)([0-9]{4})$|^(01[16789]{1}-?)([0-9]{3}-?)([0-9]{4})$/;
  public static readonly REGEXP_PASSWORD: RegExp = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!"#$%&'()*+,./:;<=>?@\^_`{|}~-])[^\s]{8,}$/;

  public static readonly COMPONENT_DISPLAY_NONE_CLASS_NAME: string = 'component-display-none';
  public static readonly COMPONENT_DISPLAY_CLASS_NAME: string = 'component-display';
  public static readonly DEFAULT_MEMBER_STATE_CODE: string = 'NORMAL';
  public static readonly DEFAULT_MEMBER_TYPE_CODE: string = 'MEM';
  public static readonly EMPLOYEE_MEMBER_TYPE_CODE: string = 'EMP';
  public static readonly NON_MEMBER_TYPE_CODE: string = 'NO_MEM';
  public static readonly ADMIN_MEMBER_TYPE_CODE: string = 'ADMIN';

  public static readonly UNKNOWN_SERVER_ERROR_CODE: string = 'UNKNOWN.SERVER';
  public static readonly NOT_ALLOW_EMP_EMAIL: string = 'NOT.ALLOW.EMP_EMAIL';

  public static readonly NAME_MAX_LENGTH: number = 100;
}

export enum UserTypeParamEnum {
  SSO_EMPLOYEE = 'SE',
  NON_SSO_EMPLOYEE = 'NSE',
  OUTSIDER_MEMBER = 'OM',
  NON_MEMBER = 'NM',
}

export enum UserTypeParamEnumForTermViewAPICall {
  EMPLOYEE = 'EMP',
  OUTSIDER = 'MEM',
  NON_MEMBER = 'NO_MEM',
  WRONG_PARAM = 'WRONG_PARAM',
}

export enum CertificationTypeCode {
  SMS = 'SMS',
  KAKAO = 'KAKAO',
  EMAIL = 'EMAIL',
}
