import ReservationConstants from '../model/ReservationConstants';
import { isValidPhoneNumber, parsePhoneNumber, formatPhoneNumber } from 'react-phone-number-input';

export const getNullToEmpty = (str: string | undefined): string => {
  if (str === undefined || str === null) {
    return '';
  } else {
    return str;
  }
};

export const getArrIndexStringValue = (arr: any[] | undefined, index: number, key: string): string => {
  let result = '';
  if (arr && arr.length > 0) {
    result = arr[index][key];
  }
  return result;
};

export const covertAndValideMobileString = (mobile: string): string => {
  if (ReservationConstants.REGEXPMOBILE_FOR_CONVERT_KO_01X.test(mobile)) {
    return mobile.replace(ReservationConstants.REGEXPMOBILE_FOR_CONVERT_KO_01X, '$1-$2-$3');
  } else if (ReservationConstants.REGEXPMOBILE_FOR_CONVERT_KO_010.test(mobile)) {
    return mobile.replace(ReservationConstants.REGEXPMOBILE_FOR_CONVERT_KO_010, '$1-$2-$3');
  }
  return mobile;
};

export const conditionalOperator = (condition: boolean, caseTrue: any, caseFalse: any): any => {
  return condition ? caseTrue : caseFalse;
};

export const getMobileAsteriskMask = (mobile: string): any => {
  let x = '';
  if (mobile.length === 11) {
    x = mobile.substring(0, 3) + '-' + mobile.substring(3, 6) + '•-' + mobile.substring(7, 8) + '•••';
  } else if (mobile.length === 10) {
    x = mobile.substring(0, 3) + '-' + mobile.substring(3, 5) + '•-' + mobile.substring(6, 7) + '•••';
  } else {
    x = mobile;
  }

  return x;
};

export const checkMobileValidation = (mobile: string): boolean => {
  if (mobile) {
    if (!ReservationConstants.REGEXP_MOBILE_GLOBAL_FORMAT.test(mobile)) {
      return ReservationConstants.REGEXP_MOBILE_VALUE.test(mobile);
    } else if (parsePhoneNumber(mobile)?.country == 'KR') {
      return ReservationConstants.REGEXP_MOBILE.test(formatPhoneNumber(mobile));
    } else {
      return isValidPhoneNumber(mobile);
    }
  } else {
    return false;
  }
};
