import React, { ReactElement } from 'react';
import { Footer } from '../../layouts';
import { Container, Row } from 'react-bootstrap';

const AboutPage = (): ReactElement => {
  return (
    <Container>
      <Row>
        <h1>Header Here</h1>
      </Row>
      <Row>
        <h1>Contents Container Here</h1>
      </Row>
      <Row>
        <Footer />
      </Row>
    </Container>
  );
};

export default AboutPage;
