import React, { ReactElement, useState } from 'react';
import { Form, Badge, Image, InputGroup } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { Visitor } from '../model/ReservationTypes';
import { User } from '../model/User';
import { reservationDetailUtil } from '../utils/ReservationDetailUtil';
import moment from 'moment';
import ReservationConstants, {
  UserTypeEnum,
  UserTypeEnumForClass,
  ImportedGoodsCodeEnum,
  MemberTypeCode,
  DateFormat,
} from '../model/ReservationConstants';
import './ReservationBottomForNoneMember.scss';
import InputMask from 'react-input-mask';
import close24Icon from '../../../icons/close-24px.svg';

export interface VisitorInterface {
  visitor: Visitor;
  fromVisitPeriod: string;
  toVisitPeriod: string;
  sessionUser: User;
  deleteVisitor?: Function;
  sessionUserDisabled?: boolean;
  addUser?: Function;
  index?: any;
  disabled?: boolean;
  handleVisitorInfoModal?: Function;
  infoMessage?: string;
  setFieldValue?: Function;
  formProps: any;
}

export const ReservationBottomForNoneMember: React.FC<any> = (props: VisitorInterface): ReactElement => {
  const visitor = props.visitor;
  const indexKey = props.index;

  const { t } = useTranslation();

  const [visitToPhoneMask, setVisitToPhoneMask] = useState<string>('999-9999-9999');

  const handleUserMobileMaskChange = (newState, oldState, userInput) => {
    const newValue: string = newState.value;
    const oldValue: string = oldState.value;
    const regexN = /[^0-9]/;

    if (newValue.length > 3) {
      if (!newValue.startsWith('010')) setVisitToPhoneMask('999-999-9999');
      else setVisitToPhoneMask('999-9999-9999');
    }
    if (
      (userInput === null || oldValue.length < visitToPhoneMask.length) &&
      (userInput !== ' ' || regexN.test(userInput))
    ) {
      return newState;
    } else {
      return oldState;
    }
  };

  const getDiffClass = (allStart: string, allEnd: string, start: string, end: string): string => {
    let result = 'bgGray';
    if (allStart !== start || allEnd !== end) {
      result = 'bgPink';
    }
    return result;
  };

  const getDateText = (start: string, end: string): string => {
    let result = '';
    const startStr = moment(start).format(DateFormat.MONTH_AND_DAY_DISP);
    const endStr = moment(end).format(DateFormat.MONTH_AND_DAY_DISP);
    result = start === end ? startStr : startStr + ' ~ ' + endStr;
    return result;
  };

  const getGoodsInfo = (): string[] => {
    const goodsInfo: string[] = [];

    if (props.visitor.importedGoodsList && props.visitor.importedGoodsList.length > 0) {
      let laptopCnt = 0;
      let othersCnt = 0;
      props.visitor.importedGoodsList.map(item => {
        if (item.importedGoodsCode === ImportedGoodsCodeEnum.NOTEBOOK) {
          laptopCnt += Number(item.importedGoodsCounts);
        } else {
          othersCnt += Number(item.importedGoodsCounts);
        }
      });

      laptopCnt > 0 &&
        goodsInfo.push(t('reservation.label.ReservationBottomVisitor.body.importedGoods.laptop') + laptopCnt);

      othersCnt > 0 &&
        goodsInfo.push(t('reservation.label.ReservationBottomVisitor.body.importedGoods.others') + othersCnt);
    }
    return goodsInfo;
  };

  const handleVisitorInfoModal = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    event.stopPropagation();
    event.preventDefault();
    !props.disabled && props.handleVisitorInfoModal && props.handleVisitorInfoModal(indexKey);
  };

  const handleUserNameCondChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    visitor.name = event.currentTarget.value;
    props.sessionUser.name = event.currentTarget.value;
    props.setFieldValue && props.setFieldValue('visitors', [visitor]);
  };

  const handleUserMobileCondChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const mobile: string = event.target.value ? event.target.value.replace(/-/gi, '') : '';
    visitor.applyMemberMobilePhone = mobile;
    props.sessionUser.mobile = mobile;
    props.setFieldValue && props.setFieldValue('visitors', [visitor]);
  };

  const handleUserCompanyCondChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    visitor.companyName = event.currentTarget.value;
    props.sessionUser.companyName = event.currentTarget.value;
    props.setFieldValue && props.setFieldValue('visitors', [visitor]);
  };

  const handleUserEmpTypeCondChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    visitor.position = event.currentTarget.value;
    props.sessionUser.position = event.currentTarget.value;
    props.setFieldValue && props.setFieldValue('visitors', [visitor]);
  };

  const clearVisitorInfo = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    visitor.name = '';
    visitor.applyMemberMobilePhone = '';
    visitor.companyName = '';
    visitor.position = '';

    props.setFieldValue && props.setFieldValue('visitors', [visitor]);
  };

  return (
    <div
      className={props.disabled ? 'ReservationBottomForNoneMember disabled' : 'ReservationBottomForNoneMember'}
      id={'ReservationBottomForNoneMember'}
      data-testid={'ReservationBottomForNoneMember'}
    >
      <div className={'noneMember-visit-info-header'} onClick={handleVisitorInfoModal}>
        <Form className={'visitorInfo ' + (props.sessionUserDisabled ? 'disabled ' : '')}>
          <Form.Group controlId="visitorInfo">
            <span>{t('reservation.label.ReservationBottomForms.visitors.title.noneMemberLogin')}</span>

            <div data-testid="items" className="inline float-right">
              {!visitor.applyMemberCarNumber && visitor.importedGoodsList.length === 0 && props.infoMessage && (
                <span className="inputInfo">{props.infoMessage}</span>
              )}
              {visitor.applyMemberCarNumber && (
                <Badge className={'badge bgGray'}>{t('reservation.label.ReservationBottomVisitor.body.car')}</Badge>
              )}
              {getGoodsInfo().map((goods: string, i) => {
                if (goods === '') return null;
                return (
                  <Badge className={'badge bgGray'} key={i}>
                    {goods}
                  </Badge>
                );
              })}
              {visitor.fromVisitPeriod && visitor.toVisitPeriod && (
                <Badge
                  className={
                    'badge ' +
                    getDiffClass(
                      props.fromVisitPeriod,
                      props.toVisitPeriod,
                      visitor.fromVisitPeriod,
                      visitor.toVisitPeriod
                    )
                  }
                >
                  {getDateText(visitor.fromVisitPeriod, visitor.toVisitPeriod)}
                </Badge>
              )}
            </div>
          </Form.Group>
        </Form>
      </div>
      <div className={'title bottom-line'}></div>
      <div className={'noneMember-visit-info-body'}>
        <InputGroup className={!props.disabled ? '' : 'disabled'}>
          <InputGroup.Prepend className="d-inline-block"></InputGroup.Prepend>
          <div className="form-item">
            <i></i>
            <Form.Control
              id="userNameInput"
              data-id="userNameInput"
              className="required d-inline-block"
              type="text"
              placeholder={t(ReservationConstants.MSG_CODE_FOR_NAME_NONEMEMBER)}
              name="userName"
              value={visitor.name}
              onChange={handleUserNameCondChange}
              disabled={props.disabled}
              maxLength={ReservationConstants.NAME_MAX_LENGTH}
            />
          </div>
          <div className="form-item mobile">
            <i></i>
            {props.disabled ? (
              <Form.Control
                id="userMobileInput"
                data-id="userMobileInput"
                className="required d-inline-block"
                type="tel"
                placeholder={t(ReservationConstants.MSG_CODE_FOR_MOBILE_NOMEMBER)}
                name="userMobile"
                value={visitor.applyMemberMobilePhone}
                disabled={true}
              />
            ) : (
              <InputMask
                mask={visitToPhoneMask}
                formatChars={{
                  '9': '[0-9]',
                }}
                maskChar={null}
                value={visitor.applyMemberMobilePhone || ''}
                beforeMaskedValueChange={handleUserMobileMaskChange}
                onChange={handleUserMobileCondChange}
              >
                {(inputProps: any) => {
                  return (
                    <Form.Control
                      id="userMobileInput"
                      data-id="userMobileInput"
                      className="required d-inline-block"
                      type="tel"
                      placeholder={t(ReservationConstants.MSG_CODE_FOR_MOBILE_NOMEMBER)}
                      name="userMobile"
                      value={inputProps.value}
                    />
                  );
                }}
              </InputMask>
            )}
          </div>
          <div className={'title bottom-line mobile'}></div>

          <div className="form-item">
            <i></i>
            <Form.Control
              id="userCompanyInput"
              data-testid="userCompanyInput"
              className="d-inline-block"
              type="text"
              placeholder={t(ReservationConstants.MSG_CODE_FOR_COMPANY)}
              name="userCompany"
              value={visitor.companyName}
              onChange={handleUserCompanyCondChange}
              disabled={props.disabled}
            />
          </div>

          <div className="form-item">
            <Form.Control
              id="userTypeInput"
              data-testid="userTypeInput"
              className="d-inline-block"
              type="text"
              placeholder={t(ReservationConstants.MSG_CODE_FOR_TYPE)}
              name="userType"
              value={visitor.position}
              onChange={handleUserEmpTypeCondChange}
              disabled={props.disabled}
            />
          </div>
          {!props.disabled && (
            <div>
              <Image
                src={close24Icon}
                onClick={clearVisitorInfo}
                data-testid="clear-visitor-info"
                className="d-inline-block clear-visitor-info"
              />
            </div>
          )}
        </InputGroup>

        {props.formProps.values.isSubmited && props.formProps.errors.noMemberNameAndMobile && (
          <span data-testid="purposeDetailValidationMessage" className={'text-danger'}>
            {t(props.formProps.errors.noMemberNameAndMobile)}
          </span>
        )}
      </div>
    </div>
  );
};

export default ReservationBottomForNoneMember;
