import cookie from 'react-cookies';
export default class CookieService {
  public setCookie(name: string, value: string, maxAge?: number): void {
    const defaultMaxAge = 24 * 60 * 60 * 365 * 10; // Cookie cannot have permanent life. Set lifetime 10 years.
    cookie.save(name, value, { path: '/', maxAge: maxAge ? maxAge : defaultMaxAge });
  }

  public getCookie(name: string): string {
    const cookieValue = cookie.load(name);
    return cookieValue ? cookieValue : '';
  }

  public deleteCookie(name: string): void {
    cookie.remove(name, { path: '/' });
  }
}
