import moment from 'moment';

import BaseService from '../../../service/BaseService';
import { Service } from '../../../service/model/Service';
import {
  Reservation,
  HistorySearchConditionModel,
  LatestReservationModel,
  VisitHistory,
  VisitorState,
} from '../model/ReservationTypes';
import { User } from '../model/User';
import {
  ReservationResponse,
  QueryType,
  ApproveReservationRequest,
  VisitRequestByQrcodeModel,
  ResponseVisitEntrySequence,
} from '../model/Reservation';
import { UserTypeEnum, ApprovalStatusEnum, MemberTypeCode, DateFormat } from '../model/ReservationConstants';
import { ReservationByWorkplaceRequestModel, ReservationByWorkplaceResponseModel } from '../model/ReservationStatus';

export default class ReservationService extends BaseService {
  private serviceName = Service.VISIT;

  async getReservationList(
    user: User,
    startIndex?: number,
    searchCondtion?: HistorySearchConditionModel,
    pageSize: number = this.defaultPagingSize
  ) {
    const queryParam: any = {
      queryType: QueryType.REQUEST,
      startIndex: startIndex,
      pageSize: pageSize,
    };

    if (user.type === UserTypeEnum.NONEMEMBER) {
      queryParam.applyMemberName = user.name;
      queryParam.applyMemberMobilePhone = user.mobile;
      if (user.kioskYn) queryParam.kioskYn = user.kioskYn;
    } else {
      queryParam.memberId = user.id;
      if (user.kioskYn) queryParam.kioskYn = user.kioskYn;
    }

    if (searchCondtion) {
      if (searchCondtion.fromApplyDate) {
        searchCondtion.fromApplyDate = moment(searchCondtion.fromApplyDate)
          .startOf('day')
          .utc()
          .format();
      }
      if (searchCondtion.toApplyDate) {
        searchCondtion.toApplyDate = moment(searchCondtion.toApplyDate)
          .endOf('day')
          .utc()
          .format();
      }
      queryParam.condition = JSON.stringify(searchCondtion);
    }

    const method = 'GET';
    const url = '/v1/reservation' + this.getQueryStringFormat(queryParam);
    let response;
    try {
      response = await this.fnRest(method, url, null, this.serviceName);
    } catch (error) {
      response = error;
    }
    return response && response.successOrNot === 'Y' ? response.data : response;
  }

  async getNonMemberReservationList(user: User, certificationId: string) {
    const queryParam: any = {
      queryType: QueryType.REQUEST,
      applyMemberName: user.name,
      applyMemberMobilePhone: user.mobile,
      certificationId: certificationId,
    };

    if (user.kioskYn) queryParam.kioskYn = user.kioskYn;

    const method = 'GET';
    const url = '/v1/nonmember/reservation' + this.getQueryStringFormat(queryParam);

    let response;
    try {
      response = await this.fnRest(method, url, null, this.serviceName);
    } catch (error) {
      response = error;
    }
    return response && response.successOrNot === 'Y' ? response.data : response;
  }

  async getApprovalList(
    user: User,
    startIndex?: number,
    searchCondtion?: HistorySearchConditionModel,
    pageSize: number = this.defaultPagingSize
  ) {
    const queryParam: any = {
      queryType: QueryType.APPROVAL,
      memberId: user.id,
      startIndex: startIndex,
      pageSize: pageSize,
    };

    if (searchCondtion) {
      if (searchCondtion.fromApplyDate) {
        searchCondtion.fromApplyDate = moment(searchCondtion.fromApplyDate)
          .startOf('day')
          .utc()
          .format();
      }
      if (searchCondtion.toApplyDate) {
        searchCondtion.toApplyDate = moment(searchCondtion.toApplyDate)
          .endOf('day')
          .utc()
          .format();
      }
      queryParam.condition = JSON.stringify(searchCondtion);
    }

    const method = 'GET';
    const url = '/v1/reservation' + this.getQueryStringFormat(queryParam);
    let response;
    try {
      response = await this.fnRest(method, url, null, this.serviceName);
    } catch (error) {
      response = error;
    }
    return response && response.successOrNot === 'Y' ? response.data : response;
  }

  async applyReservation(user: User, reservation: Reservation) {
    const method = 'POST';
    const url = '/v1/reservation';
    const body = this.createRequestBody(user, reservation);

    let response;
    try {
      response = await this.fnRest(method, url, body, this.serviceName);
    } catch (error) {
      response = error;
    }
    return response;
  }

  async approveReservation(reservation: ReservationResponse, status: ApprovalStatusEnum, message = '') {
    const method = 'POST';
    const url = '/v1/approve';
    const body: ApproveReservationRequest = {
      visitRequestId: reservation.visitRequestId,
      visitGroupCompanyId: reservation.visitGroupCompanyId,
      visitWorkplaceId: reservation.visitWorkplaceId,
      visitWorkplace: reservation.visitWorkplace,
      approvalStatus: status,
      approvalMessage: message,
    };

    let response;
    try {
      response = await this.fnRest(method, url, body, this.serviceName);
    } catch (error) {
      response = error;
    }
    return response && response.successOrNot === 'Y' ? response.data : response;
  }

  createRequestBody = (user: User, reservation: Reservation) => {
    const userType: MemberTypeCode =
      user.type === UserTypeEnum.NONEMEMBER
        ? MemberTypeCode.NO_MEM
        : user.type === UserTypeEnum.MEMBER
        ? MemberTypeCode.MEM
        : MemberTypeCode.EMP;
    if (userType === MemberTypeCode.NO_MEM && reservation.visitors && reservation.visitors.length > 0) {
      const _visitor = reservation.visitors[0];
      user.name = _visitor.name;
      user.mobile = _visitor.applyMemberMobilePhone;
      user.position = _visitor.position;
      user.companyName = _visitor.companyName;
    }
    return {
      visitRequestId: reservation.visitRequestId || '',
      visitGroupCompanyId: reservation.workplace!.groupCompanyId,
      visitWorkplaceId: reservation.workplace!.workplaceId,
      visitWorkplace: reservation.workplace!.workplace,
      applyMemberDivision: userType,
      applyMemberId: user.id,
      applyMemberName: user.name,
      applyMemberEmployeeNumber: user.employeeNumber,
      applyMemberMobilePhone: user.mobile,
      applyMemberCompanyName: user.companyName,
      applyMemberDepartment: user.dept,
      applyMemberPosition: user.position,
      hostEmployeeCompanyId: reservation.visitTo?.companyId,
      hostEmployeeCompanyName: reservation.visitTo?.companyName,
      hostEmployeeId: reservation.visitTo?.id,
      hostEmployeeNumber: reservation.visitTo?.employeeNumber,
      hostEmployeeName: reservation.visitTo?.name,
      hostEmployeeDepartment: reservation.visitTo?.dept,
      hostEmployeePosition: reservation.visitTo?.position,
      fromVisitPeriod: moment(reservation.fromVisitPeriod).format(DateFormat.DATE_IF),
      toVisitPeriod: moment(reservation.toVisitPeriod).format(DateFormat.DATE_IF),
      visitPurposeCode: reservation.purpose || '',
      visitPurposeDetail: reservation.purposeDetail || '',
      approvalStatus: reservation.status,
      termsId: reservation.termsId ? reservation.termsId : '',
      applyMemberList: reservation.visitors
        ? reservation.visitors.map(visitor => {
            return {
              applyMemberDivision:
                visitor.type === UserTypeEnum.NONEMEMBER
                  ? MemberTypeCode.NO_MEM
                  : visitor.type === UserTypeEnum.MEMBER
                  ? MemberTypeCode.MEM
                  : MemberTypeCode.EMP,
              applyMemberId: visitor.id,
              applyMemberName: visitor.name,
              applyMemberEmployeeNumber: visitor.employeeNumber,
              applyMemberMobilePhone: visitor.applyMemberMobilePhone,
              applyMemberCompanyName: visitor.companyName,
              applyMemberDepartment: visitor.dept,
              applyMemberPosition: visitor.position,
              idpUserId: visitor.idpUserId,
              fromVisitPeriod: moment(visitor.fromVisitPeriod).format(DateFormat.DATE_IF),
              toVisitPeriod: moment(visitor.toVisitPeriod).format(DateFormat.DATE_IF),
              applyMemberCarNumber: visitor.applyMemberCarNumber || '',
              applyMemberEmail: visitor.email,
              importedGoodsYesOrNo: visitor.importedGoodsList && visitor.importedGoodsList.length > 0 ? 'Y' : 'N',
              importedGoodsList: visitor.importedGoodsList,
            };
          })
        : [],
      sendTalkMessageYesorno: reservation.sendTalkMessageYesorno === false ? 'N' : 'Y',
      entryArea: reservation.entryArea,
      vaccinationYesorno: reservation.vaccinationYesorno || '',
    };
  };

  async getApprovalRequestCount(hostEmployeeId: string): Promise<number> {
    const queryParam: any = {
      hostEmployeeId: hostEmployeeId,
    };

    const method = 'GET';
    const url = '/v1/approve/requestcount' + this.getQueryStringFormat(queryParam);

    let response;
    try {
      response = await this.fnRest(method, url, null, this.serviceName);
    } catch (error) {
      response = error;
    }

    return response && response.successOrNot === 'Y' ? response.data[0].requestCount : response;
  }

  async getLatestReservationList(): Promise<VisitHistory[]> {
    const method = 'GET';
    const url = '/v1/reservation/latest';
    const latestReservation: LatestReservationModel[] = [];

    let response;
    try {
      response = await this.fnRest(method, url, null, this.serviceName);

      for (let i = 0; i < response.data.length; i++) {
        latestReservation.push({
          visitTo: JSON.parse(response.data[i].visitTo),
          workplace: JSON.parse(response.data[i].workplace),
        });
      }
    } catch (error) {
      response = error;
    }
    return response.data && response.data.length > 0
      ? this.createVisitHistoryResponse(latestReservation)
      : ({} as VisitHistory[]);
  }
  createVisitHistoryResponse = (LatestReservation: LatestReservationModel[]): VisitHistory[] => {
    const result: VisitHistory[] = [];
    let reservation: VisitHistory;

    for (let i = 0; i < LatestReservation.length; i++) {
      reservation = {
        id: LatestReservation[i].visitTo.id.toString(),
        name: LatestReservation[i].visitTo.name,
        employeeNumber: LatestReservation[i].visitTo.employeeNumber,
        companyId: LatestReservation[i].visitTo.companyId,
        companyName: LatestReservation[i].visitTo.companyName,
        dept: LatestReservation[i].visitTo.dept,
        position: LatestReservation[i].visitTo.position,
        type: UserTypeEnum.LGMEMBER,
        emailAddress: LatestReservation[i].visitTo.emailAddress,
        phoneNumber: LatestReservation[i].visitTo.phoneNumber,
        workplace: LatestReservation[i].workplace,
      };
      result.push(reservation);
    }
    return result;
  };

  async cancelReservation(visitRequestId: string) {
    const method = 'POST';
    const url = '/v1/cancel';
    const body = {
      visitRequestId: visitRequestId,
    };

    let response;
    try {
      response = await this.fnRest(method, url, body, this.serviceName);
    } catch (error) {
      response = error;
    }
    return response && response.successOrNot === 'Y' ? true : false;
  }

  async getReservationStatusByWorkplace(
    params: ReservationByWorkplaceRequestModel
  ): Promise<ReservationByWorkplaceResponseModel> {
    const method = 'GET';
    let url = '/v1/reservation/workplace/' + params.workplaceId + '?';
    let response;
    Object.keys(params).forEach((key, index) => {
      if (key !== 'workplaceId' && params[key] !== null && params[key] !== '') {
        url += key + '=' + encodeURIComponent(params[key]);
        url += index === Object.keys(params).length - 1 ? '' : '&';
      }
    });

    try {
      response = await this.fnRest(method, url, null, this.serviceName);
    } catch (error) {
      response = error;
    }

    return response && response.successOrNot === 'Y' ? response.data : null;
  }
  async getParkingCountByCarNumber(targetDate: string, carNumber: string, workplaceId: string) {
    const method = 'GET';
    const url =
      '/v1/reservation/parking/count?targetDate=' +
      targetDate +
      '&carNumber=' +
      carNumber +
      '&workplaceId=' +
      workplaceId;

    let response;
    try {
      response = await this.fnRest(method, url, null, this.serviceName);
    } catch (error) {
      response = error;
    }
    return response && response.successOrNot === 'Y' ? response.data : null;
  }

  async getVisitorInfo(visitRequestId: string, applyMemberSequence: string) {
    const method = 'POST';
    const url = '/v1/intf/visitorInfo';
    const body = {
      visitRequestId: visitRequestId,
      applyMemberSequence: applyMemberSequence,
    };

    let response;
    try {
      response = await this.fnRest(method, url, body, this.serviceName);
    } catch (error) {
      response = error;
    }
    return response && response.successOrNot === 'Y' ? response.data : null;
  }

  async getMonthlyReservationCount(workplaceId: string, queryType: string, searchMonth?: string) {
    const method = 'GET';
    let url = '/v1/reservation/count/monthly/' + workplaceId + '?queryType=' + queryType;
    if (searchMonth) {
      url += '&searchMonth=' + searchMonth;
    }

    let response;
    try {
      response = await this.fnRest(method, url, null, this.serviceName);
    } catch (error) {
      response = error;
    }
    return response && response.successOrNot === 'Y' ? response.data : null;
  }

  async getReservationByQrcode(visitRequestId: string, applyMemberSequence: string) {
    const method = 'GET';
    const url = `/v1/qrcode/reservation/${visitRequestId}/${applyMemberSequence}`;
    let response;

    try {
      response = await this.fnRest(method, url, null, this.serviceName);
    } catch (error) {
      response = error;
    }
    return response && response.successOrNot === 'Y' ? response.data : null;
  }

  async sendVisitRequestMessageByQrcode(request: VisitRequestByQrcodeModel) {
    const method = 'POST';
    const url = '/v1/reservation/request/visitqr';
    const body: VisitRequestByQrcodeModel = request;
    let response;
    try {
      response = await this.fnRest(method, url, body, this.serviceName);
    } catch (error) {
      response = error;
    }
    return response && response.successOrNot === 'Y' ? response.data : null;
  }

  async getVisitEntrySequence(
    visitRequestId: string,
    applyMemberSequence: string
  ): Promise<ResponseVisitEntrySequence> {
    const method = 'GET';
    const url = `/v1/reservation/entry/${visitRequestId}/${applyMemberSequence}`;
    let response;

    try {
      response = await this.fnRest(method, url, null, this.serviceName);
    } catch (error) {
      response = error;
    }
    return response && response.successOrNot === 'Y' ? response.data : null;
  }

  async getQrcodeHistory(visitRequestId: string, applyMemberSequence: string) {
    const method = 'GET';
    const url = `/v1/reservation/qrHistory/${visitRequestId}/${applyMemberSequence}`;
    let response;

    try {
      response = await this.fnRest(method, url, null, this.serviceName);
    } catch (error) {
      response = error;
    }
    return response && response.successOrNot === 'Y' ? response.data : null;
  }

  async getQrcodeString(visitRequestId: string, applyMemberSequence: string) {
    const method = 'GET';
    const url = `/v1/reservation/qrString/${visitRequestId}/${applyMemberSequence}`;
    let response;

    try {
      response = await this.fnRest(method, url, null, this.serviceName);
    } catch (error) {
      response = error;
    }
    return response && response.successOrNot === 'Y' ? response.data : null;
  }

  async updateApplyMemberVisitState(request: VisitorState) {
    const method = 'PATCH';
    const url = `/v1/reservation/visitor/state`;
    const body: VisitorState = request;
    let response;

    try {
      response = await this.fnRest(method, url, body, this.serviceName);
    } catch (error) {
      response = error;
    }
    return response && response.successOrNot === 'Y' ? true : false;
  }
}
