interface LocaleE {
  KO: string;
  EN: string;
}

export const localeEnum: LocaleE = {
  KO: '한국어',
  EN: 'English',
};

export const localeCodeEnum: LocaleE = {
  KO: 'ko',
  EN: 'en',
};
