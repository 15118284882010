import React, { ReactElement } from 'react';
import { Form, Badge, Modal, ButtonToolbar, Button } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

import './RequirementInvalidModal.scss';
import { MemberTypeCode } from '../model/ReservationConstants';
import { User } from '../model/User';
import { covertAndValideMobileString } from '../utils/util';
import { reservationDetailUtil } from '../utils/ReservationDetailUtil';

export interface RequirementInvalidModal {
  sessionUser: any;
  visitor: User;
  show: boolean;
  message: string;
  onConfirm: () => void;
  handleClose: () => void;
}

export const RequirementInvalidModal: React.FC<RequirementInvalidModal> = ({
  sessionUser,
  visitor,
  show,
  message,
  onConfirm,
  handleClose,
}: RequirementInvalidModal): ReactElement => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    onConfirm();
  };

  const getVisitorBadge = () => {
    const memberTypeInfo = reservationDetailUtil.getMemberTypeInfo(visitor, sessionUser);
    return (
      memberTypeInfo.type !== MemberTypeCode.EMP &&
      memberTypeInfo.type !== MemberTypeCode.MEM && (
        <Form.Label className="mr-1" data-testid="visitorType">
          <Badge className={'content-visitor-badge ' + memberTypeInfo.class}>{t(memberTypeInfo.desc)}</Badge>
        </Form.Label>
      )
    );
  };

  return (
    <Modal
      id="requirement-invalid-modal"
      className="modal-z-index requirement-invalid-modal"
      data-testid="requirement-invalid-modal"
      show={show}
      onHide={handleClose}
      centered={true}
    >
      <Modal.Header closeButton>
        <div className="divBgGray">
          <Form className={'visitorInfo'}>
            <Form.Group controlId="visitorInfo">
              {getVisitorBadge()}
              <Form.Label className="mr-1 visitor name">
                <strong>{visitor.name}</strong>
              </Form.Label>
              <Form.Label className="mr-1 visitor empType">
                <strong>{visitor.position}</strong>
              </Form.Label>
            </Form.Group>
            {visitor.companyName && (
              <Form.Group controlId="visitorInfo">
                <Form.Label className="mr-1 visitorDetail companyName" data-testid="visitorInfoCompanyName">
                  {visitor.companyName}
                </Form.Label>
                <Form.Label className="mr-1 visitorDetail">/</Form.Label>
                <Form.Label className="mr-1 visitorDetail mobile">{visitor.mobile}</Form.Label>
              </Form.Group>
            )}
            {!visitor.companyName && (
              <Form.Group controlId="visitorInfo">
                <Form.Label className="mr-1 visitorDetail mobile">
                  {covertAndValideMobileString(visitor.mobile)}
                </Form.Label>
              </Form.Group>
            )}
          </Form>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="divBgWhite">
          <p id="invalidMessage">
            <span className="invalidMessage" data-testid="invalidMessage">
              {message}
            </span>
          </p>
        </div>
        <ButtonToolbar>
          <Button id="confirmButton" data-testid="confirmButton" variant="primary" onClick={handleConfirm}>
            {t('reservation.label.RequirementInvalidModal.button.confirm')}
          </Button>
        </ButtonToolbar>
      </Modal.Body>
    </Modal>
  );
};

export default RequirementInvalidModal;
