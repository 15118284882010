import { SessionInfo, Session } from './model/SessionInfo';

export default class SessionService {
  public getSessionInfo = (): SessionInfo => {
    let sessionInfo: SessionInfo = {} as SessionInfo;
    if (this.getSessionId() !== '') {
      sessionInfo = {
        memberId:
          this.getSessionStorageValue('memberId') !== '' ? Number(this.getSessionStorageValue('memberId')) : null,
        memberTypeCode: this.getSessionStorageValue('memberTypeCode'),
        memberName: this.getSessionStorageValue('memberName'),
        noMemPhoneNumber: this.getSessionStorageValue('noMemPhoneNumber'),
        noMemCompanyName: this.getSessionStorageValue('noMemCompanyName'),
        noMemPositionName: this.getSessionStorageValue('noMemPositionName'),
      };
    }
    return sessionInfo;
  };

  public setSessionInfo = (sessionInfo: SessionInfo): void => {
    for (const [key, value] of Object.entries(sessionInfo)) {
      this.setSessionStorageValue(key, value);
    }
  };

  public deleteSessionInfo = (): void => {
    this.deleteSessionStorage();
  };

  public getSessionId = (): string => {
    return this.getSessionStorageValue(Session.SESSION_ID);
  };

  public setSessionId = (sessionId: string): void => {
    this.setSessionStorageValue(Session.SESSION_ID, sessionId);
  };

  public getSessionStorageValue = (key: string): any => {
    if (!key) return '';
    const value = sessionStorage.getItem(key);
    return value && value !== 'undefined' ? sessionStorage.getItem(key) : '';
  };

  public setSessionStorageValue = (key: string, value: any): void => {
    if (key) {
      sessionStorage.setItem(key, value);
    }
  };

  private deleteSessionStorage = (): void => {
    sessionStorage.clear();
  };
}
