import { ReactElement, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { Form, Col, Button, Card, Row, Toast, Collapse } from 'react-bootstrap';
import { MemberChangeType } from '../../model/MemberChangeType.enum';
import {
  MemberInfoChange,
  CompanyInfoChange,
  AgreeInfoChange,
  MemberChange,
  FAIL_ICON,
  CHECK_ICON,
  TOAST_ID_TYPE,
  TOAST_CLASS_TYPE,
} from '../../model/MemberChange';
import './ExternalMemberInfoForm.scss';
import PhoneCertification from '../../memberRegistration/component/PhoneCertification';
import MemberInfoService from '../../services/MemberInfoService';
import MemberRegistrationService from '../../services/MemberRegistrationService';
import { Formik } from 'formik';
import * as yup from 'yup';
import MemberRegistrationConstants from '../../model/MemberRegistrationConstants';
import TermsAcceptionModal from '../../memberRegistration/component/TermsAcceptionModal';

import TermService from '../../services/TermService';
import TermAgreeChangeModal from './TermAgreeChangeModal';
import TermsInfo from '../../model/TermsInfo';
import { covertAndValideMobileString } from '../../../reservation/utils/util';
import ReservationConstants, { MemberTypeCode } from '../../../reservation/model/ReservationConstants';
import DeleteMemberAccountModal from './DeleteMemberAccountModal';
import { AuthContext } from '../../../../App';
import { ActionType } from '../../../../reducer/actions';
import { useHistory } from 'react-router-dom';

export interface ExternalMemberInfoFormProps {
  memberInfo: MemberChange;
}

export const ExternalMemberInfoForm: React.FC<ExternalMemberInfoFormProps> = (
  props: ExternalMemberInfoFormProps
): ReactElement => {
  const { t } = useTranslation();
  const { dispatch } = useContext(AuthContext);
  const memberInfoService = new MemberInfoService();

  const [thirdTermInfo, setThirdTermInfo] = useState<TermsInfo>({});

  const [passwordInputFormOpen, setPasswordInputFormOpen] = useState(false);
  const [phonenumInputFormOpen, setPhonenumInputFormOpen] = useState(false);
  const [memberNameInputFormOpen, setMemberNameInputFormOpen] = useState(false);
  const [companyInputFormOpen, setCompanyInputFormOpen] = useState(false);
  const [serialInputFormOpen, setSerialInputFormOpen] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const clearPasswordError = (): void => {
    if (passwordError !== '') {
      setPasswordError('');
    }
  };
  const setLoading = (_isLoading: boolean) => {
    dispatch({ type: ActionType.LOADING, loading: { isLoading: _isLoading } });
  };
  const companyInfoMaker = (companyName: string, position: string) => {
    return companyName === ''
      ? position === ''
        ? ''
        : position
      : position === ''
      ? companyName
      : companyName + '/' + position;
  };

  const setDBDateInFormat = (dbString: string): string => {
    //2020-03-27T04:30:57.000Z
    const tokens: string[] = dbString.split('-');
    if (tokens && tokens.length >= 3) {
      return tokens[0] + '.' + tokens[1] + '.' + tokens[2].substr(0, 2);
    }
    return '';
  };

  const [phoneNumber, setPhoneNumber] = useState(covertAndValideMobileString(props.memberInfo.phoneNumber));
  const [memberName, setMemberName] = useState(props.memberInfo.memberName);

  const [companyName, setCompanyName] = useState(props.memberInfo.companyName);
  const [positionName, setPositionName] = useState(props.memberInfo.positionName);
  const [serialNo, setSerialNo] = useState(props.memberInfo.notebookSerialNo);
  const [termThirdAgreed, setTermThirdAgreed] = useState(props.memberInfo.thirdTermsAgreeYn === 'Y');
  const [termChangeDate, setTermChangeDate] = useState(setDBDateInFormat(props.memberInfo.termsSaveDatetime));

  const [certificationId, setCertificationId] = useState('');
  const [isPhonenumVerified, setPhonenumVerified] = useState(false);

  const [showAgreeChangeModal, setShowAgreeChangeModal] = useState(false);
  const [showTermDetailModal, setShowTermDetailModal] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastId, setToastId] = useState(TOAST_ID_TYPE.FAIL);
  const [toastClassName, setToastClassName] = useState(TOAST_CLASS_TYPE.PASSWORD);
  const [toastIcon, setToastIcon] = useState('');

  const [showDeleteMemberAccountModal, setShowDeleteMemberAccountModal] = useState(false);
  const history = useHistory();
  const handleShowDeleteMemberAccountModal = (): void => {
    history.push(history.location.hash + '#deleteMemberModal');
    setShowDeleteMemberAccountModal(true);
  };
  const closeDeleteMemberModal = (): void => {
    history.goBack();
  };
  const handleShowToast = (message: string): void => {
    setShowToast(true);
    setToastMessage(message);
  };

  const hideTermDetailModals = () => {
    history.goBack();
  };

  const hideTermAgreeChangeModal = (checkedYN: boolean | undefined) => {
    setShowAgreeChangeModal(false);
    if (checkedYN === undefined) {
      setTermThirdAgreed(!termThirdAgreed);
    } else {
      updateTermThirdAgreeYN(checkedYN);
    }
  };

  const setDateInFormat = (formatString: string): string => {
    const date = new Date();
    let returnVal = formatString;

    returnVal = returnVal.replace('YYYY', date.getFullYear() + '');
    returnVal = returnVal.replace(
      'MM',
      ('0' + (date.getMonth() + 1) + '').substring((date.getMonth() + '').length - 1)
    );
    returnVal = returnVal.replace('DD', ('0' + date.getDate()).substring((date.getDate() + '').length - 1));

    return returnVal;
  };

  const findToastMessage = (idType: TOAST_ID_TYPE, classType: TOAST_CLASS_TYPE): string => {
    switch (idType) {
      case TOAST_ID_TYPE.SUCCESS:
        switch (classType) {
          case TOAST_CLASS_TYPE.PASSWORD:
            return t('member.label.memberInfoForm.password.updateSuccess');

          case TOAST_CLASS_TYPE.PHONE:
            return t('member.label.memberInfoForm.phoneNumber.updateSuccess');

          case TOAST_CLASS_TYPE.NAME:
            return t('member.label.memberInfoForm.memberName.updateSuccess');
          case TOAST_CLASS_TYPE.COMPANY:
            return t('member.label.memberInfoForm.companyInfo.updateSuccess');
          case TOAST_CLASS_TYPE.NOTEBOOK:
            return t('member.label.memberInfoForm.serialNo.updateSuccess');
          case TOAST_CLASS_TYPE.AGREE:
            return setDateInFormat(t('member.label.memberInfoForm.changeAgreeYN.agreeSuccess'));
          case TOAST_CLASS_TYPE.DISAGREE:
            return setDateInFormat(t('member.label.memberInfoForm.changeAgreeYN.disagreeSuccess'));
          default:
            return '';
        }
      case TOAST_ID_TYPE.FAIL:
        switch (classType) {
          case TOAST_CLASS_TYPE.PASSWORD:
            return t('member.label.memberInfoForm.password.updateFail');

          case TOAST_CLASS_TYPE.PHONE:
            return t('member.label.memberInfoForm.phoneNumber.updateFail');

          case TOAST_CLASS_TYPE.NAME:
            return t('member.label.memberInfoForm.memberName.updateFail');
          case TOAST_CLASS_TYPE.COMPANY:
            return t('member.label.memberInfoForm.companyInfo.updateFail');
          case TOAST_CLASS_TYPE.NOTEBOOK:
            return t('member.label.memberInfoForm.serialNo.updateFail');
          case TOAST_CLASS_TYPE.TERM:
            return t('member.label.memberInfoForm.changeAgreeYN.updateFail');
          default:
            return '';
        }
      default:
        return '';
    }
  };
  const setToast = (idType: TOAST_ID_TYPE, classType: TOAST_CLASS_TYPE) => {
    setShowToast(false);
    setToastId(idType);
    setToastClassName(classType);
    idType === TOAST_ID_TYPE.SUCCESS ? setToastIcon(CHECK_ICON) : setToastIcon(FAIL_ICON);
    setToastMessage(findToastMessage(idType, classType));
    setShowToast(true);
  };

  const updateTermThirdAgreeYN = async (checkedYN: boolean) => {
    const agreeInfoChange: AgreeInfoChange = {
      termsId: +(thirdTermInfo.termsId + ''),
      termsAgreeYn: checkedYN ? 'Y' : 'N',
    };

    setLoading(true);
    const submitStatus = await memberInfoService.patchAccountAgreeInfo(agreeInfoChange);
    setLoading(false);

    if (submitStatus.successOrNot !== 'Y') {
      setToast(TOAST_ID_TYPE.FAIL, TOAST_CLASS_TYPE.TERM);
      setTermThirdAgreed(!termThirdAgreed);
    } else {
      checkedYN
        ? setToast(TOAST_ID_TYPE.SUCCESS, TOAST_CLASS_TYPE.AGREE)
        : setToast(TOAST_ID_TYPE.SUCCESS, TOAST_CLASS_TYPE.DISAGREE);
      setTermChangeDate(setDateInFormat('YYYY.MM.DD'));
    }
  };

  const passwordSchema = yup.object({
    password: yup.string().required(' '),
    newPassword: yup
      .string()
      .required(' ')
      .matches(MemberRegistrationConstants.REGEXP_PASSWORD, t('member.message.frontEnd.password.common')),
    newPasswordConfirm: yup
      .string()
      .required(' ')
      .oneOf([yup.ref('newPassword'), null], t('member.message.frontEnd.password.common'))
      .test('password-contains-email-id', t('member.message.frontEnd.password.containsEmailId'), function(
        value: string
      ) {
        const email = props.memberInfo.emailAddress;
        const emailId = email ? email.split('@')[0] : '';
        return !(value && value.includes(emailId));
      }),
  });

  const phoneSchema = yup.object({
    phoneNumber: yup
      .string()
      .required(' ')
      .matches(ReservationConstants.REGEXP_MOBILE, t('member.message.frontEnd.phoneNumber.common')),
    certificationKey: yup
      .string()
      .required(' ')
      .min(6, t('member.message.frontEnd.certificationKey.common'))
      .max(6, t('member.message.frontEnd.certificationKey.common')),
  });

  const memberNameSchema = yup.object({
    memberName: yup
      .string()
      .required(' ')
      .min(2, t('member.message.frontEnd.memberName.common'))
      .notOneOf([memberName, null], t('member.label.memberInfoForm.common.noChange')),
  });

  /* eslint-disable */
  useEffect(() => {
    const termService = new TermService();
    const termInfo = termService.requestTermInfo(MemberTypeCode.MEM);

    termInfo.then(response => {
      const termRes = JSON.parse(JSON.stringify(response));
      if (termRes && termRes.successOrNot && termRes.successOrNot === 'Y') {
        termRes.data.map((term: TermsInfo) => {
          if (term.termsTypeCode === 'THIRD') {
            setThirdTermInfo(term);
          }
        });
      } else {
        alert(t('member.message.backEnd.UE'));
      }
    });
  }, []);
  useEffect(() => {
    const location = history.location;
    if (!location.hash.includes('#termsDetailModal')) {
      setShowTermDetailModal(false);
    }
    if (!location.hash.includes('#deleteMemberModal')) {
      setShowDeleteMemberAccountModal(false);
    }
  }, [history.location]);
  /* eslint-enable */

  return (
    <div id="ExternalMemberInfoForm">
      <Card>
        <Card.Header>
          <Row>
            <Col md="10" xs="10" sm="10">
              <Row className="info-item-title">{t('member.label.memberInfoForm.email.titleLabel')}</Row>
              <Row className="info-item-desc" id="currentEmailAddress" data-testid="currentEmailAddress">
                {props.memberInfo?.emailAddress}
              </Row>
            </Col>
            <Col md="2" xs="2" sm="2"></Col>
          </Row>
        </Card.Header>
      </Card>

      <Form.Row className="seperation-line" />
      <Formik
        validateOnMount
        initialValues={{
          password: '',
          newPassword: '',
          newPasswordConfirm: '',
        }}
        onSubmit={async (values, { setFieldError, resetForm }) => {
          const memberChangeInfo: MemberInfoChange = {
            memberChangeType: MemberChangeType.PASSWORD,
            currentValue: values.password,
            changeValue: values.newPassword,
          };

          setLoading(true);
          const submitStatus = await memberInfoService.putMemberInfo(memberChangeInfo);
          setLoading(false);

          if (submitStatus.successOrNot === 'Y') {
            resetForm();
            setPasswordInputFormOpen(false);
            setToast(TOAST_ID_TYPE.SUCCESS, TOAST_CLASS_TYPE.PASSWORD);
          } else {
            if (submitStatus.statusCode === 'INVALID.CURRENT.PASSWORD') {
              setPasswordError(t('member.message.frontEnd.password.currentPasswordNotMatch'));
            } else if (submitStatus.statusCode === 'DUPLICATION.PASSWORD') {
              setPasswordError(t('member.message.frontEnd.password.passwordDuplicated'));
            } else {
              setToast(TOAST_ID_TYPE.FAIL, TOAST_CLASS_TYPE.PASSWORD);
            }
          }
        }}
        validationSchema={passwordSchema}
      >
        {({
          values,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldError,
          isValid,
          resetForm,
          initialStatus,
        }): any => (
          <Card>
            <Card.Header>
              <Row>
                <Col md="10" xs="10" sm="10">
                  <Row className="info-item-title">{t('member.label.memberInfoForm.password.titleLabel')}</Row>
                </Col>
                <Col md="2" xs="2" sm="2">
                  <Button
                    onClick={() => {
                      setPasswordInputFormOpen(!passwordInputFormOpen);
                      clearPasswordError();
                      resetForm();
                    }}
                    variant="link"
                    className="toggle-button"
                    id="togglePassword"
                    data-testid="togglePassword"
                  >
                    {passwordInputFormOpen
                      ? t('member.label.memberInfoForm.common.formHideLabel')
                      : t('member.label.memberInfoForm.common.formOpenLabel')}
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Collapse in={passwordInputFormOpen} data-testid="passwordCollapse">
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Control
                      type="password"
                      placeholder={t('member.label.memberInfoForm.password.placeHolder')}
                      id="password"
                      name="password"
                      data-testid="password"
                      value={values.password}
                      required
                      onChange={
                        passwordError === ''
                          ? handleChange
                          : () => {
                              clearPasswordError();
                            }
                      }
                      onBlur={handleBlur}
                      isInvalid={!!errors.password}
                    ></Form.Control>
                    <Form.Control.Feedback id="passwordFeedback" data-testid="passwordFeedback" type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      type="password"
                      placeholder={t('member.label.memberInfoForm.password.placeHolder2')}
                      id="newPassword"
                      name="newPassword"
                      data-testid="newPassword"
                      value={values.newPassword}
                      required
                      onChange={
                        passwordError === ''
                          ? handleChange
                          : () => {
                              clearPasswordError();
                            }
                      }
                      onBlur={handleBlur}
                      isInvalid={!!errors.newPassword}
                    />
                    <Form.Control
                      type="password"
                      placeholder={t('member.label.memberInfoForm.password.placeHolder3')}
                      id="newPasswordConfirm"
                      name="newPasswordConfirm"
                      data-testid="newPasswordConfirm"
                      value={values.newPasswordConfirm}
                      required
                      onChange={
                        passwordError === ''
                          ? handleChange
                          : () => {
                              clearPasswordError();
                            }
                      }
                      onBlur={handleBlur}
                      isInvalid={!!errors.newPasswordConfirm}
                    />
                    <Form.Control.Feedback id="newPasswordFeedback" data-testid="newPasswordFeedback" type="invalid">
                      {passwordError || errors.newPassword || errors.newPasswordConfirm}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button
                    id="passwordChangeButton"
                    name="passwordChangeButton"
                    data-testid="passwordChangeButton"
                    type="submit"
                    disabled={isSubmitting || !isValid}
                    className="submit-button top-empty-space"
                  >
                    {t('member.label.memberInfoForm.password.buttonLabel')}
                  </Button>
                </Form>
              </Card.Body>
            </Collapse>
          </Card>
        )}
      </Formik>
      <Form.Row className="seperation-line" />
      <Formik
        validateOnMount
        initialValues={{
          phoneNumber: '',
          certificationKey: '',
        }}
        onSubmit={async (values, { resetForm }) => {
          const memberChangeInfo: MemberInfoChange = {
            memberChangeType: MemberChangeType.PHONE_NUMBER,
            changeValue: values.phoneNumber.replace(/-/gi, ''),
          };
          setLoading(true);
          const submitStatus = await memberInfoService.putMemberInfo(memberChangeInfo);
          setLoading(false);
          if (submitStatus.successOrNot === 'Y') {
            setPhoneNumber(values.phoneNumber);
            resetForm();
            setCertificationId('');
            setPhonenumVerified(false);
            setPhonenumInputFormOpen(false);
            setToast(TOAST_ID_TYPE.SUCCESS, TOAST_CLASS_TYPE.PHONE);
          } else {
            setToast(TOAST_ID_TYPE.FAIL, TOAST_CLASS_TYPE.PHONE);
          }
        }}
        validationSchema={phoneSchema}
      >
        {({
          values,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldError,
          setFieldValue,
          isValid,
          resetForm,
        }): any => (
          <Card>
            <Card.Header>
              <Row>
                <Col md="10" xs="10" sm="10">
                  <Row className="info-item-title">{t('member.label.memberInfoForm.phoneNumber.titleLabel')}</Row>
                  <Row className="info-item-desc" id="currentPhoneNumber" data-testid="currentPhoneNumber">
                    {phoneNumber}
                  </Row>
                </Col>
                <Col md="2" xs="2" sm="2">
                  <Button
                    onClick={() => {
                      setPhonenumInputFormOpen(!phonenumInputFormOpen);
                      resetForm();
                      setCertificationId('');
                      setPhonenumVerified(false);
                    }}
                    variant="link"
                    className="toggle-button verical-align-bottom"
                    id="togglePhoneNumber"
                    data-testid="togglePhoneNumber"
                  >
                    {phonenumInputFormOpen
                      ? t('member.label.memberInfoForm.common.formHideLabel')
                      : t('member.label.memberInfoForm.common.formOpenLabel')}
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Collapse in={phonenumInputFormOpen} data-testid="phoneNumberCollapse">
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  {
                    <div className="phone-certificate-form">
                      <PhoneCertification
                        phoneCertificationFormik={{
                          handleChange: handleChange,
                          handleBlur: handleBlur,
                          errors: errors,
                          values: values,
                          setFieldError: setFieldError,
                          setFieldValue: setFieldValue,
                        }}
                        receivedCertificationId={certificationId}
                        setCertificationId={setCertificationId}
                        certificationConfirmStatus={isPhonenumVerified}
                        setCertificationConfirmStatus={setPhonenumVerified}
                        certificationBusinessTypeCode="CHANGE"
                        memberRegistrationService={new MemberRegistrationService()}
                        titleVisible={false}
                      />
                    </div>
                  }
                  <Button
                    id="phoneNumberChangeButton"
                    name="phoneNumberChangeButton"
                    data-testid="phoneNumberChangeButton"
                    type="submit"
                    disabled={isSubmitting || !isPhonenumVerified}
                    className="submit-button top-empty-space"
                  >
                    {t('member.label.memberInfoForm.phoneNumber.buttonLabel')}
                  </Button>
                </Form>
              </Card.Body>
            </Collapse>
          </Card>
        )}
      </Formik>
      <Form.Row className="seperation-line" />
      <Formik
        validateOnMount
        initialValues={{
          memberName: props.memberInfo.memberName,
        }}
        onSubmit={async (values, { resetForm }) => {
          const memberChangeInfo: MemberInfoChange = {
            memberChangeType: MemberChangeType.MEMBER_NAME,
            changeValue: values.memberName,
          };
          setLoading(true);
          const submitStatus = await memberInfoService.putMemberInfo(memberChangeInfo);
          setLoading(false);
          if (submitStatus.successOrNot === 'Y') {
            setMemberName(values.memberName);
            resetForm({ values: { memberName: memberName } });
            setMemberNameInputFormOpen(false);
            setToast(TOAST_ID_TYPE.SUCCESS, TOAST_CLASS_TYPE.NAME);
          } else {
            setToast(TOAST_ID_TYPE.FAIL, TOAST_CLASS_TYPE.NAME);
          }
        }}
        validationSchema={memberNameSchema}
      >
        {({
          values,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldError,
          isValid,
          resetForm,
        }): any => (
          <Card>
            <Card.Header>
              <Row>
                <Col md="10" xs="10" sm="10">
                  <Row className="info-item-title">{t('member.label.memberInfoForm.memberName.titleLabel')}</Row>
                  <Row className="info-item-desc" id="currentMemberName" data-testid="currentMemberName">
                    {memberName}
                  </Row>
                </Col>
                <Col md="2" xs="2" sm="2">
                  <Button
                    onClick={() => {
                      setMemberNameInputFormOpen(!memberNameInputFormOpen);
                      resetForm({ values: { memberName: memberName } });
                    }}
                    variant="link"
                    className="toggle-button verical-align-bottom"
                    id="toggleMemberName"
                    data-testid="toggleMemberName"
                  >
                    {memberNameInputFormOpen
                      ? t('member.label.memberInfoForm.common.formHideLabel')
                      : t('member.label.memberInfoForm.common.formOpenLabel')}
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Collapse in={memberNameInputFormOpen} data-testid="memberNameCollapse">
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      placeholder={t('member.label.memberInfoForm.memberName.placeHolder')}
                      id="memberName"
                      name="memberName"
                      data-testid="memberName"
                      value={values.memberName}
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.memberName}
                    ></Form.Control>
                    <Form.Control.Feedback id="memberNameFeedback" data-testid="memberNameFeedback" type="invalid">
                      {errors.memberName}
                    </Form.Control.Feedback>
                    <Button
                      id="memberNameChangeButton"
                      name="memberNameChangeButton"
                      data-testid="memberNameChangeButton"
                      type="submit"
                      disabled={isSubmitting || !isValid}
                      className="submit-button top-empty-space"
                    >
                      {t('member.label.memberInfoForm.memberName.buttonLabel')}
                    </Button>
                  </Form.Group>
                </Form>
              </Card.Body>
            </Collapse>
          </Card>
        )}
      </Formik>
      <Form.Row className="seperation-line" />

      <Formik
        validateOnMount
        initialValues={{
          companyName: props.memberInfo.companyName,
          positionName: props.memberInfo.positionName,
        }}
        onSubmit={async (values, { resetForm }) => {
          const companyInfoChange: CompanyInfoChange = {
            memberChangeType: MemberChangeType.COMPANY_INFO,
            companyName: values.companyName.trim(),
            positionName: values.positionName.trim(),
          };
          setLoading(true);
          const submitStatus = await memberInfoService.putMemberInfo(companyInfoChange);
          setLoading(false);
          if (submitStatus.successOrNot === 'Y') {
            setCompanyName(values.companyName.trim());
            setPositionName(values.positionName.trim());
            resetForm({
              values: {
                companyName: companyName,
                positionName: positionName,
              },
            });
            setCompanyInputFormOpen(false);
            setToast(TOAST_ID_TYPE.SUCCESS, TOAST_CLASS_TYPE.COMPANY);
          } else {
            setToast(TOAST_ID_TYPE.FAIL, TOAST_CLASS_TYPE.COMPANY);
          }
        }}
      >
        {({
          values,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldError,
          isValid,

          resetForm,
        }): any => (
          <Card>
            <Card.Header>
              <Row>
                <Col md="10" xs="10" sm="10">
                  <Row className="info-item-title">{t('member.label.memberInfoForm.companyInfo.titleLabel')}</Row>
                  <Row className="info-item-desc" id="currentCompanyInfo" data-testid="currentCompanyInfo">
                    {companyInfoMaker(companyName + '', positionName + '')}
                  </Row>
                </Col>
                <Col md="2" xs="2" sm="2">
                  <Button
                    onClick={() => {
                      setCompanyInputFormOpen(!companyInputFormOpen);
                      if (!companyInputFormOpen) {
                        resetForm({
                          values: {
                            companyName: companyName,
                            positionName: positionName,
                          },
                        });
                      }
                    }}
                    variant="link"
                    className={
                      'toggle-button' +
                      (companyInfoMaker(companyName + '', positionName + '') === '' ? '' : ' verical-align-bottom')
                    }
                    id="toggleCompanyInfo"
                    data-testid="toggleCompanyInfo"
                  >
                    {companyInputFormOpen
                      ? t('member.label.memberInfoForm.common.formHideLabel')
                      : t('member.label.memberInfoForm.common.formOpenLabel')}
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Collapse in={companyInputFormOpen} data-testid="companyInfoCollapse">
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Form.Group>
                    <Row>
                      <Col md="7" xs="7" sm="7">
                        <Form.Control
                          type="text"
                          placeholder={t('member.label.memberInfoForm.companyInfo.placeHolder')}
                          id="companyName"
                          name="companyName"
                          data-testid="companyName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.companyName}
                          isInvalid={values.companyName === companyName && values.positionName === positionName}
                        />
                        <Form.Control.Feedback
                          id="companyInfoFeedback"
                          data-testid="companyInfoFeedback"
                          type="invalid"
                        >
                          {values.companyName === companyName &&
                            values.positionName === positionName &&
                            t('member.label.memberInfoForm.common.noChange')}
                        </Form.Control.Feedback>
                      </Col>
                      <Col md="5" xs="5" sm="5">
                        <Form.Control
                          type="text"
                          placeholder={t('member.label.memberInfoForm.companyInfo.placeHolder2')}
                          id="positionName"
                          name="positionName"
                          data-testid="positionName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.positionName}
                          isInvalid={values.companyName === companyName && values.positionName === positionName}
                        />
                      </Col>
                    </Row>
                    <Button
                      id="companyInfoChangeButton"
                      name="companyInfoChangeButton"
                      data-testid="companyInfoChangeButton"
                      type="submit"
                      className="submit-button top-empty-space"
                      disabled={
                        isSubmitting || (values.companyName === companyName && values.positionName === positionName)
                      }
                    >
                      {t('member.label.memberInfoForm.companyInfo.buttonLabel')}
                    </Button>
                  </Form.Group>
                </Form>
              </Card.Body>
            </Collapse>
          </Card>
        )}
      </Formik>
      <Form.Row className="seperation-line" />
      <Formik
        validateOnMount
        initialValues={{
          serialNo: props.memberInfo.notebookSerialNo,
        }}
        onSubmit={async (values, { resetForm }) => {
          const memberInfoChange: MemberInfoChange = {
            memberChangeType: MemberChangeType.NOTEBOOK_SERIAL_NO,
            currentValue: null,
            changeValue: values.serialNo.trim(),
          };
          setLoading(true);
          const submitStatus = await memberInfoService.putMemberInfo(memberInfoChange);
          setLoading(false);
          if (submitStatus.successOrNot === 'Y') {
            setSerialNo(values.serialNo.trim());
            resetForm({ values: { serialNo: serialNo } });
            setSerialInputFormOpen(false);
            setToast(TOAST_ID_TYPE.SUCCESS, TOAST_CLASS_TYPE.NOTEBOOK);
          } else {
            setToast(TOAST_ID_TYPE.FAIL, TOAST_CLASS_TYPE.NOTEBOOK);
          }
        }}
      >
        {({ values, errors, isSubmitting, handleChange, handleBlur, handleSubmit, resetForm, isValid }): any => (
          <Card>
            <Card.Header>
              <Row>
                <Col md="10" xs="10" sm="10">
                  <Row className="info-item-title">{t('member.label.memberInfoForm.serialNo.titleLabel')}</Row>
                  <Row className="info-item-desc" id="currentSerialNo" data-testid="currentSerialNo">
                    {serialNo}
                  </Row>
                </Col>
                <Col md="2" xs="2" sm="2">
                  <Button
                    onClick={() => {
                      setSerialInputFormOpen(!serialInputFormOpen);
                      resetForm({ values: { serialNo: serialNo } });
                    }}
                    variant="link"
                    className={'toggle-button' + (serialNo === '' ? '' : ' verical-align-bottom')}
                    id="toggleSerialNo"
                    data-testid="toggleSerialNo"
                  >
                    {serialInputFormOpen
                      ? t('member.label.memberInfoForm.common.formHideLabel')
                      : t('member.label.memberInfoForm.common.formOpenLabel')}
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Collapse in={serialInputFormOpen} data-testid="serialNoCollapse">
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      placeholder={t('member.label.memberInfoForm.serialNo.placeHolder')}
                      id="serialNo"
                      name="serialNo"
                      data-testid="serialNo"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.serialNo}
                      isInvalid={values.serialNo === serialNo}
                    />
                    <Form.Control.Feedback id="serialNoFeedback" data-testid="serialNoFeedback" type="invalid">
                      {values.serialNo === serialNo && t('member.label.memberInfoForm.common.noChange')}
                    </Form.Control.Feedback>
                    <Button
                      id="serialNoChangeButton"
                      name="serialNoChangeButton"
                      data-testid="serialNoChangeButton"
                      type="submit"
                      className="submit-button top-empty-space"
                      disabled={isSubmitting || values.serialNo === serialNo}
                    >
                      {t('member.label.memberInfoForm.serialNo.buttonLabel')}
                    </Button>
                  </Form.Group>
                </Form>
              </Card.Body>
            </Collapse>
          </Card>
        )}
      </Formik>
      <Form.Row className="seperation-line" />

      <Card>
        <Card.Header>
          <Row>
            <Col md="10" xs="10" sm="10" className="info-item-title">
              {t('member.label.memberInfoForm.changeAgreeYN.titleLabel')}
            </Col>
            <Col md="2" xs="2" sm="2"></Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row className="term-third-title">
            <Col md="11" xs="11" sm="11">
              <Form.Check
                custom
                id="termThirdCheck"
                type="checkbox"
                name="termThirdCheck"
                data-testid="termThirdCheck"
                checked={termThirdAgreed}
                label={thirdTermInfo.termsTitle + t('member.label.memberInfoForm.changeAgreeYN.notRequired')}
                onChange={() => {
                  setTermThirdAgreed(!termThirdAgreed);
                  setShowAgreeChangeModal(!showAgreeChangeModal);
                }}
              />
            </Col>
            <Col md="1" xs="1" sm="1">
              <i
                id="firstRightArrow"
                data-testid="firstRightArrow"
                className="material-icons term-detail-icon"
                onClick={() => {
                  if (showTermDetailModal) {
                    history.goBack();
                  } else {
                    history.push(history.location.hash + '#termsDetailModal');
                  }

                  setShowTermDetailModal(!showTermDetailModal);
                }}
              >
                keyboard_arrow_right
              </i>
            </Col>
          </Row>
          <Row className="term-third-change-date" id="termThirdChangeDate">
            {t('member.label.memberInfoForm.changeAgreeYN.agreeChangeDate') + termChangeDate}
          </Row>
          <Row className="term-third-desc">{thirdTermInfo.termsContents}</Row>
        </Card.Body>
      </Card>
      <Form.Row className="seperation-line" />
      <Form.Row>
        <Button
          variant="link"
          className="resign-button"
          id="deleteMemberAccountButton"
          data-testid="deleteMemberAccountButton"
          onClick={(e: any) => {
            e.stopPropagation();
            e.preventDefault();
            handleShowDeleteMemberAccountModal();
          }}
        >
          {t('member.label.memberInfoForm.deleteMemberAccount.titleLabel')}
        </Button>
        <DeleteMemberAccountModal
          show={showDeleteMemberAccountModal}
          onHide={closeDeleteMemberModal}
          setToastMessage={handleShowToast}
        ></DeleteMemberAccountModal>
      </Form.Row>
      {showTermDetailModal && (
        <TermsAcceptionModal
          term={thirdTermInfo}
          termService={new TermService()}
          termsTypeCode="THIRD"
          memberTypeCode={props.memberInfo.memberTypeCode}
          onHide={hideTermDetailModals}
          data-testid="termsAccpectionModal"
        />
      )}
      {showAgreeChangeModal && (
        <TermAgreeChangeModal changedAgreeYN={termThirdAgreed} onHide={hideTermAgreeChangeModal} />
      )}
      <Toast
        id={toastId}
        data-testid={toastId}
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
        className={toastClassName}
      >
        <Toast.Body>
          <span class-name="toast-contents">
            <i className="material-icons">{toastIcon}</i>
            <span id="toastMessage" className="toast-message">
              {toastMessage}
            </span>
          </span>
        </Toast.Body>
      </Toast>
    </div>
  );
};
export default ExternalMemberInfoForm;
