import React, { ReactElement, useEffect, useState } from 'react';
import { Form, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { Visitor } from '../model/ReservationTypes';
import { User } from '../model/User';
import { reservationDetailUtil } from '../utils/ReservationDetailUtil';
import moment from 'moment';
import {
  UserTypeEnum,
  UserTypeEnumForClass,
  ImportedGoodsCodeEnum,
  MemberTypeCode,
  DateFormat,
} from '../model/ReservationConstants';
import './ReservationBottomVisitor.scss';
import ReservationVisitorList from './ReservationVisitorList';
import ReservationService from '../services/ReservationService';
import { useHistory } from 'react-router-dom';

export interface VisitorInterface {
  visitor: Visitor;
  fromVisitPeriod: string;
  toVisitPeriod: string;
  sessionUser: User;
  deleteVisitor?: Function;
  sessionUserDisabled?: boolean;
  addUser?: Function;
  index?: any;
  disabled?: boolean;
  handleVisitorInfoModal?: Function;
  infoMessage?: string;
  pageType?: string;
  eventKey?: any;
  closeOtherToggle?: Function;
  otherCollapsed?: string;
  visitRequestId?: string;
  setQrcodeHistory?: Function;
  qrcodeHistory?: any;
  checkVisitorModalClose?: Function;
  checkVisitorModalShow?: Function;
}

export const ReservationBottomVisitor: React.FC<any> = (props: VisitorInterface): ReactElement => {
  const visitor = props.visitor;
  const indexKey = props.index;
  const reservationService = new ReservationService();
  const [bgColor, setBgColor] = useState<string>('bgGreen');
  const history = useHistory();

  const { t } = useTranslation();

  const dummyFun = () => {
    return false;
  };

  const visitorModalShow = props.checkVisitorModalShow ? props.checkVisitorModalShow : dummyFun;

  const getDiffClass = (allStart: string, allEnd: string, start: string, end: string): string => {
    let result = 'bgGray';
    if (allStart !== start || allEnd !== end) {
      result = 'bgPink';
    }
    return result;
  };

  const getDateText = (start: string, end: string): string => {
    let result = '';
    const startStr = moment(start).format(DateFormat.MONTH_AND_DAY_DISP);
    const endStr = moment(end).format(DateFormat.MONTH_AND_DAY_DISP);
    result = start === end ? startStr : startStr + ' ~ ' + endStr;
    return result;
  };

  const memberTypeInfo = reservationDetailUtil.getMemberTypeInfo(visitor, props.sessionUser);
  const getVisitorBadge = () => {
    return (
      memberTypeInfo.type !== MemberTypeCode.EMP &&
      memberTypeInfo.type !== MemberTypeCode.MEM && (
        <Form.Label data-testid="visitorType">
          <Badge className={memberTypeInfo.class}>{t(memberTypeInfo.desc)}</Badge>
        </Form.Label>
      )
    );
  };

  const getGoodsInfo = (): string[] => {
    const goodsInfo: string[] = [];

    if (props.visitor.importedGoodsList && props.visitor.importedGoodsList.length > 0) {
      let laptopCnt = 0;
      let othersCnt = 0;
      props.visitor.importedGoodsList.map(item => {
        if (item.importedGoodsCode === ImportedGoodsCodeEnum.NOTEBOOK) {
          laptopCnt += Number(item.importedGoodsCounts);
        } else {
          othersCnt += Number(item.importedGoodsCounts);
        }
      });

      laptopCnt > 0 &&
        goodsInfo.push(t('reservation.label.ReservationBottomVisitor.body.importedGoods.laptop') + laptopCnt);

      othersCnt > 0 &&
        goodsInfo.push(t('reservation.label.ReservationBottomVisitor.body.importedGoods.others') + othersCnt);
    }
    return goodsInfo;
  };

  const handleVisitorInfoModal = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    event.stopPropagation();
    event.preventDefault();
    !props.disabled && props.handleVisitorInfoModal && props.handleVisitorInfoModal(indexKey);
    props.visitor.applyMemberQrcodeYn === 'Y' && getQrcodeHistory();
  };

  const getQrcodeHistory = async () => {
    if (props.qrcodeHistory.length != 0) return;
    const visitRequestId = props.visitRequestId || '';
    const applyMemberSequence = visitor.applyMemberSequence || '';
    const result = await reservationService.getQrcodeHistory(visitRequestId, applyMemberSequence);
    props.setQrcodeHistory && props.setQrcodeHistory(result);
  };

  const checkVisitToggle = async () => {
    const params = {
      visitRequestId: props.visitRequestId || '',
      applyMemberSequence: visitor.applyMemberSequence || '',
      applyMemberVisitYesorno: visitor.applyMemberVisitYesorno
        ? visitor.applyMemberVisitYesorno === 'N'
          ? 'Y'
          : 'N'
        : '',
    };
    const result = await reservationService.updateApplyMemberVisitState(params);
    if (result) {
      visitorModalShow(true);
      setBgColor(bgColor === 'bgGreen' ? 'bgPink' : 'bgGreen');
    } else {
      visitorModalShow(false);
    }
  };

  useEffect(() => {
    if (visitor.applyMemberVisitYesorno === 'N') setBgColor('bgPink');
    if (visitor.applyMemberVisitYesorno === 'Y') setBgColor('bgGreen');
  }, []);

  return (
    <div
      className={props.disabled ? 'ReservationBottomVisitor disabled' : 'ReservationBottomVisitor'}
      id={'ReservationBottomVisitor' + visitor.id}
      data-testid={'ReservationBottomVisitor' + visitor.id}
      onClick={handleVisitorInfoModal}
    >
      {(visitor.applyMemberQrcodeYn === 'Y' ||
        visitor.applyMemberCarNumber ||
        (visitor.importedGoodsList && visitor.importedGoodsList.length > 0)) &&
      props.pageType &&
      props.pageType == 'showDetail' ? (
        <ReservationVisitorList
          visitor={props.visitor}
          fromVisitPeriod={props.fromVisitPeriod}
          toVisitPeriod={props.toVisitPeriod}
          sessionUser={props.sessionUser}
          deleteVisitor={props.deleteVisitor}
          sessionUserDisabled={props.sessionUserDisabled}
          addUser={props.addUser}
          index={props.index}
          disabled={props.disabled}
          handleVisitorInfoModal={props.handleVisitorInfoModal}
          infoMessage={props.infoMessage}
          pageType={props.pageType}
          eventKey={props.eventKey}
          closeOtherToggle={props.closeOtherToggle}
          otherCollapsed={props.otherCollapsed}
          visitorModalShow={visitorModalShow}
        />
      ) : (
        <Form
          className={
            'visitorInfo ' +
            (memberTypeInfo.class === UserTypeEnumForClass.ME && props.sessionUserDisabled ? 'disabled ' : '') +
            memberTypeInfo.class
          }
        >
          <Form.Group controlId="visitorInfo">
            {getVisitorBadge()}

            <Form.Label className="mr-1 visitorBaseInfo">
              <strong>{visitor.name}</strong>&nbsp;
              <strong>{visitor.position}</strong>&nbsp;
              {((visitor.companyName && visitor.companyName.length > 0) || (visitor.dept && visitor.dept.length > 0)) &&
                '/ '}
              {visitor.companyName}&nbsp;
              {visitor.dept}&nbsp;
            </Form.Label>

            <div data-testid="items" className="inline float-right">
              {!visitor.applyMemberCarNumber && visitor.importedGoodsList.length === 0 && props.infoMessage && (
                <span className="inputInfo">{props.infoMessage}</span>
              )}
              {/* {
                history.location.pathname.includes("workplaceManagement") &&
                <Badge className={`badge mr-1 ${bgColor} pointer`} onClick={() => checkVisitToggle()}>
                  {bgColor === 'bgGreen' ? '방문' : '미방문'}
                </Badge>
              } */}
              {visitor.applyMemberCarNumber && (
                <Badge className={'badge mr-1 bgGray'}>
                  {t('reservation.label.ReservationBottomVisitor.body.car')}
                </Badge>
              )}
              {visitor.applyMemberQrcodeYn == 'Y' && (
                <Badge className={'badge mr-1 bgGray'}>
                  {t('reservation.label.ReservationBottomVisitor.body.qrcodeYn')}
                </Badge>
              )}
              {getGoodsInfo().map((goods: string, i) => {
                if (goods === '') return null;
                return (
                  <Badge className={'badge mr-1 bgGray'} key={i}>
                    {goods}
                  </Badge>
                );
              })}
              {visitor.fromVisitPeriod && visitor.toVisitPeriod && (
                <Badge
                  className={
                    'badge mr-1 ' +
                    getDiffClass(
                      props.fromVisitPeriod,
                      props.toVisitPeriod,
                      visitor.fromVisitPeriod,
                      visitor.toVisitPeriod
                    )
                  }
                >
                  {getDateText(visitor.fromVisitPeriod, visitor.toVisitPeriod)}
                </Badge>
              )}

              {memberTypeInfo.class === UserTypeEnumForClass.ME && props.sessionUserDisabled ? (
                <OverlayTrigger
                  key={'top'}
                  placement={'top'}
                  overlay={
                    <Tooltip id={'tooltip-top'}>
                      {t('reservation.label.ReservationBottomVistor.visitor.button.addTooltip')}
                    </Tooltip>
                  }
                >
                  <i
                    className="material-icons pointer addUser"
                    data-testid="addUser"
                    onClick={() => {
                      props.addUser && props.addUser(props.sessionUser);
                    }}
                  >
                    refresh
                  </i>
                </OverlayTrigger>
              ) : (
                props.deleteVisitor &&
                props.sessionUser.type !== UserTypeEnum.NONEMEMBER && (
                  <i
                    className="material-icons pointer"
                    data-testid="deleteVisitor"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      e.preventDefault();
                      props.deleteVisitor && props.deleteVisitor(indexKey);
                    }}
                  >
                    close
                  </i>
                )
              )}
            </div>
          </Form.Group>
        </Form>
      )}
    </div>
  );
};

export default ReservationBottomVisitor;
