import React, { useState, ReactElement, useContext } from 'react';
import { Form, Modal, Container, Button, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './FindIdModal.scss';
import { useTranslation } from 'react-i18next';
import Member from '../../model/Member';
import { Formik, FormikProps, FormikHelpers } from 'formik';
import * as yup from 'yup';
import MemberRegistrationConstants from '../../model/MemberRegistrationConstants';
import MemberRegistrationService from '../../services/MemberRegistrationService';
import { covertAndValideMobileString } from '../../../reservation/utils/util';
import ReservationConstants from '../../../reservation/model/ReservationConstants';
import { AuthContext } from '../../../../App';
import { ActionType } from '../../../../reducer/actions';
import InputMask from 'react-input-mask';
export interface FindIdModalProps {
  show: boolean;
  onHide: () => void;
  showIdInfo: boolean;
  setShowIdInfo: (value: boolean) => void;
  passwordOnHide: () => void;
}

export interface FindId {
  showIdInfo: boolean;
  memberName: string;
  phoneNumber: string;
}

const FindIdModal: React.FC<FindIdModalProps> = ({
  show,
  onHide,
  showIdInfo,
  setShowIdInfo,
  passwordOnHide,
}: FindIdModalProps): ReactElement => {
  const memberRegistrationService = new MemberRegistrationService();
  const { t } = useTranslation();
  const { dispatch } = useContext(AuthContext);
  const [members, setMembers] = useState<Member[]>([]);
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [phoneMask, setPhoneMask] = useState<string>('999-9999-9999');
  const initFindId: FindId = {
    showIdInfo: true,
    memberName: '',
    phoneNumber: '',
  };

  const schema = yup.object({
    showIdInfo: yup.boolean(),
    memberName: yup.string().when('showIdInfo', {
      is: showIdInfo,
      then: yup
        .string()
        .required(t('member.message.frontEnd.memberName.required'))
        .min(2, t('member.message.frontEnd.memberName.common')),
    }),
    phoneNumber: yup.string().when('showIdInfo', {
      is: showIdInfo,
      then: yup
        .string()
        .required(t('member.message.frontEnd.phoneNumber.required'))
        .test('phoneNumberValidation', t('member.message.frontEnd.phoneNumber.common'), function(value) {
          const result01X = ReservationConstants.REGEXPMOBILE_FOR_CONVERT_KO_01X.test(value);
          const result010 = ReservationConstants.REGEXPMOBILE_FOR_CONVERT_KO_010.test(value);
          if (!result01X && !result010) {
            return false;
          }
          return true;
        }),
    }),
  });

  const setLoading = (_isLoading: boolean) => {
    dispatch({ type: ActionType.LOADING, loading: { isLoading: _isLoading } });
  };
  const modalBody = (props: FormikProps<FindId>): JSX.Element => {
    const handleMemberName = (event: React.ChangeEvent<HTMLInputElement>) => {
      props.setFieldValue(event.target.id, event.target.value);
    };

    const handleUserMobileCondChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const returnVal = covertAndValideMobileString(event.target.value);
      setPhoneNumber(returnVal);
      props.setFieldValue(event.target.id, returnVal);
    };
    const handleUserMobileMaskChange = (newState, oldState, userInput) => {
      const value: string = newState.value;
      const regexN = /[^0-9]/;

      if (value.length > 3) {
        if (!value.startsWith('010')) setPhoneMask('999-999-9999');
        else setPhoneMask('999-9999-9999');
      }
      if (userInput !== ' ' || regexN.test(userInput)) {
        return newState;
      } else {
        return oldState;
      }
    };
    const handleClose = () => {
      props.resetForm();
      setPhoneNumber('');
      onHide();
    };

    const handleResetPage = (): void => {
      props.resetForm();
      setPhoneNumber('');

      passwordOnHide();
    };

    return (
      <Modal
        show={show}
        onHide={handleClose}
        centered={true}
        aria-labelledby="contained-modal-title-vcenter"
        className="find-id-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Container>
              <div className="header bold">{t('member.label.findIdModal.header.title')}</div>
              <div className="header light">
                {showIdInfo
                  ? t('member.label.findIdModal.header.contentsMemberInfo')
                  : t('member.label.findIdModal.header.contentsMatchedID')}
              </div>
            </Container>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={props.handleSubmit}>
            {/* first view */}
            {showIdInfo && (
              <div>
                {/* phone */}
                <Form.Row className="top-empty-space">
                  <Form.Group>
                    <Form.Label>{t('member.label.findIdModal.phoneNumber.label')}</Form.Label>
                    <InputMask
                      mask={phoneMask}
                      formatChars={{
                        '9': '[0-9]',
                      }}
                      maskChar={null}
                      value={phoneNumber || ''}
                      beforeMaskedValueChange={handleUserMobileMaskChange}
                      onChange={handleUserMobileCondChange}
                      id="phoneNumber"
                    >
                      {(inputProps: any) => {
                        return (
                          <Form.Control
                            id="phoneNumber"
                            name="phoneNumber"
                            data-testid="phoneNumber"
                            required
                            type="text"
                            placeholder={t('member.label.findIdModal.phoneNumber.placeholder')}
                            value={inputProps.phoneNumber}
                            className="phone-number-input"
                            isInvalid={props.errors.phoneNumber ? true : false}
                          />
                        );
                      }}
                    </InputMask>

                    <Form.Control.Feedback id="phoneNumberFeedback" data-testid="phoneNumberFeedback" type="invalid">
                      {props.errors.phoneNumber}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                {/* name */}
                <Form.Row className="top-empty-space">
                  <Form.Group>
                    <Form.Label>{t('member.label.findIdModal.memberName.label')}</Form.Label>
                    <Form.Control
                      id="memberName"
                      name="memberName"
                      data-testid="memberName"
                      required
                      type="text"
                      placeholder={t('member.label.findIdModal.memberName.placeholder')}
                      onChange={handleMemberName}
                      isInvalid={props.errors.memberName ? true : false}
                    />
                    <Form.Control.Feedback id="memberNameFeedback" data-testid="memberNameFeedback" type="invalid">
                      {props.errors.memberName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <div id="submitArea" className="submit-area">
                  <Form.Row className="submit-row">
                    <Button
                      id="findIdSubmitButton"
                      name="findIdSubmitButton"
                      data-testid="nextButton"
                      className="submit-button"
                      type="submit"
                      disabled={props.isSubmitting || !props.isValid}
                    >
                      <span
                        id="findIdSubmitButtonText"
                        data-testid="findIdSubmitButtonText"
                        className="submit-button-text"
                      >
                        {t('member.label.findIdModal.button.next')}
                      </span>
                    </Button>
                  </Form.Row>
                </div>
              </div>
            )}
            {/* second view */}
            {!showIdInfo && (
              <div>
                <Form.Row className="top-empty-space">
                  <Form.Group>
                    <Form.Label className="findidlabel">{t('member.label.findIdModal.id.label')}</Form.Label>
                    <div id="findIdList">
                      {members.map((m: Member, i) => {
                        return (
                          <div key={i}>
                            <Row className="retrievedIdsLabel top-empty-space left-empty-space">{m.emailAddress}</Row>
                          </div>
                        );
                      })}
                    </div>
                  </Form.Group>
                </Form.Row>
                <Form.Row className="top-empty-space">
                  <Form.Group>
                    <Form.Label className="markingRemarklabel">
                      {t('member.label.findIdModal.id.maskingLabel')}
                    </Form.Label>
                  </Form.Group>
                </Form.Row>
                <div id="submitArea" className="submit-area">
                  <Form.Row className="submit-row">
                    <Button
                      id="moveToLoginButton"
                      name="moveToLoginButton"
                      data-testid="moveToLoginButton"
                      className="submit-button top-empty-space"
                      onClick={handleClose}
                    >
                      <span
                        id="moveToLoginButtonText"
                        data-testid="moveToLoginButtonText"
                        className="submit-button-text"
                      >
                        {t('member.label.findIdModal.button.moveToLoginPage')}
                      </span>
                    </Button>
                    <Button
                      id="resetPasswordButton"
                      name="resetPasswordButton"
                      data-testid="resetPasswordButton"
                      className="submit-button top-empty-space"
                      onClick={handleResetPage}
                    >
                      <span
                        id="resetPasswordButtonText"
                        data-testid="resetPasswordButtonText"
                        className="submit-button-text"
                      >
                        {t('member.label.findIdModal.button.resetPassword')}
                      </span>
                    </Button>
                  </Form.Row>
                </div>
              </div>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  const handleSubmit = async (values: FindId, actions: FormikHelpers<FindId>): Promise<void> => {
    const findIdRequest = {
      memberName: values.memberName,
      phoneNumber: values.phoneNumber.replace(/-/gi, ''),
      emailAddress: '',
      memberTypeCode: '',
      password: '',
      memberStateCode: '',
      certificationId: '',
      certificationSendAddress: '',
    };
    setLoading(true);
    const response = await memberRegistrationService.findId(findIdRequest);
    setLoading(false);
    if (response && response.statusCode === 'SUCCESS') {
      if (response.data !== undefined && response.data.length > 0) {
        const tempIdList = response.data.map((data: Member) => {
          return {
            emailAddress: data.emailAddress,
          };
        });
        setMembers(tempIdList);
        setShowIdInfo(false);
        actions.resetForm();
      } else if (response.data !== undefined && response.data.length === 0) {
        actions.setFieldError('memberName', t('member.message.findId.response.INVALID_ACCOUNT'));
        actions.setFieldError('phoneNumber', ' ');
      }
    } else if (
      response.successOrNot === 'N' &&
      response.statusCode === MemberRegistrationConstants.UNKNOWN_SERVER_ERROR_CODE
    ) {
      alert(t('member.message.backEnd.UE'));
    } else {
      alert(t('member.message.backEnd.' + response.statusCode));
    }
  };

  return (
    <div>
      <Formik
        data-testid="FindIdModalFormik"
        enableReinitialize={true}
        initialValues={initFindId}
        validationSchema={schema}
        render={modalBody}
        onSubmit={handleSubmit}
        isInitialValid={schema.isValidSync(initFindId)}
      />
    </div>
  );
};

export default FindIdModal;
