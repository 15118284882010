import { MemberChangeType } from './MemberChangeType.enum';

export interface MemberChange {
  memberId: number;
  memberTypeCode: string;
  memberName: string;
  emailAddress: string;
  phoneNumber: string;
  companyName: string;
  positionName: string;
  notebookSerialNo: string;
  thirdTermsAgreeYn: string;
  termsSaveDatetime: string;
  departmentName: string;
  companyCode: string;
  mainWorkplaceId: string;
  delegateMemberId: string;
  delegateMemberName: string;
  delegateMemberPositionName: string;
}

export interface MemberInfoChange {
  memberChangeType: MemberChangeType;
  currentValue?: string | null;
  changeValue: string;
}

export interface CompanyInfoChange {
  memberChangeType: MemberChangeType;
  companyName: string | null;
  positionName: string | null;
}

export interface AgreeInfoChange {
  termsId: number;
  termsAgreeYn: string;
}

export interface SelectItem {
  value: string;
  label: string;
}

export enum TOAST_ID_TYPE {
  SUCCESS = 'changeSuccessInformToast',
  FAIL = 'changeFailInformToast',
}

export enum TOAST_CLASS_TYPE {
  PASSWORD = 'password-toast',
  PHONE = 'phone-toast',
  NAME = 'name-toast',
  COMPANY = 'company-toast',
  NOTEBOOK = 'notebook-toast',
  TERM = 'term-toast',
  AGREE = 'agree-toast',
  DISAGREE = 'disagree-toast',
  WORKPLACE = 'workplace-toast',
  DELEGATE = 'delegate-toase',
}

export const CHECK_ICON = 'check_circle';
export const FAIL_ICON = 'cancel';
