import React, { ReactElement, useState } from 'react';
import { Modal, Container, Row, Col, ButtonToolbar, Button, Image, Accordion, Card } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { Visitor, Reservation, ImportedGoods } from '../model/ReservationTypes';
import { User } from '../model/User';

import { ReservationBottomVisitor } from './ReservationBottomVisitor';

import { reservationDetailUtil } from '../utils/ReservationDetailUtil';
import './ReservationCompleteModal.scss';

import { ApprovalStatusEnum } from '../model/ReservationConstants';
import { CommonCode } from '../model/Code';
import CommonCodeService from '../services/CommonCodeService';
import { VisitorGoodsDetail } from './VisitorGoodsDetail';

export interface ReservationCompleteModalI {
  reservation: Reservation;
  sessionUser: User;
  show: boolean;
  onHide: () => void;
  commonCodeMap: Map<string, CommonCode[]> | undefined;
}

export const ReservationCompleteModal: React.FC<ReservationCompleteModalI> = ({
  reservation,
  sessionUser,
  show,
  onHide,
  commonCodeMap,
}: ReservationCompleteModalI): ReactElement => {
  const { t } = useTranslation();

  const getStatusIcon = (status: string): ReactElement => {
    let ret;
    switch (status) {
      case ApprovalStatusEnum.IP016001:
        break;
      case ApprovalStatusEnum.IP016002:
        ret = 'more_horiz';
        break;
      case ApprovalStatusEnum.IP016003:
        ret = 'done';
        break;
      case ApprovalStatusEnum.IP016004:
        ret = 'warning';
        break;
      case ApprovalStatusEnum.IP016005:
        break;
    }
    return <i className="material-icons pointer">{ret}</i>;
  };

  const getStatusStyle = (status: string): string => {
    let ret = '';
    switch (status) {
      case ApprovalStatusEnum.IP016001:
        ret = 'top-status-val bold temp-save';
        break;
      case ApprovalStatusEnum.IP016002:
        ret = 'top-status-val bold';
        break;
      case ApprovalStatusEnum.IP016003:
        ret = 'top-status-val bold approve-done';
        break;
      case ApprovalStatusEnum.IP016004:
        ret = 'top-status-val bold reject';
        break;
      case ApprovalStatusEnum.IP016005:
        ret = 'top-status-val bold cancel';
        break;
    }
    return ret;
  };

  const getCodeName = (groupCodeId: string, codeId: string) => {
    let codeName = '';
    if (commonCodeMap) {
      const codeList = commonCodeMap.get(groupCodeId);
      if (codeList && codeList.length > 0) {
        const code = codeList.find(code => code.codeId === codeId);
        codeName = code ? code.codeName : '';
      }
    }
    return codeName;
  };

  const [otherCollapsed, setOtherCollapsed] = useState('init');

  const closeOtherToggle = (presentToggle: string) => {
    setOtherCollapsed(presentToggle);
  };

  const getCommonCode = (groupCodeId: string) => {
    if (commonCodeMap) {
      return commonCodeMap.get(groupCodeId);
    }
  };

  const CheckNoMem = () => {
    sessionUser.memberTypeCode === 'NO_MEM'
      ? (() => {
          sessionStorage.clear();
          window.location.reload();
        })()
      : onHide();
  };

  return (
    <Modal
      data-testid="reservation-complete-modal"
      onHide={CheckNoMem}
      centered={true}
      show={show}
      className="modal-z-index reservation-complete-modal"
      onExited={() => {
        window.location.reload();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="visitToTargetContainer">
          <Row>
            <Col>
              <span className="complete-title">
                {t(reservationDetailUtil.getApproveModalTitle(reservation.status as ApprovalStatusEnum))}
              </span>
            </Col>
          </Row>
          <Row>
            <Col></Col>
          </Row>
          <Row className="top-title-row">
            <Col>
              <span className="complete-top-title title">
                {t('reservation.label.ReservationCompleteModal.body.top.title')}
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <Container className="center table">
                <Row>
                  <Col className="left-label">
                    <span className="complete-top-location">
                      {t('reservation.label.ReservationCompleteModal.body.top.workplace')}
                    </span>
                  </Col>
                  <Col>
                    <span className="complete-top-companyName-val">
                      {reservation && reservation.workplace && reservation.workplace.groupCompanyName}{' '}
                    </span>
                    <span className="complete-top-location-val">
                      {reservation && reservation.workplace && reservation.workplace.workplace}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col className="left-label">
                    <span className="complete-top-location">
                      {t('reservation.label.ReservationCompleteModal.body.top.visitToPerson')}
                    </span>
                  </Col>
                  <Col>
                    <span className="complete-top-name bold">
                      {reservation && reservation.visitTo && reservation.visitTo.name}{' '}
                      {reservation && reservation.visitTo && reservation.visitTo.position}
                    </span>
                    <span className="complete-top-slush"> / </span>
                    <span className="complete-top-dept">
                      {reservation && reservation.visitTo && reservation.visitTo.companyName}{' '}
                      {reservation && reservation.visitTo && reservation.visitTo.dept}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col className="left-label">
                    <span className="complete-top-purpose">
                      {t('reservation.label.ReservationCompleteModal.body.top.purpose')}
                    </span>
                  </Col>
                  <Col>
                    <span className="complete-top-purpose-val">
                      {reservation &&
                        reservation.purpose &&
                        getCodeName(CommonCodeService.VISIT_PURPOSE_CODE, reservation.purpose)}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col className="left-label">
                    <span className="complete-top-status">
                      {t('reservation.label.ReservationCompleteModal.body.top.status')}
                    </span>
                  </Col>
                  <Col>
                    <span
                      id="complete-top-status-val"
                      data-testid="complete-top-status-val"
                      className={reservation && reservation.status && getStatusStyle(reservation.status)}
                    >
                      {reservation && reservation.status && getStatusIcon(reservation.status)}
                      {t(
                        reservationDetailUtil.getStatusDesc(
                          reservation.status as ApprovalStatusEnum,
                          JSON.stringify(reservation.workplace)
                        )
                      )}
                    </span>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        <div className="divBgGray">
          <Container className="visitorsContainer">
            <Row className="RMB15 visitor-title-row">
              <Col>
                <span className="complete-visitor-title title">
                  {t('reservation.label.ReservationCompleteModal.body.buttom.title')}
                </span>
                <span className="title">(</span>
                <span className="title complete-visitor-title-val">
                  {reservation.visitors ? reservation.visitors.length : 0}
                </span>
                <span className="title">)</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <div key="visitorList" className="visitorList">
                  <Accordion>
                    {sessionUser &&
                      reservation.visitors &&
                      reservation.visitors.map((eachVisitor: Visitor, i) => {
                        const visitor: Visitor = {
                          id: eachVisitor.id,
                          type: eachVisitor.type,
                          name: eachVisitor.name,
                          employeeNumber: eachVisitor.employeeNumber,
                          companyName: eachVisitor.companyName || '',
                          dept: eachVisitor.dept || '',
                          position: eachVisitor.position || '',
                          applyMemberMobilePhone: eachVisitor.applyMemberMobilePhone,
                          email: eachVisitor.email,
                          applyMemberCarNumber: eachVisitor.applyMemberCarNumber,
                          fromVisitPeriod: eachVisitor.fromVisitPeriod,
                          toVisitPeriod: eachVisitor.toVisitPeriod,
                          importedGoodsList: eachVisitor.importedGoodsList || [],
                        };

                        return (
                          <div key={'visitorListDiv' + '_' + i}>
                            <div key={'visitorDiv' + '_' + i}>
                              <ReservationBottomVisitor
                                className="visitorDiv"
                                closeOtherToggle={closeOtherToggle}
                                otherCollapsed={otherCollapsed}
                                {...{
                                  visitor: visitor,
                                  fromVisitPeriod: reservation.fromVisitPeriod,
                                  toVisitPeriod: reservation.toVisitPeriod,
                                  sessionUser: sessionUser,
                                  pageType: 'showDetail',
                                  eventKey: 'toggle_' + i,
                                }}
                              />
                            </div>
                            <Accordion.Collapse eventKey={'toggle_' + i}>
                              <div className="detailsDiv">
                                {reservation.workplace && reservation.workplace.parkingAbleYesorno == 'Y' && (
                                  <div id="parkingInfo">
                                    <span>{t('reservation.label.VisitorGoodsDetail.body.car.title')}</span>
                                    <Card>
                                      <Card.Body>
                                        {visitor.applyMemberCarNumber ? (
                                          <span className="carInfo">{visitor.applyMemberCarNumber}</span>
                                        ) : (
                                          <span className="disabled">
                                            {t('reservation.label.VisitorGoodsDetail.body.car.empty')}
                                          </span>
                                        )}
                                      </Card.Body>
                                    </Card>
                                    <br />
                                  </div>
                                )}
                                {visitor.importedGoodsList && visitor.importedGoodsList.length > 0 ? (
                                  <span>{t('reservation.label.VisitorGoodsDetail.body.importedGoods.title')}</span>
                                ) : (
                                  <div id="importedGoodsDiv">
                                    <span>{t('reservation.label.VisitorGoodsDetail.body.importedGoods.title')}</span>
                                    <Card>
                                      <Card.Body>
                                        <span className="disabled">
                                          {t('reservation.label.VisitorGoodsDetail.body.importedGoods.empty')}
                                        </span>
                                      </Card.Body>
                                    </Card>
                                  </div>
                                )}
                                {visitor.importedGoodsList &&
                                  visitor.importedGoodsList.map((product: ImportedGoods, i) => {
                                    return (
                                      <div key={'goodsDiv' + visitor.id + '_' + i} className="goodsDiv">
                                        <VisitorGoodsDetail
                                          className="goodsDiv"
                                          getCommonCode={getCommonCode}
                                          {...{
                                            product: product,
                                            carNumber: visitor.applyMemberCarNumber || '',
                                            index: i,
                                          }}
                                        />
                                        <br />
                                      </div>
                                    );
                                  })}
                              </div>
                            </Accordion.Collapse>
                          </div>
                        );
                      })}
                  </Accordion>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <ButtonToolbar>
          <Button name="confirm" data-testid="confirm-button" variant="primary" onClick={CheckNoMem}>
            <span>{t('reservation.label.button.ok')}</span>
          </Button>
        </ButtonToolbar>
      </Modal.Body>
    </Modal>
  );
};

export default ReservationCompleteModal;
