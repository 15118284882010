import React, { ReactElement, useState, useEffect } from 'react';
import { Form, Badge, OverlayTrigger, Tooltip, useAccordionToggle, Accordion, Col } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { Visitor } from '../model/ReservationTypes';
import { User } from '../model/User';
import { reservationDetailUtil } from '../utils/ReservationDetailUtil';
import moment from 'moment';
import {
  UserTypeEnum,
  UserTypeEnumForClass,
  ImportedGoodsCodeEnum,
  MemberTypeCode,
  DateFormat,
} from '../model/ReservationConstants';
import './ReservationVisitorList.scss';
import arrowUp from '../../../icons/ico_arrow_up.svg';
import arrowDown from '../../../icons/ico_arrow_down.svg';
import { event } from 'jquery';
import ReservationService from '../services/ReservationService';

export interface VisitorInterface {
  visitor: Visitor;
  fromVisitPeriod: string;
  toVisitPeriod: string;
  sessionUser: User;
  deleteVisitor?: Function;
  sessionUserDisabled?: boolean;
  addUser?: Function;
  index?: any;
  disabled?: boolean;
  handleVisitorInfoModal?: Function;
  infoMessage?: string;
  pageType?: string;
  eventKey: any;
  closeOtherToggle: Function;
  otherCollapsed: string;
  visitorModalShow?: Function;
}

export const ReservationVisitorList: React.FC<any> = (props: VisitorInterface): ReactElement => {
  const visitor = props.visitor;
  const indexKey = props.index;
  const [collapsed, setCollapsed] = useState(true);
  const otherCollapsed = props.otherCollapsed;
  const [bgColor, setBgColor] = useState<string>('bgPink');
  const reservationService = new ReservationService();

  useEffect(() => {
    if (!otherCollapsed.includes('init') && !otherCollapsed.includes(props.eventKey)) {
      setCollapsed(true);
    }
  }, [otherCollapsed, props.eventKey]);

  useEffect(() => {
    if (visitor.applyMemberVisitYesorno === 'N') setBgColor('bgPink');
    if (visitor.applyMemberVisitYesorno === 'Y') setBgColor('bgGreen');
  }, []);

  const dummyFun = () => {
    return false;
  };

  const visitorModalShow = props.visitorModalShow ? props.visitorModalShow : dummyFun;

  const { t } = useTranslation();

  const getDiffClass = (allStart: string, allEnd: string, start: string, end: string): string => {
    let result = 'bgGray';
    if (allStart !== start || allEnd !== end) {
      result = 'bgPink';
    }
    return result;
  };

  const getDateText = (start: string, end: string): string => {
    let result = '';
    const startStr = moment(start).format(DateFormat.MONTH_AND_DAY_DISP);
    const endStr = moment(end).format(DateFormat.MONTH_AND_DAY_DISP);
    result = start === end ? startStr : startStr + ' ~ ' + endStr;
    return result;
  };

  const memberTypeInfo = reservationDetailUtil.getMemberTypeInfo(visitor, props.sessionUser);
  const getVisitorBadge = () => {
    return (
      memberTypeInfo.type !== MemberTypeCode.EMP &&
      memberTypeInfo.type !== MemberTypeCode.MEM && (
        <Form.Label className="mr-1" data-testid="visitorType">
          <Badge className={memberTypeInfo.class}>{t(memberTypeInfo.desc)}</Badge>
        </Form.Label>
      )
    );
  };

  const getGoodsInfo = (): string[] => {
    const goodsInfo: string[] = [];

    if (props.visitor.importedGoodsList && props.visitor.importedGoodsList.length > 0) {
      let laptopCnt = 0;
      let othersCnt = 0;
      props.visitor.importedGoodsList.map(item => {
        if (item.importedGoodsCode === ImportedGoodsCodeEnum.NOTEBOOK) {
          laptopCnt += Number(item.importedGoodsCounts);
        } else {
          othersCnt += Number(item.importedGoodsCounts);
        }
      });

      laptopCnt > 0 &&
        goodsInfo.push(t('reservation.label.ReservationBottomVisitor.body.importedGoods.laptop') + laptopCnt);

      othersCnt > 0 &&
        goodsInfo.push(t('reservation.label.ReservationBottomVisitor.body.importedGoods.others') + othersCnt);
    }
    return goodsInfo;
  };

  const checkVisitToggle = async e => {
    e.stopPropagation();
    e.preventDefault();
    const params = {
      visitRequestId: visitor.visitRequestId || '',
      applyMemberSequence: visitor.applyMemberSequence || '',
      applyMemberVisitYesorno: visitor.applyMemberVisitYesorno
        ? visitor.applyMemberVisitYesorno === 'N'
          ? 'Y'
          : 'N'
        : '',
    };
    const result = await reservationService.updateApplyMemberVisitState(params);
    if (result) {
      visitorModalShow(true);
      setBgColor(bgColor === 'bgGreen' ? 'bgPink' : 'bgGreen');
    } else {
      visitorModalShow(false);
    }
  };

  const ReturnToggleResult = (toggleProps: VisitorInterface) => {
    const eventKey = toggleProps.eventKey;
    const decoratedOnClick = useAccordionToggle(eventKey, () => {
      setCollapsed(!collapsed);
      toggleProps.closeOtherToggle(eventKey);
    });
    return (
      <Form
        onClick={decoratedOnClick}
        className={
          'visitorInfo ' +
          (memberTypeInfo.class === UserTypeEnumForClass.ME && toggleProps.sessionUserDisabled ? 'disabled ' : '') +
          memberTypeInfo.class +
          (toggleProps.visitor.importedGoodsList.length == 0 &&
          toggleProps.visitor.applyMemberCarNumber &&
          toggleProps.visitor.applyMemberCarNumber.length == 0
            ? ' nonInfo '
            : ' infoDiv ')
        }
      >
        <Form.Group controlId="visitorInfo">
          {getVisitorBadge()}

          <Form.Label className="mr-1 visitorBaseInfo">
            <strong>{toggleProps.visitor.name}</strong>&nbsp;
            <strong>{toggleProps.visitor.position}</strong>&nbsp;
            {((toggleProps.visitor.companyName && toggleProps.visitor.companyName.length > 0) ||
              (toggleProps.visitor.dept && toggleProps.visitor.dept.length > 0)) &&
              '/ '}
            {toggleProps.visitor.companyName}&nbsp;
            {toggleProps.visitor.dept}&nbsp;
          </Form.Label>

          <div data-testid="items" className="inline float-right">
            {!toggleProps.visitor.applyMemberCarNumber &&
              toggleProps.visitor.importedGoodsList.length === 0 &&
              toggleProps.infoMessage && <span className="inputInfo">{toggleProps.infoMessage}</span>}
            {/* <Badge className={`badge mr-1 ${bgColor}`} onClick={(e) => checkVisitToggle(e)}>
              {bgColor === 'bgGreen' ? '방문' : '미방문'}
            </Badge> */}
            {toggleProps.visitor.applyMemberCarNumber && (
              <Badge className={'badge mr-1 bgGray'}>{t('reservation.label.ReservationBottomVisitor.body.car')}</Badge>
            )}
            {toggleProps.visitor.applyMemberQrcodeYn == 'Y' && (
              <Badge className={'badge mr-1 bgGray'}>
                {t('reservation.label.ReservationBottomVisitor.body.qrcodeYn')}
              </Badge>
            )}
            {getGoodsInfo().map((goods: string, i) => {
              if (goods === '') return null;
              return (
                <Badge className={'badge mr-1 bgGray'} key={i}>
                  {goods}
                </Badge>
              );
            })}
            {toggleProps.visitor.fromVisitPeriod && toggleProps.visitor.toVisitPeriod && (
              <Badge
                className={
                  'badge mr-1 ' +
                  getDiffClass(
                    toggleProps.fromVisitPeriod,
                    toggleProps.toVisitPeriod,
                    toggleProps.visitor.fromVisitPeriod,
                    toggleProps.visitor.toVisitPeriod
                  )
                }
              >
                {getDateText(toggleProps.visitor.fromVisitPeriod, toggleProps.visitor.toVisitPeriod)}
              </Badge>
            )}

            <button className="arrow_button" type="button">
              {!collapsed ? <img src={arrowUp} className={'arrow'} /> : <img src={arrowDown} className={'arrow'} />}
            </button>

            {memberTypeInfo.class === UserTypeEnumForClass.ME && toggleProps.sessionUserDisabled ? (
              <OverlayTrigger
                key={'top'}
                placement={'top'}
                overlay={
                  <Tooltip id={'tooltip-top'}>
                    {t('reservation.label.ReservationBottomVistor.visitor.button.addTooltip')}
                  </Tooltip>
                }
              >
                <i
                  className="material-icons pointer addUser"
                  data-testid="addUser"
                  onClick={() => {
                    toggleProps.addUser && toggleProps.addUser(toggleProps.sessionUser);
                  }}
                >
                  refresh
                </i>
              </OverlayTrigger>
            ) : (
              toggleProps.deleteVisitor &&
              toggleProps.sessionUser.type !== UserTypeEnum.NONEMEMBER && (
                <i
                  className="material-icons pointer"
                  data-testid="deleteVisitor"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    e.preventDefault();
                    toggleProps.deleteVisitor && toggleProps.deleteVisitor(indexKey);
                  }}
                >
                  close
                </i>
              )
            )}
          </div>
        </Form.Group>
      </Form>
    );
  };

  return <div>{ReturnToggleResult(props)}</div>;
};

export default ReservationVisitorList;
