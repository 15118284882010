import { Service } from '../../../service/model/Service';
import BaseService from '../../../service/BaseService';

export default class InterfaceService extends BaseService {
  async getIdpUserId(
    companyCode: string,
    workplaceId: string,
    userName: string,
    employeeNumber: string,
    workingFormCode: string
  ): Promise<any> {
    const serviceName = Service.VISIT;
    const method = 'GET';

    const queryParam: any = {
      companyCode: companyCode,
      workplaceId: workplaceId,
      userName: userName,
      employeeNumber: employeeNumber,
      workingFormCode: workingFormCode,
    };

    const url = '/v1/idpretrieveuser' + this.getQueryStringFormat(queryParam);

    let response;
    try {
      response = await this.fnRest(method, url, null, serviceName);
    } catch (error) {
      response = error;
    }

    return response.data?.idpUserId ? response.data.idpUserId : null;
  }
}
