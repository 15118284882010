import React, { ReactElement } from 'react';
import { Modal, Button } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

import { Reservation } from './../model/ReservationTypes';
import './CheckModal.scss';

export interface CheckModalProps {
  show: boolean;
  onCheck: (values: Reservation) => Promise<void>;
  handleClose: () => void;
  formState: Reservation;
}

export const CheckModal: React.FC<CheckModalProps> = (props: CheckModalProps): ReactElement => {
  const { t } = useTranslation();
  const onCheck = (formState: Reservation): Promise<void> => {
    props.handleClose();
    return props.onCheck(formState);
  };
  return (
    <Modal
      id="checkModal"
      data-testid="checkModal"
      show={props.show}
      onHide={props.handleClose}
      className="ConfirmModal"
      enforceFocus={false}
      centered
    >
      <Modal.Body>
        <p id="checkMessage" data-testid="checkMessage">
          {t('reservation.label.CheckModal.checkSubmit')}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button id="nButton" data-testid="nButton" variant="dark" onClick={props.handleClose}>
          {t('reservation.label.ConfirmModal.button.cancel')}
        </Button>
        <Button id="yButton" data-testid="yButton" variant="primary" onClick={e => onCheck(props.formState)}>
          <i className="material-icons md-24">check</i>
          {t('reservation.label.ConfirmModal.button.confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CheckModal;
