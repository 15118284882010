import React, { useState, useContext } from 'react';
import './EmployeeMemberRegistrationForm.scss';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik, Field, FormikErrors, FormikValues } from 'formik';
import SubmitStatus from '../../model/SubmitStatus';
import MemberRegistrationService from '../../services/MemberRegistrationService';
import MemberRegistrationConstants, { UserTypeParamEnum } from '../../model/MemberRegistrationConstants';
import StepInformation from '../../model/StepInformation';
import MemberRegistrationSubmitStatus from '../../model/MemberRegistrationSubmitStatus';
import EmployeeCertification from '../../model/EmployeeCertification';
import { AuthContext } from '../../../../App';
import BaseService from '../../../../service/BaseService';
import { Service } from '../../../../service/model/Service';
import { ActionType } from '../../../../reducer/actions';
import { useHistory } from 'react-router-dom';

export interface EmployeeMemberRegistrationFormProps {
  stepParams: StepInformation;
  setStepParams: (stepParams: StepInformation) => void;
  memberRegistrationService: MemberRegistrationService;
}
let emailErrorString = '';
export const EmployeeMemberRegistrationForm: React.FC<EmployeeMemberRegistrationFormProps> = (
  props: EmployeeMemberRegistrationFormProps
) => {
  const { state, dispatch } = useContext(AuthContext);
  const { t } = useTranslation();
  const [emailCheckValidationFlag, setEmailCheckValidationFlag] = useState(false);
  const [emailCheckVisible, setEmailCheckVisible] = useState(false);
  const [emailErrorStatus, setEmailErrorStatus] = useState(false);

  const setLoading = (_isLoading: boolean) => {
    dispatch({ type: ActionType.LOADING, loading: { isLoading: _isLoading } });
  };
  const schema = yup.object({
    password: yup
      .string()
      .required(t('member.message.frontEnd.password.required'))
      .min(8, t('member.message.frontEnd.password.common'))
      .max(32, t('member.message.frontEnd.password.common'))
      .matches(MemberRegistrationConstants.REGEXP_PASSWORD, t('member.message.frontEnd.password.common'))
      .test('password-contains-email-id', t('member.message.frontEnd.password.containsEmailId'), function(
        value: string
      ) {
        const email = this.resolve(yup.ref('emailAddress'));
        const emailId = email ? email.split('@')[0] : undefined;
        return value && value.includes(emailId) ? false : true;
      }),
    passwordConfirm: yup
      .string()
      .required(t('member.message.frontEnd.password.common'))
      .oneOf([yup.ref('password'), null], t('member.message.frontEnd.password.common')),
    employeeNumber: yup.string().required(t('member.message.frontEnd.employeeNumber.required')),
  });

  const checkEmailValidation = async (values: string, setFieldError: any): Promise<void> => {
    const emailAddress = values;
    const emailRule = MemberRegistrationConstants.REGEXP_EMAIL;
    if (emailRule.test(emailAddress)) {
      setEmailCheckValidationFlag(false);
      setEmailCheckVisible(false);
      setEmailErrorStatus(false);
      setFieldError('emailAddress', undefined);
      let submitStatus: SubmitStatus = {
        successOrNot: 'N',
        statusCode: '',
      };
      submitStatus = await props.memberRegistrationService.checkExistFlagEmailAddress(
        emailAddress,
        MemberRegistrationConstants.EMPLOYEE_MEMBER_TYPE_CODE
      );
      if (submitStatus.successOrNot === 'Y') {
        setEmailCheckValidationFlag(true);
        setEmailCheckVisible(true);
        setEmailErrorStatus(false);
        setFieldError('emailAddress', undefined);
      } else if (submitStatus.statusCode === MemberRegistrationConstants.UNKNOWN_SERVER_ERROR_CODE) {
        setEmailCheckValidationFlag(false);
        setEmailCheckVisible(false);
        setEmailErrorStatus(false);
        alert(t('member.message.backEnd.UE'));
      } else {
        setEmailCheckValidationFlag(false);
        setEmailCheckVisible(false);
        setEmailErrorStatus(true);
        emailErrorString = t('member.message.backEnd.' + submitStatus.statusCode);
        setFieldError('emailAddress', emailErrorString);
      }
    } else {
      setFieldError(t('member.message.frontEnd.emailAddress.common'));
    }
  };
  const validateEmail = (values: string): FormikErrors<FormikValues> => {
    const emailRule = MemberRegistrationConstants.REGEXP_EMAIL;
    const error: any = {};
    if (!emailRule.test(values)) {
      setEmailCheckVisible(false);
      setEmailCheckValidationFlag(false);
      setEmailErrorStatus(false);
      error.email = t('member.message.frontEnd.emailAddress.common');
    } else {
      if (!emailCheckValidationFlag && emailErrorStatus) {
        error.email = emailErrorString;
        setEmailCheckVisible(false);
      }
    }
    return error.email;
  };
  const history = useHistory();
  return (
    <div id="MainInformationForm">
      <Formik
        initialValues={{
          emailAddress: state.ssoUserInfo?.emailAddress ? state.ssoUserInfo?.emailAddress : '',
          password: '',
          passwordConfirm: '',
          employeeNumber: state.ssoUserInfo?.employeeNumber ? state.ssoUserInfo?.employeeNumber : '',
          memberTypeCode: MemberRegistrationConstants.EMPLOYEE_MEMBER_TYPE_CODE,
          termsApplyGroupCode: props.stepParams.termAgreeInfo?.termsApplyGroupCode,
          termsAgree: props.stepParams.termAgreeInfo?.termsAgree,
        }}
        onSubmit={async values => {
          const employeeCertification: EmployeeCertification = values;
          if (props.stepParams.memberType === UserTypeParamEnum.SSO_EMPLOYEE) {
            employeeCertification.ssoUseYn = 'Y';
          } else {
            employeeCertification.ssoUseYn = 'N';
          }
          setLoading(true);
          const submitStatus: MemberRegistrationSubmitStatus = await props.memberRegistrationService.certificateEmployeeMember(
            employeeCertification
          );
          setLoading(false);
          if (submitStatus.successOrNot === 'Y') {
            if (props.stepParams.memberType === UserTypeParamEnum.SSO_EMPLOYEE) {
              const baseService = new BaseService();
              const tenantId = state.ssoUserInfo?.tenantId;
              const ssoRedirectUrl = baseService.fnCheckServiceUrl('/v1/account/sso/' + tenantId, Service.MEMBER);
              window.location.assign(ssoRedirectUrl);
            } else {
              const stepParams: StepInformation = {
                currentStep: 3,
                emailAddress: values.emailAddress,
                memberId: submitStatus.data!.memberId,
              };
              props.setStepParams(stepParams);
              history.push(history.location.pathname + history.location.search + '#step3');
            }
          } else if (
            submitStatus.successOrNot === 'N' &&
            submitStatus.statusCode === MemberRegistrationConstants.UNKNOWN_SERVER_ERROR_CODE
          ) {
            alert(t('member.message.backEnd.UE'));
          } else {
            if (
              submitStatus.statusCode === 'NO.SEARCH.EMPLOYEE' &&
              props.stepParams.memberType === UserTypeParamEnum.NON_SSO_EMPLOYEE
            ) {
              alert(t('member.message.backEnd.NOT.MATCH.EMPLOYEENUMBER'));
            } else {
              alert(t('member.message.backEnd.' + submitStatus.statusCode));
            }
          }
        }}
        validationSchema={schema}
      >
        {({ values, errors, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldError, isValid }): any => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Row>
              <Form.Group>
                <Form.Label>{t('member.label.employeeMemberRegistrationForm.emailAddress.label')}</Form.Label>
                <br />
                <Field
                  id="emailAddress"
                  name="emailAddress"
                  data-testid="emailAddress"
                  className="formik-field"
                  required
                  type="text"
                  placeholder={t('member.label.employeeMemberRegistrationForm.emailAddress.placeholder')}
                  onChange={handleChange}
                  onBlur={async (): Promise<void> => {
                    await checkEmailValidation(values.emailAddress, setFieldError);
                  }}
                  validate={validateEmail}
                  disabled={!!state.ssoUserInfo?.emailAddress}
                />
                {emailCheckVisible && (
                  <div className="checked-area">
                    <i id="email-checked" data-testid="email-checked" className="material-icons">
                      check
                    </i>
                  </div>
                )}
                {errors.emailAddress && (
                  <div id="emailAddressFeedback" data-testid="emailAddressFeedback" className="input-feedback">
                    {errors.emailAddress}
                  </div>
                )}
              </Form.Group>
            </Form.Row>
            <Form.Row className="top-empty-space">
              <Form.Group>
                <Form.Label>{t('member.label.employeeMemberRegistrationForm.employeeNumber.label')}</Form.Label>
                <br />
                <Field
                  id="employeeNumber"
                  name="employeeNumber"
                  data-testid="employeeNumber"
                  className="formik-field"
                  required
                  type="text"
                  placeholder={t('member.label.employeeMemberRegistrationForm.employeeNumber.placeholder')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={!!state.ssoUserInfo?.employeeNumber}
                />
                {errors.employeeNumber && (
                  <div id="employeeNumberFeedback" data-testid="employeeNumberFeedback" className="input-feedback">
                    {errors.employeeNumber}
                  </div>
                )}
                {/* <Form.Control
                  id="employeeNumber"
                  name="employeeNumber"
                  data-testid="employeeNumber"
                  required
                  type="text"
                  placeholder={t('member.label.employeeMemberRegistrationForm.employeeNumber.placeholder')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.employeeNumber}
                  //  value={!!state.ssoUserInfo?.employeeNumber ? state.ssoUserInfo?.employeeNumber : ''}
                  disabled={!!state.ssoUserInfo?.employeeNumber}
                />
                <Form.Control.Feedback id="employeeNumberFeedback" data-testid="employeeNumberFeedback" type="invalid">
                  {errors.employeeNumber}
                </Form.Control.Feedback> */}
              </Form.Group>
            </Form.Row>
            <Form.Row className="top-empty-space">
              <Form.Group>
                <Form.Label>{t('member.label.employeeMemberRegistrationForm.password.label')}</Form.Label>
                <Form.Control
                  id="password"
                  name="password"
                  data-testid="password"
                  required
                  type="password"
                  placeholder={t('member.label.employeeMemberRegistrationForm.password.placeholder1')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.password}
                />
                <Form.Control
                  id="passwordConfirm"
                  name="passwordConfirm"
                  data-testid="passwordConfirm"
                  required
                  type="password"
                  placeholder={t('member.label.employeeMemberRegistrationForm.password.placeholder2')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.passwordConfirm}
                />
                <Form.Control.Feedback id="passwordFeedback" data-testid="passwordFeedback" type="invalid">
                  {errors.password || errors.passwordConfirm}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Button
              id="registrationButton"
              name="registrationButton"
              data-testid="registrationButton"
              type="submit"
              disabled={
                !!state.ssoUserInfo?.emailAddress && !!state.ssoUserInfo?.employeeNumber
                  ? isSubmitting || !isValid || !values.password
                  : isSubmitting || !isValid || !emailCheckValidationFlag || emailErrorStatus
              }
              className="submit-button top-empty-space"
            >
              {t('member.label.employeeMemberRegistrationForm.button.registration')}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default EmployeeMemberRegistrationForm;
