import React, { ReactElement, useState, SyntheticEvent, useEffect } from 'react';
import { Form, Card, InputGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { useTranslation } from 'react-i18next';
import { ImportedGoods } from '../model/ReservationTypes';
import { CommonCode } from '../model/Code';

import { PortSealExceptCodeEnum, ImportedGoodsCodeEnum } from '../model/ReservationConstants';
import './ImportedNotebookForm.scss';
import TextareaAutosize from 'react-textarea-autosize';
import { setRawCookie } from 'react-cookies';
export interface ImportedNotebookFormProps {
  importedGoods: ImportedGoods;
  portSealExceptYesorno: string;
  wifiAbleYesorno: string;
  macAddressYesorno: string;
  onClose: Function;
  errors?: any;
  touched?: any;
  onImportedGoodsChange?: Function;
  portSealExceptCodeList?: CommonCode[];
  importedGoodsPurposeCodeList?: CommonCode[];
  notebookSerialNo?: string;
}

export const ImportedNotebookForm: React.FC<ImportedNotebookFormProps> = (
  props: ImportedNotebookFormProps
): ReactElement => {
  const { t } = useTranslation();
  const importedGoods = { ...props.importedGoods };
  const [importedGoodsDescription, setImportedGoodsDescription] = useState<string>(
    importedGoods.importedGoodsDescription || ''
  );
  const [portSealExceptDescription, setPortSealExceptDescription] = useState<string>(
    importedGoods.importedGoodsPortSealExceptDescription || ''
  );
  const [importedGoodsPurpose, setImportedGoodsPurpose] = useState<string>(importedGoods.importedGoodsPurpose);

  const [showPortSealExceptDropDown, setShowPortSealExceptDropDown] = useState<boolean>(false);
  const [portSealExceptUseYn, setPortSealExceptUseYn] = useState<boolean>(
    importedGoods.importedGoodsPortSealExceptYesOrNo === 'Y' ? true : false
  );
  const [serialNumber, setSerialNumber] = useState<string>(importedGoods.importedGoodsSerialNumber || '');
  const [goodsModelName, setGoodsModelName] = useState<string>(importedGoods.importedGoodsModelName || '');
  const [importedGoodsWifiYesOrNo, setImportedGoodsWifiYesOrNo] = useState<boolean>(
    importedGoods.importedGoodsWifiYesOrNo === 'Y' ? true : false
  );
  const [importedGoodsTechPreviewYesOrNo, setImportedGoodsTechPreviewYesOrNo] = useState<boolean>(
    importedGoods.importedGoodsTechPreviewYesOrNo === 'Y' ? true : false
  );
  const [importedGoodsWifiMacAddress, setImportedGoodsWifiMacAddress] = useState<string>(
    importedGoods.importedGoodsWifiMacAddress || ''
  );
  const [importedGoodsManagerSupportYesOrNo, setImportedGoodsManagerSupportYesOrNo] = useState<boolean>(
    importedGoods.importedGoodsManagerSupportYesOrNo === 'Y' ? true : false
  );

  const [clientCompanyName, setClientCompanyName] = useState<string>(
    importedGoods.importedGoodsClientCompanyName || ''
  );
  const [goodsFloor, setGoodsFloor] = useState<string>(importedGoods.importedGoodsFloor || '');

  const getCheckedCodeList = (): CommonCode[] => {
    const checkedPortSealExceptCodeList: CommonCode[] = [];
    props.portSealExceptCodeList &&
      props.portSealExceptCodeList.forEach(commonCode => {
        switch (commonCode.codeId) {
          case PortSealExceptCodeEnum.USB:
            importedGoods.importedGoodsPortSealExceptUsb === 'Y' &&
              checkedPortSealExceptCodeList.push({ codeId: commonCode.codeId, codeName: commonCode.codeName });
            break;
          case PortSealExceptCodeEnum.LAN:
            importedGoods.importedGoodsPortSealExceptLan === 'Y' &&
              checkedPortSealExceptCodeList.push({ codeId: commonCode.codeId, codeName: commonCode.codeName });
            break;
          case PortSealExceptCodeEnum.SD_CARD:
            importedGoods.importedGoodsPortSealExceptSdCard === 'Y' &&
              checkedPortSealExceptCodeList.push({ codeId: commonCode.codeId, codeName: commonCode.codeName });

            break;
          case PortSealExceptCodeEnum.CAMERA:
            importedGoods.importedGoodsPortSealExceptCamera === 'Y' &&
              checkedPortSealExceptCodeList.push({ codeId: commonCode.codeId, codeName: commonCode.codeName });
            break;
          case PortSealExceptCodeEnum.ETC:
            importedGoods.importedGoodsPortSealExceptEtc === 'Y' &&
              checkedPortSealExceptCodeList.push({ codeId: commonCode.codeId, codeName: commonCode.codeName });
            break;
          default:
            break;
        }
      });
    return checkedPortSealExceptCodeList;
  };

  const onClose = (): void => {
    props.onClose(importedGoods.id);
  };

  const [checkedPortSealExceptCodeList, setCheckedPortSealExceptCodeList] = useState<CommonCode[]>(
    getCheckedCodeList()
  );
  const checkedPortSealCodIdList = checkedPortSealExceptCodeList.map(commonCode => commonCode.codeId);
  const getPortSealExceptTitle = (): string => {
    let title = '';
    checkedPortSealExceptCodeList.forEach((commonCode, index) => {
      if (index === 0) {
        title += commonCode.codeName;
      } else {
        title += ', ' + commonCode.codeName;
      }
    });
    return title || t('reservation.label.ImportedNotebookForm.portSealExcept.select');
  };
  const getImportedGoodsPurposeName = (): string => {
    let goodsPurposeCode;
    if (props.importedGoodsPurposeCodeList) {
      goodsPurposeCode = props.importedGoodsPurposeCodeList.find(
        commonCode => commonCode.codeId === importedGoodsPurpose
      );
    }
    return goodsPurposeCode ? goodsPurposeCode.codeName : t('reservation.label.ImportedNotebookForm.purpose.select');
  };
  const onNotebookSerialNumberChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let notebookSn = event.target.value;
    if (notebookSn.length > 100) {
      notebookSn = notebookSn.substring(0, 100);
    }
    setSerialNumber(notebookSn);
    importedGoods.importedGoodsSerialNumber = notebookSn;
    props.onImportedGoodsChange && props.onImportedGoodsChange(importedGoods);
  };

  const onGoodsModelNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let modelName = event.target.value;
    if (modelName.length > 100) {
      modelName = modelName.substring(0, 100);
    }
    setGoodsModelName(modelName);
    importedGoods.importedGoodsModelName = modelName;
    props.onImportedGoodsChange && props.onImportedGoodsChange(importedGoods);
  };

  const onTechPreviewChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      setImportedGoodsTechPreviewYesOrNo(true);
      importedGoods.importedGoodsTechPreviewYesOrNo = 'Y';
      props.onImportedGoodsChange && props.onImportedGoodsChange(importedGoods);
    } else {
      setImportedGoodsTechPreviewYesOrNo(false);
      importedGoods.importedGoodsTechPreviewYesOrNo = 'N';
      props.onImportedGoodsChange && props.onImportedGoodsChange(importedGoods);
    }
  };

  const onManagerSupportChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      setImportedGoodsManagerSupportYesOrNo(true);
      importedGoods.importedGoodsManagerSupportYesOrNo = 'Y';
      props.onImportedGoodsChange && props.onImportedGoodsChange(importedGoods);
    } else {
      setImportedGoodsManagerSupportYesOrNo(false);
      importedGoods.importedGoodsManagerSupportYesOrNo = 'N';
      props.onImportedGoodsChange && props.onImportedGoodsChange(importedGoods);
    }
  };

  const onWifiAbleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      setImportedGoodsWifiYesOrNo(true);
      importedGoods.importedGoodsWifiYesOrNo = 'Y';
      props.onImportedGoodsChange && props.onImportedGoodsChange(importedGoods);
    } else {
      setImportedGoodsWifiYesOrNo(false);
      setImportedGoodsWifiMacAddress('');
      importedGoods.importedGoodsWifiMacAddress = '';
      importedGoods.importedGoodsWifiYesOrNo = 'N';
      props.onImportedGoodsChange && props.onImportedGoodsChange(importedGoods);
    }
  };

  const onMacAddressChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setImportedGoodsWifiMacAddress(event.target.value);
    importedGoods.importedGoodsWifiMacAddress = event.target.value;
    props.onImportedGoodsChange && props.onImportedGoodsChange(importedGoods);
  };

  const onImportedGoodsPurposeChange = (eventKey: any, event: Record<string, any>): void => {
    setImportedGoodsPurpose(eventKey);
    importedGoods.importedGoodsPurpose = eventKey;
    props.onImportedGoodsChange && props.onImportedGoodsChange(importedGoods);
  };
  const onPortSealExceptUseYnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      importedGoods.importedGoodsPortSealExceptYesOrNo = 'Y';
    } else {
      importedGoods.importedGoodsPortSealExceptYesOrNo = 'N';
      importedGoods.importedGoodsPortSealExceptUsb = 'N';
      importedGoods.importedGoodsPortSealExceptLan = 'N';
      importedGoods.importedGoodsPortSealExceptSdCard = 'N';
      importedGoods.importedGoodsPortSealExceptCamera = 'N';
      importedGoods.importedGoodsPortSealExceptEtc = 'N';
      importedGoods.importedGoodsPortSealExceptDescription = '';

      setCheckedPortSealExceptCodeList([]);
      setPortSealExceptDescription('');
    }
    setPortSealExceptUseYn(event.target.checked);
    props.onImportedGoodsChange && props.onImportedGoodsChange(importedGoods);
  };

  const onImportedGoodsDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    let description = event.target.value;
    if (description.length > 150) {
      description = description.substring(0, 150);
    }
    setImportedGoodsDescription(description);
    importedGoods.importedGoodsDescription = description;
    props.onImportedGoodsChange && props.onImportedGoodsChange(importedGoods);
  };

  const onPortSealExceptDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    let description = event.target.value;
    if (description.length > 100) {
      description = description.substring(0, 100);
    }
    setPortSealExceptDescription(description);
    importedGoods.importedGoodsPortSealExceptDescription = description;
    props.onImportedGoodsChange && props.onImportedGoodsChange(importedGoods);
  };
  const onSelectPortSealExcept = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const checkedCode = [...checkedPortSealExceptCodeList];
    const eventCode = event.target.value;
    const checked = event.target.checked;
    const value = checked ? 'Y' : 'N';
    switch (eventCode) {
      case PortSealExceptCodeEnum.USB:
        importedGoods.importedGoodsPortSealExceptUsb = value;
        break;
      case PortSealExceptCodeEnum.LAN:
        importedGoods.importedGoodsPortSealExceptLan = value;
        break;
      case PortSealExceptCodeEnum.SD_CARD:
        importedGoods.importedGoodsPortSealExceptSdCard = value;
        break;
      case PortSealExceptCodeEnum.CAMERA:
        importedGoods.importedGoodsPortSealExceptCamera = value;
        break;
      case PortSealExceptCodeEnum.ETC:
        importedGoods.importedGoodsPortSealExceptEtc = value;
        break;
      default:
        break;
    }
    if (checked) {
      checkedCode.push({ codeId: eventCode, codeName: event.target.name });
      setCheckedPortSealExceptCodeList(checkedCode);
    } else {
      setCheckedPortSealExceptCodeList(checkedCode.filter(commonCode => commonCode.codeId !== eventCode));
    }
    props.onImportedGoodsChange && props.onImportedGoodsChange(importedGoods);
  };

  const onClientCompanyNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let companyName = event.target.value;
    if (companyName.length > 100) {
      companyName = companyName.substring(0, 100);
    }
    setClientCompanyName(companyName);
    importedGoods.importedGoodsClientCompanyName = companyName;
    props.onImportedGoodsChange && props.onImportedGoodsChange(importedGoods);
  };

  const onGoodsFloorChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let floor = event.target.value;
    if (floor.length > 100) {
      floor = floor.substring(0, 100);
    }
    setGoodsFloor(floor);
    importedGoods.importedGoodsFloor = floor;
    props.onImportedGoodsChange && props.onImportedGoodsChange(importedGoods);
  };

  const onToggle = (
    isOpen: boolean,
    event: SyntheticEvent<Dropdown<'div'>, Event>,
    metadata: { source: 'select' | 'click' | 'rootClose' | 'keydown' }
  ): void => {
    if (metadata.source === 'click' || !metadata.source) {
      setShowPortSealExceptDropDown(isOpen);
    }
  };
  /* eslint-disable */
  useEffect(() => {
    if (!serialNumber && props.notebookSerialNo) {
      setSerialNumber(props.notebookSerialNo);
      importedGoods.importedGoodsSerialNumber = props.notebookSerialNo;
      props.onImportedGoodsChange && props.onImportedGoodsChange(importedGoods);
    }
  }, [props.notebookSerialNo]);

  /* eslint-enable */
  return (
    <Card id="importedNotebookForm" data-testid="importedNotebookForm" className="importedNotebookForm">
      <Card.Header>
        {importedGoods.importedGoodsName}
        <i id="closeIcon" data-testid="itemCloseIcon" className="material-icons pointer" onClick={onClose}>
          close
        </i>
      </Card.Header>
      <Card.Body>
        <InputGroup
          id="importedGoodsClientCompanyNameGroup"
          data-testid="importedGoodsClientCompanyNameGroup"
          className={
            props.errors &&
            props.touched &&
            props.errors.importedGoodsClientCompanyName &&
            props.touched.importedGoodsPurpose
              ? 'required error'
              : 'required'
          }
        >
          <InputGroup.Prepend>
            <InputGroup.Text>{t('reservation.label.ImportedCNSGoodsForm.clientCompanyName.name')}</InputGroup.Text>
          </InputGroup.Prepend>
          <i className="required"></i>
          <Form.Control
            id="clientCompanyName"
            data-testid="clientCompanyName"
            aria-label="clientCompanyName"
            type="text"
            name="importedGoodsClientCompanyName"
            onChange={onClientCompanyNameChange}
            value={clientCompanyName}
            maxLength={100}
            placeholder={t('reservation.label.ImportedCNSGoodsForm.clientCompanyName.placeholder')}
          />
        </InputGroup>
        <InputGroup
          id="goodsFloorGroup"
          data-testid="goodsFloorGroup"
          className={
            props.errors && props.touched && props.errors.importedGoodsFloor && props.touched.importedGoodsPurpose
              ? 'required error'
              : 'required'
          }
        >
          <InputGroup.Prepend>
            <InputGroup.Text>{t('reservation.label.ImportedCNSGoodsForm.goodsFloor.name')}</InputGroup.Text>
          </InputGroup.Prepend>
          <i className="required"></i>
          <Form.Control
            id="goodsFloor"
            data-testid="goodsFloor"
            aria-label="goodsFloor"
            type="text"
            name="importedGoodsFloor"
            onChange={onGoodsFloorChange}
            value={goodsFloor}
            maxLength={100}
            placeholder={t('reservation.label.ImportedCNSGoodsForm.goodsFloor.placeholder')}
          />
        </InputGroup>
        {importedGoods.importedGoodsCode !== ImportedGoodsCodeEnum.PART && (
          <InputGroup
            id="serialNumberGroup"
            data-testid="serialNumberGroup"
            className={
              props.errors &&
              props.touched &&
              props.errors.importedGoodsSerialNumber &&
              props.touched.importedGoodsPurpose
                ? 'required error'
                : 'required'
            }
          >
            <InputGroup.Prepend>
              <InputGroup.Text>{t('reservation.label.ImportedNotebookForm.serialNumber.name')}</InputGroup.Text>
            </InputGroup.Prepend>
            <i className="required"></i>
            <Form.Control
              id="notebookSerialNumber"
              data-testid="notebookSerialNumber"
              aria-label="notebookSerialNumber"
              type="text"
              name="importedGoodsSerialNumber"
              onChange={onNotebookSerialNumberChange}
              value={serialNumber}
              maxLength={100}
              placeholder={t('reservation.label.ImportedCNSGoodsForm.serialNumber.placeholder')}
            />
          </InputGroup>
        )}
        <InputGroup
          id="goodsModelNameGroup"
          data-testid="goodsModelNameGroup"
          className={
            props.errors && props.touched && props.errors.importedGoodsModelName && props.touched.importedGoodsPurpose
              ? 'required error'
              : 'required'
          }
        >
          <InputGroup.Prepend>
            <InputGroup.Text>{t('reservation.label.ImportedCNSGoodsForm.goodsModelName.name')}</InputGroup.Text>
          </InputGroup.Prepend>
          <i className="required"></i>
          <Form.Control
            id="goodsModelName"
            data-testid="goodsModelName"
            aria-label="goodsModelName"
            type="text"
            name="goodsModelName"
            onChange={onGoodsModelNameChange}
            value={goodsModelName}
            maxLength={100}
            placeholder={t('reservation.label.ImportedCNSGoodsForm.goodsModelName.placeholder')}
          />
        </InputGroup>
        <InputGroup
          id="importedGoodsPurposeGroup"
          data-testid="importedGoodsPurposeGroup"
          className={
            props.errors && props.touched && props.errors.importedGoodsPurpose && props.touched.importedGoodsPurpose
              ? 'required error'
              : 'required'
          }
        >
          <InputGroup.Prepend>
            <InputGroup.Text>{t('reservation.label.ImportedNotebookForm.purpose.name')}</InputGroup.Text>
          </InputGroup.Prepend>
          <i className="required"></i>
          <DropdownButton
            id="importedGoodsPurpose"
            className="importedGoodsPurpose"
            title={getImportedGoodsPurposeName()}
          >
            {props.importedGoodsPurposeCodeList &&
              props.importedGoodsPurposeCodeList.map((commonCode: CommonCode, index) => (
                <Dropdown.Item
                  onSelect={onImportedGoodsPurposeChange}
                  className="importedGoodsPurpose"
                  key={commonCode.codeId}
                  eventKey={commonCode.codeId}
                  data-testid="importedGoodsPurposeItem"
                >
                  {commonCode.codeName}
                </Dropdown.Item>
              ))}
          </DropdownButton>
        </InputGroup>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>{t('reservation.label.ImportedNotebookForm.description.name')}</InputGroup.Text>
          </InputGroup.Prepend>

          <TextareaAutosize
            className="contentEdit"
            value={importedGoodsDescription}
            onChange={onImportedGoodsDescriptionChange}
            placeholder={t('reservation.label.ImportedNotebookForm.description.placeholder')}
            id="importedGoodsDescription"
            data-testid="importedGoodsDescription"
            maxRows={5}
            minRows={1}
          />
        </InputGroup>
        {importedGoods.importedGoodsCode !== ImportedGoodsCodeEnum.PART && (
          <InputGroup
            id="importedGoodsTechPreviewYesOrNoGroup"
            data-testid="importedGoodsTechPreviewYesOrNoGroup"
            className={
              props.errors && props.touched && props.errors.importedGoodsTechPreviewYesOrNo
                ? 'required error'
                : 'required'
            }
          >
            <InputGroup.Prepend>
              <InputGroup.Text
                dangerouslySetInnerHTML={{
                  __html: t('reservation.label.ImportedCNSGoodsForm.techPreview.name', {
                    interpolation: { escapeValue: false },
                  }),
                }}
                style={{ textAlign: 'left' }}
              ></InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Check
              type="switch"
              //id="importedGoodsTechPreviewYesOrNo"
              id={`importedGoodsTechPreviewYesOrNo${importedGoods.id}`}
              data-testid="importedGoodsTechPreviewYesOrNo"
              className="secondary d-inline-block"
              name="importedGoodsTechPreviewYesOrNo"
              label=""
              onChange={onTechPreviewChange}
              checked={importedGoodsTechPreviewYesOrNo}
            />
          </InputGroup>
        )}
        <InputGroup
          id="importedGoodsManagerSupportYesOrNoGroup"
          data-testid="importedGoodsManagerSupportYesOrNoGroup"
          className={
            props.errors && props.touched && props.errors.importedGoodsManagerSupportYesOrNo
              ? 'required error'
              : 'required'
          }
        >
          <InputGroup.Prepend>
            <InputGroup.Text
              dangerouslySetInnerHTML={{
                __html: t('reservation.label.ImportedCNSGoodsForm.managerSupport.name', {
                  interpolation: { escapeValue: false },
                }),
              }}
              style={{ textAlign: 'left' }}
            ></InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Check
            type="switch"
            //id="importedGoodsManagerSupportYesOrNo"
            id={`importedGoodsManagerSupportYesOrNo${importedGoods.id}`}
            data-testid="importedGoodsManagerSupportYesOrNo"
            className="secondary d-inline-block"
            name="importedGoodsManagerSupportYesOrNo"
            label=""
            onChange={onManagerSupportChange}
            checked={importedGoodsManagerSupportYesOrNo}
          />
        </InputGroup>
      </Card.Body>
    </Card>
  );
};

export default ImportedNotebookForm;
