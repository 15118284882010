import React, { ReactElement, MouseEvent, useState, useEffect } from 'react';
import { ButtonToolbar, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ApprovalStatusEnum, MemberTypeCode, UserTypeEnum } from '../model/ReservationConstants';
import './ReservationBottomButton.scss';
import { Visitor } from '../model/ReservationTypes';
import { PolicyCompleteSummary, PolicyContentsModel } from '../model/Workplace';
import WorkplaceService from '../services/WorkplaceService';
import ReservationEduAndAgreementModal from './ReservationEduAndAgreementModal';
import { WorkplaceVisitInfo } from '../model/ReservationTypes';
import { reservationSchema } from '../validation/reservationValidationSchema';
import HistorySearchConditions from './HistorySearchConditions';
import { useHistory } from 'react-router-dom';

export const ReservationBottomButton: React.FC<any> = (props: any): ReactElement => {
  const workplaceService = new WorkplaceService();
  const workplace = props.values.workplace;
  const sessionUser = props.sessionUser;
  const visitors = props.values.visitors;

  const [showEduAndAgreementModal, setShowEduAndAgreementModal] = useState<boolean>(false);
  const [policyContentsList, setPolicyContentsList] = useState<PolicyContentsModel[]>([] as PolicyContentsModel[]);
  const [selectedPolicyContent, setSelectedPolicyContent] = useState<PolicyContentsModel>({} as PolicyContentsModel);
  const history = useHistory();
  const { t } = useTranslation();
  const handleSubmit = (event: MouseEvent<HTMLButtonElement>): void => {
    props.setFieldValue('isSubmited', true, true);

    let isValid = false;
    if (visitors && visitors.length > 0) {
      isValid = true;
    }

    if (isValid) {
      props.setFieldValue('status', ApprovalStatusEnum.IP016002);
      props.handleSubmit(props);
    }
  };

  /*
  const isValidSessionUser = async (aUser: Visitor): Promise<boolean> => {
    aUser.isValid = true;
    if (
      workplace &&
      workplace.workplaceId &&
      (aUser.type !== UserTypeEnum.MEMBER ||
        (aUser.type === UserTypeEnum.MEMBER && (sessionUser.termsAgreeYn === 'Y' || props.values.termPersonal)))
    ) {
      const userStatus: PolicyCompleteSummary | null = await workplaceService.getPolicyCompleteSummary(
        workplace.workplaceId,
        aUser.id,
        aUser.type
      );

      if (
        userStatus &&
        (userStatus.safeEducationComplete !== 'Y' ||
          userStatus.securityEducationComplete !== 'Y' ||
          userStatus.safeAgreementComplete !== 'Y' ||
          userStatus.securityAgreementComplete !== 'Y')
      ) {
        aUser.isValid = false;
        if (Object.keys(props.errors).length === 0) {
          handleShowEduAndAgreementModal();
        }
        return aUser.isValid;
      }
    }

    return aUser.isValid;
  };

  const handleShowEduAndAgreementModal = async (): Promise<void> => {
    const policyContentsList: PolicyContentsModel[] = await workplaceService.getPolicyAllContents(
      workplace.workplaceId
    );
    if (policyContentsList && policyContentsList.length > 0) {
      setPolicyContentsList(policyContentsList);
      setSelectedPolicyContent(policyContentsList[0]);

      setShowEduAndAgreementModal(true);
      history.push(history.location.hash + '#eduAndAgreementModal');
    }
  };
  */

  const handleWorkplaceVisitInfo = (workplaceVisitInfo: WorkplaceVisitInfo): void => {
    props.setWorkplaceVisitInfo(workplaceVisitInfo);
    props.setFieldValue('isPass', workplaceVisitInfo.isPass);
  };

  const handleHideEduAndAgreementModal = () => {
    history.goBack();
  };

  const getSaveButtonClass = (): string => {
    return Object.keys(props.errors).length > 0 ||
      (sessionStorage.getItem('memberTypeCode') !== MemberTypeCode.NO_MEM &&
        props.isSessionVisitorDisabled &&
        props.visitors.length < 2) ||
      (props.values.isIncludedNoneMember && !props.values.termNoneMember)
      ? 'disabled'
      : 'enabled';
  };

  const tempSave = () => {
    let result;
    props.setFieldValue('isTempSaved', true, true);

    try {
      result = reservationSchema.validateSync(props.values, { abortEarly: false });
    } catch (error) {
      result = error.value;
    }
    let isValid = true;
    if (
      result.workplace.workplaceId === undefined ||
      result.visitTo === null ||
      result.visitTo.id === undefined ||
      result.fromVisitPeriod === null ||
      result.fromVisitPeriod === undefined ||
      result.toVisitPeriod === null ||
      result.toVisitPeriod === undefined
    ) {
      isValid = false;
    }

    if (isValid) {
      props.handleShowConfirmModal(t('reservation.message.confirm.tempSave'), props.tempSave);
    }
  };
  /* eslint-disable */
  useEffect(() => {
    const location = history.location;

    if (!location.hash.includes('#eduAndAgreementModal')) {
      setShowEduAndAgreementModal(false);
    }
  }, [history.location]);
  return (
    <div>
      <div className="ReservationBottomButton" id="ReservationBottomButton" data-testid="ReservationBottomButton">
        <ButtonToolbar>
          <Button
            name="save"
            className={getSaveButtonClass() + ' save'}
            data-testid="save-button"
            variant="primary"
            onClick={handleSubmit}
          >
            <i className="material-icons pointer">check</i>
            <span>{t('reservation.label.button.register')}</span>
          </Button>
          {sessionUser.memberTypeCode !== MemberTypeCode.NO_MEM && (
            <Button
              name="tempSave"
              className="tempSave"
              data-testid="temp-save-button"
              variant="dark"
              onClick={tempSave}
            >
              <span>{t('reservation.label.button.tempSave')}</span>
            </Button>
          )}
        </ButtonToolbar>
      </div>
      <div>
        <ReservationEduAndAgreementModal
          {...{
            reservation: props.values,
            user: sessionUser,
            policyContents: policyContentsList,
            initPolicyContent: selectedPolicyContent,
            workplaceVisitInfo: props.workplaceVisitInfo,
            setWorkplaceVisitInfo: handleWorkplaceVisitInfo,
            show: showEduAndAgreementModal,
            onHide: handleHideEduAndAgreementModal,
            setLoading: props.setLoading,
          }}
        />
      </div>
    </div>
  );
};

export default ReservationBottomButton;
