import React, { useState, ReactElement, useContext } from 'react';
import { Form, Modal, Container, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ResetPasswordModal.scss';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps, FormikHelpers } from 'formik';
import * as yup from 'yup';
import MemberRegistrationConstants, { CertificationTypeCode } from '../../model/MemberRegistrationConstants';
import MemberRegistrationService from '../../services/MemberRegistrationService';
import PhoneCertification from '../../memberRegistration/component/PhoneCertification';
import EmailCertificationForm from '../../memberRegistration/component/EmailCertificationForm';
import { BusinessTypeCode } from '../../model/CommonConstants';
import ReservationConstants from '../../../reservation/model/ReservationConstants';
import { AuthContext } from '../../../../App';
import { ActionType } from '../../../../reducer/actions';

export interface ReservationCompleteModalI {
  show: boolean;
  onHide: () => void;
  showMemberInfo: boolean;
  passwordOnHide: (value: boolean) => void;
  setToastMessage: (msg: string) => void;
}

export interface PasswordReset {
  showMemberInfo: boolean;
  memberName: string;
  emailAddress: string;
  phoneNumber: string;
  certificationId: string;
  certificationKey: string;
  password: string;
  passwordConfirm: string;
}

const ResetPasswordModal: React.FC<ReservationCompleteModalI> = ({
  show,
  onHide,
  showMemberInfo,
  passwordOnHide,
  setToastMessage,
}: ReservationCompleteModalI): ReactElement => {
  const memberRegistrationService = new MemberRegistrationService();
  const { t } = useTranslation();
  const { dispatch } = useContext(AuthContext);
  const [certificationConfirmStatus, setCertificationConfirmStatus] = useState(false);
  const [validAccount, setValidAccount] = useState(false);
  const [certificationType, setCertificationType] = useState<string>(CertificationTypeCode.SMS);

  const setLoading = (_isLoading: boolean) => {
    dispatch({ type: ActionType.LOADING, loading: { isLoading: _isLoading } });
  };
  const initPasswordReset: PasswordReset = {
    showMemberInfo: true,
    memberName: '',
    emailAddress: '',
    phoneNumber: '',
    certificationId: '',
    certificationKey: '',
    password: '',
    passwordConfirm: '',
  };

  const schema = yup.object({
    showMemberInfo: yup.boolean(),
    memberName: yup.string().when('showMemberInfo', {
      is: showMemberInfo,
      then: yup
        .string()
        .required(t('member.message.frontEnd.memberName.required'))
        .min(2, t('member.message.frontEnd.memberName.common')),
    }),
    emailAddress: yup.string().when('showMemberInfo', {
      is: showMemberInfo,
      then: yup
        .string()
        .email(t('member.message.frontEnd.emailAddress.common'))
        .required(t('member.message.frontEnd.emailAddress.required')),
    }),
    phoneNumber: yup.string().when('showMemberInfo', {
      is: showMemberInfo,
      then: yup
        .string()
        .required(t('member.message.frontEnd.phoneNumber.required'))
        .matches(ReservationConstants.REGEXP_MOBILE, t('member.message.frontEnd.phoneNumber.common')),
    }),
    certificationKey: yup.string().when('showMemberInfo', {
      is: showMemberInfo,
      then: yup
        .string()
        .required(t('member.message.frontEnd.certificationKey.required'))
        .min(6, t('member.message.frontEnd.certificationKey.common'))
        .max(6, t('member.message.frontEnd.certificationKey.common')),
    }),
    password: yup.string().when('showMemberInfo', {
      is: !showMemberInfo,
      then: yup
        .string()
        .required(t('member.message.frontEnd.password.required'))
        .min(8, t('member.message.frontEnd.password.common'))
        .max(32, t('member.message.frontEnd.password.common'))
        .matches(MemberRegistrationConstants.REGEXP_PASSWORD, t('member.message.frontEnd.password.common'))
        .test('password-contains-email-id', t('member.message.frontEnd.password.containsEmailId'), function(
          value: string
        ) {
          const email = this.resolve(yup.ref('emailAddress'));
          const emailId = email ? email.split('@')[0] : undefined;
          return value && value.includes(emailId) ? false : true;
        }),
    }),
    passwordConfirm: yup.string().when('showMemberInfo', {
      is: !showMemberInfo,
      then: yup
        .string()
        .required(t('member.message.frontEnd.password.common'))
        .oneOf([yup.ref('password'), null], t('member.message.frontEnd.password.common')),
    }),
  });

  const modalBody = (props: FormikProps<PasswordReset>): JSX.Element => {
    const handleMemberInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
      if (!event.target.value) setValidAccount(false);
      props.setFieldValue(event.target.id, event.target.value);
    };

    const handleCertificationId = (id: string) => {
      props.setFieldValue('certificationId', id);
    };

    const handleCertificationView = (type: string): void => {
      props.resetForm();
      setCertificationType(type);
      setValidAccount(false);
      setCertificationConfirmStatus(false);
    };

    const handleClose = () => {
      props.resetForm();
      setCertificationType(CertificationTypeCode.SMS);
      setValidAccount(false);
      setCertificationConfirmStatus(false);
      onHide();
    };

    const checkMemberAccount = async (): Promise<boolean> => {
      let isExists = false;
      if (
        !validAccount &&
        props.errors.memberName === undefined &&
        props.values.memberName !== '' &&
        props.errors.emailAddress === undefined &&
        props.values.emailAddress !== '' &&
        props.errors.phoneNumber === undefined &&
        props.values.phoneNumber !== ''
      ) {
        const checkAccountRequest = {
          emailAddress: props.values.emailAddress,
          memberName: props.values.memberName,
          phoneNumber: props.values.phoneNumber.replace(/-/gi, ''),
          memberTypeCode: '',
          password: '',
          memberStateCode: '',
          certificationId: '',
          certificationSendAddress: '',
          searchConditionType: CertificationTypeCode.SMS,
        };
        setLoading(true);
        const result = await memberRegistrationService.checkAccountInfo(checkAccountRequest);
        setLoading(false);
        if (result.successOrNot !== 'Y') {
          props.setFieldError('phoneNumber', t('member.message.resetPassword.response.' + result.statusCode));
          setValidAccount(false);
        } else {
          if (result.data.existenceYn !== 'Y') {
            props.setFieldError('phoneNumber', t('member.message.resetPassword.response.INVALID_ACCOUNT'));
            setValidAccount(false);
          } else {
            isExists = true;
            setValidAccount(true);
          }
        }
      }
      return isExists;
    };

    const handlePage = (): void => {
      passwordOnHide(false);
    };

    const checkMemberWithNameAndEmail = async (): Promise<boolean> => {
      let isExists = false;
      if (
        !validAccount &&
        props.errors.memberName === undefined &&
        props.values.memberName !== '' &&
        props.errors.emailAddress === undefined &&
        props.values.emailAddress !== ''
      ) {
        const checkAccountRequest = {
          emailAddress: props.values.emailAddress,
          memberName: props.values.memberName,
          phoneNumber: '',
          memberTypeCode: '',
          password: '',
          memberStateCode: '',
          certificationId: '',
          certificationSendAddress: '',
          searchConditionType: CertificationTypeCode.EMAIL,
        };
        setLoading(true);
        const result = await memberRegistrationService.checkAccountInfo(checkAccountRequest);
        setLoading(false);
        if (result.successOrNot !== 'Y') {
          props.setFieldError('emailAddress', t('member.message.resetPassword.response.' + result.statusCode));
          setValidAccount(false);
        } else {
          if (result.data.existenceYn !== 'Y') {
            props.setFieldError('emailAddress', t('member.message.resetPassword.response.INVALID_ACCOUNT'));
            setValidAccount(false);
          } else {
            isExists = true;
            setValidAccount(true);
          }
        }
      }

      return isExists;
    };

    return (
      <Modal
        show={show}
        onHide={handleClose}
        centered={true}
        aria-labelledby="contained-modal-title-vcenter"
        className="reset-password-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Container>
              <div className="header bold">{t('member.label.resetPasswordModal.header.title')}</div>
              <div className="header light">
                {showMemberInfo
                  ? t('member.label.resetPasswordModal.header.contentsMemberInfo')
                  : t('member.label.resetPasswordModal.header.contentsPassword')}
              </div>
            </Container>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={props.handleSubmit}>
            {showMemberInfo && (
              <div>
                <div>
                  <Form.Row className="top-empty-space bottom-empty-space">
                    <Button
                      id="phoneviewButton"
                      name="phoneviewButton"
                      data-testid="phoneviewButton"
                      className="type-phone-button"
                      variant={certificationType === CertificationTypeCode.SMS ? 'secondary' : 'outline-secondary'}
                      onClick={() => handleCertificationView(CertificationTypeCode.SMS)}
                    >
                      <span id="phoneviewButtonText" data-testid="phoneviewButtonText" className="submit-button-text">
                        {t('member.label.resetPasswordModal.certification.phone')}
                      </span>
                    </Button>
                    <Button
                      id="emailviewButton"
                      name="emailviewButton"
                      data-testid="emailviewButton"
                      className="type-email-button"
                      variant={certificationType === CertificationTypeCode.EMAIL ? 'secondary' : 'outline-secondary'}
                      onClick={() => handleCertificationView(CertificationTypeCode.EMAIL)}
                    >
                      <span id="emailviewButtonText" data-testid="emailviewButtonText" className="submit-button-text">
                        {t('member.label.resetPasswordModal.certification.email')}
                      </span>
                    </Button>
                  </Form.Row>
                </div>
                {certificationType === CertificationTypeCode.SMS && (
                  <div>
                    <Form.Row className="top-empty-space">
                      <Form.Group>
                        <Form.Label>{t('member.label.resetPasswordModal.memberName.label')}</Form.Label>
                        <Form.Control
                          id="memberName"
                          name="memberName"
                          data-testid="memberName"
                          required
                          type="text"
                          placeholder={t('member.label.resetPasswordModal.memberName.placeholder')}
                          onChange={handleMemberInput}
                          isInvalid={props.errors.memberName ? true : false}
                        />
                        <Form.Control.Feedback id="memberNameFeedback" data-testid="memberNameFeedback" type="invalid">
                          {props.errors.memberName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group className="form-email">
                        <Form.Label>{t('member.label.resetPasswordModal.emailAddress.label')}</Form.Label>
                        <Form.Control
                          id="emailAddress"
                          name="emailAddress"
                          data-testid="emailAddress"
                          required
                          type="text"
                          placeholder={t('member.label.resetPasswordModal.emailAddress.placeholder')}
                          onChange={handleMemberInput}
                          isInvalid={props.errors.emailAddress ? true : false}
                        />
                        <Form.Control.Feedback
                          id="emailAddressFeedback"
                          data-testid="emailAddressFeedback"
                          type="invalid"
                        >
                          {props.errors.emailAddress}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <PhoneCertification
                      phoneCertificationFormik={{
                        ...props,
                        handleChange: handleMemberInput,
                        handleBlur: () => {}, // eslint-disable-line
                        handleCertification: checkMemberAccount,
                        isCustomInVisible: props.errors.emailAddress ? true : false,
                      }}
                      receivedCertificationId={props.values.certificationId}
                      setCertificationId={handleCertificationId}
                      certificationConfirmStatus={certificationConfirmStatus}
                      setCertificationConfirmStatus={setCertificationConfirmStatus}
                      certificationBusinessTypeCode={BusinessTypeCode.PSWD}
                      memberRegistrationService={memberRegistrationService}
                    />
                    <div id="submitArea" className="submit-area">
                      <Form.Row className="submit-row">
                        <Button
                          id="loginSubmitButton"
                          name="loginSubmitButton"
                          data-testid="nextButton"
                          className="submit-button"
                          type="submit"
                          disabled={
                            props.isSubmitting || !props.isValid || !certificationConfirmStatus || !validAccount
                          }
                          onClick={handlePage}
                        >
                          <span
                            id="loginSubmitButtonText"
                            data-testid="loginSubmitButtonText"
                            className="submit-button-text"
                          >
                            {t('member.label.resetPasswordModal.button.next')}
                          </span>
                        </Button>
                      </Form.Row>
                    </div>
                  </div>
                )}
                {certificationType === CertificationTypeCode.EMAIL && (
                  <div>
                    <Form.Row className="top-empty-space">
                      <Form.Group>
                        <Form.Label>{t('member.label.resetPasswordModal.memberName.label')}</Form.Label>
                        <Form.Control
                          id="memberName"
                          name="memberName"
                          data-testid="memberNameForEmailForm"
                          required
                          type="text"
                          placeholder={t('member.label.resetPasswordModal.memberName.placeholder')}
                          onChange={handleMemberInput}
                          isInvalid={props.errors.memberName ? true : false}
                        />
                        <Form.Control.Feedback
                          id="memberNameFeedback"
                          data-testid="memberNameFeedbackForEmailForm"
                          type="invalid"
                        >
                          {props.errors.memberName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <EmailCertificationForm
                      emailCertificationFormik={{
                        ...props,
                        handleChange: handleMemberInput,
                        handleBlur: () => {}, // eslint-disable-line
                        handleCertification: checkMemberWithNameAndEmail,
                        isCustomInVisible: props.errors.memberName ? true : false,
                      }}
                      receivedCertificationId={props.values.certificationId}
                      setCertificationId={handleCertificationId}
                      certificationConfirmStatus={certificationConfirmStatus}
                      setCertificationConfirmStatus={setCertificationConfirmStatus}
                      certificationBusinessTypeCode={BusinessTypeCode.PSWD}
                      memberRegistrationService={memberRegistrationService}
                    />
                    <div id="submitArea" className="submit-area">
                      <Form.Row className="submit-row">
                        <Button
                          id="resetPasswordNextButton"
                          name="resetPasswordNextButton"
                          data-testid="resetPasswordNextButton"
                          className="submit-button"
                          type="submit"
                          disabled={
                            props.isSubmitting ||
                            props.errors.memberName !== undefined ||
                            props.errors.emailAddress !== undefined ||
                            !certificationConfirmStatus ||
                            !validAccount
                          }
                          onClick={handlePage}
                        >
                          <span
                            id="resetPasswordNextButtonText"
                            data-testid="resetPasswordNextButtonText"
                            className="submit-button-text"
                          >
                            {t('member.label.resetPasswordModal.button.next')}
                          </span>
                        </Button>
                      </Form.Row>
                    </div>
                  </div>
                )}
              </div>
            )}
            {!showMemberInfo && (
              <div>
                <Form.Row className="top-empty-space">
                  <Form.Group>
                    <Form.Label>{t('member.label.resetPasswordModal.password.label')}</Form.Label>
                    <Form.Control
                      id="password"
                      name="password"
                      data-testid="password"
                      required
                      type="password"
                      placeholder={t('member.label.resetPasswordModal.password.placeholder1')}
                      onChange={props.handleChange} // eslint-disable-line
                      onBlur={props.handleBlur} // eslint-disable-line
                      isInvalid={props.errors.password ? true : false}
                    />
                    <Form.Control
                      id="passwordConfirm"
                      name="passwordConfirm"
                      data-testid="passwordConfirm"
                      required
                      type="password"
                      placeholder={t('member.label.resetPasswordModal.password.placeholder2')}
                      onChange={props.handleChange} // eslint-disable-line
                      onBlur={props.handleBlur} // eslint-disable-line
                      isInvalid={props.errors.passwordConfirm ? true : false}
                    />
                    <Form.Control.Feedback id="passwordFeedback" data-testid="passwordFeedback" type="invalid">
                      {props.errors.password || props.errors.passwordConfirm}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <div id="submitArea" className="submit-area">
                  <Form.Row className="submit-row">
                    <Button
                      id="resetPasswordButton"
                      name="resetPasswordButton"
                      data-testid="resetPasswordButton"
                      className="submit-button"
                      type="submit"
                      disabled={props.isSubmitting || !props.isValid}
                    >
                      <span
                        id="resetPasswordButtonText"
                        data-testid="resetPasswordButtonText"
                        className="submit-button-text"
                      >
                        {t('member.label.resetPasswordModal.button.complete')}
                      </span>
                    </Button>
                  </Form.Row>
                </div>
              </div>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  const handleSubmit = async (values: PasswordReset, actions: FormikHelpers<PasswordReset>): Promise<void> => {
    const updatePasswordRequest = {
      emailAddress: values.emailAddress,
      memberName: values.memberName,
      phoneNumber: values.phoneNumber.replace(/-/gi, ''),
      certificationId: values.certificationId,
      password: values.password,
      memberTypeCode: '',
      memberStateCode: '',
      certificationSendAddress: '',
      searchConditionType: certificationType,
    };
    setLoading(true);
    const response = await memberRegistrationService.updatePassword(updatePasswordRequest);
    setLoading(false);
    if (response && response.statusCode === 'SUCCESS') {
      setToastMessage(t('member.message.resetPassword.response.SUCCESS'));
      setValidAccount(false);
      actions.resetForm();
      onHide();
    } else if (response.statusCode === 'DUPLICATION.PASSWORD') {
      actions.setFieldError('password', t('member.message.resetPassword.response.DUPLICATION_PASSWORD'));
    } else if (
      response.successOrNot === 'N' &&
      response.statusCode === MemberRegistrationConstants.UNKNOWN_SERVER_ERROR_CODE
    ) {
      alert(t('member.message.backEnd.UE'));
    } else {
      alert(t('member.message.backEnd.' + response.statusCode));
    }
  };

  return (
    <div>
      <Formik
        data-testid="ResetPasswordModalFormik"
        enableReinitialize={true}
        initialValues={initPasswordReset}
        validationSchema={schema}
        render={modalBody}
        onSubmit={handleSubmit}
        isInitialValid={schema.isValidSync(initPasswordReset)}
      />
    </div>
  );
};

export default ResetPasswordModal;
