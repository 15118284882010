import * as yup from 'yup';
import ReservationConstants from '../model/ReservationConstants';
import moment from 'moment';
import { ExcelValidationMsg } from '../model/Excel';
import { Workplace } from '../model/Workplace';

let workplaceInfo: Workplace;

export const getVisitorExcelValidationSchema = (_workplaceInfo: Workplace) => {
  workplaceInfo = _workplaceInfo;
  return VisitorExcelValidationSchema;
};

export const VisitorExcelValidationSchema = yup.array().of(
  yup.object().shape({
    visitorName: yup.string().required(ExcelValidationMsg.requiredName),
    mobile: yup
      .string()
      .required(ExcelValidationMsg.requiredMobile)
      .test('mobile', 'required', function(mobile) {
        const createError = this.createError;
        const path = this.path;
        /* eslint-disable */
        if (
          mobile &&
          !ReservationConstants.REGEXP_MOBILE.test(mobile)
        ) {
          if(!ReservationConstants.REGEXP_MOBILE_VALUE.test(mobile))
            return createError({
              path,
              message: ExcelValidationMsg.invalidMobile,
            });
        }
        return true;
      }),
    company: yup.string().required(ExcelValidationMsg.requiredCompany),
    visitStart: yup
      .string()
      .nullable()
      .test('visitPeriod', 'required', function(_fromVisitPeriod) {
        const createError = this.createError;
        const path = this.path;
        const _toVisitPeriod = this.resolve(yup.ref('visitEnd'));
        if (_toVisitPeriod) {
          /* eslint-disable */
          if (_fromVisitPeriod) {
            const mFromVisitPeriod = moment(_fromVisitPeriod);
            const mToVisitPeriod = moment(_toVisitPeriod);
            if (!mFromVisitPeriod.isValid() || !mToVisitPeriod.isValid()) {
              return createError({
                path,
                message: ExcelValidationMsg.invalidPeriod,
              });
            } else if (mFromVisitPeriod.format('YYYYMMDD') < moment().format('YYYYMMDD')) {
              return createError({
                path,
                message: ExcelValidationMsg.invalidStartDate, 
              });
            } else if (mFromVisitPeriod.isAfter(mToVisitPeriod)) {
              return createError({
                path,
                message: ExcelValidationMsg.invalidPeriod,
              });
            } else if (workplaceInfo.registerVisitorEachPeriodYesorno === 'Y' && mToVisitPeriod.diff(mFromVisitPeriod, 'day') > (workplaceInfo.maxVisitPeriod - 1)) {
              return createError({
                path,
                message: ExcelValidationMsg.maxVisitPeriod,
              });
            }
          } else {
            return createError({
              path,
              message: ExcelValidationMsg.requiredPeriod,
            });
          }
          /* eslint-enable */
        }

        return true;
      }),
    visitEnd: yup
      .string()
      .nullable()
      .test('visitPeriod', 'required', function(_toVisitPeriod) {
        const createError = this.createError;
        const path = this.path;
        const _fromVisitPeriod = this.resolve(yup.ref('fromVisitPeriod'));
        if (_fromVisitPeriod && !_toVisitPeriod) {
          return createError({
            path,
            message: ExcelValidationMsg.requiredPeriod,
          });
        }

        return true;
      }),
  })
);
