import React, { useState, ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';

import { ReservationBaseInfo } from './components/ReservationBaseInfo';
import { Reservation, WorkplaceVisitInfo } from './model/ReservationTypes';
import { Formik, FormikProps } from 'formik';

import { ReservationBottomContainer } from './components/ReservationBottomContainer';
import { reservationSchema } from './validation/reservationValidationSchema';

import { User } from './model/User';

import './utils/util';

import './ReservationContainer.scss';

import ReservationCompleteModal from './components/ReservationCompleteModal';
import ReservationService from './services/ReservationService';
import ConfirmModal from './components/ConfirmModal';
import CommonCodeService from './services/CommonCodeService';
import MemberService from './services/MemberService';
import { RequirementInvalidModal } from './components/RequirementInvalidModal';
import { AlertModal } from './components/AlertModal';
import WorkplaceNoticeModal from './components/WorkplaceNoticeModal';

import CheckModal from './components/CheckModal';
import { useHistory } from 'react-router-dom';
import ReservationConstants, { MemberTypeCode, ApprovalStatusEnum } from './model/ReservationConstants';
import { SystemNoticeModal } from './components/SystemNoticeModal';
import SystemNoticeModifyModal from './components/SystemNoticeModifyModal';
import { NoticeList } from './model/Workplace';
import { Log } from './model/Log';
import { SinglexHeader } from '../../layouts';
import SinglexModal from '../../layouts/SinglexModal';
import moment from 'moment';
import CookieService from '../../service/CookieService';

interface ReservationContainer {
  reservationService: ReservationService;
  commonCodeService: CommonCodeService;
  commonCodeMap: any;
  sessionUser: User;
  setLoading: Function;
  reservation: Reservation;
}

export const ReservationContainer: React.FC<ReservationContainer> = (props: ReservationContainer): ReactElement => {
  const { t } = useTranslation();

  /* eslint-disable */
  const sessionUser = props.sessionUser;
  const cookieService = new CookieService();

  const [workplaceVisitInfo, setWorkplaceVisitInfo] = useState<WorkplaceVisitInfo | null>();
  const [showRequirementInvalidModal, setShowRequirementInvalidModal] = useState<boolean>(false);
  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false);

  const [visitor, setVisitor] = useState<User | null>(null);

  const [requirementInvalidModalMessage, setRequirementInvalidModalMessage] = useState<string>(
    t('reservation.label.RequirementInvalidModal.message')
  );
  const [confirmModalMessage, setConfirmModalMessage] = useState<string>('');
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [onConfirm, setOnConfirm] = useState<() => void>((): void => {});
  const [showWorkplaceModal, setShowWorkplaceModal] = useState<boolean>(false);
  const [showAlertModal, setShowAlertModal] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [detailErrorMessage, setDetailErrorMessage] = useState<string>('');
  const [showCheckModal, setShowCheckModal] = useState<boolean>(false);
  const [showBottomForms, setShowBottomForms] = useState<boolean>(false);
  const [showSystemNoticeModal, setShowSystemNoticeModal] = useState<boolean>(false);
  const singlexHeaderUse = window.location.hostname.includes('singlex') ? true : false;
  const [showSinglexModal, setShowSinglexModal] = useState<boolean>(
    cookieService.getCookie("singlexModalDisableDate") == moment().format('YYYYMMDD') ?
    false :
    true
  );

  const history = useHistory();
  const memberService = new MemberService();

  const handleCloseConfirmModal = (): void => {
    history.goBack();
  };
  /* eslint-enable */
  const handleShowComplete = (): void => {
    history.goBack();
  };
  const handleShowRequirementInvalidModal = (): void => {
    history.goBack();
  };

  const handleCloseWorkplaceModal = (): void => {
    history.goBack();
  };

  const onAlertClose = (): void => {
    history.goBack();
  };

  const handleCloseCheckModal = (): void => {
    history.goBack();
  };
  const handleShowCheckModal = (): void => {
    setShowCheckModal(true);
    history.push(history.location.hash + '#checkModal');
  };
  const handleCloseSystemNoticeModal = (): void => {
    setShowSystemNoticeModal(false);
  };
  const handleCloseSinglexModal = () => {
    setShowSinglexModal(false);
  };

  /* eslint-disable */
  useEffect(() => {
    const location = history.location;

    if (!location.hash.includes('#checkModal')) {
      setShowCheckModal(false);
    }
    if (!location.hash.includes('#alertModal')) {
      setShowAlertModal(false);
    }
    if (!location.hash.includes('#workplaceNoticeModal')) {
      setShowWorkplaceModal(false);
    }
    if (!location.hash.includes('#systemNoticeModal')) {
      setShowSystemNoticeModal(false);
    }
    if (!location.hash.includes('#confirmModal')) {
      setShowConfirmModal(false);
    }
    if (!location.hash.includes('#invalidModal')) {
      setShowRequirementInvalidModal(false);
    }
    if (!location.hash.includes('#completeModal')) {
      setShowCompleteModal(false);
    }
    if (!location.hash.includes('#visitorInfo')) {
      setShowBottomForms(false);
    }
  }, [history.location]);

  /* eslint-enable */
  /* eslint-disable */
  useEffect(() => {
    /* eslint-enable */
    if (props.reservation.workplace) {
      const workplace = props.reservation.workplace;
      const workplaceVisitInfo: WorkplaceVisitInfo = {
        workplaceNotice: workplace.workplaceNotice || '',
        groupCompanyName: workplace.groupCompanyName,
        workplace: workplace.workplace,
        requiredEducation: workplace.requiredEducation === 0 ? false : true,
        requestNotiYesorno: workplace.requestNotiYesorno === 'Y',
        approvalNotiYesorno: workplace.approvalNotiYesorno === 'Y',
      };
      setWorkplaceVisitInfo(workplaceVisitInfo);
    }
  }, [props.reservation]);
  /* eslint-enable */

  const handleValidate = (values: Reservation) => {
    let error: any = {};
    if (sessionUser.memberTypeCode === MemberTypeCode.NO_MEM && values.visitors && values.visitors.length > 0) {
      if (!values.visitors[0].name || !values.visitors[0].applyMemberMobilePhone) {
        error = {
          noMemberNameAndMobile: ReservationConstants.MSG_CODE_FOR_MISSING_REQUIRED_FIELD,
        };
      } else if (values.visitors[0].name.length < 2) {
        error = {
          noMemberNameAndMobile: ReservationConstants.MSG_CODE_FOR_NAME_MIN_LENGTH_FIELD,
        };
      } else if (!ReservationConstants.REGEXP_MOBILE_VALUE.test(values.visitors[0].applyMemberMobilePhone)) {
        error = {
          noMemberNameAndMobile: ReservationConstants.MSG_CODE_FOR_MOBILE_FORMAT,
        };
      } else if (values.visitors[0].companyName === '') {
        error = {
          noMemberNameAndMobile: ReservationConstants.MSG_CODE_FOR_MISSING_COMPANY,
        };
      }
    }
    return error;
  };

  const containerBody = (formProps: FormikProps<Reservation>): JSX.Element => {
    const handleShowConfirmModal = (confirmMessage: string, onConfirm: Function): void => {
      setConfirmModalMessage(confirmMessage);
      setOnConfirm(() => onConfirm);
      setShowConfirmModal(true);
      history.push(history.location.hash + '#confirmModal');
    };
    const tempSave = (): void => {
      if (history.location.hash.includes('#confirmModal')) {
        history.goBack();
      }
      formProps.values.status = ApprovalStatusEnum.IP016001;
      formProps.setFieldValue('status', ApprovalStatusEnum.IP016001);
      handleSubmit(formProps.values);
    };

    /* eslint-disable */
    return (
      <Container className="ReservationContainer">
        {
          singlexHeaderUse &&
          <>
            <SinglexHeader />
            <SinglexModal show={showSinglexModal} onClose={handleCloseSinglexModal} />
          </>
        }
        <Row>
          <Col>
            <ReservationBaseInfo
              {...formProps}
              {...formProps.values}
              formProps={formProps}
              values={formProps.values}
              errors={formProps.errors}
              sessionUser={sessionUser}
              handleShowConfirmModal={handleShowConfirmModal}
              checkIsSubmited={checkIsSubmited}
              setWorkplaceVisitInfo={setWorkplaceVisitInfo}
              commonCodeMap={props.commonCodeMap}
            ></ReservationBaseInfo>
          </Col>
        </Row>
        <Row>
          <Col>
            <ReservationBottomContainer
              {...{
                ...formProps,
                sessionUser: sessionUser,
                checkIsSubmited: checkIsSubmited,
                workplaceVisitInfo: workplaceVisitInfo,
                commonCodeService: props.commonCodeService,
                commonCodeMap: props.commonCodeMap,
                setWorkplaceVisitInfo: setWorkplaceVisitInfo,
                setRequirementInvalidModalMessage: setRequirementInvalidModalMessage,
                setShowRequirementInvalidModal: setShowRequirementInvalidModal,
                setVisitor: setVisitor,
                setShowWorkplaceModal: setShowWorkplaceModal,
                setLoading: props.setLoading,
                setShowBottomForms: setShowBottomForms,
                showBottomForms: showBottomForms,
                handleShowConfirmModal: handleShowConfirmModal,
                tempSave: tempSave,
                setShowSystemNoticeModal: setShowSystemNoticeModal,
              }}
            />
          </Col>
        </Row>
        <Row>
          <ReservationCompleteModal
            {...{
              reservation: formProps.values,
              sessionUser: sessionUser,
              show: showCompleteModal,
              onHide: handleShowComplete,
              commonCodeMap: props.commonCodeMap,
            }}
          />
        </Row>
        <ConfirmModal
          message={confirmModalMessage}
          show={showConfirmModal}
          onConfirm={onConfirm}
          handleClose={handleCloseConfirmModal}
        />
        <AlertModal
          message={alertMessage}
          show={showAlertModal}
          onClose={onAlertClose}
          useDetailView={true}
          detailMessage={detailErrorMessage}
        />

        {visitor && (
          <RequirementInvalidModal
            {...{
              visitor: visitor,
              sessionUser: sessionUser,
              show: showRequirementInvalidModal,
              message: requirementInvalidModalMessage,
              onConfirm: handleShowRequirementInvalidModal,
              handleClose: handleShowRequirementInvalidModal,
            }}
          />
        )}
        {workplaceVisitInfo && (
          <WorkplaceNoticeModal
            {...{
              workplaceVisitInfo: workplaceVisitInfo,
              show: showWorkplaceModal,
              onClose: handleCloseWorkplaceModal,
            }}
          />
        )}

        <CheckModal
          show={showCheckModal}
          onCheck={handleSubmit}
          handleClose={handleCloseCheckModal}
          formState={formProps.values}
        />
        {showSystemNoticeModal && <SystemNoticeModal onHide={handleCloseSystemNoticeModal} sessionUser={sessionUser} />}
      </Container>
    );
  };

  const handleSubmit = async (values: Reservation): Promise<void> => {
    props.setLoading && props.setLoading(true);

    if (values.isSessionVisitorDisabled && values.visitors && values.visitors.length > 0) {
      values.visitors = values.visitors.filter(visitor => {
        return props.sessionUser.id !== visitor.id;
      });
    }

    /*
    if(values.visitTo) {
      if (ReservationConstants.REGEXP_MOBILE_GLOBAL.test(values.visitTo.phoneNumber)) {
        const globalIndex = values.visitTo.phoneNumber.indexOf('82');
        values.visitTo.phoneNumber = '0' + values.visitTo.phoneNumber.substring(globalIndex+2);
      }
    }
    */
    const reservationResult = await props.reservationService.applyReservation(sessionUser, values);

    if(reservationResult && reservationResult.successOrNot == 'Y') {
      history.push(history.location.hash + '#completeModal');
      setShowCompleteModal(true);
    } else if(reservationResult && reservationResult.successOrNot == 'N' && reservationResult.statusCode && reservationResult.statusCode.startsWith('ALREADY.CREATE')) {
      history.push(history.location.hash + '#alertModal');
      setShowAlertModal(true);
      setAlertMessage(t('reservation.message.exception.' + reservationResult.statusCode));
      setDetailErrorMessage(
        t('common.message.AlertModal.errorDetail', {
          systemName: '방문포탈',
          statusCode: reservationResult.statusCode,
          statusMessage: t('common.message.AlertModal.defaultStatusMessage'),
          errorMessage: t('common.message.AlertModal.defaultErrorMessage'),
        })
      );
    } else if(!reservationResult) {
      const log: Log = {
        memberId: Number(props.sessionUser.id),
        requestedMemberId: Number(props.sessionUser.id),
        logCode: "ERROR",
        ip: "Load Reservation Error"
      }

      memberService.saveLog(log);

      history.push(history.location.hash + '#alertModal');
      setShowAlertModal(true);
      setAlertMessage(t('reservation.message.exception.applyFailed'));
      setDetailErrorMessage(
        t('common.message.AlertModal.errorDetail', {
          systemName: '방문포탈',
          statusCode: t('common.message.AlertModal.defaultStatusCode'),
          statusMessage: t('common.message.AlertModal.defaultStatusMessage'),
          errorMessage: '방문신청결과를 확인할 수 없습니다. 페이지를 새로고침 후 방문신청결과를 확인해주세요.',
        })
      );
    } else {
      const log: Log = {
        memberId: Number(props.sessionUser.id),
        requestedMemberId: Number(props.sessionUser.id),
        logCode: "ERROR",
        ip: reservationResult
      }

      memberService.saveLog(log);

      history.push(history.location.hash + '#alertModal');
      setShowAlertModal(true);
      setDetailErrorMessage(
        t('common.message.AlertModal.errorDetail', {
          systemName:
            (reservationResult && reservationResult.data && reservationResult.data.target) || t('common.message.AlertModal.defaultSystemName'),
          statusCode:
            (reservationResult && reservationResult.data && reservationResult.data.detailStatusCode) ||
            t('common.message.AlertModal.defaultStatusCode'),
          statusMessage:
            (reservationResult && reservationResult.data && reservationResult.data.detailStatusMessage) ||
            t('common.message.AlertModal.defaultStatusMessage'),
          errorMessage:
            (reservationResult && reservationResult.data && reservationResult.data.displayMessage && reservationResult.data.displayMessage.meaning) ||
            t('common.message.AlertModal.defaultErrorMessage'),
        })
      );

      if (values.status === ApprovalStatusEnum.IP016001) {
        setAlertMessage(t('reservation.message.exception.saveFailed'));
      } else if (values.status === ApprovalStatusEnum.IP016002) {
        setAlertMessage(t('reservation.message.exception.applyFailed'));
      }
    }

    props.setLoading && props.setLoading(false);
  };

  return (
    <div>
      <Formik
        data-testid="ReseravtionContainerFormik"
        initialValues={props.reservation}
        validationSchema={reservationSchema}
        validate={handleValidate}
        render={containerBody}
        onSubmit={handleShowCheckModal}
        isInitialValid={reservationSchema.isValidSync(props.reservation)}
        enableReinitialize={true}
      />
    </div>
  );
};

export const checkIsSubmited = (formProps: Partial<FormikProps<Reservation>>): boolean => {
  return true;
};
export default ReservationContainer;