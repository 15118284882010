import React, { ReactElement, useState, useContext } from 'react';
import { Modal, Container, Row, Col, InputGroup, FormControl, ButtonToolbar, Button } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { ReservationResponse } from '../model/Reservation';
import { User } from '../model/User';

import { ModalVisitors } from './ModalVisitors';
import ModalVisitToTarget from './ModalVisitToTarget';

import { ApprovalStatusEnum } from '../model/ReservationConstants';
import ReservationService from '../services/ReservationService';
import './ReservationApproveModal.scss';
import { CommonCode } from '../model/Code';
import { AuthContext } from '../../../App';
import { ActionType } from '../../../reducer/actions';

export interface ReservationApproveModal {
  reservation: ReservationResponse;
  sessionUser: User;
  show: boolean;
  commonCodeMap: Map<string, CommonCode[]> | undefined;
  onHide: (status?: ApprovalStatusEnum, message?: string) => void;
  reservationService: ReservationService;
}

export const ReservationApproveModal: React.FC<ReservationApproveModal> = ({
  reservation,
  sessionUser,
  show,
  commonCodeMap,
  onHide,
  reservationService,
}: ReservationApproveModal): ReactElement => {
  const { t } = useTranslation();
  const { dispatch, state } = useContext(AuthContext);

  const [approvalMsg, setApprovalMsg] = useState<string>('');
  const [successYesOrNo, setSuccessYesOrNo] = useState<string>();

  const setLoading = (_isLoading: boolean) => {
    dispatch({ type: ActionType.LOADING, loading: { isLoading: _isLoading } });
  };
  const isIntegrateApproval = () => {
    let isIntegrateApproval = false;
    if (reservation.workplaceInfo) {
      const workplace = JSON.parse(reservation.workplaceInfo);
      isIntegrateApproval = workplace.integrateApprovalYesorno === 'Y' ? true : false;
    }
    return isIntegrateApproval;
  };

  const changeApprovalMsg = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setApprovalMsg(event.target.value);
  };

  const handleSubmit = async (approvalStatus: ApprovalStatusEnum): Promise<void> => {
    setLoading(true);
    const response: any = await reservationService.approveReservation(reservation, approvalStatus, approvalMsg);
    setLoading(false);
    if (response && response.successYesOrNo === 'Y') {
      onHide(approvalStatus, approvalMsg);
      dispatch({ type: ActionType.REFRESH_NAV_BAR, refreshNavBar: !state.refreshNavBar });
    } else {
      setSuccessYesOrNo('N');
    }
  };
  return (
    <Modal
      data-testid="reservation-approve-modal"
      onHide={() => onHide(undefined)}
      centered={true}
      show={show}
      className="modal-z-index reservation-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body data-testid="modalbody">
        <ModalVisitToTarget
          reservation={reservation}
          sessionUser={sessionUser}
          showRequestor={true}
          commonCodeMap={commonCodeMap}
        />
        <ModalVisitors reservation={reservation} sessionUser={sessionUser} commonCodeMap={commonCodeMap} />
        {reservation.approvalStatus === ApprovalStatusEnum.IP016002 && !isIntegrateApproval() ? (
          <div>
            <Container className="opinionContainer" data-testid="opinionContainer">
              <Row>
                <Col>
                  <span>{t('reservation.label.ReservationApproveModal.body.opinionTitle')}</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputGroup>
                    <FormControl
                      autoFocus={true}
                      id="approval-msg"
                      data-testid="approval-msg"
                      placeholder={t('reservation.label.ReservationApproveModal.body.opinionDefault')}
                      name="approval-msg"
                      maxLength={100}
                      value={approvalMsg ? approvalMsg : ''}
                      onChange={changeApprovalMsg}
                    />
                  </InputGroup>
                  <hr></hr>
                </Col>
              </Row>
            </Container>
            <ButtonToolbar>
              <Button
                name="reject"
                data-testid="reject-button"
                variant="dark"
                className="reject-button"
                onClick={(): Promise<void> => handleSubmit(ApprovalStatusEnum.IP016004)}
              >
                <span>{t('reservation.label.button.reject')}</span>
              </Button>
              <Button
                name="approve"
                data-testid="approve-button"
                variant="primary"
                className="approve-button"
                onClick={(): Promise<void> => handleSubmit(ApprovalStatusEnum.IP016003)}
              >
                <span>
                  <i className="material-icons pointer">check</i>
                  {t('reservation.label.button.approve')}
                </span>
              </Button>

              {successYesOrNo && successYesOrNo === 'N' && (
                <p
                  className="drawer__msg--alert"
                  dangerouslySetInnerHTML={{
                    __html: t('reservation.label.ReservationApproveModal.message.approveFailed', {
                      interpolation: { escapeValue: false },
                    }),
                  }}
                ></p>
              )}
            </ButtonToolbar>
          </div>
        ) : (
          <ButtonToolbar>
            <Button
              className="approveConformButton"
              name="ok"
              data-testid="ok-button"
              variant="primary"
              onClick={() => onHide(undefined)}
            >
              <span>{t('reservation.label.button.ok')}</span>
            </Button>
          </ButtonToolbar>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ReservationApproveModal;
