import React, { ReactElement } from 'react';
import ApprovalInfoContainer from './ApprovalInfoContainer';

export interface WorkplaceIdParam {
  match: {
    params: {
      workplaceId: string;
    };
  };
}

/* eslint-disable */
const ApprovalInfoPage = ({ match } : WorkplaceIdParam): ReactElement => {
  const workplaceId = match.params.workplaceId as string;

  return <ApprovalInfoContainer workplaceId={workplaceId} />;
};
/* eslint-disable */

export default ApprovalInfoPage;
