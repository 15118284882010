import { Form, Button } from 'react-bootstrap';
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import MemberRegistrationConstants, { CertificationTypeCode } from '../../model/MemberRegistrationConstants';
import CertificationRequest from '../../model/CertificationRequest';
import CertificationConfirmRequest from '../../model/CertificationConfirmRequest';
import MemberRegistrationService from '../../services/MemberRegistrationService';
import './PhoneCertification.scss';
import InputMask from 'react-input-mask';
import { AuthContext } from '../../../../App';
import { ActionType } from '../../../../reducer/actions';

export interface PhoneCertificationProps {
  phoneCertificationFormik?: any;
  receivedCertificationId: string;
  setCertificationId: (receivedCertificationId: string) => void;
  certificationConfirmStatus: boolean;
  setCertificationConfirmStatus: (certificationConfirmStatus: boolean) => void;
  certificationBusinessTypeCode: string;
  memberRegistrationService: MemberRegistrationService;
  titleVisible?: boolean;
  visitorInfo?: any;
  isKiosk?: boolean;
}

export const PhoneCertification: React.FC<PhoneCertificationProps> = ({
  phoneCertificationFormik,
  receivedCertificationId,
  setCertificationId,
  certificationConfirmStatus,
  setCertificationConfirmStatus,
  certificationBusinessTypeCode,
  memberRegistrationService,
  titleVisible,
  visitorInfo,
  isKiosk,
}: PhoneCertificationProps) => {
  const { t } = useTranslation();
  const { dispatch } = useContext(AuthContext);
  const getTranslationKey = (): string => {
    if (certificationBusinessTypeCode === 'CHANGE') {
      return 'memberInfoForm.phoneNumber.certificationComponent';
    } else {
      return 'memberRegistrationForm';
    }
  };
  const translationKey = getTranslationKey();

  const [phoneMask, setPhoneMask] = useState<string>('999-9999-9999');

  const [minutes, setMinutes] = useState(2);
  const [seconds, setSeconds] = useState(59);
  const [isActive, setIsActive] = useState(false);
  const componentDisplayNoneClassName: string = MemberRegistrationConstants.COMPONENT_DISPLAY_NONE_CLASS_NAME;
  const componentDisplayClassName: string = MemberRegistrationConstants.COMPONENT_DISPLAY_CLASS_NAME;
  const [certificationKeyformGroupClassName, setCertificationKeyformGroupClassName] = useState(
    componentDisplayNoneClassName
  );
  const [certificationKeyRequestButtonClassName, setCertificationKeyRequestButtonClassName] = useState(
    'authentication-number-button'
  );
  const [certificationKey, setCertificationKey] = useState('');
  const [maskPhoneNumber, setMaskPhoneNumber] = useState('');
  const [layout, setLayout] = useState<string>('default');
  const [focused, setFocused] = useState<string>('');
  const [_visitorInfo, setVisitorInfo] = useState<any>(visitorInfo);
  const setLoading = (_isLoading: boolean) => {
    dispatch({ type: ActionType.LOADING, loading: { isLoading: _isLoading } });
  };
  const timerToggle = (): void => {
    if (isActive) {
      setMinutes(2);
      setSeconds(59);
    }
    _visitorInfo && _visitorInfo.applyMemberMobilePhone ? setIsActive(true) : setIsActive(!isActive);
  };
  const certificationKeyReset = (): void => {
    setCertificationKeyformGroupClassName(componentDisplayNoneClassName);
    setCertificationKeyRequestButtonClassName(componentDisplayClassName);
    timerToggle();
    setCertificationId('');
    setCertificationConfirmStatus(false);
    setCertificationKey('');
  };
  /* eslint-disable */
  useEffect(
    /* istanbul ignore next */ () => {
      let interval: any = null;
      if (isActive) {
        interval = setInterval(() => {
          if (seconds === 0 && minutes > 0) {
            setSeconds(seconds => 59);
            setMinutes(minutes => minutes - 1);
          } else if (seconds === 0 && minutes === 0) {
            certificationKeyReset();
          } else {
            setSeconds(seconds => seconds - 1);
          }
        }, 1000);
      } else if (!isActive && seconds !== 0 && minutes !== 0) {
        clearInterval(interval);
      }
      return (): void => clearInterval(interval);
    },
    [isActive, minutes, seconds]
  );

  /* eslint-disable */
  useEffect(() => {
    if (certificationBusinessTypeCode === 'CHANGE' && receivedCertificationId === '') {
      certificationKeyReset();
    }
  }, [receivedCertificationId]);
  /* eslint-enable */

  const requestCertificationKey = async (phoneNumber: string): Promise<void> => {
    if (phoneCertificationFormik.handleCertification) {
      const isExists = await phoneCertificationFormik.handleCertification();
      if (!isExists) {
        return;
      }
    }
    setCertificationConfirmStatus(false);
    const phoneNumberWithoutSplitter = phoneNumber.replace(/-/g, '');
    const certificationRequest: CertificationRequest = {
      certificationTypeCode: CertificationTypeCode.SMS,
      certificationSendAddress: phoneNumberWithoutSplitter,
      certificationBusinessTypeCode: certificationBusinessTypeCode,
    };
    setLoading(true);
    const submitStatus = await memberRegistrationService.requestCertificationKey(certificationRequest);
    setLoading(false);
    if (submitStatus.successOrNot === 'Y') {
      setCertificationKeyformGroupClassName(componentDisplayClassName);
      setCertificationKeyRequestButtonClassName(componentDisplayNoneClassName);
      timerToggle();
      setCertificationId(submitStatus.data.certificationId);
    } else if (submitStatus.statusCode === MemberRegistrationConstants.UNKNOWN_SERVER_ERROR_CODE) {
      setCertificationId('');
      alert(t('member.message.backEnd.UE'));
    } else {
      setCertificationId('');
      alert(t('member.message.backEnd.FAIL.KEY.REQUEST'));
    }

    if (isKiosk && !_visitorInfo) {
      setVisitorInfo({
        applyMemberMobilePhone: phoneNumber,
      });
    }
  };
  const confirmCertificationKey = async () => {
    const phoneNumberWithoutSplitter = phoneCertificationFormik.values.phoneNumber.replace(/-/g, '');
    const certificationConfirmRequest: CertificationConfirmRequest = {
      certificationId: receivedCertificationId,
      certificationSendAddress: phoneNumberWithoutSplitter,
      certificationKey: phoneCertificationFormik.values.certificationKey,
    };
    setLoading(true);
    const submitStatus = await memberRegistrationService.confirmCertificationKey(certificationConfirmRequest);
    setLoading(false);
    if (submitStatus.successOrNot === 'Y') {
      timerToggle();
      setCertificationConfirmStatus(true);
      setCertificationKeyformGroupClassName(componentDisplayNoneClassName);
    } else if (submitStatus.statusCode === MemberRegistrationConstants.UNKNOWN_SERVER_ERROR_CODE) {
      setCertificationConfirmStatus(false);
      alert(t('member.message.backEnd.UE'));
    } else {
      setCertificationConfirmStatus(false);
      phoneCertificationFormik.setFieldError(
        'certificationKey',
        t('member.message.backEnd.' + submitStatus.statusCode)
      );
    }
  };

  const handleUserMobileMaskChange = (newState, oldState, userInput) => {
    const value: string = newState.value;
    const regexN = /[^0-9]/;

    if (value.length > 3) {
      if (!value.startsWith('010')) setPhoneMask('999-999-9999');
      else setPhoneMask('999-9999-9999');
    }
    if (userInput !== ' ' || regexN.test(userInput)) {
      return newState;
    } else {
      return oldState;
    }
  };

  const _phoneMask = value => {
    let phoneNumber: string = value;
    phoneNumber.length < 11
      ? (phoneNumber = phoneNumber
          .replace(phoneNumber, phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/gi, '$1***$3'))
          .replace(phoneNumber.substring(phoneNumber.length - 4, phoneNumber.length - 2), '**'))
      : (phoneNumber = phoneNumber
          .replace(phoneNumber, phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/gi, '$1-****-$3'))
          .replace(phoneNumber.substring(phoneNumber.length - 4, phoneNumber.length - 2), '**'));

    return phoneNumber;
  };

  const reRequestCertificationKey = (): void => {
    certificationKeyReset();
    requestCertificationKey(_visitorInfo.applyMemberMobilePhone);
  };

  const handleShift = () => {
    setLayout(layout == 'default' ? 'shift' : 'default');
  };

  const handlePress = (input: string) => {
    if (input === '{shift}' || input === '{lock}') {
      handleShift();
    } else if (input === '{bksp}') {
      setCertificationKey(certificationKey.slice(0, certificationKey.length - 1));
    } else {
      setCertificationKey(certificationKey + input);
    }
    const inputValue = document.getElementById('certificationKey') as HTMLInputElement;
    setCertificationKey(certificationKey + input);
    inputValue.focus();
  };

  /* eslint-disable */
  useEffect(() => {
    setVisitorInfo(visitorInfo);
    if(visitorInfo && visitorInfo.applyMemberMobilePhone) {
      phoneCertificationFormik.values.phoneNumber = visitorInfo.applyMemberMobilePhone;
      requestCertificationKey(visitorInfo.applyMemberMobilePhone);
    }
  }, [visitorInfo])

  useEffect(() => {
    _visitorInfo && phoneCertificationFormik.handleChange(certificationKey);
  },[certificationKey])

  return (
    <div id="PhoneCertification">
      <Form.Row>
        <Form.Group>
          {(titleVisible === undefined || titleVisible) && (
            <Form.Label>{t('member.label.' + translationKey + '.phoneNumber.label')}</Form.Label>
          )}
          {
            !_visitorInfo ?
            <InputMask
              mask={phoneMask}
              maskChar={null}
              beforeMaskedValueChange={handleUserMobileMaskChange}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                phoneCertificationFormik.handleChange(event);

                if (isActive) {
                  certificationKeyReset();
                }
              }}
              id="phoneNumber"
              name="phoneNumber"
              data-testid="phoneNumber"
              required
              type="tel"
              placeholder={isKiosk ? t('kiosk.main.mobilePhoneNumber') : t('member.label.' + translationKey + '.phoneNumber.placeholder')}
              onBlur={phoneCertificationFormik.handleBlur}
              disabled={certificationConfirmStatus}
              value={phoneCertificationFormik.values.phoneNumber}
              autoComplete="off"
              className={
                phoneCertificationFormik.errors.phoneNumber
                  ? 'phone-number-input form-control is-invalid'
                  : 'phone-number-input form-control'
              }
            />
            :
            <div>{_phoneMask((phoneCertificationFormik.values.phoneNumber).replace(/-/gi, ''))}<span>&nbsp;번호로 인증번호를 발송하였습니다.</span></div>
          }

          <div className="certification-area">
            {certificationConfirmStatus && (
              <i id="phoneNumberChecked" data-testid="phoneNumberChecked" className="material-icons">
                check
              </i>
            )}
            {(phoneCertificationFormik.errors.phoneNumber === undefined &&
              phoneCertificationFormik.values.phoneNumber.length > 0 &&
              !_visitorInfo &&
              (!phoneCertificationFormik.isCustomInVisible ||
                (phoneCertificationFormik.isCustomInVisible &&
                  phoneCertificationFormik.errors.memberName === undefined &&
                  phoneCertificationFormik.errors.emailAddress === undefined)) || (isKiosk && !_visitorInfo)) && (
                <Button
                  id="certificationKeyRequestButton"
                  name="certificationKeyRequestButton"
                  data-testid="certificationKeyRequestButton"
                  className={isKiosk ? 
                      !phoneCertificationFormik.errors.memberName && !phoneCertificationFormik.errors.phoneNumber && phoneCertificationFormik.values.phoneNumber.length > 0 ? 
                        "authentication-number-button" : "authentication-number-button invalid"
                      :
                      certificationKeyRequestButtonClassName
                  }
                  variant="outline-dark"
                  disabled={!phoneCertificationFormik.errors.memberName && !phoneCertificationFormik.errors.phoneNumber && phoneCertificationFormik.values.phoneNumber.length > 0 ? false : true}
                  onClick={(): Promise<void> => requestCertificationKey(phoneCertificationFormik.values.phoneNumber)}
                >
                  {t('member.label.' + translationKey + '.phoneNumber.button')}
                </Button>
              )}
          </div>
          <Form.Control.Feedback id="phoneNumberFeedback" data-testid="phoneNumberFeedback" type="invalid">
            {phoneCertificationFormik.errors.phoneNumber}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>{' '}
      <Form.Row id="certificationKeyRow">
        <Form.Group data-testid="certificationKeyformGroup" className={certificationKeyformGroupClassName}>
          <Form.Control
            id="certificationKey"
            name="certificationKey"
            data-testid="certificationKey"
            required
            type="tel"
            placeholder={isKiosk ? t('kiosk.main.certificationKey') : t('member.label.' + translationKey + '.certificationKey.placeholder')}
            value={certificationKey}
            onChange={(e: any) => {
              phoneCertificationFormik.handleChange(e);
              setCertificationKey(e.target.value);
            }}
            onBlur={phoneCertificationFormik.handleBlur}
            isInvalid={!!phoneCertificationFormik.errors.certificationKey}
            autoComplete="off"
            className="phone-number-input"
          ></Form.Control>
          <div className="certification-area">
            <span id="certificationTimer" data-testid="certificationTimer" className="certification-timer">
              {minutes}:{seconds.toString().padStart(2, '0')}
            </span>
            {((phoneCertificationFormik.errors.certificationKey === undefined &&
              phoneCertificationFormik.values.certificationKey.length > 0) || isKiosk) && (
                <Button
                  id="certificationCheckButton"
                  name="certificationCheckButton"
                  data-testid="certificationCheckButton"
                  disabled={!phoneCertificationFormik.errors.certificationKey && certificationKey ? false : true}
                  className={!phoneCertificationFormik.errors.certificationKey && certificationKey ? 
                    "authentication-number-button" :
                    "authentication-number-button invalid"
                  }
                  variant="outline-dark"
                  onClick={confirmCertificationKey}
                >
                  {t('member.label.' + translationKey + '.certificationKey.button')}
                </Button>
              )}
          </div>
          <Form.Control.Feedback id="certificationKeyFeedback" data-testid="certificationKeyFeedback" type="invalid">
            {phoneCertificationFormik.errors.certificationKey}
          </Form.Control.Feedback>
          {isKiosk &&
            <div id="reRequestButtonArea">
              <br/>
              <Button
                id="certificationReRequestButton"
                className={isKiosk && minutes >= 2 && seconds >= 50 ? "certificationReRequestButton invalid" : "certificationReRequestButton"}
                disabled={isKiosk && minutes >= 2 && seconds >= 50 ? true : false}
                onClick={!_visitorInfo ? (): void => certificationKeyReset() : (): void => reRequestCertificationKey()}
              >
                {t('kiosk.main.button.certificationReRequest')}
              </Button>
            </div>
          }
          {!isKiosk && 
            <span
              className="certificationReRequest"
              data-testid="certificationReRequest"
              onClick={!_visitorInfo ? (): void => certificationKeyReset() : (): void => reRequestCertificationKey()}
            >
              {t('member.label.' + translationKey + '.certificationKey.reRequest')}
            </span>
          }
        </Form.Group>
      </Form.Row>
    </div>
  );
};
export default PhoneCertification;