import React, { useState, useEffect, useContext } from 'react';
import './InvitationModal.scss';
import { Modal, Container, FormControl, Button, FormLabel, Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../../App';
import ReservationConstants from '../../../reservation/model/ReservationConstants';
import MemberRegistrationService from '../../services/MemberRegistrationService';
import InvitationModel from '../../model/InvitationMessageModel';
import AlertModal from '../../../../layouts/AlertModal';
import SubmitStatus from '../../model/SubmitStatus';
import { Formik, FormikErrors, FormikProps, FormikValues } from 'formik';
import { ActionType } from '../../../../reducer/actions';
import InputMask from 'react-input-mask';
import TextareaAutosize from 'react-textarea-autosize';
import * as yup from 'yup';
import emojiRegex from 'emoji-regex';

export interface InvitationModalI {
  onHide: () => void;
}

export const InvitationModal: React.FC<InvitationModalI> = (props: InvitationModalI) => {
  const memberRegistrationService = new MemberRegistrationService();
  const { t } = useTranslation();
  const { dispatch } = useContext(AuthContext);
  const [phoneMask, setPhoneMask] = useState<string>('999-9999-9999');
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const messageMaxLength = 900;
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>(t('member.label.InvitationModal.error'));
  const [invitationMessageInput, setInvitationMessageInput] = useState<string>('');

  const setLoading = (_isLoading: boolean) => {
    dispatch({ type: ActionType.LOADING, loading: { isLoading: _isLoading } });
  };

  const submitInvitation = async (formikErrors: FormikErrors<any>, formikValues: FormikValues) => {
    setIsSubmit(true);
    if (
      !Object.prototype.hasOwnProperty.call(formikErrors, 'inviteeName') &&
      !Object.prototype.hasOwnProperty.call(formikErrors, 'inviteePhoneNumber')
    ) {
      const inviteMessage: InvitationModel = {
        inviteeName: formikValues.inviteeName,
        inviteePhoneNumber: formikValues.inviteePhoneNumber,
        invitationMessage: invitationMessageInput,
      };

      setLoading(true);
      const response: SubmitStatus = await memberRegistrationService.invieteMember(inviteMessage);
      setLoading(false);

      if (response && response.statusCode === 'SUCCESS') {
        setAlertMessage(t('member.label.InvitationModal.success'));
        setShowAlert(true);
        //props.onHide();
      } else {
        setAlertMessage(t('member.label.InvitationModal.error'));
        setShowAlert(true);
      }
    }
  };

  const hideAlert = () => {
    setShowAlert(false);
    if (alertMessage === t('member.label.InvitationModal.success')) {
      props.onHide();
    }
  };

  const handleUserMobileMaskChange = (newState, oldState, userInput) => {
    const value: string = newState.value;
    const regexN = /[^0-9]/;

    if (value.length > 3) {
      if (!value.startsWith('010')) setPhoneMask('999-999-9999');
      else setPhoneMask('999-9999-9999');
    }
    if (userInput !== ' ' || regexN.test(userInput)) {
      return newState;
    } else {
      return oldState;
    }
  };

  const validationSchema = yup.object({
    inviteeName: yup
      .string()
      .required(t('member.message.frontEnd.memberName.required'))
      .min(2, t('member.message.frontEnd.memberName.common')),
    inviteePhoneNumber: yup.string().when('inviteeName', {
      is: inviteeName => inviteeName && inviteeName.length > 0,
      then: yup
        .string()
        .required(t('member.message.frontEnd.phoneNumber.required'))
        .test('phoneNumberValidation', t('member.message.frontEnd.phoneNumber.common'), function(value) {
          const result01X = ReservationConstants.REGEXPMOBILE_FOR_CONVERT_KO_01X.test(value);
          const result010 = ReservationConstants.REGEXPMOBILE_FOR_CONVERT_KO_010.test(value);
          if (!result01X && !result010) {
            return false;
          }
          return true;
        }),
    }),
    invitationMessage: yup.string().nullable(),
  });

  const handleChangeInvitationMessageInput = (event: React.ChangeEvent<any>) => {
    const regex = emojiRegex();
    setInvitationMessageInput(event.target.value.replace(regex, ''));
  };

  return (
    <Modal
      show={true}
      onHide={props.onHide}
      centered={true}
      className="invitation-modal"
      data-testid="invitation-modal-test"
      id="InvitationModal"
    >
      <Modal.Header closeButton>
        <div className="title bold">{t('member.label.InvitationModal.header.title')}</div>
        <div className="modal__header--info">{t('member.label.InvitationModal.header.titleSub')}</div>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Formik
            initialValues={{ inviteeName: '', inviteePhoneNumber: '', invitationMessage: '' }}
            validationSchema={validationSchema}
            onSubmit={values => {
              console.log(values);
            }}
          >
            {(props: FormikProps<any>) => (
              <Form>
                <div id="inviteeAddArea" className="state" data-testid="inviteeAddArea">
                  <div className="title d-inline-block">
                    <span className="formHeader">{t('member.label.InvitationModal.body.formHeader')}</span>
                  </div>
                  <div className="title bottom-line"></div>

                  <div className="inviteeAdd">
                    <InputGroup>
                      <div className="form-item">
                        <i></i>
                        <Form.Control
                          id="inviteeName"
                          data-id="inviteeName"
                          className="required d-inline-block"
                          type="text"
                          placeholder={t('member.label.InvitationModal.body.name')}
                          name="inviteeName"
                          value={props.values.inviteeName}
                          onChange={props.handleChange} // eslint-disable-line
                          maxLength={ReservationConstants.NAME_MAX_LENGTH}
                          isInvalid={props.errors.inviteeName ? true : false}
                        />
                      </div>
                      <div className="form-item">
                        <i></i>
                        <InputMask
                          mask={phoneMask}
                          formatChars={{
                            '9': '[0-9]',
                          }}
                          maskChar={null}
                          value={props.values.inviteePhoneNumber}
                          beforeMaskedValueChange={handleUserMobileMaskChange}
                          onChange={props.handleChange} // eslint-disable-line
                          id="inviteePhoneNumber"
                        >
                          {(inputProps: any) => {
                            return (
                              <Form.Control
                                id="inviteePhoneNumber"
                                name="inviteePhoneNumber"
                                data-testid="inviteePhoneNumber"
                                required
                                type="text"
                                placeholder={t('member.label.InvitationModal.body.phoneNumber')}
                                value={inputProps.phoneNumber}
                                className="phone-number-input"
                                isInvalid={props.errors.inviteePhoneNumber ? true : false}
                              />
                            );
                          }}
                        </InputMask>
                      </div>
                    </InputGroup>
                    {isSubmit && props.errors.inviteeName ? (
                      <div className="error-message">{props.errors.inviteeName}</div>
                    ) : null}
                    {isSubmit && !props.errors.inviteeName && props.errors.inviteePhoneNumber ? (
                      <div className="error-message">{props.errors.inviteePhoneNumber}</div>
                    ) : null}
                  </div>
                </div>
                <Form.Group>
                  <FormLabel>{t('member.label.InvitationModal.body.message')}</FormLabel>
                  <FormControl
                    as={TextareaAutosize}
                    id="invitationMessage"
                    rows={8}
                    value={invitationMessageInput}
                    onChange={handleChangeInvitationMessageInput} // eslint-disable-line
                  />
                </Form.Group>
                <div className={'span-group'}>
                  <span className={'byte-span'}>
                    {'(' + invitationMessageInput.length + '/' + messageMaxLength + ')'}
                  </span>
                  <span className={'byte-span'}>
                    {localStorage.getItem('lang') == 'en'
                      ? t('workplace.label.common.byte')
                      : t('workplace.label.common.words')}
                  </span>
                  <br />
                  <Button
                    disabled={props.isSubmitting}
                    onClick={() =>
                      props.validateForm().then(FormikErrors => submitInvitation(FormikErrors, props.values))
                    }
                  >
                    {t('member.label.InvitationModal.body.button')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Container>
      </Modal.Body>
      <AlertModal message={alertMessage} show={showAlert} onClose={hideAlert} />
    </Modal>
  );
};

export default InvitationModal;
