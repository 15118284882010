import { State } from './states';
import { Action, ActionType } from './actions';
import SessionService from '../service/SessionService';

const sessionService = new SessionService();

export const initialState: State = window.location.hostname.includes('singlex')
  ? { useTooltip: sessionService.getSessionStorageValue('trialGuideOn') == 'Y' ? true : false }
  : {};

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.PASS_INACTIVE_USER_INFO_TO_EMAIL_PAGE:
      return {
        ...state,
        inactiveUserInfo: action.inactiveUserInfo,
      };
    case ActionType.SHOW_TOAST:
      return {
        ...state,
        toastMessage: action.toastMessage,
      };
    case ActionType.SSO_LOGIN:
      return {
        ...state,
        ssoUserInfo: action.ssoUserInfo,
      };
    case ActionType.LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          ...action.loading,
        },
      };
    case ActionType.REFRESH_NAV_BAR:
      return {
        ...state,
        refreshNavBar: action.refreshNavBar,
      };
    case ActionType.RESET_KIOSK_TIME:
      return {
        ...state,
        kioskTime: action.kioskTime,
      };
    case ActionType.USE_TOOLTIP:
      return {
        ...state,
        useTooltip: action.useTooltip,
      };
    case ActionType.CLOSE_TOOLTIP:
      return {
        ...state,
        closeTooltip: action.closeTooltip,
      };
    case ActionType.RESET_ALL:
    default:
      return initialState;
  }
};
