import React from 'react';
import './RegistrationStatusPage.scss';
import { useTranslation } from 'react-i18next';
import StepInformation from '../../model/StepInformation';
import { UserTypeParamEnum } from '../../model/MemberRegistrationConstants';
export interface RegistrationStatusPageProps {
  stepParams: StepInformation;
}
export const RegistrationStatusPage: React.FC<RegistrationStatusPageProps> = (props: RegistrationStatusPageProps) => {
  const { t } = useTranslation();
  return (
    <div id="StatusPage">
      {props.stepParams.memberType === UserTypeParamEnum.NON_MEMBER ? (
        <div>
          <div className="status-step-line">
            <span className="circle-badge-icon">1</span>
            <span className="status">{t('member.label.statusPage.stepStatus.first')}</span>
          </div>
        </div>
      ) : (
        <div>
          <div className="status-step-line">
            <span className="circle-badge-icon">1</span>
            <span className="status">{t('member.label.statusPage.stepStatus.first')}</span>
          </div>
          <div className="status-step-line">
            <span className={props.stepParams.currentStep >= 2 ? 'circle-badge-icon' : 'circle-badge-icon-disable'}>
              2
            </span>
            <span className={props.stepParams.currentStep >= 2 ? 'status' : 'status-disable'}>
              {t('member.label.statusPage.stepStatus.second')}
            </span>
          </div>
          <div className="status-step-line">
            <span className={props.stepParams.currentStep >= 3 ? 'circle-badge-icon' : 'circle-badge-icon-disable'}>
              3
            </span>
            <span className={props.stepParams.currentStep >= 3 ? 'status' : 'status-disable'}>
              {t('member.label.statusPage.stepStatus.third')}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
export default RegistrationStatusPage;
