import React, { ReactElement } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { ReservationResponse, QueryType } from '../model/Reservation';
import { User } from '../model/User';

import { reservationDetailUtil } from '../utils/ReservationDetailUtil';
import './ModalVisitToTarget.scss';

import { ApprovalStatusEnum, DateFormat } from '../model/ReservationConstants';
import moment from 'moment';
import { WorkplaceContent } from '../model/Workplace';
import { CommonCode } from '../model/Code';
import CommonCodeService from '../services/CommonCodeService';
import copyIcon from '../../../icons/ic_copy.svg';
import editIcon from '../../../icons/ic_edit.svg';
export interface ModalVisitToTarget {
  reservation: ReservationResponse;
  sessionUser: User;
  showRequestor: boolean;
  commonCodeMap: Map<string, CommonCode[]> | undefined;
  onCopyReservation?: Function;
  rightSideInfoType?: string;
}

export const ModalVisitToTarget: React.FC<ModalVisitToTarget> = ({
  reservation,
  sessionUser,
  showRequestor,
  commonCodeMap,
  onCopyReservation,
  rightSideInfoType,
}: ModalVisitToTarget): ReactElement => {
  const { t } = useTranslation();
  const languageCode = localStorage.getItem('lang') ? (localStorage.getItem('lang') as string) : 'ko';

  /* eslint-disable */
  const getCompanyAndWorkplace = () => {
    let workplace;

    if (reservation && reservation.workplaceContent && reservation.workplaceContent.length > 0) {
      const workplaceContents: WorkplaceContent[] = JSON.parse(reservation.workplaceContent);
      workplace = workplaceContents.find(
        workplace => workplace.languageCode.toUpperCase() === languageCode.toUpperCase()
      );
    }

    if (!workplace) {
      workplace = {};
      workplace.groupCompanyName = reservation.hostEmployeeCompanyName;
      workplace.workplace = reservation.visitWorkplace;
    }

    return (
      <div>
        <span className="top-companyName-val">{workplace && workplace.groupCompanyName}</span>
        <span className="top-location-val">{workplace && workplace.workplace}</span>
      </div>
    );
  };

  const getStatusIcon = (): ReactElement => {
    let ret;
    switch (reservation.approvalStatus) {
      case ApprovalStatusEnum.IP016001:
        break;
      case ApprovalStatusEnum.IP016002:
        ret = 'more_horiz';
        break;
      case ApprovalStatusEnum.IP016003:
        ret = 'done';
        break;
      case ApprovalStatusEnum.IP016004:
        ret = 'warning';
        break;
      case ApprovalStatusEnum.IP016005:
        break;
    }
    return <i className="material-icons pointer">{ret}</i>;
  };

  const getStatusStyle = (): string => {
    let ret = '';
    switch (reservation.approvalStatus) {
      case ApprovalStatusEnum.IP016001:
        ret = 'top-status-val bold temp-save';
        break;
      case ApprovalStatusEnum.IP016002:
        ret = 'top-status-val bold';
        break;
      case ApprovalStatusEnum.IP016003:
        ret = 'top-status-val bold approve-done';
        break;
      case ApprovalStatusEnum.IP016004:
        ret = 'top-status-val bold reject';
        break;
      case ApprovalStatusEnum.IP016005:
        ret = 'top-status-val bold cancel';
        break;
    }
    return ret;
  };

  const getCodeName = (groupCodeId: string, codeId: string) => {
    let codeName = '';
    if (commonCodeMap) {
      const codeList = commonCodeMap.get(groupCodeId);
      if (codeList && codeList.length > 0) {
        const code = codeList.find(code => code.codeId === codeId);
        codeName = code ? code.codeName : '';
      }
    }
    return codeName;
  };

  return (
    <Container className="visitToTargetContainer" data-testid="visitToTargetContainer">
      <Row>
        <Col>
          <span className="modal-title">
            {t(reservationDetailUtil.getApproveModalTitle(reservation.approvalStatus as ApprovalStatusEnum))}
          </span>
        </Col>
      </Row>
      <Row>
        <Col></Col>
      </Row>
      <Row className="top-title-row">
        <Col>
          <span className="top-title title">{t('reservation.label.ModalVisitToTarget.body.top.title')}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <Container className="center table">
            <Row>
              <Col className="left-label">
                <span className="top-location">{t('reservation.label.ModalVisitToTarget.body.top.location')}</span>
              </Col>
              <Col>{getCompanyAndWorkplace()}</Col>
            </Row>
            <Row>
              <Col className="left-label">
                <span className="top-location">{t('reservation.label.ModalVisitToTarget.body.top.visitToPerson')}</span>
              </Col>
              <Col>
                <span className="top-visitTo-name bold">
                  {reservation && reservation.hostEmployeeName} {reservation && reservation.hostEmployeePosition}
                </span>
                <span className="top-slush"> / </span>
                <span className="top-visitTo-dept">
                  {reservation.hostEmployeeCompanyName} {reservation.hostEmployeeDepartment}
                </span>
              </Col>
            </Row>
            {showRequestor && (
              <Row>
                <Col className="left-label">
                  <span className="top-location">
                    {t('reservation.label.ModalVisitToTarget.body.top.requestPerson')}
                  </span>
                </Col>
                <Col>
                  <span className="top-request-name bold">
                    {reservation && reservation.applyMemberName} {reservation && reservation.applyMemberPosition}
                  </span>
                  <span className="top-slush">
                    {' '}
                    {reservation.applyMemberCompanyName && reservation.applyMemberCompanyName.length > 0 && ' / '}
                  </span>
                  <span className="top-request-dept">
                    {reservation.applyMemberCompanyName} {reservation.applyMemberDepartment}
                  </span>
                </Col>
              </Row>
            )}
            <Row>
              <Col className="left-label">
                <span className="top-purpose">{t('reservation.label.ModalVisitToTarget.body.top.purpose')}</span>
              </Col>
              <Col>
                <span className="top-purpose-val">
                  {reservation &&
                    reservation.visitPurposeCode &&
                    getCodeName(CommonCodeService.VISIT_PURPOSE_CODE, reservation.visitPurposeCode)}
                </span>
              </Col>
            </Row>
            <Row>
              <Col className="left-label">
                <span className="top-status">{t('reservation.label.ModalVisitToTarget.body.top.status')}</span>
              </Col>
              <Col>
                <span className={getStatusStyle()}>
                  {getStatusIcon()}
                  {t(
                    reservationDetailUtil.getStatusDesc(
                      reservation.approvalStatus as ApprovalStatusEnum,
                      reservation.workplaceInfo
                    )
                  )}
                </span>
                {(reservation.approvalStatus === ApprovalStatusEnum.IP016003 ||
                  reservation.approvalStatus === ApprovalStatusEnum.IP016004) && (
                  <span>{moment(reservation.lastUpdateDate).format(DateFormat.DATE_DISP)}</span>
                )}
              </Col>
            </Row>
            <Row>
              <Col className="left-label"></Col>
              <Col className="opinion-col">
                <span>{reservation.approvalMessage}</span>
              </Col>
            </Row>
          </Container>
          {reservation.approvalStatus !== ApprovalStatusEnum.IP016001 && rightSideInfoType === QueryType.REQUEST && (
            <Image
              src={copyIcon}
              id="copyIcon"
              data-testid="copyIcon"
              className="load copyIcon"
              onClick={(e: any) => {
                e.stopPropagation();
                e.preventDefault();
                onCopyReservation && onCopyReservation();
              }}
            />
          )}
          {reservation.approvalStatus === ApprovalStatusEnum.IP016001 && (
            <Image
              src={editIcon}
              id="editIcon"
              data-testid="editIcon"
              className="load editIcon"
              onClick={(e: any) => {
                e.stopPropagation();
                e.preventDefault();
                onCopyReservation && onCopyReservation();
              }}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ModalVisitToTarget;
