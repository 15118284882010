import React, { ReactElement, useContext, useState } from 'react';
import './SinglexHeader.scss';
import { Image } from 'react-bootstrap';
import download from '../icons/icon-download.png';
import condensed from '../icons/icon-condensed.png';
import { AuthContext } from '../App';
import { ActionType } from '../reducer/actions';
import SessionService from '../service/SessionService';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SinglexHeader: React.FC = (): ReactElement => {
  const sessionService = new SessionService();
  const endDay = localStorage.getItem('endDay') != null ? localStorage.getItem('endDay') : 0;
  const path = process.env.REACT_APP_S3_FILE_DOWNLOAD_URL || null;
  const singlexUrl = window.location.hostname.includes('visit-town')
    ? 'https://www.singlex.com/app/introinquiry?appId=visitportal&inquiryTypeCd=INTRO'
    : 'https://dev.singlex.com/app/introinquiry';
  const { state, dispatch } = useContext(AuthContext);
  /* eslint-disable */

  const toggleChange = () => {
    toast.dismiss();
    const toastMessage = state.useTooltip == false ? "가이드 투어 On 되었습니다." : "가이드 투어 Off 되었습니다."
    toast(toastMessage);
    sessionService.setSessionStorageValue('trialGuideOn', state.useTooltip == true ? 'N' : 'Y');
    dispatch({ type: ActionType.USE_TOOLTIP, useTooltip: !state.useTooltip });
    dispatch({ type: ActionType.CLOSE_TOOLTIP, closeTooltip: false });
  }
  /* eslint-enable */

  return (
    <div className="band-banner-wrap bg">
      <div className="inner">
        <ul className="menu">
          {/* <li className="menu-item">
            <div className="tour-toggle">
              <span className="txt">Tour</span>
              <div className="swich-toggle">
                  <input type="checkbox" name="ch-toggle" id="tour-check" checked={state.useTooltip} autoComplete="off" onChange={toggleChange}/>
                  <label htmlFor="tour-check"><span className="blind">Tour on/off</span></label>
              </div>
            </div>
          </li> */}
          <li className="menu-item">
            <span className="d-day">{`D-${endDay}`}</span>
            <span className="txt">무료체험 사용 중</span>
          </li>
          <li className="menu-item">
            <a href={singlexUrl} target="_blank" rel="noopener noreferrer" className="link-inquire">
              <span className="txt">
                도입문의
                <i className="icon">
                  <Image src={condensed} alt="" />
                </i>
              </span>
            </a>
          </li>
        </ul>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={2500}
        limit={1}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default SinglexHeader;
