import React, { ReactElement, useState } from 'react';
import { Form, Card, InputGroup, Image, DropdownButton, Dropdown } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { useTranslation } from 'react-i18next';
import plusIcon from '../../../icons/plus_icon-16px.svg';
import minusIcon from '../../../icons/minus_icon-16px.svg';
import { ImportedGoods } from '../model/ReservationTypes';
import { CommonCode } from '../model/Code';
import './ImportedGoodsForm.scss';
import TextareaAutosize from 'react-textarea-autosize';
export interface ImportedGoodsFormProps {
  importedGoods: ImportedGoods;
  onClose: Function;
  errors?: any;
  touched?: any;
  onImportedGoodsChange?: Function;
  importedGoodsPurposeCodeList?: CommonCode[];
}

export const ImportedGoodsForm: React.FC<ImportedGoodsFormProps> = (props: ImportedGoodsFormProps): ReactElement => {
  const { t } = useTranslation();
  const importedGoods = props.importedGoods;
  const [importedGoodsCounts, setImportedGoodsCounts] = useState<string>(importedGoods.importedGoodsCounts || '1');
  const [importedGoodsDescription, setImportedGoodsDescription] = useState<string>(
    importedGoods.importedGoodsDescription || ''
  );
  const [importedGoodsPurpose, setImportedGoodsPurpose] = useState<string>(importedGoods.importedGoodsPurpose);

  const onClose = (): void => {
    props.onClose(importedGoods.id);
  };

  const onMinusClick = (): void => {
    if (+importedGoodsCounts > 1) {
      setImportedGoodsCounts(+importedGoodsCounts - 1 + '');
      importedGoods.importedGoodsCounts = +importedGoodsCounts - 1 + '';
      props.onImportedGoodsChange && props.onImportedGoodsChange(importedGoods);
    }
  };
  const onPlusClick = (): void => {
    if (+importedGoodsCounts < 99999) {
      setImportedGoodsCounts(+importedGoodsCounts + 1 + '');
      importedGoods.importedGoodsCounts = +importedGoodsCounts + 1 + '';
      props.onImportedGoodsChange && props.onImportedGoodsChange(importedGoods);
    }
  };
  const onImportedGoodsCountsChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setImportedGoodsCounts(event.target.value);
    importedGoods.importedGoodsCounts = event.target.value;
    props.onImportedGoodsChange && props.onImportedGoodsChange(importedGoods);
  };

  const onImportedGoodsCountsBlur = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const goodsCount = event.target.value;
    if (goodsCount === '') {
      setImportedGoodsCounts('1');
      importedGoods.importedGoodsCounts = '1';
      props.onImportedGoodsChange && props.onImportedGoodsChange(importedGoods);
    }
  };
  const onImportedGoodsPurposeChange = (eventKey: any, event: Record<string, any>): void => {
    setImportedGoodsPurpose(eventKey);
    importedGoods.importedGoodsPurpose = eventKey;
    props.onImportedGoodsChange && props.onImportedGoodsChange(importedGoods);
  };
  const onImportedGoodsDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    let description = event.target.value;
    if (description.length > 150) {
      description = description.substring(0, 150);
    }
    setImportedGoodsDescription(description);
    importedGoods.importedGoodsDescription = description;
    props.onImportedGoodsChange && props.onImportedGoodsChange(importedGoods);
  };

  const getImportedGoodsPurposeName = (): string => {
    const goodsPurposeCode =
      props.importedGoodsPurposeCodeList &&
      props.importedGoodsPurposeCodeList.find(commonCode => commonCode.codeId === importedGoodsPurpose);
    return goodsPurposeCode ? goodsPurposeCode.codeName : t('reservation.label.ImportedGoodsForm.purpose.select');
  };

  return (
    <Card id="importedGoodsForm" data-testid="importedGoodsForm" className="importedGoodsForm">
      <Card.Header>
        {importedGoods.importedGoodsName}
        <i id="closeIcon" data-testid="itemCloseIcon" className="material-icons pointer" onClick={onClose}>
          close
        </i>
      </Card.Header>
      <Card.Body>
        <InputGroup className="d-inline-block">
          <InputGroup.Prepend className="d-inline-block">
            <InputGroup.Text>{t('reservation.label.ImportedGoodsForm.count.name')}</InputGroup.Text>
          </InputGroup.Prepend>
          <Image
            src={minusIcon}
            onClick={onMinusClick}
            id="minusIcon"
            data-testid="minusIcon"
            className="d-inline-block minusIcon"
          />
          <InputMask
            mask="09999"
            formatChars={{
              '0': '[1-9]',
              '9': '[0-9]',
            }}
            maskChar={null}
            value={importedGoodsCounts}
            onChange={onImportedGoodsCountsChange}
            onBlur={onImportedGoodsCountsBlur}
          >
            {(inputProps: any) => (
              <Form.Control
                id="importedGoodsCount"
                data-testid="importedGoodsCount"
                aria-label="importedGoodsCount"
                type="text"
                value={inputProps.importedGoodsCounts}
                autoComplete={'off'}
                className="d-inline-block importedGoodsCount"
              />
            )}
          </InputMask>
          <Image
            src={plusIcon}
            onClick={onPlusClick}
            id="plusIcon"
            data-testid="plusIcon"
            className="d-inline-block plusIcon"
          />
        </InputGroup>
        <InputGroup
          id="importedGoodsPurposeGroup"
          data-testid="importedGoodsPurposeGroup"
          className={
            props.errors && props.touched && props.errors.importedGoodsPurpose && props.touched.importedGoodsPurpose
              ? 'required error'
              : 'required'
          }
        >
          <InputGroup.Prepend>
            <InputGroup.Text>{t('reservation.label.ImportedGoodsForm.purpose.name')}</InputGroup.Text>
          </InputGroup.Prepend>
          <i className="required"></i>
          <DropdownButton
            id="importedGoodsPurpose"
            className="importedGoodsPurpose"
            title={getImportedGoodsPurposeName()}
          >
            {props.importedGoodsPurposeCodeList &&
              props.importedGoodsPurposeCodeList.map((commonCode: CommonCode, index) => (
                <Dropdown.Item
                  onSelect={onImportedGoodsPurposeChange}
                  className="importedGoodsPurpose"
                  key={commonCode.codeId}
                  eventKey={commonCode.codeId}
                  data-testid="importedGoodsPurposeItem"
                >
                  {commonCode.codeName}
                </Dropdown.Item>
              ))}
          </DropdownButton>
        </InputGroup>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>{t('reservation.label.ImportedGoodsForm.description.name')}</InputGroup.Text>
          </InputGroup.Prepend>
          <TextareaAutosize
            className="contentEdit"
            value={importedGoodsDescription}
            onChange={onImportedGoodsDescriptionChange}
            placeholder={t('reservation.label.ImportedGoodsForm.description.placeholder')}
            id="importedGoodsDescription"
            data-testid="importedGoodsDescription"
            maxRows={5}
            minRows={1}
          />
        </InputGroup>
      </Card.Body>
    </Card>
  );
};

export default ImportedGoodsForm;
