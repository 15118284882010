import BaseService from '../../../service/BaseService';
import { Service } from '../../../service/model/Service';

import { VisitTo, Visitor, SearchUser } from '../model/ReservationTypes';
import { User } from '../model/User';
import { Log } from '../model/Log';
import {
  MemberSearchResponse,
  SearchVisitToInfo,
  SearchVisitToModel,
  CheckMemberModel,
  CheckMemberResponse,
} from '../model/Member';
import { MemberTypeCode, UserTypeEnum } from '../model/ReservationConstants';
import { Workplace, PolicyCompleteSummary } from '../model/Workplace';
import InterfaceService from './InterfaceService';
import WorkplaceService from './WorkplaceService';
import { useTranslation } from 'react-i18next';

export default class MemberService extends BaseService {
  async searchEmployee(searchInfo: SearchVisitToInfo): Promise<any> {
    const serviceName = Service.MEMBER;
    const method = 'POST';
    const url = '/v1/employees/search';
    const body = this.createSearchRequestBody(searchInfo);

    let response;
    try {
      response = await this.fnRest(method, url, body, serviceName);
    } catch (error) {
      response = error;
    }
    return response.data && response.data.length > 0 ? this.createSearchResultResponse(response.data) : null;
  }

  async getSearchUsers(visitors: Visitor[], condUser: SearchUser): Promise<User[]> {
    const serviceName = Service.MEMBER;
    const method = 'POST';
    const url = '/v1/members/search';
    const body = {
      memberName: condUser.name,
      phoneNumber: condUser.mobile?.replace(/-/g, ''),
    };
    let response;
    try {
      response = await this.fnRest(method, url, body, serviceName);
    } catch (error) {
      response = error;
    }
    return response.data && response.data.length > 0 ? this.createSearchUsersResponse(visitors, response.data) : [];
  }

  async getMemberInfo(): Promise<User> {
    const serviceName = Service.MEMBER;
    const method = 'POST';
    const url = '/v1/account/detailview';
    const body = {};

    let response: any = null;
    try {
      response = await this.fnRest(method, url, body, serviceName);
    } catch (error) {
      response = error;
    }
    return response.data && Object.keys(response.data).length > 0
      ? this.createMemberInfoResponse(response.data)
      : ({} as User);
  }

  async getUserInfoBySessionId(): Promise<MemberSearchResponse> {
    const serviceName = Service.MEMBER;
    const method = 'GET';
    const url = '/v1/account/detailview';

    let response: any = null;
    try {
      response = await this.fnRest(method, url, null, serviceName);
    } catch (error) {
      response = error;
    }
    return response && response.successOrNot === 'Y' && response.data
      ? (response.data as MemberSearchResponse)
      : ({} as MemberSearchResponse);
  }

  async checkEmployee(memberIds: number[]): Promise<CheckMemberResponse[] | null> {
    const serviceName = Service.MEMBER;
    const method = 'POST';
    const url = '/v1/employees/check';
    const body = { languageCode: this.languageCode, memberIds: memberIds };

    let response: any = null;
    try {
      response = await this.fnRest(method, url, body, serviceName);
    } catch (error) {
      response = error;
    }
    return response && response.successOrNot === 'Y' && response.data ? (response.data as CheckMemberResponse[]) : null;
  }

  createSearchRequestBody = (searchInfo: SearchVisitToInfo): SearchVisitToInfo => {
    if (searchInfo.emailAddress) {
      return {
        companyCode: searchInfo.companyCode,
        emailAddress: searchInfo.emailAddress,
        visitToPartnerYesorno: searchInfo.visitToPartnerYesorno,
        visitToTemporaryYesorno: searchInfo.visitToTemporaryYesorno,
      } as SearchVisitToInfo;
    } else {
      return {
        companyCode: searchInfo.companyCode,
        employeeName: searchInfo.employeeName,
        phoneNumber: searchInfo.phoneNumber,
        visitToPartnerYesorno: searchInfo.visitToPartnerYesorno,
        visitToTemporaryYesorno: searchInfo.visitToTemporaryYesorno,
      } as SearchVisitToInfo;
    }
  };

  createSearchResultResponse = (searchInfo: SearchVisitToModel[]): VisitTo[] => {
    return searchInfo.map(searchInfo => {
      return ({
        id: searchInfo.memberId.toString(),
        name: searchInfo.employeeName,
        employeeNumber: searchInfo.employeeNumber,
        companyId: searchInfo.companyCode,
        companyName: searchInfo.companyName,
        dept: searchInfo.departmentName,
        position: searchInfo.positionName,
        type: UserTypeEnum.LGMEMBER,
        emailAddress: searchInfo.emailAddress,
        phoneNumber: searchInfo.phoneNumber,
      } as unknown) as VisitTo;
    });
  };

  createSearchUsersResponse = (visitors: Visitor[], members: MemberSearchResponse[]): User[] => {
    let result: User[] = members.map(member => {
      return ({
        id: member.memberId.toString(),
        name: member.memberName,
        mobile: member.phoneNumber,
        empType: member.positionName,
        companyName: member.companyName,
        dept: member.departmentName,
        type: member.memberTypeCode === MemberTypeCode.EMP ? UserTypeEnum.LGMEMBER : UserTypeEnum.MEMBER,
        memberTypeCode: member.memberTypeCode,
        termsAgreeYn: member.thirdTermsAgreeYn,
        idpUserId: member.idpUserId,
        companyCode: member.companyCode,
        employeeNumber: member.employeeNumber,
        workingFormCode: member.workingFormCode,
        emailAddress: member.emailAddress,
      } as unknown) as User;
    });

    if (visitors) {
      result = result.filter((user: User, i) => {
        return visitors.findIndex((visitor: Visitor) => user.id === visitor.id) === -1;
      });
    }

    return result;
  };
  createMemberInfoResponse = (member: MemberSearchResponse): User => {
    return {
      id: member.memberId + '',
      type: member.memberTypeCode === MemberTypeCode.EMP ? UserTypeEnum.LGMEMBER : UserTypeEnum.MEMBER,
      memberTypeCode: member.memberTypeCode,
      name: member.memberName,
      companyCode: member.companyCode,
      companyName: member.companyName || '',
      dept: member.departmentName || '',
      position: member.positionName || '',
      mobile: member.phoneNumber,
      email: member.emailAddress,
      termsAgreeYn: member.termsAgreeYn,
      idpUserId: member.idpUserId,
      employeeNumber: member.employeeNumber,
      workingFormCode: member.workingFormCode,
      mainWorkplaceId: member.mainWorkplaceId,
      emailAddress: member.emailAddress,
      notebookSerialNo: member.notebookSerialNo,
      termsSaveDatetime: member.termsSaveDatetime,
      delegateMemberId: member.delegateMemberId,
      delegateMemberName: member.delegateMemberName,
      delegateMemberPositionName: member.delegateMemberPositionName,
      delegateDepartmentName: member.delegateDepartmentName,
    };
  };

  isValidMember = async (aUser: User, workplace: Workplace, sessionUserInfo, isTmpSave = false): Promise<User> => {
    const interfaceService = new InterfaceService();
    const workplaceService = new WorkplaceService();

    aUser.isValid = true;
    if (aUser.type === UserTypeEnum.NONEMEMBER) {
      return aUser;
    }
    // 제3자 동의 여부 체크 (임직원 & 비회원 = 동의체크 스킵, 회원 = 동의체크)
    if (isTmpSave) {
      aUser.isValid = true;
    }
    if (aUser.type !== UserTypeEnum.MEMBER || (aUser.type === UserTypeEnum.MEMBER && aUser.termsAgreeYn === 'Y')) {
      if (workplace && workplace.workplaceId && aUser.type !== UserTypeEnum.NONEMEMBER) {
        let idpUserId = null;
        if (aUser.type === UserTypeEnum.LGMEMBER) {
          // I/F 연동모듈 호출 (companyCode, workplaceId, employeeNumber, workingFormCode)
          idpUserId = await interfaceService.getIdpUserId(
            aUser.companyCode!,
            workplace.workplaceId,
            aUser.name,
            aUser.employeeNumber!,
            aUser.workingFormCode!
          );

          if (idpUserId) {
            // idp 사용자ID 추가
            aUser.idpUserId = idpUserId;
          } else if (workplace.idpYesorno === 'Y') {
            aUser.isValid = false;
            aUser.invalidMessage = 'reservation.label.RequirementInvalidModal.message.noIdpInfo';
            // '출입권한이 없습니다. 비회원으로 등록하시길 바랍니다.';
            return aUser;
          }
        }

        const userStatus: PolicyCompleteSummary | null = await workplaceService.getPolicyCompleteSummary(
          workplace.workplaceId,
          aUser.id,
          aUser.type
        );

        if (
          userStatus &&
          (userStatus.safeEducationComplete !== 'Y' ||
            userStatus.securityEducationComplete !== 'Y' ||
            userStatus.safeAgreementComplete !== 'Y' ||
            userStatus.securityAgreementComplete !== 'Y')
        ) {
          aUser.isValid = false;
          if (
            userStatus &&
            (userStatus.safeEducationComplete === 'N' ||
              userStatus.securityEducationComplete === 'N' ||
              userStatus.safeAgreementComplete === 'N' ||
              userStatus.securityAgreementComplete === 'N')
          ) {
            aUser.invalidMessage = 'reservation.label.RequirementInvalidModal.message.default';
            // "본 회원은 교육 또는 서약이 완료되지 않아 동행자로 등록할 수 없습니다."
          } else {
            aUser.invalidMessage = 'reservation.label.RequirementInvalidModal.message.expired';
            // "본 회원은 교육 또는 서약의 유효기간이 만료되어 동행자로 등록할 수 없습니다."
          }
        }
      }
    } else if (sessionUserInfo.id !== aUser.id) {
      aUser.isValid = false;
      aUser.invalidMessage = 'reservation.label.RequirementInvalidModal.message.noThirdTermsAgree';
      // '제3자 정보제공동의가 되지 않았습니다.';
    }

    return aUser;
  };

  async saveLog(log: Log): Promise<any> {
    const serviceName = Service.MEMBER;
    const method = 'POST';
    const url = '/v1/log/privacy';
    let response;
    try {
      response = await this.fnRest(method, url, log, serviceName);
    } catch (error) {
      response = error;
    }

    return response && response.successOrNot === 'Y' ? response : null;
  }
}
