import SubmitStatus from '../model/SubmitStatus';
import LoginRequest from '../model/LoginRequest';
import { Service } from '../../../service/model/Service';
import BaseService from '../../../service/BaseService';

export default class LoginService {
  public baseService: BaseService = new BaseService();

  async requestLogin(loginRequest: LoginRequest): Promise<SubmitStatus> {
    let submitStatus: SubmitStatus = {
      successOrNot: 'N',
      statusCode: '',
    };

    const method = 'POST';
    const url = '/v1/account/session';
    const serviceName = Service.MEMBER;
    const body = JSON.stringify(loginRequest);

    try {
      submitStatus = await this.baseService.fnRest(method, url, body, serviceName);
    } catch (error) {
      submitStatus = error;
    }

    return submitStatus;
  }

  async requestLogout(): Promise<SubmitStatus> {
    let submitStatus: SubmitStatus = {
      successOrNot: 'N',
      statusCode: '',
    };

    const method = 'DELETE';
    const url = '/v1/account/session';
    const serviceName = Service.MEMBER;

    try {
      submitStatus = await this.baseService.fnRest(method, url, null, serviceName);
    } catch (error) {
      submitStatus = error;
    }

    return submitStatus;
  }

  async requestTrialLogin(loginRequest: any): Promise<SubmitStatus> {
    let submitStatus: SubmitStatus = {
      successOrNot: 'N',
      statusCode: '',
    };

    const method = 'POST';
    const url = '/v1/account/session/trial';
    const serviceName = Service.MEMBER;
    const body = JSON.stringify(loginRequest);

    try {
      submitStatus = await this.baseService.fnRest(method, url, body, serviceName);
    } catch (error) {
      submitStatus = error;
    }

    return submitStatus;
  }
}
